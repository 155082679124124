import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core'
import { Router, } from '@angular/router';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { DataService } from "../service/data.service"
import { ApiService } from "../service/api.service"
import { AuthSessionParamsService } from '../service/auth-session-params.service'
import { Meta, Title } from '@angular/platform-browser';
// For Search Debounce
import { of } from "rxjs";
import {
  debounceTime,
  map,
  distinctUntilChanged,
  filter
} from "rxjs/operators";
import { fromEvent } from 'rxjs';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styles: []
})
export class HeaderComponent implements OnInit {
  isUserDropdownActive: boolean = false
  isSearchOverlayActive: boolean = false
  isMobileMenuActive: boolean = false
  isUserLoggedIn: boolean = false
  productCategories: any;
  @Output() emitCategories = new EventEmitter<any>();

  wishlists: number[]
  searchInvitesGroup: FormGroup
  isSearchLoading: boolean = false;
  searchTerm: string
  @ViewChild('searchInput') searchInput: ElementRef
  searchProducts: any[] = []

  isHeaderNavigationDropdownActive: boolean = false
  isMenuHovered: boolean = false

  activeMenuCategory: number = 0
  menuParents: any = []
  menuItems: any = []
  parentCategory: any = []
  wishlistCount: any = 0

  pageUrl: string = ""
  hasProfilePicture: boolean = false
  profile_picture: string = "assets/img/user_60.png"

  /* Hide menu using props */
  getMobileMenuHideEvent: boolean;
  @Input()
  get ashu(): boolean { return this.ashu }
  set ashu(a: boolean) {
    this.getMobileMenuHideEvent = a
  }
  constructor(private title: Title, private meta: Meta, private dataService: DataService, private authSession: AuthSessionParamsService, private router: Router, private apiService: ApiService) {
    this.dataService.getSessionMessage.subscribe(
      message => {
        this.isUserLoggedIn = message
      }
    );
  }

  ngOnInit() {
    let _page_url: any = this.router.url
    _page_url = _page_url.split('/');

    if (_page_url[2] != undefined) {
      if (_page_url[2] != 'handler' && _page_url[2] != 'reset') {
        localStorage.setItem('url', this.router.url)
      }
    }

    _page_url = _page_url[1];
    if (_page_url == "vcards" || _page_url == "vcard") {
      this.pageUrl = "V-cards"
    } else if (_page_url == "ecards" || _page_url == "ecard") {
      this.pageUrl = "E-cards"
    } else if (_page_url == "occasions") {
      this.pageUrl = "Occasions"
    } else if (_page_url == "help") {
      this.pageUrl = "Help"
    } else if (_page_url == 'reset') {
      localStorage.removeItem('url');
    }

    this.searchInvitesGroup = new FormGroup(
      {
        term: new FormControl('', [Validators.required]),
      }
    )

    this.apiService.getCategories().subscribe(res => {
      this.menuParents = res.parents;
      this.parentCategory = this.menuParents[0]
      this.menuItems = res.items;
      this.dataService.setCategories(this.productCategories);
    })

    this.isUserLoggedIn = this.authSession.isUserParamsAuthenticated();
    if (this.isUserLoggedIn) {

      let user: any = localStorage.getItem('user');
      if (user != null) {
        user = JSON.parse(user);
        if (user.profile_picture != undefined && user.profile_picture != "") {
          this.hasProfilePicture = true
          this.profile_picture = user.profile_picture
        } else {
          this.profile_picture = 'assets/img/preview-image-placeholder.jpg';
        }
      }


      this.apiService.getWishlistCount().subscribe(res => {
        if (res.response == "success") {
          this.wishlistCount = res.data
        } else {
          this.wishlistCount = 0
        }
      })
    }
    // Search 
    /*
    fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
      // get value
      map((event: any) => {
        return event.target.value;
      })
      , filter(res => {
        if (res.length > 2) {
          return true
        } else {
          return false
        }
      })
      , debounceTime(1000)
      , distinctUntilChanged()
    ).subscribe((q: string) => {
      this.searchTerm = q
      this.isSearchLoading = true
      this._searchInvites(q);
    });
  */
  }

  _updateMeta(d) {
    if (d.parent_cat_id != "0") {
      if (d.meta_title) {
        this.title.setTitle(d.meta_title);
      }
      if (d.meta_desc) {
        this.meta.updateTag({ name: 'description', content: d.meta_desc });
      }
      if (d.meta_keywords) {
        this.meta.updateTag({ name: 'keywords', content: d.meta_keywords });
      }
    }
  }


  _searchSubmit() {
    if (this.searchInvitesGroup.valid) {
      let data = this.searchInvitesGroup.value
      this.router.navigate([`/search/${data.term}`])
    } else {
      console.log('Not Valid')
    }
  }

  _searchInvites(q: string) {
    this.apiService.getSearchResult({ 'term': q }).subscribe(res => {
      if (res.response == 'success') {
        this.searchProducts = res.data
      } else {
        this.searchProducts = []
      }
      this.isSearchLoading = false
    })
  }

  _search_mobile() {
    this.isSearchOverlayActive = true;
  }

  _closeMobileSearch() {
    this.isSearchOverlayActive = false
  }

  _mobile_menu() {
    if (!this.isMobileMenuActive) {
      this.isMobileMenuActive = true
    } else {
      this.isMobileMenuActive = false
    }
  }

  _openUserDropdown() {
    this.isUserDropdownActive ? this.isUserDropdownActive = false : this.isUserDropdownActive = true;
  }

  _redirect(category) {
    this.dataService.setCategory(category.id);
    this.router.navigate([`/products/${category.cat_url}`]);
  }

  _logout() {
    if (this.isUserLoggedIn) {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      this.dataService.sessionMessage(false);
      this.router.navigate([`/login`]);
    }
  }

  _navigationDropdown(mode: string, category: number) {
    if (mode == 'over') {
      this.activeMenuCategory = category
      this.isHeaderNavigationDropdownActive = true
    } else if (mode == 'out') {
      this.activeMenuCategory = 0
      this.isHeaderNavigationDropdownActive = false
    }
  }
}
