<div class="_main_container _header_shadow">
  <app-header></app-header>
</div>

<div class="_main_container" *ngIf="isProductLoading">
  <p class="_loading">
    <span class="loader"></span>
  </p>
</div>

<div class="_main_container" *ngIf="!isProductLoading">
  <div class="_preview_content">
    <div class="_prev_heading">Preview</div>

    <ng-container *ngIf="slides?.length > 0">
      <div class="_inner_preview" *ngFor="let slide of slides; let i = index" [ngClass]="{'_border' : i != 0}">
        <div class="_under_inner_area" [ngClass]="orientation">
          <div class="_first_preview">
            <div class="_count" [innerHTML]="i+1"></div>
            <div class="_card">
              <img [src]="productMediaPath + slide.image_url" alt="">
            </div>
          </div>

          <ng-container *ngIf="slide.fields?.length > 0">
            <div class="_middle_fields">
              <ng-container *ngFor="let field of slide.fields">
                <div class="_second_preview" *ngIf="field.type == 'textarea' && field.text">
                  <h2 class="_heading">Your Information</h2>
                  <div class="_field_area">
                    <div class="_preview_container" [innerHTML]="field.text"> </div>
                  </div>
                </div>

                <div class="_second_preview" *ngIf="field.type == 'text' && field.text">
                  <h2 class="_heading">Your Information</h2>
                  <div class="_field_area">
                    <div class="_preview_container" [innerHTML]="field.text"> </div>
                  </div>
                </div>

                <div class="_third_preview" *ngIf="field.type == 'file' && field.file">
                  <div class="_field_area">
                    <img class="_image" [src]="field.file" />
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-container>

        </div>
      </div>
    </ng-container>


    <div class="_button_area">
      <div class="_checkout">
        <button class="_action" (click)="_goToCustomize()"> <i class="fa fa-edit"></i> Continue Edit</button>
      </div>
      <div class="_checkout">
        <button class="_action" (click)="_goToRsvp()">Submit</button>
      </div>
    </div>
  </div>
</div>

<div class=" _clearfix"></div>
<app-footer></app-footer>