import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms"
import { Router } from "@angular/router"
import { AuthService } from "angularx-social-login";
import {
  GoogleLoginProvider,
  FacebookLoginProvider
} from "angularx-social-login";
import { ApiService } from "../service/api.service"
import { DataService } from '../service/data.service';
import { NotificationService } from "../service/notification.service";

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styles: []
})
export class SignupComponent implements OnInit {
  signupFormGroup: FormGroup
  constructor(private authService: AuthService, private apiService: ApiService, private dataService: DataService, private router: Router, private nfs: NotificationService) { }

  ngOnInit() {
    this.signupFormInit();
  }

  signupFormInit() {
    this.signupFormGroup = new FormGroup(
      {
        name: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.required, Validators.email]),
        mobile: new FormControl('', [Validators.required, Validators.pattern(/^[6-9]\d{9}$/)]),
        password: new FormControl('', [Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/)])
      }
    )
  }

  createAccount() {
    if (this.signupFormGroup.valid) {
      let postParams = { ...this.signupFormGroup.value, "provider": "WEB" }
      this.apiService.register(postParams).subscribe(response => {
        if (response.response == 'success') {
          this.router.navigate(['/login']);
          this.nfs.showNotification("default", 'Check your email');
        } else if (response.response == 'exist') {
          this.router.navigate(['/login']);
          this.nfs.showNotification("default", 'User Already Exist');
        }
      })
    } else {
      this.validateAllFormFields(this.signupFormGroup)
    }
  }

  /* Social Fb/Google Actions */
  async registerGoogle() {
    await this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then(res => {
      this.apiService.register(res).subscribe(response => {
        if (response.response == 'success') {
          localStorage.setItem('token', response.token);
          localStorage.setItem('user', JSON.stringify(response.data));
          this.dataService.sessionMessage(true);
          this.nfs.showNotification("default", 'Redirecting..');
          this.router.navigate(['/']);
        } else if (response.response == 'exist') {
          this.nfs.showNotification("default", 'User Already Exist');
        }
      })
    }).catch(err => console.log(err));
  }

  async registerFacebook() {
    await this.authService
      .signIn(FacebookLoginProvider.PROVIDER_ID)
      .then(res => {
        this.apiService.register(res).subscribe(response => {
          if (response.response == 'success') {
            localStorage.setItem('token', response.token);
            localStorage.setItem('user', JSON.stringify(response.data));
            this.dataService.sessionMessage(true);
            this.nfs.showNotification("default", 'Redirecting..');
            this.router.navigate(['/']);
          } else if (response.response == 'exist') {
            this.nfs.showNotification("default", 'User Already Exist');
          }
        })
      })
      .catch(err => console.log('err', err));
  }
  /* Social Fb/Google Actions */

  /* Form Validations and Errors */
  formControlValid(field: string) {
    return !this.signupFormGroup.get(field).valid && this.signupFormGroup.get(field).touched;
  }

  formControlClass(field: string) {
    if (!this.formControlValid(field)) {
      return { '_noerr': true }
    } else {
      return { '_err': true }
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
  /* Form Validations and Errors */



}
