import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'
import { NgForm } from "@angular/forms"
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ApiService } from "../service/api.service"
import { NotificationService } from "../service/notification.service";
import { environment } from 'src/environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector';

interface ProductSlides {
  created_date: string,
  id: number
  image_status: string
  image_url: string,
  is_featured: number
  ordering: number
  prod_id: number
  slide_fields: any
}

interface SlideData {
  title: string,
  text: string,
  file: string,
  media: string
}

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styles: []
})
export class DetailComponent implements OnInit {
  isProductLoading: boolean = true
  wordCount: number = 150;
  slidesCount: number = 0
  slides: Array<{ id: string, src: string, big_src: string, alt: string, title: string, fields: any, prod_id: number }>
  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    margin: 0,
    navSpeed: 700,
    navText: ['<img src="assets/img/left-arrow.png" />', '<img src="assets/img/right-arrow.png" />'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 6
      }
    },
    nav: false
  }
  productUrl: string;
  productSlidesCount: number = 0
  productSlides: Array<ProductSlides>
  productMediaPath: string = ''
  orientation: string
  orientationClass: string = ""
  isTempVerticalLayout: boolean = true
  activeSlideIndex: number
  activeSlide: string = '3'
  activeSlideSrc: string = '_detail_active_slide.jpg'
  activeSlideFields: any = []
  submitButtonText: string = 'Save draft'
  isSubmitButtonActive: boolean = false
  tempSlides: any = []
  activeProductId: number;
  isNextButtonActive: boolean = true

  // Form Model
  title: string = ''
  text: string = ''
  media: string = ''
  slideImage: string = ''
  selectedFile: File
  uploaded_file: string = ''
  @ViewChild('slideImageRef') slideImageRef;
  uploadMessage: string = "Upload picture"
  isDesktop: boolean = true;
  constructor(private route: ActivatedRoute, private router: Router, private apiService: ApiService, private nfs: NotificationService, private deviceService: DeviceDetectorService) {
    this.productMediaPath = environment.PROD_BASEPATH
    this.isDesktop = this.deviceService.isDesktop();
  }

  ngOnInit() {
    this.activeSlideIndex = 0
    this.route.params.subscribe(params => {
      this.productUrl = params.product_url
      this.apiService.getCustomizedSlides(params).subscribe(data => {
        if (data.response == 'success') {
          setTimeout(() => {
            this.isProductLoading = false
          }, 500)
          this.productSlidesCount = data.data.length
          if (this.productSlidesCount > 0) {
            this.productSlides = data.data
            this.orientationClass = data.orientation
            if (data.orientation == 'square' || data.orientation == 'vertical') {
              this.orientation = 'vertical'
              this.customOptions.margin = 30
            } else {
              this.orientation = data.orientation
            }

            let finalArray = []
            for (let product of this.productSlides) {
              let fieldArray = []
              let fields = JSON.parse(product.slide_fields);
              for (let f of fields) {
                fieldArray.push({ 'type': f.field_type, 'name': f.field_name, 'id': f.field_id, 'placeholder': f.field_placeholder })
              }
              let p = {
                'id': product.id,
                'src': product.image_url,
                'big_src': '',
                'alt': "",
                'title': "",
                'fields': fieldArray,
                'prod_id': product.prod_id
              }
              finalArray.push(p)
            }
            this.slides = finalArray
            this.slidesCount = this.slides.length
            if (this.slidesCount == 1) {
              this.isNextButtonActive = false
              this.isSubmitButtonActive = true
              this.submitButtonText = "Submit and preview"
            }
            this.activeProductId = Number(this.slides[0].prod_id)
            this.activeSlideSrc = this.slides[0].src
            this.activeSlide = this.slides[0].id
            this.activeSlideFields = this.slides[0].fields
            // console.log(this.activeSlideFields);
            this._setSlides();
          }
        }
        // Get Save Slides Data 
        this._getLocalSlidesData(this.activeSlide);
      })
    })
  }

  _getLocalSlidesData(slideId) {
    let order: any = localStorage.getItem('order');
    if (order != null) {
      order = JSON.parse(order);
      if (this.activeProductId == order.prod) {
        this.apiService.getSlidesDataByOrderno(order).subscribe(data => {
          if (data.response == 'success') {
            if (data.data) {
              let savedSlides = JSON.parse(data.data);
              if (savedSlides.length > 0) {
                this.tempSlides = savedSlides
                this._setValueInInputs(slideId);
              }
            }
          }
        })
      }
    }
  }

  _setSlides() {
    let windowWidth = window.innerWidth
    if (this.orientation == 'horizontal') {
      if (windowWidth < 500) {
        this.isTempVerticalLayout = false
      }
    }
  }


  _prevSlide() {
    if (this.activeSlideIndex > 0) {
      this.activeSlideIndex = this.activeSlideIndex - 1
      let slide = this.slides[this.activeSlideIndex]
      this.activeSlideFields = slide.fields
      this.activeSlide = slide.id
      this.activeSlideSrc = slide.src;

      if ((this.activeSlideIndex + 1) == this.productSlidesCount) {
        this.isNextButtonActive = false
        this.isSubmitButtonActive = true
        this.submitButtonText = "Submit and preview"
      } else {
        this.isSubmitButtonActive = false
        this.submitButtonText = "Save draft"
        this.isNextButtonActive = true
      }
      // Put Data into Slides
      this._setValueInInputs(this.activeSlide);
    }
  }

  _nextSlide(f?: NgForm) {
    if (this.activeSlideIndex < this.productSlidesCount) {
      // Save Slide Data On Next
      var slide_fields_save = [];
      var formElemInstance: any = document.getElementById(`_slidesForm_${this.activeSlide}`);
      formElemInstance = formElemInstance.elements
      // Iterate over the form controls
      for (let i = 0; i < formElemInstance.length; i++) {
        let field_type = formElemInstance[i].type
        let field_input_tag_id = formElemInstance[i].id
        let field_value = formElemInstance[i].value
        if (field_type == "text" || field_type == "textarea") {
          slide_fields_save.push({ 'type': field_type, 'text': field_value, 'tag_id': field_input_tag_id })
        }
        if (field_type == "file") {
          slide_fields_save.push({ 'type': 'file', 'file': this.uploaded_file, 'tag_id': field_input_tag_id })
        }
      }
      let slideFields = slide_fields_save
      let finalSlideData = { 'slide_id': this.activeSlide, 'slide_index': this.activeSlideIndex, 'slides': slideFields }
      // Check if slide exists
      let checkExistIndex = this.tempSlides.map((s) => {
        return s.slide_id
      }).indexOf(this.activeSlide)
      if (checkExistIndex < 0) {
        this.tempSlides.push(finalSlideData)
      } else {
        this.tempSlides[checkExistIndex] = finalSlideData
      }


      if (slideFields.length < 1) {
        this.nfs.showNotification("default", 'You are saving slide with empty data.');
        return false;
      } else {
        let slide = this.productSlides[this.activeSlideIndex];
        let prod_id = slide.prod_id
        let order: any = localStorage.getItem('order');
        var order_number = '';
        if (order != null) {
          order = JSON.parse(order);
          if (this.activeProductId == order.prod) {
            if (Object.keys(order).length > 0) {
              order_number = order.order_no
            }
          } else {
            localStorage.removeItem('order');
          }
        }

        if (this.tempSlides.length > 0 && slideFields.length > 0) {
          this.apiService.postCustomizedSlides({ prod_id: Number(prod_id), order_number: order_number, item_detail: this.tempSlides, 'order_type': "vcard" }).subscribe(data => {
            if (data.response == 'success') {
              this.slideImage = ''
              let orderNumber = data.data.order_number
              if (orderNumber) {
                let storeOrder = { 'order_no': orderNumber, 'prod': this.activeProductId }
                localStorage.setItem('order', JSON.stringify(storeOrder));
              }

              let nextIndex = this.activeSlideIndex + 1
              if (nextIndex < this.productSlidesCount) {
                this.activeSlideIndex = nextIndex
                let slide = this.slides[this.activeSlideIndex]
                this.activeSlideFields = slide.fields
                this.activeSlide = slide.id
                this.activeSlideSrc = slide.src;

                // Put Data into Slides
                this._setValueInInputs(this.activeSlide);
                if ((this.activeSlideIndex + 1) == this.productSlidesCount) {
                  this.isSubmitButtonActive = true
                  this.submitButtonText = "Submit and preview"
                  this.isNextButtonActive = false
                } else {
                  this.isSubmitButtonActive = false
                  this.submitButtonText = "Save draft"
                  this.isNextButtonActive = true
                }
              }
              // this.nfs.showNotification("default", "Slide Saved.");
            }
          })
        }
      }
    }
  }



  gotoSlide(mode: string) {
    if (mode == 'previous' || mode == 'next') {
      if (mode == 'previous') {
        this._prevSlide()
      } else if (mode == 'next') {
        this._nextSlide()
      }
    }
  }




  _saveSlides(f: NgForm) {
    this.submitButtonText = "Submitting..."

    // Save Slide Data On Next
    var slide_fields_save = [];
    var formElemInstance: any = document.getElementById(`_slidesForm_${this.activeSlide}`);
    formElemInstance = formElemInstance.elements
    // Iterate over the form controls
    for (let i = 0; i < formElemInstance.length; i++) {
      let field_type = formElemInstance[i].type
      let field_input_tag_id = formElemInstance[i].id
      let field_value = formElemInstance[i].value
      if (field_type == "text" || field_type == "textarea") {
        slide_fields_save.push({ 'type': field_type, 'text': field_value, 'tag_id': field_input_tag_id })
      }
      if (field_type == "file") {
        slide_fields_save.push({ 'type': 'file', 'file': this.uploaded_file, 'tag_id': field_input_tag_id })
      }
    }
    let slideFields = slide_fields_save
    let finalSlideData = { 'slide_id': this.activeSlide, 'slide_index': this.activeSlideIndex, 'slides': slideFields }
    // Check if slide exists
    let checkExistIndex = this.tempSlides.map((s) => {
      return s.slide_id
    }).indexOf(this.activeSlide)
    if (checkExistIndex < 0) {
      this.tempSlides.push(finalSlideData)
    } else {
      this.tempSlides[checkExistIndex] = finalSlideData
    }


    if (slideFields.length < 1) {
      this.nfs.showNotification("default", 'You are saving slide with empty fields.');
      return false;
    }

    let slide = this.productSlides[this.activeSlideIndex];
    let prod_id = slide.prod_id
    let order: any = localStorage.getItem('order');
    var order_number = '';
    if (order != null) {
      order = JSON.parse(order);
      if (this.activeProductId == order.prod) {
        if (Object.keys(order).length > 0) {
          order_number = order.order_no
        }
      } else {
        localStorage.removeItem('order');
      }
    }

    if (this.tempSlides.length > 0 && slideFields.length > 0) {
      this.apiService.postCustomizedSlides({ prod_id: Number(prod_id), order_number: order_number, item_detail: this.tempSlides, 'order_type': "vcard" }).subscribe(data => {
        if (data.response == 'success') {
          this.slideImage = ''
          let orderNumber = data.data.order_number
          if (orderNumber) {
            let storeOrder = { 'order_no': orderNumber, 'prod': this.activeProductId }
            localStorage.setItem('order', JSON.stringify(storeOrder));
          }
          if (this.isSubmitButtonActive) {
            this.router.navigate([`/preview/${this.productUrl}`]);
          }
          // Move to next Slide
          if (this.activeSlideIndex < this.productSlidesCount) {
            this._nextSlide();
          }
          this.nfs.showNotification("default", "Slide Saved.");
        } else {
          this.submitButtonText = "Submit and preview";
        }
      })
    }

  }

  _selectSlideImage(event, field_id) {
    this.uploadMessage = "Uploading..."
    this.selectedFile = event.target.files[0];
    const uploadData = new FormData();
    uploadData.append("image", this.selectedFile, this.selectedFile.name);
    this.apiService.postSlideImage(uploadData).subscribe(res => {
      this.slideImageRef.nativeElement.value = "";
      if (res.response == "success") {
        this.slideImage = res.data.image
        this.media = this.slideImage
        this.uploaded_file = this.slideImage
        this.nfs.showNotification("default", "Uploaded");
      } else if (res.response == "failed") {
        this.nfs.showNotification("default", res.message);
      }
      setTimeout(() => {
        this.uploadMessage = "Upload picture";
      }, 1000);
    });
  }

  _removeUploadedSlideImage() {
    this.media = ''
    this.slideImage = ''
    // Blank ngmodel input hidden value
    this.uploaded_file = ''
  }


  slideAction(slideId: string, slide: any, i: number) {
    this.activeSlideIndex = i
    this.activeSlideFields = slide.fields
    this.activeSlide = slideId
    this.activeSlideSrc = slide.src;

    if ((i + 1) == this.productSlidesCount) {
      this.isNextButtonActive = false
      this.isSubmitButtonActive = true
      this.submitButtonText = "Submit and preview"
    } else {
      this.isSubmitButtonActive = false
      this.submitButtonText = "Save draft"
      this.isNextButtonActive = true
    }
    // Put Data into Slides
    this._setValueInInputs(slideId);
  }


  _setValueInInputs(slideId) {
    if (this.tempSlides.length > 0) {
      let checkSlideIndex = this.tempSlides.map((s) => {
        return s.slide_id
      }).indexOf(slideId)
      if (checkSlideIndex > -1 && checkSlideIndex != undefined) {
        let slide = this.tempSlides[checkSlideIndex];
        let slides = slide.slides
        if (slides.length > 0) {
          this.media = ''
          this.slideImage = ''
          setTimeout(() => {
            for (let s of slides) {
              let getInputElemInstance: any = document.getElementById(`${s.tag_id}`);
              if (s.type == 'file') {
                this.media = s.file
                this.slideImage = s.file
                this.uploaded_file = s.file
                let getInputFileHiddenElemInstance: any = document.getElementById(`${s.tag_id}_hidden`);
                getInputFileHiddenElemInstance.value = s.file
              } else {
                getInputElemInstance.value = s.text
              }
            }
          }, 1000);
        }
      } else {
        this.media = ''
        this.uploaded_file = ''
      }
    } else {
      this.media = ''
      this.uploaded_file = ''
    }
  }

  word_count(event) {
    let max = 150
    let thisEl = event.target.value
    let wordCount = event.target.value.length;
    this.wordCount = max - wordCount
    if (event.which < 0x20) {
      // e.which < 0x20, then it's not a printable character
      // e.which === 0 - Not a character
      return;     // Do nothing
    }
    if (wordCount == max) {
      event.preventDefault();
    } else if (wordCount > max) {
      // Maximum exceeded
      this.text = thisEl.substring(0, max);
    }
  }
}
