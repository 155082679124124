<div class="_main_container _header_shadow" wmAnimate="fadeIn" speed="fast" aos>
  <app-header></app-header>
</div>
<div class="_bespoken">
  <!--banner section-->
  <div class="_banner_section" wmAnimate="landing" speed="fast" aos once>
    <div class="_inner_section">
      <img src="assets/img/bespoken/banner.jpg" class="_desktop">
      <img src="assets/img/bespoken/mobile/Cutout_Lekarde_Bespoke_Banner[Mobile].png" class="_mobile">
    </div>
  </div>
  <!--banner section-->

  <!--first section-->
  <div class="_first_section" wmAnimate="fadeInDown" speed="fast" aos once>
    <div class="_inner_section">
      <p>Want your card to be just for you? Don’t want to share your design with anyone.
        Get in touch with one of our representatives.</p>
      <p>Contact us now </p>
      <p>Timings, Monday - Saturday, 11am - 6pm </p>
      <ul>
        <li>
          <a href="mailto:Orders@lekarde.com">
            <img src="assets/img/bespoken/email.png">
            Orders@lekarde.com
          </a>
        </li>
        <li>
          <a href="tel:9810186266">
            <img src="assets/img/bespoken/phone.png">
            9810186266
          </a>
        </li>
        <li>
          <a href="javascript:void(0);">
            <img src="assets/img/bespoken/whatsapp.png">
            9810186266
          </a>
        </li>
      </ul>
      <!-- <div class="_form_area _mobile">
        <form action="/">
          <div class="_filed">
            <input type="text" placeholder="Drop your email id">
            <span><img src="email.png"></span>
          </div>
          <div class="_filed">
            <input type="text" placeholder="Your phone number">
            <span><img src="phone.png"></span>
          </div>
          <div class="_btn">
            <a href="javascript:void(0);">Submit</a>
          </div>
        </form>
      </div> -->
    </div>
  </div>
  <!--first section-->

  <!--second section-->
  <div class="_second_section">
    <h2>Our Customization Process</h2>
    <div class="_inner_section">
      <img src="assets/img/bespoken/bannersecond.jpg" class="_desktop">
      <div class="_max_container" wmAnimate="fadeIn" aos>
        <h2>Brainstorming</h2>
        <p>Creativity resides in every person. Brainstorming as a team is where we begin every card designed at
          Le Karde. Collective minds work better than one.
          This is where the idea, vision of the card is created. </p>
      </div>
      <img src="assets/img/bespoken/mobile/Cutout_Lekarde_Bespoke_Brainstorming[Mobile].png" class="_mobile _brain">
    </div>
  </div>
  <!--second section-->

  <!--third section-->
  <div class="_third_section">
    <div class="_inner_section">
      <img src="assets/img/bespoken/thirdbanner.jpg" class="_desktop">
      <div class="_max_container" wmAnimate="fadeIn" aos>
        <h2>Ground Work</h2>
        <p>Planning is the key to create beautiful cards. We understand that inadequate planning leads to poor
          work. It is here the ideas are put together tomake it a reality. This part
          involved us making decisions on how the
          project will be executed. </p>
      </div>
      <img src="assets/img/bespoken/mobile/Cutout_Lekarde_Bespoke_Groundwork[Mobile].png" class="_mobile _brain">
    </div>
  </div>
  <!--third section-->

  <!--third section-->
  <div class="_fourth_section">
    <div class="_inner_section">
      <img src="assets/img/bespoken/fourthbanner.jpg" class="_desktop">
      <div class="_max_container" wmAnimate="fadeIn" aos>
        <h2>Piecing it together</h2>
        <p>Artists draw, Animators animate. We let our creative
          specialists do what they do best. It is here where
          the card comes to life. </p>
      </div>
      <img src="assets/img/bespoken/mobile/Cutout_Lekarde_Bespoke_PieceItTogether[Mobile].png" class="_mobile _brain">
    </div>
  </div>
  <!--third section-->

  <!--third section-->
  <div class="_fifth_section">
    <div class="_inner_section">
      <img src="assets/img/bespoken/fifthbanner.jpg" class="_desktop">
      <div class="_max_container" wmAnimate="fadeIn" aos>
        <h2>Icing on the cake</h2>
        <p>There is no better satisfaction for an artist to see absolute perfection in the art he/she has
          created. Having a beautiful card with an
          understanding of detailing is where we put a lot of emphasis.
          After all we are working towards creating a
          master piece for you.</p>
      </div>
      <img src="assets/img/bespoken/mobile/Cutout_Lekarde_Bespoke_IcingOnTheCake[Mobile].png" class="_mobile"
        style="margin-left: -25px;">
    </div>
  </div>
  <!--third section-->
</div>

<app-footer></app-footer>