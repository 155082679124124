<footer class="_footer">
  <div class="_inner">
    <div class="_links">
      <ng-container *ngIf="facebook">
        <a href="{{facebook}}" target="_blank"><i class="fa fa-facebook"></i></a>
      </ng-container>
      <ng-container *ngIf="instagram">
        <a href="{{instagram}}" target="_blank"><i class="fa fa-instagram"></i></a>
      </ng-container>
      <ng-container *ngIf="youtube">
        <a routerLink="{{youtube}}" target="_blank"><i class="fa fa-youtube-play"></i></a>
      </ng-container>
      <ng-container *ngIf="twitter">
        <a routerLink="{{twitter}}" target="_blank"><i class="fa fa-twitter"></i></a>
      </ng-container>

    </div>
    <div class="_clearfix"></div>
  </div>
  <p class="_copyright"><a href="assets/TERMS OF SERVICE Lekarde.pdf" target="_blank">Terms of service</a> | <a
      href="assets/Dreamtek India Pvt Ltd Privacy Policy.pdf" target="_blank">Privacy Policy</a>
  </p>
  <p class="_copyright">&copy; {{currentYear}} www.lekarde.com. All rights reserved</p>
</footer>