/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../safe-style.pipe";
import * as i3 from "@angular/platform-browser";
import * as i4 from "../header/header.component.ngfactory";
import * as i5 from "../header/header.component";
import * as i6 from "../service/data.service";
import * as i7 from "../service/auth-session-params.service";
import * as i8 from "@angular/router";
import * as i9 from "../service/api.service";
import * as i10 from "../footer/footer.component.ngfactory";
import * as i11 from "../footer/footer.component";
import * as i12 from "./ecard-handler.component";
import * as i13 from "../service/notification.service";
import * as i14 from "ngx-device-detector";
var styles_EcardHandlerComponent = [];
var RenderType_EcardHandlerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EcardHandlerComponent, data: {} });
export { RenderType_EcardHandlerComponent as RenderType_EcardHandlerComponent };
function View_EcardHandlerComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "_main_container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "p", [["class", "_loading"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "span", [["class", "loader"]], null, null, null, null, null))], null, null); }
function View_EcardHandlerComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "_form_details _font_color_default"]], [[8, "style", 2]], null, null, null, null)), i0.ɵppd(2, 1), (_l()(), i0.ɵeld(3, 0, null, null, 0, "p", [["class", "_content_editable"]], [[4, "color", null], [8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent.parent.parent.parent, 0), _v.parent.context.$implicit.styles)); _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.color; var currVal_2 = _v.parent.context.$implicit.defaultValue; _ck(_v, 3, 0, currVal_1, currVal_2); }); }
function View_EcardHandlerComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "_form_details"]], [[8, "style", 2]], null, null, null, null)), i0.ɵppd(2, 1), (_l()(), i0.ɵeld(3, 0, null, null, 0, "p", [["class", "_content_editable"]], [[4, "color", null], [4, "fontSize", null], [8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent.parent.parent.parent, 0), _v.parent.context.$implicit.styles)); _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.color; var currVal_2 = (_v.parent.context.$implicit.fontSize + "px"); var currVal_3 = _v.parent.context.$implicit.value; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3); }); }
function View_EcardHandlerComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "_form_details _media_preview"]], [[8, "style", 2]], null, null, null, null)), i0.ɵppd(2, 1), (_l()(), i0.ɵeld(3, 0, null, null, 1, "div", [["class", "_show_image"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, "img", [["class", "_image"]], [[8, "id", 0], [8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent.parent.parent.parent, 0), _v.parent.context.$implicit.styles)); _ck(_v, 1, 0, currVal_0); var currVal_1 = i0.ɵinlineInterpolate(1, "_image_", _v.parent.context.$implicit.id, ""); var currVal_2 = _v.parent.context.$implicit.value; _ck(_v, 4, 0, currVal_1, currVal_2); }); }
function View_EcardHandlerComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EcardHandlerComponent_5)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EcardHandlerComponent_6)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EcardHandlerComponent_7)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (_v.context.$implicit.type == "text"); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.context.$implicit.type == "input"); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_v.context.$implicit.type == "image"); _ck(_v, 6, 0, currVal_2); }, null); }
function View_EcardHandlerComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EcardHandlerComponent_4)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.previewCardData; _ck(_v, 2, 0, currVal_0); }, null); }
function View_EcardHandlerComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "div", [["class", "_card_grid"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 4, "div", [["class", "_card"], ["id", "_card"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "img", [["class", "_card_img"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 2, "div", [["class", "_input_filed"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EcardHandlerComponent_3)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (((_co.previewCardData == null) ? null : _co.previewCardData.length) > 0); _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.productMediaPath + "/upload/cards/") + _co.cardBackgroundTemplate); _ck(_v, 3, 0, currVal_0); }); }
function View_EcardHandlerComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "_download_ecard"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "a", [["download", ""], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Download"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.generatedCardFinalUrl; _ck(_v, 1, 0, currVal_0); }); }
function View_EcardHandlerComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 9, "div", [["class", "_status"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 8, "div", [["class", "_order_status"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["Order No : ", ""])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "span", [["class", "_badge"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Hey! Your order has been placed successfully"])), (_l()(), i0.ɵeld(7, 0, null, null, 1, "span", [["class", "_badge"], ["style", "margin-top: 5px; font-weight: bold;"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Click below to download"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EcardHandlerComponent_9)), i0.ɵdid(10, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.isDownloadActive; _ck(_v, 10, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.order_no; _ck(_v, 4, 0, currVal_0); }); }
export function View_EcardHandlerComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i2.SafeStylePipe, [i3.DomSanitizer]), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "_main_container _header_shadow"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "app-header", [], null, null, null, i4.View_HeaderComponent_0, i4.RenderType_HeaderComponent)), i0.ɵdid(3, 114688, null, 0, i5.HeaderComponent, [i3.Title, i3.Meta, i6.DataService, i7.AuthSessionParamsService, i8.Router, i9.ApiService], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EcardHandlerComponent_1)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 4, "div", [["class", "_ecard_handler"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EcardHandlerComponent_2)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EcardHandlerComponent_8)), i0.ɵdid(10, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(11, 0, null, null, 0, "div", [["class", "_clearfix"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 1, "app-footer", [], null, null, null, i10.View_FooterComponent_0, i10.RenderType_FooterComponent)), i0.ɵdid(13, 114688, null, 0, i11.FooterComponent, [i9.ApiService], null, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 3, 0); var currVal_0 = _co.isProductsLoading; _ck(_v, 5, 0, currVal_0); var currVal_1 = ((((_co.previewCardData == null) ? null : _co.previewCardData.length) > 0) && !_co.isProductsLoading); _ck(_v, 8, 0, currVal_1); var currVal_2 = ((((_co.previewCardData == null) ? null : _co.previewCardData.length) > 0) && !_co.isProductsLoading); _ck(_v, 10, 0, currVal_2); _ck(_v, 13, 0); }, null); }
export function View_EcardHandlerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-ecard-handler", [], null, null, null, View_EcardHandlerComponent_0, RenderType_EcardHandlerComponent)), i0.ɵdid(1, 114688, null, 0, i12.EcardHandlerComponent, [i9.ApiService, i13.NotificationService, i8.ActivatedRoute, i8.Router, i14.DeviceDetectorService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EcardHandlerComponentNgFactory = i0.ɵccf("app-ecard-handler", i12.EcardHandlerComponent, View_EcardHandlerComponent_Host_0, {}, {}, []);
export { EcardHandlerComponentNgFactory as EcardHandlerComponentNgFactory };
