import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from "../service/api.service";
import { NotificationService } from "../service/notification.service";
import { environment } from 'src/environments/environment';
// import domtoimage from 'dom-to-image';
import domtoimage from 'retina-dom-to-image';
import { DeviceDetectorService } from 'ngx-device-detector';
var EcardHandlerComponent = /** @class */ (function () {
    function EcardHandlerComponent(apiService, nfs, route, router, deviceService) {
        this.apiService = apiService;
        this.nfs = nfs;
        this.route = route;
        this.router = router;
        this.deviceService = deviceService;
        this.productMediaPath = '';
        this.order_no = "";
        this.cardBackgroundTemplate = "";
        this.isProductsLoading = true;
        this.generatedCardFinalUrl = "";
        this.isDownloadActive = false;
        this.productMediaPath = environment.PROD_BASEPATH;
    }
    EcardHandlerComponent.prototype.ngOnInit = function () {
        var _this = this;
        var deviceInfo = this.deviceService.getDeviceInfo();
        this.os = deviceInfo;
        this.route.params.subscribe(function (params) {
            if (params.order_no != '') {
                _this.order_no = params.order_no;
                _this.apiService.postEcardHandler({ 'order_number': _this.order_no }).subscribe(function (res) {
                    if (res.response == "success") {
                        _this.isProductsLoading = false;
                        var cardData = res.data;
                        if (cardData != "") {
                            _this.previewCardData = JSON.parse(res.data.card_data);
                            _this.cardBackgroundTemplate = res.data.card_template;
                            _this.nfs.showNotification("default", "Generating your E-CARD.");
                            setTimeout(function () {
                                _this._generateImage();
                            }, 2000);
                        }
                    }
                    else if (res.response == "redirect") {
                        _this.router.navigate(['dashboard/orders']);
                    }
                    else if (res.response == "login") {
                        _this.router.navigate(['login']);
                    }
                });
            }
        });
    };
    EcardHandlerComponent.prototype._generateImage = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var node, blob, uploadData;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        node = document.getElementById('_card');
                        return [4 /*yield*/, domtoimage.toBlob(node)];
                    case 1:
                        blob = _a.sent();
                        uploadData = new FormData();
                        uploadData.append('image', blob);
                        uploadData.append('order_number', this.order_no);
                        uploadData.append('os', JSON.stringify(this.os));
                        this.apiService.postEcardPreview(uploadData).subscribe(function (res) {
                            if (res.response == "success") {
                                var url = res.data.url;
                                if (url) {
                                    _this.generatedCardFinalUrl = "" + environment.API_URL + url;
                                    _this.isDownloadActive = true;
                                }
                            }
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    EcardHandlerComponent.prototype._downloadImage = function (image_url) {
        var link = document.createElement('a');
        link.download = "P_" + this.order_no + ".jpeg";
        link.href = "http://lekarde.com/authority/" + image_url;
        link.click();
    };
    return EcardHandlerComponent;
}());
export { EcardHandlerComponent };
