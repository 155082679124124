<div class="_main_container _header_shadow">
  <app-header></app-header>
</div>

<div class="_main_container" *ngIf="isProductLoading">
  <p class="_loading">
    <span class="loader"></span>
  </p>
</div>

<ng-container *ngIf="productOrientation == 'vertical' || productOrientation == 'square'">
  <div class="_main_container _vp_product_bg">
    <div class="_product_vertical">
      <div class="_left">
        <div class="_card" [ngClass]="{'_video_preview' : !isvideoActive}">
          <iframe *ngIf="isvideoActive"
            [src]="'https://player.vimeo.com/video/' + product.video_url + '?api=1&autoplay=1&loop=1&title=0&byline=0&sidedock=0' | safeUrl"
            style="width:100%; height:494px;" frameborder="0" id="videoCardRef" webkitallowfullscreen mozallowfullscreen
            allowfullscreen></iframe>
          <img *ngIf="!isvideoActive" [src]="productMediaPath + product.image_url" [alt]="product.product_name" lazy>
          <div class="_click_video" *ngIf="!isvideoActive" (click)="_openVideo()">
            Click to watch video
          </div>
        </div>
      </div>
      <div class="_right">
        <div class="_inner">
          <h2 class="_heading" [innerHTML]="product.product_name"></h2>
          <span class="_price" [innerHTML]="product.short_description"></span>
          <p class="_inr_price">


            <span class="_live" *ngIf="product.spl_price != product.price">
              <i class="fa fa-inr" aria-hidden="true"></i> {{product.spl_price}}
            </span>
            <span
              [ngClass]="{'_original' : product.spl_price != product.price, '_live' : product.spl_price == product.price}"
              *ngIf="product.price">
              <i class="fa fa-inr" aria-hidden="true"></i> {{product.price}}
            </span>

          </p>
          <p class="_inr_para" [innerHTML]="product.description"></p>
        </div>
        <div class="_checkout">
          <button class="_action" (click)="_redirectToCustomize(product.product_url_name)">Customize</button>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="productOrientation == 'horizontal'">
  <div class="_main_container _hp_product_bg">
    <div class="_product_horizontal">
      <div class="_top_section">
        <h1 class="_heading" [innerHTML]="product.product_name"></h1>
        <!-- <div class="_card"><img [src]="productMediaPath + product.image_url" [alt]="product.product_name"></div> -->

        <div class="_card" [ngClass]="{'_video_preview' : !isvideoActive}">
          <iframe *ngIf="isvideoActive"
            [src]="'https://player.vimeo.com/video/' + product.video_url + '?api=1&autoplay=1&loop=1&title=0&byline=0&sidedock=0' | safeUrl"
            style="width:100%; height:494px;" frameborder="0" id="videoCardRef" webkitallowfullscreen mozallowfullscreen
            allowfullscreen></iframe>
          <img *ngIf="!isvideoActive" [src]="productMediaPath + product.image_url" [alt]="product.product_name">
          <div class="_click_video" *ngIf="!isvideoActive" (click)="_openVideo()">
            Click to watch video
          </div>
        </div>

      </div>
      <div class="_bottom_section">
        <div class="_left">
          <div class="_inner">
            <span class="_price" [innerHTML]="product.short_description"></span>
            <p class="_inr_price">
              <!-- <span class="_live">
                <i class="fa fa-inr" aria-hidden="true"></i> {{product.spl_price}}
              </span>
              <span class="_original">
                <i class="fa fa-inr" aria-hidden="true"></i> {{product.price}}
              </span> -->

              <span class="_live" *ngIf="product.spl_price != product.price">
                <i class="fa fa-inr" aria-hidden="true"></i> {{product.spl_price}}
              </span>
              <span
                [ngClass]="{'_original' : product.spl_price != product.price, '_live' : product.spl_price == product.price}"
                *ngIf="product.price">
                <i class="fa fa-inr" aria-hidden="true"></i> {{product.price}}
              </span>

            </p>
          </div>
        </div>
        <div class="_right">
          <p [innerHTML]="product.description"></p>
          <div class="_checkout"><button class="_action"
              (click)="_redirectToCustomize(product.product_url_name)">Customize</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<div class="_clearfix"></div>
<app-footer></app-footer>