<div class="_main_container _header_shadow">
  <app-header></app-header>
</div>

<div class="_main_container" *ngIf="isProductLoading">
  <p class="_loading">
    <span class="loader"></span>
  </p>
</div>

<ng-container *ngIf="!isProductLoading && orientation != 'horizontal'">

  <div class="_main_container _rsvp_bg">
    <div class="_rsvp_content">
      <div class="_left">
        <h1 class="_heading" [innerHTML]="productName"></h1>
        <div class="_card">
          <ng-container *ngIf="productImage">
            <img [src]="productImage" lazy>
          </ng-container>
        </div>
      </div>
      <div class="_right">

        <h2 class="_heading">Event Details</h2>
        <div class="_form_card">
          <div class="_field _checkbox">
            <span>RSVP?</span>
            <div class="_checkbox_input">
              <input type="checkbox" id="rsvp_checkbox" (change)="_isRsvpChecked($event)" value="true" />
              <label for="rsvp_checkbox"></label>
            </div>
          </div>
          <!-- <datetime [(ngModel)]="date"></datetime> -->

          <form [formGroup]="rsvpForm" (ngSubmit)="_addEvent()">
            <ng-container *ngIf="isRsvpFormActive">
              <ng-container *ngFor="let ticket of t.controls; let i = index">
                <div [formGroup]="ticket" class="_rsvp_row{{i}}">


                  <div class="_field _inputs">
                    <ng-container *ngIf="i > 0">
                      <a href="javascript:;" class="_remove_event" (click)="_removeAddEventForm(i)"><i
                          aria-hidden="true" class="fa fa-times-circle"></i></a>
                    </ng-container>
                    <label for="_host_message" class="_rsvp_host_label">Add a message (Message from host)</label>
                    <textarea class="_rsvp_host_message" placeholder="Add Message" formControlName="message"
                      name="_host_message">
                      </textarea>
                    <div *ngIf="submitted && ticket.controls.message.errors" class="invalid-feedback">
                      <span class="errors" *ngIf="ticket.controls.message.errors.required">* Host Message is
                        required</span>
                    </div>
                  </div>

                  <div class="_field _inputs _field_margin">
                    <label for="event">Event Name</label>
                    <input type="text" placeholder="Wedding" formControlName="event" name="event" />
                    <div *ngIf="submitted && ticket.controls.event.errors" class="invalid-feedback">
                      <span class="errors" *ngIf="ticket.controls.event.errors.required">* Event is required</span>
                    </div>
                  </div>

                  <div class="_field _inputs">
                    <label for="hosted">Hosted By</label>
                    <input type="text" placeholder="Hosted By" name="hosted" formControlName="hostedBy"
                      class="_hosted" />
                    <input type="text" placeholder="Mobile" name="hostedByMobile" formControlName="hostedByMobile"
                      class="_hosted" maxlength="15" minlength="6" (keyup)="_validateMobileNumber($event)" />
                    <div *ngIf="submitted && ticket.controls.hostedBy.errors" class="invalid-feedback">
                      <span class="errors" *ngIf="ticket.controls.hostedBy.errors.required">* Name</span>
                    </div>
                    <div *ngIf="submitted && ticket.controls.hostedByMobile.errors" class="invalid-feedback">
                      <span class="errors" *ngIf="ticket.controls.hostedByMobile.errors.required">* Mobile</span>
                    </div>
                  </div>


                  <ng-container *ngIf="_coHosts['event_'+i]?.length > 0">
                    <div class="_field _inputs" *ngFor="let host of _coHosts['event_'+i]; let j = index">
                      <label for="coHostedBy_{{j}}">Co-Hosted By</label>
                      <div class="_co_host_inputs">
                        <input type="text" placeholder="Co-Hosted By" name="coHostedBy_{{j}}"
                          (keyup)="_getCoHostValue($event,j,'name',i)" id="coHostedBy_{{j}}" class="_hosted _co" />
                        <input type="text" placeholder="Co-Host Mobile" maxlength="15" minlength="6"
                          name="coHostedMobile_{{j}}" (blur)="_validateMobileNumber($event,j,i)"
                          (keyup)="_getCoHostValue($event,j,'mobile',i)" id="coHostedMobile_{{j}}"
                          class="_hosted _co" />
                        <button class="_remove_cohost" type="button" (click)="_removeCoHost(j,i)"><i
                            class="fa fa-close"></i></button>
                      </div>
                    </div>
                  </ng-container>
                  <div class="_field _inputs">
                    <span class="_add_host"><a href="javascript:;" (click)="_addCoHost(i)">Add Co-Host</a></span>
                  </div>
                  <div class="_field _inputs">
                    <label for="event_date">Event Date</label>
                    <input placeholder="MM/DD/YYYY" name="event_date" formControlName="heldOn" [owlDateTime]="dt1"
                      [min]="todayDate" [owlDateTimeTrigger]="dt1">
                    <owl-date-time [pickerMode]="'dialog'" #dt1></owl-date-time>
                    <div *ngIf="submitted && ticket.controls.heldOn.errors" class="invalid-feedback">
                      <span class="errors" *ngIf="ticket.controls.heldOn.errors.required">* Event Date</span>
                    </div>
                  </div>

                  <div class="_field _inputs">
                    <label for="location">Location Name</label>
                    <input type="text" placeholder="My house,The bar,etc.." formControlName="location"
                      name="location" />
                    <div *ngIf="submitted && ticket.controls.location.errors" class="invalid-feedback">
                      <span class="errors" *ngIf="ticket.controls.location.errors.required">* Location</span>
                    </div>
                  </div>

                  <div class="_field _inputs">
                    <label for="address">Street Address</label>
                    <input type="text" placeholder="Street Address" formControlName="address" name="address" />
                    <div *ngIf="submitted && ticket.controls.address.errors" class="invalid-feedback">
                      <span class="errors" *ngIf="ticket.controls.address.errors.required">* Address</span>
                    </div>
                  </div>

                  <div class="_field _inputs">
                    <label for="city">City</label>
                    <input type="text" placeholder="City" formControlName="city" name="city" />
                    <div *ngIf="submitted && ticket.controls.city.errors" class="invalid-feedback">
                      <span class="errors" *ngIf="ticket.controls.city.errors.required">* City</span>
                    </div>
                  </div>

                  <div class="_field _inputs">
                    <label for="state">State</label>
                    <input type="text" placeholder="State" formControlName="state" name="state" />
                    <div *ngIf="submitted && ticket.controls.state.errors" class="invalid-feedback">
                      <span class="errors" *ngIf="ticket.controls.state.errors.required">* State</span>
                    </div>
                  </div>

                  <div class="_field _inputs">
                    <label for="postal">Postal Code</label>
                    <input type="text" placeholder="Postal Code" maxlength="6" formControlName="postal" name="postal" />
                    <div *ngIf="submitted && ticket.controls.postal.errors" class="invalid-feedback">
                      <span class="errors" *ngIf="ticket.controls.postal.errors.required">* Postal Code</span>
                    </div>
                  </div>

                  <div class="_field _map">
                    <input type="text" placeholder="My house,The bar,etc.." formControlName="event_location"
                      name="event_location" id="_location_{{i}}"
                      [ngClass]="{'_error' : submitted && ticket.controls.event_location.errors}" />
                    <agm-map [latitude]="_eventLocations[i].latitude" [longitude]="_eventLocations[i].longitude"
                      [zoom]="_eventLocations[i].zoom">
                      <agm-marker [latitude]="_eventLocations[i].latitude" [longitude]="_eventLocations[i].longitude"
                        [markerDraggable]="true" [iconUrl]="_eventMarker" (dragEnd)="_setLocationPointer($event,i)">
                      </agm-marker>
                    </agm-map>
                    <span class="_agm_drag_info">Drag pointer to get precised location</span>
                  </div>
                </div>
              </ng-container>
              <a href="javascript:;" class="_add_event_button" (click)="_addMoreEvent('add')">+ Add Event</a>
            </ng-container>

            <div class="_field _checkbox">
              <span>Do you want the personalised link?</span>
              <div class="_checkbox_input">
                <input type="checkbox" id="rsvp_checkbox_link" (change)="_isCustomUrlChecked($event)" value="true" />
                <label for="rsvp_checkbox_link"></label>
              </div>
            </div>

            <ng-container *ngIf="isCustomUrlFormActive">
              <div class="_field _custom_link">
                <span>www.lekarde.com/share/{{rsvp_type}}/</span>
                <a href="javascript:;" (click)="_activeCustomUrl()" *ngIf="!isCustomUrlInputActive">Customize
                  Link</a>
                <input placeholder="Type here" *ngIf="isCustomUrlInputActive" type="text" name="customUrl"
                  formControlName="customUrl" id="customUrl" (keyup)="_checkUrlExist($event)"
                  (blur)="_checkUrlExist($event)" [ngClass]="{'_error':isSubmitDisabled}" /><br />
                <span class="_message" [innerHTML]="personalisedLinkAvailableText"></span>
              </div>
            </ng-container>

            <div class="_field _checkbox">
              <span>Apply Coupon</span>
              <div class="_checkbox_input">
                <input type="checkbox" id="_coupon_active" (change)="_isCouponChecked($event)" value="true" />
                <label for="_coupon_active"></label>
              </div>
            </div>

            <ng-container *ngIf="isCouponFormActive">
              <div class="_field _inputs">
                <label style="width:30%;" for="coupon">Coupon Code</label>
                <input type="text" placeholder="Enter code here" formControlName="coupon" id="coupon" name="coupon" />
                <button class="_apply_coupon_button" type="button" (click)="_applyCoupon1()">Apply</button>
                <span class="_coupon_list" *ngIf="appliedCouponInfo" [innerHTML]="appliedCouponInfo"> </span>
              </div>
            </ng-container>

            <span class="_rsvp_price_info">
              Product Price : <i class="fa fa-inr" aria-hidden="true"></i> {{productPrice}} <br>
              <ng-container *ngIf="isCustomUrlFormActive">
                Rsvp Url : <i class="fa fa-inr" aria-hidden="true"></i> {{domainPrice}} <br>
              </ng-container>
              <ng-container *ngIf="totalPrice == 1 else chargedTemplate">
                Proceed to checkout (Free Coupon Applied)
              </ng-container>
            </span>

            <div class="_validation_message" *ngIf="!isFormValidationComplete">
              Your inputs are not valid to be submitted. Please check and then proceed to checkout
            </div>

            <div class="_checkout">
              <button type="submit" class="_action" id="_rsvpSubmit" [innerHTML]="submitButtonText"></button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<!--HORIZONTAL START-->
<ng-container *ngIf="!isProductLoading && orientation == 'horizontal'">
  <div class="_main_container _rsvp_horizontal_bg">
    <div class="_rsvp_horizontal">
      <div class="_top_section">
        <h1 class="_heading" [innerHTML]="productName"></h1>
        <div class="_card">
          <img [src]="productImage">
        </div>
      </div>
      <div class="_bottom_section">
        <h2 class="_heading">Event Details</h2>

        <form [formGroup]="rsvpForm" (ngSubmit)="_addEvent()">
          <div class="_form_card">
            <div class="_field _checkbox">
              <span>RSVP?</span>
              <div class="_checkbox_input">
                <input type="checkbox" id="rsvp_checkbox" (change)="_isRsvpChecked($event)" value="true" />
                <label for="rsvp_checkbox"></label>
              </div>
            </div>

            <ng-container *ngIf="isRsvpFormActive">
              <ng-container *ngFor="let ticket of t.controls; let i = index">

                <div [formGroup]="ticket" class="_rsvp_row{{i}}">
                  <div class="_inner_form">
                    <div class="_field _inputs" style="margin-bottom: 20px;">
                      <ng-container *ngIf="i > 0">
                        <a href="javascript:;" class="_remove_event" (click)="_removeAddEventForm(i)"><i
                            aria-hidden="true" class="fa fa-times-circle"></i></a>
                      </ng-container>

                      <label for="_host_message" class="_rsvp_host_label">Add a message (Message from host)</label>
                      <textarea class="_rsvp_host_message" placeholder="Add Message" formControlName="message"
                        name="_host_message"></textarea>
                      <div *ngIf="submitted && ticket.controls.message.errors" class="invalid-feedback">
                        <span class="errors" [ngStyle]="{'text-align' : 'left'}"
                          *ngIf="ticket.controls.message.errors.required">* Host Message is
                          required</span>
                      </div>
                    </div>

                    <div class="_form_left">
                      <div class="_field _inputs">
                        <label for="event">Event Name</label>
                        <input type="text" placeholder="Wedding" formControlName="event" name="event" />
                        <div *ngIf="submitted && ticket.controls.event.errors" class="invalid-feedback">
                          <span class="errors" *ngIf="ticket.controls.event.errors.required">* Event is required</span>
                        </div>
                      </div>

                      <div class="_field _inputs">
                        <label for="hosted">Hosted By</label>
                        <input type="text" placeholder="Hosted By" name="hosted" formControlName="hostedBy"
                          class="_hosted" />
                        <input type="text" placeholder="Mobile" name="hostedByMobile" formControlName="hostedByMobile"
                          class="_hosted" maxlength="15" minlength="6" (keyup)="_validateMobileNumber($event)" />

                        <div *ngIf="submitted && ticket.controls.hostedBy.errors" class="invalid-feedback">
                          <span class="errors" *ngIf="ticket.controls.hostedBy.errors.required">* Name</span>
                        </div>
                        <div *ngIf="submitted && ticket.controls.hostedByMobile.errors" class="invalid-feedback">
                          <span class="errors" *ngIf="ticket.controls.hostedByMobile.errors.required">* Mobile</span>
                        </div>
                      </div>

                      <ng-container *ngIf="_coHosts['event_'+i]?.length > 0">
                        <div class="_field _inputs" *ngFor="let host of _coHosts['event_'+i]; let j = index">
                          <label for="coHostedBy_{{j}}">Co-Hosted By</label>
                          <div class="_co_host_inputs">
                            <input type="text" placeholder="Co-Hosted By" name="coHostedBy_{{j}}"
                              (keyup)="_getCoHostValue($event,j,'name',i)" id="coHostedBy_{{j}}" class="_hosted _co" />
                            <input type="text" placeholder="Co-Host Mobile" maxlength="15" minlength="6"
                              name="coHostedMobile_{{j}}" (blur)="_validateMobileNumber($event,j,i)"
                              (keyup)="_getCoHostValue($event,j,'mobile',i)" id="coHostedMobile_{{j}}"
                              class="_hosted _co" />
                            <button class="_remove_cohost" type="button" (click)="_removeCoHost(j,i)"><i
                                class="fa fa-close"></i></button>
                          </div>
                        </div>
                      </ng-container>

                      <div class="_field _inputs">
                        <span class="_add_host"><a href="javascript:;" (click)="_addCoHost(i)">Add Co-Host</a></span>
                      </div>


                      <div class="_field _inputs">
                        <label for="event_date">Event Date</label>
                        <input placeholder="MM/DD/YYYY" name="event_date" formControlName="heldOn" [owlDateTime]="dt1"
                          [min]="todayDate" [owlDateTimeTrigger]="dt1">
                        <owl-date-time [pickerMode]="'dialog'" #dt1></owl-date-time>
                        <div *ngIf="submitted && ticket.controls.heldOn.errors" class="invalid-feedback">
                          <span class="errors" *ngIf="ticket.controls.heldOn.errors.required">* Event Date</span>
                        </div>
                      </div>

                      <div class="_field _inputs">
                        <label for="location">Location Name</label>
                        <input type="text" placeholder="My house,The bar,etc.." formControlName="location"
                          name="location" />
                        <div *ngIf="submitted && ticket.controls.location.errors" class="invalid-feedback">
                          <span class="errors" *ngIf="ticket.controls.location.errors.required">* Location</span>
                        </div>
                      </div>

                    </div>

                    <div class="_form_right">
                      <div class="_field _inputs">
                        <label for="address">Street Address</label>
                        <input type="text" placeholder="Street Address" formControlName="address" name="address" />
                        <div *ngIf="submitted && ticket.controls.address.errors" class="invalid-feedback">
                          <span class="errors" *ngIf="ticket.controls.address.errors.required">* Address</span>
                        </div>
                      </div>

                      <div class="_field _inputs">
                        <label for="city">City</label>
                        <input type="text" placeholder="City" formControlName="city" name="city" />
                        <div *ngIf="submitted && ticket.controls.city.errors" class="invalid-feedback">
                          <span class="errors" *ngIf="ticket.controls.city.errors.required">* City</span>
                        </div>
                      </div>

                      <div class="_field _inputs">
                        <label for="state">State</label>
                        <input type="text" placeholder="State" formControlName="state" name="state" />
                        <div *ngIf="submitted && ticket.controls.state.errors" class="invalid-feedback">
                          <span class="errors" *ngIf="ticket.controls.state.errors.required">* State</span>
                        </div>
                      </div>

                      <div class="_field _inputs">
                        <label for="postal">Postal Code</label>
                        <input type="text" placeholder="Postal Code" maxlength="6" formControlName="postal"
                          name="postal" />
                        <div *ngIf="submitted && ticket.controls.postal.errors" class="invalid-feedback">
                          <span class="errors" *ngIf="ticket.controls.postal.errors.required">* Postal Code</span>
                        </div>
                      </div>
                    </div>

                    <div class="_field _map">
                      <input type="text" placeholder="My house,The bar,etc.." formControlName="event_location"
                        name="event_location" id="_location_{{i}}"
                        [ngClass]="{'_error' : submitted && ticket.controls.event_location.errors}" />
                      <agm-map [latitude]="_eventLocations[i].latitude" [longitude]="_eventLocations[i].longitude"
                        [zoom]="_eventLocations[i].zoom">
                        <agm-marker [latitude]="_eventLocations[i].latitude" [longitude]="_eventLocations[i].longitude"
                          [markerDraggable]="true" [iconUrl]="_eventMarker" (dragEnd)="_setLocationPointer($event,i)">
                        </agm-marker>
                      </agm-map>
                      <span class="_agm_drag_info">Drag pointer to get precised location</span>
                    </div>
                  </div>
                </div>
              </ng-container>


              <div style="width: 100%; float: left;">
                <a href="javascript:;" class="_add_event_button" (click)="_addMoreEvent('add')">+ Add Event</a>
              </div>
            </ng-container>



            <div class="_inner_bottom">
              <div class="_field _checkbox">
                <span>Do you want the personalised link?</span>
                <div class="_checkbox_input">
                  <input type="checkbox" id="rsvp_checkbox_link" (change)="_isCustomUrlChecked($event)" value="true" />
                  <label for="rsvp_checkbox_link"></label>
                </div>
              </div>

              <ng-container *ngIf="isCustomUrlFormActive">
                <div class="_field _custom_link">
                  <span>www.lekarde.com/share/{{rsvp_type}}/</span>
                  <a href="javascript:;" (click)="_activeCustomUrl()" *ngIf="!isCustomUrlInputActive">Customize
                    Link</a>
                  <input placeholder="Type here" *ngIf="isCustomUrlInputActive" type="text" name="customUrl"
                    formControlName="customUrl" id="customUrl" (keyup)="_checkUrlExist($event)"
                    (blur)="_checkUrlExist($event)" [ngClass]="{'_error':isSubmitDisabled}" /><br />
                  <span class="_message" [innerHTML]="personalisedLinkAvailableText"></span>
                </div>
              </ng-container>


              <div class="_field _checkbox">
                <span>Apply Coupon</span>
                <div class="_checkbox_input">
                  <input type="checkbox" id="_coupon_active" (change)="_isCouponChecked($event)" value="true" />
                  <label for="_coupon_active"></label>
                </div>
              </div>

              <ng-container *ngIf="isCouponFormActive">
                <div class="_field _inputs">
                  <label style="width:30%;" for="coupon">Coupon Code</label>
                  <input type="text" placeholder="Enter code here" formControlName="coupon" id="coupon" name="coupon" />
                  <button class="_apply_coupon_button" type="button" (click)="_applyCoupon1()">Apply</button>
                  <span class="_coupon_list" *ngIf="appliedCouponInfo" [innerHTML]="appliedCouponInfo"> </span>
                </div>
              </ng-container>


              <span class="_rsvp_price_info">
                Product Price : <i class="fa fa-inr" aria-hidden="true"></i> {{productPrice}} <br>
                <ng-container *ngIf="isCustomUrlFormActive">
                  Rsvp Url : <i class="fa fa-inr" aria-hidden="true"></i> {{domainPrice}} <br>
                </ng-container>
                <ng-container *ngIf="totalPrice == 1 else chargedTemplate">
                  Proceed
                </ng-container>
              </span>

              <div class="_validation_message" *ngIf="!isFormValidationComplete">
                Your inputs are not valid to be submitted. Please check and then proceed to checkout
              </div>

              <div class="_checkout">
                <button type="submit" class="_action" id="_rsvpSubmit" [innerHTML]="submitButtonText"></button>
              </div>


            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-container>


<ng-template #chargedTemplate>
  Total : <i class="fa fa-inr" aria-hidden="true"></i> {{totalPrice}}
</ng-template>

<div class="_clearfix"></div>
<app-footer></app-footer>

<ng-template #plusTemplate>
  <i>+</i>
</ng-template>