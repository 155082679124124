import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService, GoogleLoginProvider, FacebookLoginProvider } from "angularx-social-login";
import { ApiService } from "../service/api.service";
import { DataService } from '../service/data.service';
import { NotificationService } from "../service/notification.service";
var LoginComponent = /** @class */ (function () {
    function LoginComponent(authService, apiService, dataService, router, nfs) {
        this.authService = authService;
        this.apiService = apiService;
        this.dataService = dataService;
        this.router = router;
        this.nfs = nfs;
        this.isLoginFormActive = true;
    }
    LoginComponent.prototype.ngOnInit = function () {
        this.signupFormInit();
    };
    LoginComponent.prototype.signupFormInit = function () {
        // password: new FormControl('', [Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/)])
        this.loginFormGroup = new FormGroup({
            email: new FormControl('', [Validators.required, Validators.email]),
            password: new FormControl('', [Validators.required])
        });
        this.forgotFormGroup = new FormGroup({
            email: new FormControl('', [Validators.required, Validators.email])
        });
    };
    LoginComponent.prototype._login = function () {
        var _this = this;
        if (this.loginFormGroup.valid) {
            var postParams = this.loginFormGroup.value;
            this.apiService.login(postParams).subscribe(function (response) {
                if (response.response == 'success') {
                    localStorage.setItem('token', response.token);
                    localStorage.setItem('user', JSON.stringify(response.data));
                    _this.dataService.sessionMessage(true);
                    _this.nfs.showNotification("default", 'Redirecting..');
                    _this._redirectToPage();
                }
                else {
                    _this.nfs.showNotification("default", response.message);
                    return false;
                }
            });
        }
        else {
            this.validateAllFormFields(this.loginFormGroup);
        }
    };
    LoginComponent.prototype._forgotPassword = function () {
        var _this = this;
        if (this.forgotFormGroup.valid) {
            var postParams = this.forgotFormGroup.value;
            this.apiService.postForgotPassword(postParams).subscribe(function (response) {
                if (response.response == 'success') {
                    _this.forgotFormGroup.reset();
                    _this.nfs.showNotification("default", 'Please check you email.');
                }
                else {
                    _this.nfs.showNotification("default", response.message);
                }
            });
        }
        else {
            this.validateAllFormFields(this.forgotFormGroup);
        }
    };
    LoginComponent.prototype._redirectToPage = function () {
        var url = localStorage.getItem('url');
        if (url != null && url != 'undefined') {
            this.router.navigate(["" + url]);
        }
        else {
            this.router.navigate(['/']);
        }
    };
    /* Form Validations and Errors */
    LoginComponent.prototype.formControlValid = function (field) {
        return !this.loginFormGroup.get(field).valid && this.loginFormGroup.get(field).touched;
    };
    LoginComponent.prototype.formControlClass = function (field) {
        if (!this.formControlValid(field)) {
            return { '_noerr': true };
        }
        else {
            return { '_err': true };
        }
    };
    LoginComponent.prototype.validateAllFormFields = function (formGroup) {
        var _this = this;
        Object.keys(formGroup.controls).forEach(function (field) {
            var control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
            }
            else if (control instanceof FormGroup) {
                _this.validateAllFormFields(control);
            }
        });
    };
    /* Form Validations and Errors */
    /* Social Fb/Google Actions */
    LoginComponent.prototype.registerGoogle = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then(function (res) {
                            _this.apiService.login(res).subscribe(function (response) {
                                if (response.response == 'success') {
                                    localStorage.setItem('token', response.token);
                                    localStorage.setItem('user', JSON.stringify(response.data));
                                    _this.dataService.sessionMessage(true);
                                    _this.nfs.showNotification("default", 'Redirecting..');
                                    _this._redirectToPage();
                                }
                            });
                        }).catch(function (err) { return console.log('ashutosh'); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    LoginComponent.prototype.registerFacebook = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.authService
                            .signIn(FacebookLoginProvider.PROVIDER_ID)
                            .then(function (res) {
                            _this.apiService.login(res).subscribe(function (response) {
                                if (response.response == 'success') {
                                    localStorage.setItem('token', response.token);
                                    localStorage.setItem('user', JSON.stringify(response.data));
                                    _this.dataService.sessionMessage(true);
                                    _this.nfs.showNotification("default", 'Redirecting..');
                                    _this._redirectToPage();
                                }
                            });
                        })
                            .catch(function (err) { return console.log(err); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return LoginComponent;
}());
export { LoginComponent };
