<div class="_main_container _header_shadow">
  <app-header></app-header>
</div>

<div class="_main_container _dashboard_container">
  <div class="_user_dashboard">
    <div class="_inner_dashboard">
      <div class="_tab_area">
        <ul>
          <li [ngClass]="{'_active' : isProfileTabActive == 'profile'}" (click)="_profileTabs('profile')">Profile</li>
          <li [ngClass]="{'_active' : isProfileTabActive == 'order'}" (click)="_profileTabs('order')">Order History</li>
          <li [ngClass]="{'_active' : isProfileTabActive == 'draft'}" (click)="_profileTabs('draft')">Drafts</li>
          <li [ngClass]="{'_active' : isProfileTabActive == 'wishlist'}" (click)="_profileTabs('wishlist')">Wishlist
          </li>
        </ul>
      </div>
      <ng-container *ngIf="isProfileTabActive == 'profile'">
        <p class="_loading" *ngIf="isProfileLoading">
          <span class="loader"></span>
        </p>

        <div class="_inner_area" *ngIf="!isProfileLoading">
          <div class="_left">
            <div class="_profile">
              <img [src]="profileImage" lazy>
              <span class="_image">
                <label for="_update_profile_image">
                  UPDATE
                </label>
                <input type="file" id="_update_profile_image" #profileRef (change)="_selectProfileImage($event)"
                  name="_update_profile_image" />
              </span>
            </div>
            <div class="_content">
              <ng-container *ngIf="!isEditFormActive else editProfileForm">
                <p *ngIf="name" [innerHTML]="name"></p>
                <p *ngIf="email" [innerHTML]="email"></p>
                <p *ngIf="mobile" [innerHTML]="mobile"></p>
                <p *ngIf="address" [innerHTML]="address"></p>
                <div class="_btn_profile">
                  <button class="_action" (click)="_openEditProfile()">Edit my profile</button>
                </div>
              </ng-container>

              <ng-template #editProfileForm>
                <form class="_form_sign custom-forms" novalidate="" [formGroup]="editProfileFormGroup"
                  (ngSubmit)="_updateProfile()">
                  <div class="_form_field" [ngClass]="{'_invalid' : formControlValid('name')}">
                    <input formControlName="name" id="name" name="name" placeholder="Name" type="email">
                  </div>
                  <div class="_form_field">
                    <input id="email" name="email" [value]="email" disabled placeholder="Email (e.g. abc@gmail.com)"
                      type="text">
                  </div>
                  <div class="_form_field">
                    <input id="mobile" formControlName="mobile" name="mobile" placeholder="Mobile" type="text">
                  </div>
                  <div class="_form_field">
                    <textarea formControlName="address" id="address" name="address"
                      placeholder="Address here"></textarea>
                  </div>
                  <div class="_action">
                    <div class="_btn">
                      <button type="submit" [disabled]="editProfileFormGroup.invalid"> Update </button>
                    </div>
                  </div>
                </form>
              </ng-template>
            </div>
          </div>
        </div>
      </ng-container>


      <ng-container *ngIf="isProfileTabActive == 'order'">
        <p class="_loading" *ngIf="isOrdersLoading">
          <span class="loader"></span>
          <span class="_text">Loading..</span>
        </p>
        <div class="_orders" *ngIf="!isOrdersLoading">
          <div class="_draft_filter">
            <button [ngClass]="{'_active' : selctedOrderFilter == 'all'}" (click)="_orderFilter('all')">All</button>
            <button [ngClass]="{'_active' : selctedOrderFilter == 'vcard'}"
              (click)="_orderFilter('vcard')">V-Card</button>
            <button [ngClass]="{'_active' : selctedOrderFilter == 'ecard'}"
              (click)="_orderFilter('ecard')">E-Card</button>
          </div>


          <ng-container *ngIf="selctedOrderFilter == 'all'">
            <ng-container *ngIf="allOrders?.length > 0">

              <ng-container *ngFor="let order of allOrders; let i = index">
                <div *ngIf="order.order_type == 'vcard'" class="_inner_area" [ngClass]="{'_border' : i != 0}">
                  <div class="_order_history">
                    <div class="_first">
                      <div class="_card">
                        <img [src]="order.image_url" lazy>
                      </div>
                      <h2 class="_heading" *ngIf="order.event_name else emptyTemplate" [innerHTML]="order.event_name">
                      </h2>
                    </div>
                    <div class="_third">
                      <div>
                        <p *ngIf="order.status">
                          Status : Success
                        </p>
                        <p *ngIf="order.order_number" [innerHTML]="'Order : ' + order.order_number"></p>
                        <p *ngIf="order.order_total">
                          Amount : <i class="fa fa-inr" aria-hidden="true"></i> {{order.order_total}}
                        </p>
                        <p class="_coupon_applied_text" *ngIf="order.coupon">
                          {{order.coupon}} Coupon Applied
                        </p>
                      </div>
                    </div>
                    <div class="_fourth">
                      <div class="_btn_profile">
                        <ng-container *ngIf="order.shared_url">
                          <a class="_action" href="javascript:void(0)"
                            (click)="_goToOrderDetail('vcard','link',i,'all')">View Card</a>
                        </ng-container>
                        <ng-container *ngIf="!order.shared_url">
                          <a class="_action" target="_blank" href="javascript:void(0)"
                            routerLink="/view/vcard/{{order.order_number}}/{{order.order_id}}">View Card</a>
                        </ng-container>
                        <ng-container *ngIf="order.order_rsvp">
                          <a class="_action" href="javascript:void(0)"
                            (click)="_goToOrderDetail('vcard','detail',i,'all')">RSVP</a>
                        </ng-container>
                        <a *ngIf="order.video_url" class="_order_history_download _action" [href]="order.video_url"
                          target="_blank" download>Download</a>

                        <div class="_share_order">
                          <ng-container *ngIf="order.shared_url">
                            Share your Invite <br>
                            <a href="javascript:;" title="Share on Whatsapp"
                              (click)="_whatsappShare('whatsapp',order.shared_url,'vcard')"><img
                                src="assets/img/whatsapp.png" width="15" /></a>
                            <a href="javascript:;" title="Share on Facebook"
                              (click)="_whatsappShare('facebook',order.shared_url,'vcard')"><img
                                src="assets/img/facebook.png" width="15" /></a>
                            <a href="javascript:;" title="Click to copy url" ngxClipboard *ngxClipboardIfSupported
                              (cbOnSuccess)="_copied($event)"
                              [cbContent]="'https://lekarde.com/vcard/'+order.shared_url"><img
                                src="assets/img/clipboard.png" width="14" /></a>
                          </ng-container>
                        </div>


                      </div>
                    </div>
                  </div>

                  <div *ngIf="order.order_date" class="_card_delivery_status _checkbox_setting">
                    {{order.order_date}}
                  </div>

                  <div *ngIf="!order.card_url" class="_card_delivery_status">
                    Status : Video creation is in process. We will notify you when its done.
                  </div>
                </div>


                <div *ngIf="order.order_type == 'ecard'" class="_inner_area" [ngClass]="{'_border' : i != 0}">
                  <div class="_order_history">
                    <div class="_first">
                      <div class="_card">
                        <img [src]="productMediaPath +'/' + order.order_image_card" lazy>
                      </div>
                    </div>

                    <div class="_third">
                      <div>
                        <p *ngIf="order.status">
                          Status : Success
                        </p>
                        <p *ngIf="order.order_number" [innerHTML]="'Order : ' + order.order_number"></p>
                        <p *ngIf="order.order_total">
                          Amount : <i class="fa fa-inr" aria-hidden="true"></i> {{order.order_total}}
                        </p>
                        <p class="_coupon_applied_text" *ngIf="order.coupon">
                          {{order.coupon}} Coupon Applied
                        </p>

                      </div>
                    </div>
                    <div class="_fourth">
                      <div class="_btn_profile">
                        <ng-container *ngIf="order.shared_url">
                          <a class="_action" href="javascript:void(0)"
                            (click)="_goToOrderDetail('ecard','link',i,'all')">View Card</a>
                        </ng-container>
                        <ng-container *ngIf="!order.shared_url">
                          <a class="_action" target="_blank" href="javascript:void(0)"
                            routerLink="/view/ecard/{{order.order_number}}/{{order.order_id}}">View Card</a>
                        </ng-container>
                        <ng-container *ngIf="order.order_rsvp">
                          <a class="_action" href="javascript:void(0)"
                            (click)="_goToOrderDetail('ecard','detail',i,'all')">RSVP</a>
                        </ng-container>
                        <a *ngIf="order.order_image_card" class="_order_history_download _action"
                          [href]="productMediaPath +'/' + order.order_image_card" target="_blank" download>Download</a>
                        <div class="_share_order">
                          <ng-container *ngIf="order.shared_url">
                            Share your Invite <br>
                            <a href="javascript:;" title="Share on Whatsapp"
                              (click)="_whatsappShare('whatsapp',order.shared_url,'ecard')"><img
                                src="assets/img/whatsapp.png" width="15" /></a>
                            <a href="javascript:;" title="Share on Facebook"
                              (click)="_whatsappShare('facebook',order.shared_url,'ecard')"><img
                                src="assets/img/facebook.png" width="15" /></a>
                            <a href="javascript:;" title="Click to copy url" ngxClipboard *ngxClipboardIfSupported
                              (cbOnSuccess)="_copied($event)"
                              [cbContent]="'https://lekarde.com/' +'ecard/'+ order.shared_url"><img
                                src="assets/img/clipboard.png" width="14" /></a>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="_card_delivery_status _checkbox_setting">
                    <p *ngIf="order.order_date else emptyTemplate">
                      {{order.order_date}}
                    </p>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="selctedOrderFilter == 'vcard'">
            <ng-container *ngIf="vCardOrders?.length > 0">
              <div class="_inner_area" *ngFor="let order of vCardOrders; let i = index"
                [ngClass]="{'_border' : i != 0}">

                <div class="_order_history">
                  <div class="_first">
                    <div class="_card">
                      <img [src]="order.image_url" lazy>
                    </div>
                    <h2 class="_heading" *ngIf="order.event_name else emptyTemplate" [innerHTML]="order.event_name">
                    </h2>
                  </div>
                  <div class="_third">
                    <div>
                      <p *ngIf="order.status">
                        Status : Success
                      </p>
                      <p *ngIf="order.order_number" [innerHTML]="'Order : ' + order.order_number"></p>
                      <p *ngIf="order.order_total">
                        Amount : <i class="fa fa-inr" aria-hidden="true"></i> {{order.order_total}}
                      </p>
                      <p class="_coupon_applied_text" *ngIf="order.coupon">
                        {{order.coupon}} Coupon Applied
                      </p>
                    </div>
                  </div>
                  <div class="_fourth">
                    <div class="_btn_profile">
                      <ng-container *ngIf="order.shared_url">
                        <a class="_action" href="javascript:void(0)"
                          (click)="_goToOrderDetail('vcard','link',i,'vcard')">View Card</a>
                      </ng-container>
                      <ng-container *ngIf="!order.shared_url">
                        <a class="_action" target="_blank" href="javascript:void(0)"
                          routerLink="/view-card/v/{{order.order_number}}/{{order.order_id}}">View Card</a>
                      </ng-container>
                      <ng-container *ngIf="order.order_rsvp">
                        <a class="_action" href="javascript:void(0)"
                          (click)="_goToOrderDetail('vcard','detail',i,'vcard')">RSVP</a>
                      </ng-container>
                      <a *ngIf="order.video_url" class="_order_history_download _action" [href]="order.video_url"
                        target="_blank" download>Download</a>

                      <div class="_share_order">
                        <ng-container *ngIf="order.shared_url">
                          Share your Invite <br>
                          <a href="javascript:;" title="Share on Whatsapp"
                            (click)="_whatsappShare('whatsapp',order.shared_url,'vcard')"><img
                              src="assets/img/whatsapp.png" width="15" /></a>
                          <a href="javascript:;" title="Share on Facebook"
                            (click)="_whatsappShare('facebook',order.shared_url,'vcard')"><img
                              src="assets/img/facebook.png" width="15" /></a>
                          <a href="javascript:;" title="Click to copy url" ngxClipboard *ngxClipboardIfSupported
                            (cbOnSuccess)="_copied($event)"
                            [cbContent]="'https://lekarde.com/vcard/'+order.shared_url"><img
                              src="assets/img/clipboard.png" width="14" /></a>
                        </ng-container>
                      </div>

                    </div>
                  </div>
                </div>

                <div *ngIf="order.order_date" class="_card_delivery_status _checkbox_setting">
                  {{order.order_date}}
                </div>

                <div *ngIf="!order.card_url" class="_card_delivery_status">
                  Status : Video creation is in process. This will take upto 24 hrs. We will notify you when its done.
                </div>

              </div>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="selctedOrderFilter == 'ecard'">
            <ng-container *ngIf="eCardOrders?.length > 0">
              <div class="_inner_area" *ngFor="let order of eCardOrders; let i = index"
                [ngClass]="{'_border' : i != 0}">
                <div class="_order_history">
                  <div class="_first">
                    <div class="_card">
                      <img [src]="productMediaPath +'/' + order.order_image_card" lazy>
                    </div>
                  </div>

                  <div class="_third">
                    <div>
                      <p *ngIf="order.status"> Status : Success </p>
                      <p *ngIf="order.order_number" [innerHTML]="'Order : ' + order.order_number"></p>
                      <p *ngIf="order.order_total">
                        Amount : <i class="fa fa-inr" aria-hidden="true"></i> {{order.order_total}}
                      </p>
                      <p class="_coupon_applied_text" *ngIf="order.coupon">
                        {{order.coupon}} Coupon Applied
                      </p>
                    </div>
                  </div>
                  <div class="_fourth">
                    <div class="_btn_profile">
                      <ng-container *ngIf="order.shared_url">
                        <a class="_action" href="javascript:void(0)"
                          (click)="_goToOrderDetail('ecard','link',i,'ecard')">View Card</a>
                      </ng-container>
                      <ng-container *ngIf="!order.shared_url">
                        <a class="_action" target="_blank" href="javascript:void(0)"
                          routerLink="/view-card/e/{{order.order_number}}/{{order.order_id}}">View Card</a>
                      </ng-container>
                      <ng-container *ngIf="order.order_rsvp">
                        <a class="_action" href="javascript:void(0)"
                          (click)="_goToOrderDetail('ecard','detail',i,'ecard')">RSVP</a>
                      </ng-container>
                      <a *ngIf="order.order_image_card" class="_order_history_download _action"
                        [href]="productMediaPath +'/' + order.order_image_card" target="_blank" download>Download</a>
                      <div class="_share_order">
                        <ng-container *ngIf="order.shared_url">
                          Share your Invite <br>
                          <a href="javascript:;" title="Share on Whatsapp"
                            (click)="_whatsappShare('whatsapp',order.shared_url,'ecard')"><img
                              src="assets/img/whatsapp.png" width="15" /></a>
                          <a href="javascript:;" title="Share on Facebook"
                            (click)="_whatsappShare('facebook',order.shared_url,'ecard')"><img
                              src="assets/img/facebook.png" width="15" /></a>
                          <a href="javascript:;" title="Click to copy url" ngxClipboard *ngxClipboardIfSupported
                            (cbOnSuccess)="_copied($event)"
                            [cbContent]="'https://lekarde.com/' +'ecard/'+ order.shared_url"><img
                              src="assets/img/clipboard.png" width="14" /></a>
                        </ng-container>
                      </div>



                    </div>
                  </div>
                </div>

                <div class="_card_delivery_status _checkbox_setting">
                  <p *ngIf="order.order_date else emptyTemplate">
                    {{order.order_date}}
                  </p>
                </div>
              </div>
            </ng-container>
          </ng-container>

          <ng-template #noOrdersTemplate>
            <div class="_inner_area _blank">
              Your Orders is currently empty
            </div>
          </ng-template>
        </div>
      </ng-container>




      <ng-container *ngIf="isProfileTabActive == 'draft'">

        <div class="_orders _draft_container">
          <div class="_draft_filter">
            <button [ngClass]="{'_active' : selctedEcardOrderFilter == 'vcard'}"
              (click)="selctedEcardOrderFilter = 'vcard'">V-Card</button>
            <button [ngClass]="{'_active' : selctedEcardOrderFilter == 'ecard'}"
              (click)="selctedEcardOrderFilter = 'ecard'">E-Card</button>
          </div>
          <ng-container *ngIf="selctedEcardOrderFilter == 'vcard'">
            <ng-container *ngIf="archivedOrders?.length > 0 else noArchiveOrdersTemplate">
              <div class="_inner_area" *ngFor="let order of archivedOrders; let i = index"
                [ngClass]="{'_border' : i != 0}">
                <div class="_order_history">
                  <div class="_first">
                    <div class="_card">
                      <img [src]="order.image_url" lazy>
                    </div>
                  </div>
                  <div class="_third">
                    <p *ngIf="order.order_date else emptyTemplate" [innerHTML]="order.order_date"></p>
                  </div>
                  <div class="_fourth">
                    <div class="_btn_profile">
                      <!-- <button class="_action" (click)="_goCustomizeSavedCard(i,'vcard')">Customize</button> -->
                      <a class="_action" href="javascript:void(0)"
                        (click)="_goCustomizeSavedCard(i,'vcard')">Customize</a>

                      <a class="_action" href="javascript:void(0)" (click)="_removeDraft(i,'vcard')">Remove</a>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <ng-template #noArchiveOrdersTemplate>
            <div class="_inner_area _blank">
              Your Drafts is currently empty
            </div>
          </ng-template>

          <ng-container *ngIf="selctedEcardOrderFilter == 'ecard'">
            <ng-container *ngIf="archivedEcardOrders?.length > 0">
              <div class="_inner_area" *ngFor="let order of archivedEcardOrders; let i = index"
                [ngClass]="{'_border' : i != 0}">
                <div class="_order_history">
                  <div class="_first">
                    <div class="_card">
                      <img [src]="order.image_url" lazy>
                    </div>
                  </div>
                  <div class="_third">
                    <p *ngIf="order.order_date else emptyTemplate" [innerHTML]="order.order_date"></p>
                  </div>
                  <div class="_fourth">
                    <div class="_btn_profile">
                      <a class="_action" href="javascript:void(0)"
                        (click)="_goCustomizeSavedCard(i,'ecard')">Customize</a>

                      <a class="_action" href="javascript:void(0)" (click)="_removeDraft(i,'ecard')">Remove</a>

                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>

      <ng-container *ngIf="isProfileTabActive == 'wishlist'">
        <p class="_loading" *ngIf="isWishlistLoading">
          <span class="loader"></span>
          <span class="_text">Loading..</span>
        </p>
        <div class="_orders" *ngIf="!isWishlistLoading">
          <ng-container *ngIf="VcardWishlists?.length > 0 else noVcardWishlistTemplate">
            <div class="_inner_area" *ngFor="let wishlist of VcardWishlists; let i = index"
              [ngClass]="{'_border' : i != 0}">
              <div class="_order_history">
                <div class="_order_status">
                  <span class="_badge _black" *ngIf="wishlist.type" [innerHTML]="wishlist.type"></span>
                </div>
                <div class="_first">
                  <div class="_card">
                    <img [src]="productMediaPath + wishlist.image_url" lazy>
                  </div>
                </div>
                <div class="_fourth">
                  <div class="_btn_profile">
                    <button class="_action" (click)="_removeFromWishlist(wishlist,i,'vcard')">Remove</button>
                    <a class="_action" routerLink="/vcard/create/{{wishlist.product_url}}">Customize</a>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="EcardWishlists?.length > 0 else noEcardWishlistTemplate">
            <div class="_inner_area _ecard_wishlist" *ngFor="let wishlist of EcardWishlists; let i = index"
              [ngClass]="{'_border' : i != 0}">
              <div class="_order_history">
                <div class="_order_status">
                  <span class="_badge _black" *ngIf="wishlist.type" [innerHTML]="wishlist.type"></span>
                </div>
                <div class="_first">
                  <div class="_card">
                    <img [src]="eCardPath + wishlist.image_url" lazy>
                  </div>
                </div>
                <div class="_fourth">
                  <div class="_btn_profile">
                    <button class="_action" (click)="_removeFromWishlist(wishlist,i,'ecard')">Remove</button>
                    <a class="_action"
                      routerLink="/ecard/create/{{wishlist.product_url}}/{{wishlist.design_no}}">Create</a>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-template #noVcardWishlistTemplate>
            <div class="_inner_area _blank">
              Your "Vcard" Wishlist is currently empty
            </div>
          </ng-template>
          <ng-template #noEcardWishlistTemplate>
            <div class="_inner_area _blank">
              Your "Ecard" Wishlist is currently empty
            </div>
          </ng-template>
        </div>
      </ng-container>

    </div>
  </div>
</div>

<ng-template #emptyTemplate>

</ng-template>

<div class="_clearfix"></div>

<app-footer></app-footer>