/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../safe-style.pipe";
import * as i3 from "@angular/platform-browser";
import * as i4 from "./mockup.component";
import * as i5 from "@angular/router";
import * as i6 from "../service/api.service";
var styles_MockupComponent = [".Static_card[_ngcontent-%COMP%]{justify-content: center;}._left[_ngcontent-%COMP%]{background:#f8f8f8}._preview_download[_ngcontent-%COMP%]{ background: #3c8dbc;}"];
var RenderType_MockupComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_MockupComponent, data: {} });
export { RenderType_MockupComponent as RenderType_MockupComponent };
function View_MockupComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "_main_container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "p", [["class", "_loading"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "span", [["class", "loader"]], null, null, null, null, null))], null, null); }
function View_MockupComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "_form_details _font_color_default"]], [[8, "id", 0], [8, "style", 2]], null, null, null, null)), i0.ɵppd(2, 1), (_l()(), i0.ɵeld(3, 0, null, null, 0, "p", [["class", "_text"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i0.ɵinlineInterpolate(1, "_get_prop_", _v.parent.context.$implicit.id, ""); var currVal_1 = i0.ɵunv(_v, 1, 1, _ck(_v, 2, 0, i0.ɵnov(_v.parent.parent.parent.parent.parent.parent, 0), _v.parent.context.$implicit.styles)); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _v.parent.context.$implicit.value; _ck(_v, 3, 0, currVal_2); }); }
function View_MockupComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "_form_details"]], [[8, "id", 0], [8, "style", 2], [4, "font-size", "px"], [4, "width", "px"], [4, "height", "px"]], null, null, null, null)), i0.ɵppd(2, 1), (_l()(), i0.ɵeld(3, 0, null, null, 0, "div", [["class", "_content_editable"], ["contenteditable", "true"], ["spellcheck", "false"]], [[8, "id", 0], [8, "innerHTML", 1]], null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, "div", [["style", "overflow: auto; height: 50px; visibility: hidden;"]], [[8, "id", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i0.ɵinlineInterpolate(1, "_get_prop_", _v.parent.context.$implicit.id, ""); var currVal_1 = i0.ɵunv(_v, 1, 1, _ck(_v, 2, 0, i0.ɵnov(_v.parent.parent.parent.parent.parent.parent, 0), _v.parent.context.$implicit.styles)); var currVal_2 = _v.parent.context.$implicit.fontSize; var currVal_3 = _v.parent.context.$implicit.width; var currVal_4 = _v.parent.context.$implicit.height; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = i0.ɵinlineInterpolate(1, "_inputs_", _v.parent.context.$implicit.id, ""); var currVal_6 = _v.parent.context.$implicit.value; _ck(_v, 3, 0, currVal_5, currVal_6); var currVal_7 = i0.ɵinlineInterpolate(1, "_getTextLength_", _v.parent.context.$implicit.id, ""); _ck(_v, 4, 0, currVal_7); }); }
function View_MockupComponent_10(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "img", [["class", "_image"]], [[8, "id", 0], [8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i0.ɵinlineInterpolate(1, "_image_", _v.parent.parent.context.$implicit.id, ""); var currVal_1 = _v.parent.parent.context.$implicit.value; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_MockupComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "div", [["class", "_form_details _upload_area"]], [[8, "id", 0], [8, "style", 2], [4, "width", "px"], [4, "height", "px"]], null, null, null, null)), i0.ɵppd(2, 1), (_l()(), i0.ɵeld(3, 0, null, null, 4, "div", [["class", "_show_image _upload_placeholder"]], null, null, null, null, null)), i0.ɵdid(4, 278528, null, 0, i1.NgStyle, [i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i0.ɵpod(5, { "background-image": 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MockupComponent_10)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_4 = _ck(_v, 5, 0, (("url(./assets/img/upload-" + _v.parent.context.$implicit.uploadPlaceholder) + "-placeholder.png)")); _ck(_v, 4, 0, currVal_4); var currVal_5 = _v.parent.context.$implicit.value; _ck(_v, 7, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i0.ɵinlineInterpolate(1, "_get_prop_", _v.parent.context.$implicit.id, ""); var currVal_1 = i0.ɵunv(_v, 1, 1, _ck(_v, 2, 0, i0.ɵnov(_v.parent.parent.parent.parent.parent.parent, 0), _v.parent.context.$implicit.styles)); var currVal_2 = _v.parent.context.$implicit.width; var currVal_3 = _v.parent.context.$implicit.height; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
function View_MockupComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MockupComponent_7)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MockupComponent_8)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MockupComponent_9)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (_v.context.$implicit.type == "text"); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.context.$implicit.type == "input"); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_v.context.$implicit.type == "image"); _ck(_v, 6, 0, currVal_2); }, null); }
function View_MockupComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MockupComponent_6)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.frontendCustomizatedData; _ck(_v, 2, 0, currVal_0); }, null); }
function View_MockupComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "_card"], ["id", "_card"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "img", [["class", "_card_img"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "_input_filed"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MockupComponent_5)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (((_co.frontendCustomizatedData == null) ? null : _co.frontendCustomizatedData.length) > 0); _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.productMediaPath + "/upload/cards/") + _v.context.$implicit.card_img); _ck(_v, 2, 0, currVal_0); }); }
function View_MockupComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MockupComponent_4)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.products; _ck(_v, 2, 0, currVal_0); }, null); }
function View_MockupComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "Static_card"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "div", [["class", "_left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "a", [["class", "_ecard_draft _preview_download"], ["href", "javascript:;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co._download_mockup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Download E-CARD"])), (_l()(), i0.ɵeld(4, 0, null, null, 2, "div", [["class", "_slide _vertical"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MockupComponent_3)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((((_co.products == null) ? null : _co.products.length) > 0) && !_co.isProductLoading); _ck(_v, 6, 0, currVal_0); }, null); }
export function View_MockupComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i2.SafeStylePipe, [i3.DomSanitizer]), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MockupComponent_1)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MockupComponent_2)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isProductLoading; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.isProductLoading; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_MockupComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-mockup", [], null, null, null, View_MockupComponent_0, RenderType_MockupComponent)), i0.ɵdid(1, 114688, null, 0, i4.MockupComponent, [i5.ActivatedRoute, i6.ApiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MockupComponentNgFactory = i0.ɵccf("app-mockup", i4.MockupComponent, View_MockupComponent_Host_0, {}, {}, []);
export { MockupComponentNgFactory as MockupComponentNgFactory };
