import { Component, OnInit } from '@angular/core';
import { ApiService } from "../service/api.service"


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styles: []
})
export class FooterComponent implements OnInit {
  facebook: string = ''
  instagram: string = ''
  youtube: string = ''
  twitter: string = ''
  currentYear: number
  constructor(private apiService: ApiService) { }

  ngOnInit() {
    this._getSocialMediaLink();
    this.currentYear = new Date().getFullYear()
  }

  _getSocialMediaLink() {
    this.apiService.getSocialMediaLinkFooter().subscribe(data => {

      if (data.response == 'Success') {
        this.facebook = data.data.facebook
        this.instagram = data.data.instragram
        this.youtube = data.data.youtube
        this.twitter = data.data.twitter
      }
    })
  }

}
