<div id="_header_container">

  <header class="_header">
    <nav class="_nav">
      <div class="_left">
        <a routerLink="/">
          <img alt="Lekarde" src="assets/img/logo.png">
        </a>
      </div>
      <div class="_middle">
        <ul class="_nav_links">

          <!-- (click)="_redirect(category)" [innerHtml]="category[0]" -->
          <li *ngFor="let category_p of parentCategory"
            [ngClass]="{'_active' : category_p == activeMenuCategory,'_current' : pageUrl == menuItems[category_p].cat_name}"
            (mouseover)="_navigationDropdown('over',category_p)" (mouseout)="_navigationDropdown('out',category_p)">
            <a class="_main_title" [routerLink]="'/'+menuItems[category_p].cat_url"
              (click)="_updateMeta(menuItems[category_p])">{{menuItems[category_p].cat_name}}</a>
            <div class="_menu_main" *ngIf="menuParents[category_p]?.length > 0">
              <div class="innernav">
                <div class="navig">
                  <ul>
                    <li class="_menu_cards" *ngFor="let category_s of menuParents[category_p]">
                      <a [routerLink]=""><strong>{{menuItems[category_s].cat_name}}</strong> </a>
                      <ul class="_inner_nav" *ngIf="menuParents[category_s]?.length > 0">
                        <li *ngFor="let category of menuParents[category_s]">
                          <a (click)="_updateMeta(menuItems[category])"
                            routerLink="/{{menuItems[category_p].cat_url}}/{{menuItems[category_s].cat_url}}/{{menuItems[category].cat_url}}">{{menuItems[category].cat_name}}</a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

          </li>
        </ul>
      </div>
      <div class="_right">
        <ul>
          <li class="_user">
            <div class="_dropdown">
              <a href="javascript:;" (click)="_openUserDropdown()" class="_profile_picture"><img alt="Lekarde"
                  class="_pic" [ngClass]="{'_width100' : hasProfilePicture, '_display_picture' : !hasProfilePicture}"
                  [src]="profile_picture" /></a>
              <div class="_dropdown-content" [ngClass]="{'_active':isUserDropdownActive}">
                <ng-container *ngIf="isUserLoggedIn else logoutTemplate">
                  <a routerLink="/dashboard/profile">Profile <i class="fa fa-tachometer" aria-hidden="true"></i></a>
                  <a routerLink="/dashboard/orders">My Orders <i class="fa fa-gift" aria-hidden="true"></i></a>
                  <a routerLink="/dashboard/wishlist">WishList <i class="fa fa-heart" aria-hidden="true"></i></a>
                  <a [routerLink]="" (click)="_logout()">Logout <i class="fa fa-lock" aria-hidden="true"></i></a>
                </ng-container>
              </div>
            </div>
          </li>
          <!-- <li class="_cart">
            <a routerLink="/"><img alt="Lekarde" src="assets/img/cart-icon.png" width="23"></a>
          </li> -->
          <li class="_mobile_menu" [ngClass]="{'_active' : isMobileMenuActive}" (click)="_mobile_menu()">
            <i *ngIf="!isMobileMenuActive" class="fa fa-bars" aria-hidden="true"></i>
            <i *ngIf="isMobileMenuActive" class="fa fa-minus" aria-hidden="true"></i>
          </li>
        </ul>
      </div>
    </nav>
  </header>
</div>

<!-- <div class="_searchResults">
  <div class="_grid">
    <a href="/product/info/wedding/wedding_card">
      <label>Wedding Card</label>
      <span class="_price"><i aria-hidden="true" class="fa fa-inr"></i>499</span>
    </a>
  </div>
  <div class="_grid">
    <a href="/product/info/wedding/wedding_card">
      <label>Wedding Card</label>
      <span class="_price"><i aria-hidden="true" class="fa fa-inr"></i>499</span>
    </a>
  </div>
  <div class="_grid">
    <a href="/product/info/wedding/wedding_card">
      <label>Wedding Card</label>
      <span class="_price"><i aria-hidden="true" class="fa fa-inr"></i>499</span>
    </a>
  </div>
</div> -->

<!-- Mobile Menu -->
<div class="_mobile_menu_grid" [ngClass]="{'_active' : isMobileMenuActive}">
  <ul class="_nav_links">
    <li *ngFor="let category_p of parentCategory"
      [ngClass]="{'_active' : category_p == activeMenuCategory,'_current' : pageUrl == menuItems[category_p].cat_name}"
      (mouseover)="_navigationDropdown('over',category_p)" (mouseout)="_navigationDropdown('out',category_p)">
      <a class="_main_title" [routerLink]="'/'+menuItems[category_p].cat_url">{{menuItems[category_p].cat_name}}</a>
      <div class="" *ngIf="menuParents[category_p]?.length > 0">
        <div class="innernav">
          <div class="navig">
            <ul>
              <li class="_menu_cards" *ngFor="let category_s of menuParents[category_p]">
                <a [routerLink]=""><strong>{{menuItems[category_s].cat_name}}</strong> </a>
                <ul class="_inner_nav" *ngIf="menuParents[category_s]?.length > 0">
                  <li *ngFor="let category of menuParents[category_s]">
                    <a
                      routerLink="/{{menuItems[category_p].cat_url}}/{{menuItems[category_s].cat_url}}/{{menuItems[category].cat_url}}">{{menuItems[category].cat_name}}</a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </li>
  </ul>
</div>

<!-- Search Overlay -->
<div class="_search_overlay" *ngIf="isSearchOverlayActive">
  <i class="fa fa-times _close" aria-hidden="true" (click)="_closeMobileSearch()"></i>
  <div class="_search">
    <input id="serach" name="serach" placeholder="Search Invites" type="text">
    <button type="submit"><img src="assets/img/search-icon.png"></button>
  </div>
</div>


<ng-template #logoutTemplate>
  <a routerLink="/login">Login <i class="fa fa-user" aria-hidden="true"></i></a>
  <a routerLink="/registration">Signup <i class="fa fa-user" aria-hidden="true"></i></a>
</ng-template>