import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate
} from '@angular/router';
import { AuthSessionParamsService } from './auth-session-params.service';
@Injectable({
  providedIn: 'root'
})
export class AuthBeforeSessionGuardService {

  constructor(private router: Router, private authSession: AuthSessionParamsService) { }
  canActivate(): boolean {
    let isAuthenticated = this.authSession.isUserParamsAuthenticated()
    if (isAuthenticated) {
      this.router.navigate(['customize']);
      return false;
    } else {
      return true;
    }
  }
}
