import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'
import { ApiService } from "../service/api.service"
import { environment } from 'src/environments/environment';
interface ProductDetail {
  cat_id: string,
  created_date: string,
  description: string,
  home_page: string,
  image_url: string,
  meta_desc: string,
  meta_keywords: string,
  meta_title: string,
  ordering: string,
  price: string,
  prod_id: string,
  prod_type: string,
  product_name: string,
  product_url_name: string,
  short_description: string,
  sku: string,
  spl_price: string,
  status: string,
  video_url: string
}

@Component({
  selector: 'app-product-vertical',
  templateUrl: './product-vertical.component.html',
  styles: []
})
export class ProductVerticalComponent implements OnInit {
  isProductLoading: boolean = true
  selectedProduct: any
  category: string = ''
  product: ProductDetail;
  productOrientation: string = ''
  productMediaPath: string = '';
  isvideoActive: boolean = false
  videoRef: any;
  constructor(private route: ActivatedRoute, private router: Router, private apiService: ApiService) {
    this.productMediaPath = environment.PROD_BASEPATH
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.selectedProduct = params
      this.category = params.category
      this.apiService.getProductDetail(params).subscribe(data => {
        if (data.response == 'success') {
          this.isProductLoading = false
          this.product = data.data
          this.productOrientation = data.data.prod_type
        }
      })
    })
  }

  _redirectToCustomize(productUrl: string) {
    if (productUrl) {
      localStorage.setItem('url', `/vcard/create/${productUrl}`);
    }
    this.router.navigate([`/vcard/create/${productUrl}`])
  }

  _openVideo() {
    this.isvideoActive = true
  }

}
