<div class="_main_container _header_shadow">
  <app-header></app-header>
</div>

<div class="_main_container" *ngIf="isProductLoading">
  <p class="_loading">
    <span class="loader"></span>
    <span class="_text">Loading..</span>
  </p>
</div>

<div class="_main_container _view_bg _shared_container" *ngIf="!isProductLoading">
  <div class="_view_content _horizontal">
    <div class="_view_left" style="padding-top: 0;">


      <ng-container *ngIf="rsvp_type == 'vcard'">
        <ng-container *ngIf="order.card_url else orderProcessingTemplate">
          <div class="_view_card _shared_video">
            <iframe [src]="order.card_url + '?autoplay=1&loop=1&color=ffffff&byline=0&portrait=0' | safeUrl"
              style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0"
              allow="autoplay; fullscreen" allowfullscreen></iframe>
            <script src="https://player.vimeo.com/api/player.js"></script>
          </div>
          <div class="_share_order">
            <h1>
              Share your Invite
            </h1>
            <ng-container *ngIf="order.card_url != null">
              <a href="javascript:;" title="Share on Whatsapp"
                (click)="_whatsappShare('whatsapp',sharedUrl,'vcard')"><img src="assets/img/whatsapp.png"
                  width="15" /></a>
              <a href="javascript:;" title="Share on Facebook"
                (click)="_whatsappShare('facebook',sharedUrl,'vcard')"><img src="assets/img/facebook.png"
                  width="15" /></a>
            </ng-container>
            <ng-container *ngIf="sharedUrl">
              <a href="javascript:;" title="Click to copy url" ngxClipboard *ngxClipboardIfSupported
                (cbOnSuccess)="_copied($event)" [cbContent]="'https://lekarde.com/share/' +'vcard/'+ sharedUrl"><img
                  src="assets/img/clipboard.png" width="14" /></a>
            </ng-container>
            <ng-container *ngIf="order.card_url && !sharedUrl">
              <a href="javascript:;" title="Click to copy url" ngxClipboard *ngxClipboardIfSupported
                (cbOnSuccess)="_copied($event)"
                [cbContent]="'https://lekarde.com/view/vcard/' +sharedOrderParams.order_number+'/'+sharedOrderParams.order_id "><img
                  src="assets/img/clipboard.png" width="14" /></a>
            </ng-container>
          </div>
        </ng-container>
        <ng-template #orderProcessingTemplate>
          <h1 class="_video_process">Video creation is in process. Please check back later.</h1>
        </ng-template>
      </ng-container>

      <ng-container *ngIf="rsvp_type == 'ecard' && order.card_url">
        <div class="_image_process">
          <img [src]="'http://lekarde.com/authority/'+order.card_url">
        </div>
        <div class="_share_order">
          <h1>
            Share your Invite
          </h1>
          <ng-container *ngIf="order.card_url != null">
            <a href="javascript:;" title="Share on Whatsapp" (click)="_whatsappShare('whatsapp',sharedUrl,'ecard')"><img
                src="assets/img/whatsapp.png" width="20" /></a>
            <a href="javascript:;" title="Share on Facebook" (click)="_whatsappShare('facebook',sharedUrl,'ecard')"><img
                src="assets/img/facebook.png" width="20" /></a>
            <ng-container *ngIf="sharedUrl">
              <a href="javascript:;" title="Click to copy url" ngxClipboard *ngxClipboardIfSupported
                (cbOnSuccess)="_copied($event)" [cbContent]="'https://lekarde.com/share/' +'ecard/'+ sharedUrl"><img
                  src="assets/img/clipboard.png" width="19" /></a>
            </ng-container>
            <ng-container *ngIf="order.card_url && !sharedUrl">
              <a href="javascript:;" title="Click to copy url" ngxClipboard *ngxClipboardIfSupported
                (cbOnSuccess)="_copied($event)"
                [cbContent]="'https://lekarde.com/view/ecard/' +sharedOrderParams.order_number+'/'+sharedOrderParams.order_id "><img
                  src="assets/img/clipboard.png" width="14" /></a>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>



      <ng-container *ngIf="order_rsvp">
        <div class="_view_right">
          <h2 class="_view_heading">RSVP</h2>
          <ng-container *ngIf="events?.length > 0">
            <div class="_view_inner" *ngFor="let e of events; let i = index">
              <h3 class="_inner_heading" [innerHTML]="e.event"></h3>
              <div class="_view_part_grid_left">
                <div class="_view_inner_left">
                  <strong class="_view_in_heading">Will you attend?</strong>
                  <div class="_view_btn">
                    <ng-container *ngIf="rsvpHeaders?.length > 0 else emptyTemplate">
                      <ng-container *ngFor="let headers of rsvpHeaders; let i = index">
                        <a [ngClass]="{'_v_button_y' : i == 0,'_v_button_n' : i == 1,'_v_button_m' : i == 2,'_v_button_d' : i == 3 , '_active' : i == selectedAttendOption}"
                          [routerLink]="" (click)="_selectAttendOption(i);"><i class="fa fa-check"
                            aria-hidden="true"></i><span class="_v_span" [innerHTML]="headers"></span></a>
                      </ng-container>
                    </ng-container>
                  </div>
                </div>
              </div>

              <div class="_view_part_grid_right">
                <div class="_view_inner_right">
                  <strong class="_view_in_heading">Message from host</strong>
                  <p class="_view_text" *ngIf="e.message else emptyTemplate" [innerHTML]="e.message"></p>
                </div>
              </div>

              <div class="_rsvp_response_form" [ngStyle]="{'float' : 'left', 'width' : '100%' , 'margin-top' : '30px'}">
                <agm-map [latitude]="e.geocodes.latitude" [longitude]="e.geocodes.longitude" [zoom]="e.geocodes.zoom">
                  <agm-marker [latitude]="e.geocodes.latitude" [longitude]="e.geocodes.longitude"
                    [markerDraggable]="false" [iconUrl]="_eventMarker">
                  </agm-marker>
                </agm-map>

                <div class="_view_map">
                  <a class="_cal_btn"
                    [href]="'https://maps.google.com/?q='+e.geocodes.latitude+','+e.geocodes.longitude+'&z=17'"
                    target="_blank"><i class="fa fa-map" aria-hidden="true"></i><span class="_cal_text">Click here to
                      view
                      on google map</span></a>
                </div>
                <div class="_view_map _rsvp_address">
                  <b>Address :</b> {{e.geocodes.address}}<br>
                  <b>Hosted By / Mobile :</b> {{e.hostedBy}} / {{e.hostedByMobile}}
                </div>

              </div>

              <div class="_rsvp_response_form _help_form">
                <form class="_form_desk " [formGroup]="rsvpResponseFormGroup" (ngSubmit)="_submitRsvpResponse(i)">
                  <div class="_field _inputs" [ngClass]="{'_invalid' : formControlValid('name')}">
                    <input type="text" placeholder="Name" formControlName="name" name="name" id="name" />
                    <span class="_error">
                      Name can not be blank
                    </span>
                  </div>
                  <div class="_field _inputs" [ngClass]="{'_invalid' : formControlValid('email')}">
                    <input type="text" placeholder="Email" formControlName="email" name="email" id="email" />
                    <span class="_error">
                      Email not valid
                    </span>
                  </div>
                  <div class="_field _inputs" [ngClass]="{'_invalid' : formControlValid('mobile')}">
                    <input type="text" placeholder="Mobile" formControlName="mobile" name="mobile" id="mobile"
                      maxlength="16" />
                    <span class="_error">
                      Mobile number not valid
                    </span>
                  </div>
                  <div class="_field _inputs">
                    <textarea placeholder="Address" class="_input_text_area" formControlName="address" name="address"
                      id="address"> </textarea>
                  </div>
                  <div class="_checkout">
                    <button class="_action" [disabled]="rsvpResponseFormGroup.invalid">Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>



    </div>
  </div>
</div>

<ng-template #emptyTemplate>
  <p class="_no_data_message">--</p>
</ng-template>

<div class="_clearfix"></div>
<app-footer></app-footer>