<div class="_main_container _header_shadow">
  <app-header></app-header>
</div>

<div class="_main_container">
  <div class="_social_container">


    <div class="_form_parent">

      <p class="_content">Continue with email:</p>
      <form class="_form_sign custom-forms" novalidate="" [formGroup]="resetPasswordFormGroup"
        (ngSubmit)="_resetPassword()">
        <div class="_form_field" [ngClass]="{'_invalid' : formControlValid('password')}">
          <input formControlName="password" id="password" name="password" placeholder="Password (e.g. Xakmasn@01)"
            type="password">
          <span class="_password_error">Must have one Capital letter, Number, Special char and length must be 8 or
            greater</span>
        </div>
        <div class="_form_field" [ngClass]="{'_invalid' : formControlValid('passwordConfirm')}">
          <input formControlName="passwordConfirm" id="passwordConfirm" name="passwordConfirm"
            placeholder="Confirm Password (e.g. Xakmasn@01)" type="password">
          <span class="_password_error">Must have matched with password.</span>
        </div>
        <div class="_action">
          <div class="_btn">
            <button type="submit" [disabled]="resetPasswordFormGroup.invalid"> Reset </button>
          </div>
        </div>
      </form>


    </div>
  </div>

</div>


<div class="_clearfix"></div>
<app-footer></app-footer>