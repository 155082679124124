import * as tslib_1 from "tslib";
import { OnInit, NgZone } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { ApiService } from "../service/api.service";
import { NotificationService } from "../service/notification.service";
import { environment } from 'src/environments/environment';
import { FormBuilder, FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { MapsAPILoader } from '@agm/core';
var RsvpComponent = /** @class */ (function () {
    function RsvpComponent(formBuilder, apiService, nfs, router, route, mapsAPILoader, ngZone) {
        var _this = this;
        this.formBuilder = formBuilder;
        this.apiService = apiService;
        this.nfs = nfs;
        this.router = router;
        this.route = route;
        this.mapsAPILoader = mapsAPILoader;
        this.ngZone = ngZone;
        // Product
        this.isProductLoading = true;
        this.orientation = '';
        this.productName = '';
        this.productImage = '';
        this.productPrice = 0;
        this.domainPrice = 0;
        // RSVP 
        this.activeAccordionTab = 0;
        this.productMediaPath = '';
        this.isRsvpFormActive = false;
        this.isCoHostActive = false;
        this.isCustomUrlFormActive = false;
        this.isEventAdded = false;
        this.isCustomUrlInputActive = false;
        this.mapAddress = "https://www.google.com/maps/embed?origin=mfe&pb=!1m3!2m1!1sgurugram+sec+49!6i18";
        this.isSubmitDisabled = false;
        this.personalisedLink = '';
        this.personalisedLinkAvailable = true;
        this.submitButtonText = 'Proceed to checkout';
        this.personalisedLinkAvailableText = '';
        this.coHostCount = 0;
        this.coHostArray = [];
        this.coHostData = [];
        this.rzp_order_id = "";
        this.totalPrice = 0;
        this.orderTotalAmount = 0;
        this.todayDate = new Date();
        this.numberOfTickets = 0;
        this.submitted = false;
        this._coHosts = [];
        this._mapUrls = ['https://www.google.com/maps/embed?origin=mfe&pb=!1m3!2m1!1sgurugram+sec+49!6i18'];
        this.coupons = [];
        this.isCouponFormActive = false;
        this.countDiscountAmount = 0;
        this._eventMarker = "assets/img/agm-marker.png";
        this._eventLocations = [{
                'latitude': 28.4479248,
                'longitude': 77.0582817,
                'address': "",
                'zoom': 18
            }];
        this.appliedCouponInfo = "";
        this.isFormValidationComplete = true;
        this.productMediaPath = environment.PROD_BASEPATH;
        this.route.params.subscribe(function (p) {
            if (p.product_url && p.rsvp_type) {
                _this.rsvp_type = p.rsvp_type;
                _this.productUrl = p.product_url;
            }
        });
    }
    RsvpComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.productUrl) {
            if (this.rsvp_type == 'ecard') {
                var order = localStorage.getItem('e_order');
                if (order != null) {
                    order = JSON.parse(order);
                    this.order = order;
                    this.apiService.getRsvpDetails(tslib_1.__assign({}, order, { 'rsvp_type': this.rsvp_type })).subscribe(function (data) {
                        if (data.response == 'success') {
                            _this.isProductLoading = false;
                            _this.orientation = data.data.orientation;
                            _this.productName = data.data.product_name;
                            _this.productImage = data.data.image_url;
                            _this.productPrice = parseFloat(data.data.price);
                            _this.domainPrice = parseInt(data.data.customized_domain_price);
                            _this.totalPrice = _this.productPrice;
                            _this.orderTotalAmount = _this.productPrice;
                        }
                    });
                }
            }
            else {
                var order = localStorage.getItem('order');
                if (order != null) {
                    order = JSON.parse(order);
                    this.order = order;
                    this.apiService.getRsvpDetails(tslib_1.__assign({}, order, { 'rsvp_type': this.rsvp_type })).subscribe(function (data) {
                        if (data.response == 'success') {
                            _this.isProductLoading = false;
                            _this.orientation = data.data.orientation;
                            _this.productName = data.data.product_name;
                            _this.productImage = data.data.image_url;
                            _this.productPrice = parseFloat(data.data.price);
                            _this.domainPrice = parseInt(data.data.customized_domain_price);
                            _this.totalPrice = _this.productPrice;
                            _this.orderTotalAmount = _this.productPrice;
                        }
                    });
                }
            }
        }
        // On Routing Page load to top 
        this.router.events.subscribe(function (evt) {
            if (evt instanceof NavigationEnd) {
                _this.router.navigated = false;
                window.scrollTo(0, 0);
            }
        });
        this.rsvpForm = new FormGroup({
            customUrl: new FormControl('', [Validators.maxLength(150)]),
            events: new FormArray([]),
            coupon: new FormControl(''),
        });
        // this.apiService.getCoupons().subscribe(data => {
        //   if (data.response == 'success') {
        //     this.coupons = data.data
        //     console.log(this.coupons)
        //   }
        // })
    };
    Object.defineProperty(RsvpComponent.prototype, "f", {
        get: function () { return this.rsvpForm.controls; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RsvpComponent.prototype, "t", {
        get: function () { return this.f.events; },
        enumerable: true,
        configurable: true
    });
    RsvpComponent.prototype._addMoreEvent = function (mode) {
        var _this = this;
        if (mode === void 0) { mode = null; }
        if (mode == 'add') {
            this.numberOfTickets++;
            this._mapUrls.push(this.mapAddress);
        }
        if (this.t.length < this.numberOfTickets) {
            var _loop_1 = function (i) {
                this_1.t.push(this_1.formBuilder.group({
                    message: new FormControl('', [Validators.required, Validators.maxLength(600)]),
                    event: ['', [Validators.required, Validators.maxLength(100)]],
                    hostedBy: ['', [Validators.required, Validators.maxLength(100)]],
                    hostedByMobile: ['', [Validators.required, Validators.pattern(/^[0-9]+$/), Validators.maxLength(15), Validators.minLength(6)]],
                    heldOn: ['', [Validators.required]],
                    location: ['', [Validators.required]],
                    address: ['', [Validators.required, Validators.maxLength(200)]],
                    city: ['', [Validators.required, Validators.pattern('[a-zA-Z ]*')]],
                    state: ['', [Validators.required, Validators.pattern('[a-zA-Z ]*')]],
                    postal: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(6), Validators.maxLength(6)]],
                    event_location: ['']
                }));
                // Init AGM
                this_1._eventLocations.push({
                    'latitude': 28.4479248,
                    'longitude': 77.0582817,
                    'address': "",
                    'zoom': 18
                });
                setTimeout(function () {
                    _this._agmInit(i);
                }, 2000);
            };
            var this_1 = this;
            for (var i = this.t.length; i < this.numberOfTickets; i++) {
                _loop_1(i);
            }
        }
        else {
            for (var i = this.t.length; i >= this.numberOfTickets; i--) {
                this.t.removeAt(i);
            }
        }
    };
    RsvpComponent.prototype._removeAddEventForm = function (i) {
        if (i > 0) {
            this.numberOfTickets = this.numberOfTickets - 1;
            this.t.removeAt(i);
        }
    };
    /* Co-Host */
    RsvpComponent.prototype._addCoHost = function (index) {
        if (this.numberOfTickets > 0) {
            var _checkExist = this._coHosts["event_" + index];
            if (_checkExist == undefined) {
                this._coHosts["event_" + index] = [{ 'name': "", 'mobile': "" }];
            }
            else {
                var _costHostLength = this._coHosts["event_" + index].length;
                if (_costHostLength < 4) {
                    this._coHosts["event_" + index].push({ 'name': "", 'mobile': "" });
                }
                else {
                    this.nfs.showNotification("default", 'Multiple Co-Host limit exceeds.');
                    return false;
                }
            }
        }
    };
    RsvpComponent.prototype._removeCoHost = function (j, index) {
        if (this._coHosts["event_" + index] != undefined) {
            this._coHosts["event_" + index].splice(j, 1);
        }
    };
    RsvpComponent.prototype._getCoHostValue = function (event, j, mode, index) {
        if (mode == "name") {
            this._coHosts["event_" + index][j].name = event.target.value;
        }
        else if (mode == "mobile") {
            this._validateMobileNumber(event);
            this._coHosts["event_" + index][j].mobile = event.target.value;
        }
    };
    RsvpComponent.prototype._validateMobileNumber = function (e, i) {
        if (i === void 0) { i = null; }
        var regex = /^[0-9]+$/;
        var valid = regex.test(e.target.value);
        if (valid) {
            return true;
        }
        else {
            e.target.value = "";
            return false;
        }
    };
    /* Co-Host */
    RsvpComponent.prototype.accordionAction = function (tab) {
        if (this.activeAccordionTab == tab) {
            this.activeAccordionTab = 0;
        }
        else {
            this.activeAccordionTab = tab;
        }
    };
    RsvpComponent.prototype._isRsvpChecked = function (event) {
        var isChecked = event.target.checked;
        this.isRsvpFormActive = isChecked;
        if (isChecked) {
            this.numberOfTickets = 1;
            this._addMoreEvent();
        }
        else {
            this._coHosts = [];
            this.numberOfTickets = 0;
        }
        if (!this.isRsvpFormActive) {
            this.rsvpForm.reset();
            this.isEventAdded = false;
        }
    };
    RsvpComponent.prototype._loadMap = function (index) {
        var model = this.rsvpForm.value;
        var events = model.events;
        if (events.length > 0) {
            model = events[index];
            if (model.address || model.state || model.city) {
                this.isEventAdded = true;
                var mapAddress = model.location + " " + model.address + " " + model.city + " " + model.state;
                this._mapUrls[index] = "https://maps.google.com/maps?q=" + mapAddress + "&t=&z=18&ie=UTF8&iwloc=&output=embed";
            }
            else {
                this.isEventAdded = false;
            }
        }
    };
    RsvpComponent.prototype._isCustomUrlChecked = function (event) {
        var isChecked = event.target.checked;
        this.isCustomUrlFormActive = isChecked;
        if (!this.isCustomUrlFormActive) {
            this.totalPrice = this.productPrice;
            this.rsvpForm.get('customUrl').setValue("");
            this.isCouponFormActive = false;
            var _c = document.getElementById("_coupon_active");
            _c.checked = false;
            this.rsvpForm.get('coupon').setValue("");
            this.countDiscountAmount = 0;
        }
        else {
            this.totalPrice = (this.productPrice - this.countDiscountAmount) + this.domainPrice;
        }
    };
    RsvpComponent.prototype._isCouponChecked = function (event) {
        var isChecked = event.target.checked;
        this.isCouponFormActive = isChecked;
        if (!this.isCouponFormActive) {
            this.rsvpForm.get('coupon').setValue("");
            this.countDiscountAmount = 0;
            var a = 0;
            if (this.isCustomUrlFormActive) {
                a = this.domainPrice;
            }
            this.totalPrice = (this.productPrice - this.countDiscountAmount) + a;
            this.appliedCouponInfo = "";
        }
    };
    RsvpComponent.prototype._applyCoupon1 = function () {
        var _this = this;
        var coupon = this.rsvpForm.get('coupon').value;
        console.log(coupon);
        if (coupon == "") {
            this.nfs.showNotification("default", 'Enter Coupon');
            return false;
        }
        var productPrice = this.productPrice;
        this.apiService.getCoupon(coupon, this.rsvp_type).subscribe(function (data) {
            if (data.response == 'success') {
                // this.coupons = data.data
                var couponRow = data.data;
                var couponCode = couponRow.coupon_code;
                var couponType = couponRow.coupon_type;
                _this.rsvpForm.get('coupon').setValue(couponCode);
                if (couponType == "amount") {
                    var couponAmount = parseInt(couponRow.coupon_value);
                    _this.appliedCouponInfo = couponAmount + " Rs. coupon applied.";
                    _this.countDiscountAmount = couponAmount;
                    var a = 0;
                    if (_this.isCustomUrlFormActive) {
                        a = _this.domainPrice;
                    }
                    _this.totalPrice = productPrice - _this.countDiscountAmount + a;
                }
                else if (couponType == "percent") {
                    var couponPercent = parseInt(couponRow.coupon_value);
                    _this.appliedCouponInfo = couponPercent + "% discount coupon applied.";
                    var percentAmount = (couponPercent / 100) * productPrice;
                    _this.countDiscountAmount = Math.ceil(percentAmount);
                    var a = 0;
                    if (_this.isCustomUrlFormActive) {
                        a = _this.domainPrice;
                    }
                    // ONLY FOR TESTING
                    if (couponPercent == 100) {
                        _this.totalPrice = 1;
                        return false;
                    }
                    _this.totalPrice = productPrice - _this.countDiscountAmount + a;
                }
            }
            else if (data.response == 'invalid') {
                _this.appliedCouponInfo = "";
                _this.nfs.showNotification("default", 'Invalid Coupon');
                return false;
            }
        });
    };
    RsvpComponent.prototype._applyCoupon = function (i) {
        var productPrice = this.productPrice;
        if (i > -1) {
            var couponCode = this.coupons[i].coupon_code;
            var couponType = this.coupons[i].coupon_type;
            this.rsvpForm.get('coupon').setValue(couponCode);
            if (couponType == "amount") {
                var couponAmount = parseInt(this.coupons[i].coupon_value);
                this.appliedCouponInfo = couponAmount + " Rs. coupon applied.";
                this.countDiscountAmount = couponAmount;
                var a = 0;
                if (this.isCustomUrlFormActive) {
                    a = this.domainPrice;
                }
                this.totalPrice = productPrice - this.countDiscountAmount + a;
            }
            else if (couponType == "percent") {
                var couponPercent = parseInt(this.coupons[i].coupon_value);
                this.appliedCouponInfo = couponPercent + "% discount coupon applied.";
                var percentAmount = (couponPercent / 100) * productPrice;
                this.countDiscountAmount = Math.ceil(percentAmount);
                var a = 0;
                if (this.isCustomUrlFormActive) {
                    a = this.domainPrice;
                }
                // ONLY FOR TESTING
                if (couponPercent == 100) {
                    this.totalPrice = 1;
                    return false;
                }
                this.totalPrice = productPrice - this.countDiscountAmount + a;
            }
        }
    };
    RsvpComponent.prototype._activeCustomUrl = function () {
        this.isCustomUrlInputActive = true;
        setTimeout(function () {
            document.getElementById("customUrl").focus();
        }, 200);
    };
    RsvpComponent.prototype._addEvent = function () {
        var _this = this;
        this.submitted = true;
        var postdata = {};
        var coHostOutput = [];
        // IF BOTH CHECKBOX IS NOT CHECKED
        if (!this.isRsvpFormActive && !this.isCustomUrlFormActive) {
            var model = this.rsvpForm.value;
            postdata = { 'coupon': model.coupon, 'unChecked': "both", 'rsvpType': this.rsvp_type, 'rsvpStyle': "", 'order_no': this.order.order_no, 'product_id': this.order.prod, 'coHostedBy': "" };
            this._saveCheckout(postdata);
            return false;
        }
        else {
            // WHEN RSVP ACTIVE
            if (this.isRsvpFormActive) {
                if (!this.rsvpForm.valid) {
                    this.isFormValidationComplete = false;
                    setTimeout(function () {
                        _this.isFormValidationComplete = true;
                    }, 5000);
                    return false;
                }
            }
            // WHEN CUSTOM URL ACTIVE
            if (this.isCustomUrlFormActive) {
                var url = this.rsvpForm.get('customUrl').value;
                if (!url) {
                    var _el = document.getElementById('customUrl');
                    if (_el == null) {
                        this.isCustomUrlInputActive = true;
                        setTimeout(function () {
                            document.getElementById('customUrl').focus();
                        }, 200);
                    }
                    else {
                        _el.focus();
                        return false;
                    }
                    this.isSubmitDisabled = true;
                    return false;
                }
                else {
                    this.isSubmitDisabled = false;
                }
            }
            if (this.isRsvpFormActive) {
                var rsvpStyle = JSON.stringify(["Yes", "No", "May be", "Decide Later"]);
            }
            else {
                var rsvpStyle = JSON.stringify([]);
            }
            //console.log(this._coHosts);
            var model = this.rsvpForm.value;
            var events = model.events;
            var b = [];
            var i = 0;
            for (var _i = 0, events_1 = events; _i < events_1.length; _i++) {
                var e = events_1[_i];
                var geocodes = [];
                if (this._eventLocations[i] != undefined) {
                    geocodes = this._eventLocations[i];
                }
                b.push(tslib_1.__assign({}, e, { 'coHostedBy': this._coHosts["event_" + i], 'geocodes': geocodes }));
                i++;
            }
            postdata = { 'events': JSON.stringify(b), 'rsvpType': this.rsvp_type, 'customUrl': model.customUrl, 'coupon': model.coupon, 'rsvpStyle': rsvpStyle, 'order_no': this.order.order_no, 'product_id': this.order.prod };
            this._saveCheckout(postdata);
        }
    };
    RsvpComponent.prototype._saveCheckout = function (data) {
        var _this = this;
        this.submitButtonText = "Processing...";
        this.apiService.postCheckout(data).subscribe(function (res) {
            if (res.response == 'success') {
                if (res.data.order_no) {
                    var order_no = res.data.order_no;
                    if (order_no == _this.order.order_no) {
                        if (_this.rsvp_type == "vcard") {
                            _this._createRzpOrder(order_no);
                        }
                        else if (_this.rsvp_type == "ecard") {
                            _this._createRzpOrder(order_no);
                        }
                        else {
                            _this.router.navigate(['/dashboard', 'orders']);
                        }
                    }
                }
            }
            else if (res.response == "coupon_invalid") {
                _this.nfs.showNotification("default", 'Invalid Coupon!');
                return false;
            }
            else {
                _this.nfs.showNotification("default", 'Please check inputs and try again.');
                return false;
            }
        });
    };
    /* AGM GOOGLE MAPS */
    RsvpComponent.prototype._agmInit = function (i) {
        var _this = this;
        // this.searchElementRef.nativeElement
        var elem = document.getElementById("_location_" + i);
        this.mapsAPILoader.load().then(function () {
            _this.geoCoder = new google.maps.Geocoder;
            // let opts = {
            //   types: ['(cities)']
            // }
            var autocomplete = new google.maps.places.Autocomplete(elem);
            autocomplete.addListener("place_changed", function () {
                _this.ngZone.run(function () {
                    var place = autocomplete.getPlace();
                    if (place.geometry === undefined || place.geometry === null) {
                        return;
                    }
                    _this._eventLocations[i].latitude = place.geometry.location.lat();
                    _this._eventLocations[i].longitude = place.geometry.location.lng();
                    _this._eventLocations[i].zoom = 18;
                });
            });
        });
    };
    RsvpComponent.prototype._setLocationPointer = function ($event, i) {
        this._eventLocations[i].latitude = $event.coords.lat;
        this._eventLocations[i].longitude = $event.coords.lng;
        this._getLocationAddress($event.coords.lat, $event.coords.lng, i);
    };
    RsvpComponent.prototype._getLocationAddress = function (latitude, longitude, i) {
        var _this = this;
        this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, function (results, status) {
            if (status === 'OK') {
                if (results[0]) {
                    _this._eventLocations[i].zoom = 18;
                    _this._eventLocations[i].address = results[0].formatted_address;
                    var dom = document.getElementById("_location_" + i);
                    dom.value = _this._eventLocations[i].address;
                }
            }
        });
    };
    /* AGM GOOGLE MAPS */
    /*
    Form Validations
    */
    RsvpComponent.prototype.formControlValid = function (field) {
        return (!this.rsvpForm.get(field).valid &&
            this.rsvpForm.get(field).touched);
    };
    RsvpComponent.prototype.validateAllFormFields = function (formGroup) {
        var _this = this;
        Object.keys(formGroup.controls).forEach(function (field) {
            var control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
            }
            else if (control instanceof FormGroup) {
                _this.validateAllFormFields(control);
            }
        });
    };
    RsvpComponent.prototype._checkUrlExist = function (e) {
        var _this = this;
        var url = this.rsvpForm.get('customUrl').value;
        if (url && url.trim() != '' && url.length > 2) {
            this.apiService.postCheckPersonalisedUrl({ 'customize_url': url, 'rsvp_type': this.rsvp_type }).subscribe(function (data) {
                if (data.response == 'success') {
                    if (!data.valid) {
                        _this.isSubmitDisabled = true;
                        _this.personalisedLinkAvailable = false;
                        _this.personalisedLinkAvailableText = 'This url is already taken.';
                        setTimeout(function () {
                            _this.personalisedLinkAvailableText = '';
                        }, 2000);
                    }
                    else {
                        _this.isSubmitDisabled = false;
                        _this.personalisedLinkAvailable = true;
                        _this.personalisedLinkAvailableText = 'Available';
                        setTimeout(function () {
                            _this.personalisedLinkAvailableText = '';
                        }, 2000);
                    }
                }
            });
        }
        else {
            this.isSubmitDisabled = true;
            // this.nfs.showNotification("default", 'Personalised link can not be blank.');
        }
    };
    RsvpComponent.prototype._createRzpOrder = function (order_no) {
        var _this = this;
        this.submitButtonText = "Loading...";
        if (order_no != undefined && order_no != "") {
            this.apiService.createRzpOrder({ order_no: order_no, type: this.rsvp_type }).subscribe(function (res) {
                if (res.response == "success") {
                    if (res.skip != undefined && res.skip == true) {
                        localStorage.removeItem('url');
                        _this.nfs.showNotification("default", 'Congrats! Your Order has been placed Successfully.');
                        if (_this.rsvp_type == "vcard") {
                            localStorage.removeItem('order');
                            window.location.href = "/dashboard/orders";
                        }
                        else if (_this.rsvp_type == "ecard") {
                            localStorage.removeItem('e_order');
                            window.location.href = environment.BASE_URL + "ecard/handler/" + order_no;
                        }
                        return false;
                    }
                    var orderData = res.orderData;
                    _this._initPayment(orderData);
                }
                else if (res.response == "coupon_invalid") {
                    _this.nfs.showNotification("default", 'Invalid Coupon!');
                    _this.submitButtonText = "Proceed to checkout";
                }
                else {
                    _this.nfs.showNotification("default", 'Failed to create order. RAZORPAY');
                }
            });
        }
    };
    //orderData.amount
    RsvpComponent.prototype._initPayment = function (orderData) {
        if (orderData != undefined && orderData.status == "created") {
            var pg_config = {
                "key": "rzp_live_Divj6uX2DdVU67",
                "amount": orderData.amount,
                "currency": "INR",
                "name": "Lekarde",
                "description": "",
                "image": "https://lekarde.com/live/assets/img/logo.png",
                "order_id": orderData.order_id,
                "handler": this._capturePayment.bind(this),
                "prefill": {
                    "name": orderData.user.name,
                    "email": orderData.user.email,
                    "contact": orderData.user.mobile
                },
                "notes": {
                    "address": orderData.user.address
                },
                "theme": {
                    "color": "#000000"
                },
                "modal": {
                    "ondismiss": this._rzrClose.bind(this)
                }
            };
            this.rzp = new window.Razorpay(pg_config);
            this.rzp.open();
        }
        else {
            this.submitButtonText = "Proceed to checkout";
        }
    };
    RsvpComponent.prototype._rzrClose = function ($this) {
        document.getElementById('_rsvpSubmit').innerHTML = "Proceed to checkout";
    };
    RsvpComponent.prototype._capturePayment = function (response) {
        var _this = this;
        this.submitButtonText = "Proceed to checkout";
        var checkKeys = Object.keys(response).length;
        if (checkKeys > 0) {
            this.apiService.capturePayment(tslib_1.__assign({}, response, { order_no: this.order.order_no, type: this.rsvp_type })).subscribe(function (res) {
                if (res.response == "success") {
                    localStorage.removeItem('url');
                    _this.nfs.showNotification("default", 'Congrats! Your Order has been placed Successfully.');
                    if (_this.rsvp_type == "vcard") {
                        localStorage.removeItem('order');
                        window.location.href = "/dashboard/orders";
                    }
                    else if (_this.rsvp_type == "ecard") {
                        localStorage.removeItem('e_order');
                        window.location.href = environment.BASE_URL + "ecard/handler/" + _this.order.order_no;
                    }
                }
                else {
                    _this.nfs.showNotification("default", 'Order Not Placed.');
                }
            });
        }
    };
    return RsvpComponent;
}());
export { RsvpComponent };
