import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'
import { FormGroup, FormControl, Validators } from "@angular/forms"
import { ApiService } from "../service/api.service"
import { NotificationService } from "../service/notification.service";
import { environment } from 'src/environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector';

interface RsvpData {
  city: string,
  held_on: string,
  image_url: string,
  location: string,
  message: string,
  postal_code: string,
  rsvp_style: any
  state: string
  street: string,
  card_type: string,
  rsvp_type: string,
  id: string,
  url: string,
  events: any,
  order_rsvp: boolean,
  card_url: string
}

interface sharedOrderParams {
  order_id: string,
  order_number: string
}

@Component({
  selector: 'app-sharedcard',
  templateUrl: './sharedcard.component.html',
  styles: []
})
export class SharedcardComponent implements OnInit {
  productMediaPath: string = ''
  sharedUrl: string = ''
  order: RsvpData;
  events: any = []
  isProductLoading: boolean = true
  rsvpHeaders: any;
  rsvpResponseFormGroup: FormGroup
  selectedAttendOption: number
  mapAddress: string = ""
  rsvp_type: string = ""
  rsvp_id: string = ""
  order_rsvp: boolean;

  name: string = ""
  email: string = ""
  _eventMarker = "assets/img/agm-marker.png"
  isMobile: boolean = false;

  sharedOrderParams: sharedOrderParams = { order_id: "", order_number: "" };
  constructor(private route: ActivatedRoute, private router: Router, private apiService: ApiService, private nfs: NotificationService, private deviceService: DeviceDetectorService) {
    this.productMediaPath = environment.PROD_BASEPATH
    let paths = this.route.snapshot.url

    if (paths[0].path == 'view') {
      if (paths[1].path == 'vcard') {
        this.rsvp_type = "vcard"
      } else if (paths[1].path == 'ecard') {
        this.rsvp_type = "ecard"
      }
      this.sharedOrderParams = { order_id: paths[3].path, order_number: paths[2].path }
    } else {
      if (paths[0].path == 'vcard') {
        this.rsvp_type = "vcard"
      } else if (paths[0].path == 'ecard') {
        this.rsvp_type = "ecard"
      } else {
        window.location.href = "/";
      }
      this.route.params.subscribe(param => {
        this.sharedUrl = param.shared_url
      })
    }


  }

  ngOnInit() {

    if (this.sharedUrl != '') {
      this._getOrderDataByUrl();
    } else {
      if (this.sharedOrderParams.order_id != "") {
        this._getOrderDataByUrl(this.sharedOrderParams);
      }
    }

    let _get_localstorage_user: any = localStorage.getItem('user');
    if (_get_localstorage_user != null && _get_localstorage_user != undefined) {
      _get_localstorage_user = JSON.parse(_get_localstorage_user);
      this.name = _get_localstorage_user.name
      this.email = _get_localstorage_user.email
    }

    this.rsvpResponseFormGroup = new FormGroup({
      name: new FormControl(this.name, [Validators.required]),
      email: new FormControl(this.email, [Validators.required, Validators.email]),
      mobile: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(16)]),
      address: new FormControl('')
    })

  }

  _getOrderDataByUrl(sharedOrderParams = null) {
    let params = { url: this.sharedUrl, type: this.rsvp_type }
    if (sharedOrderParams != null) {
      Object.assign(params, { order_id: sharedOrderParams.order_id, order_number: sharedOrderParams.order_number })
    }
    this.apiService.getOrderDataByUrl(params).subscribe(res => {
      if (res.response == 'success') {
        this.isProductLoading = false
        this.order = res.data
        this.events = JSON.parse(res.data.events);
        this.rsvp_id = this.order.id
        this.rsvpHeaders = JSON.parse(this.order.rsvp_style)
        this.rsvp_type = this.order.rsvp_type
        this.order_rsvp = res.data.order_rsvp
      }
    })
  }

  _selectAttendOption(i) {
    this.selectedAttendOption = i
  }


  _submitRsvpResponse(i: number) {
    if (this.rsvpResponseFormGroup.valid) {
      if (this.selectedAttendOption != undefined) {
        let eventTitle = this.events[i].event
        let rsvpFinalOutput = { ...this.rsvpResponseFormGroup.value, 'event': eventTitle, 'attend': this.rsvpHeaders[this.selectedAttendOption], 'rsvp_id': this.rsvp_id, 'rsvp_type': this.rsvp_type }
        this.apiService.postRsvpResponse(rsvpFinalOutput).subscribe(res => {
          if (res.response == 'success') {
            this.selectedAttendOption = undefined
            this.rsvpResponseFormGroup.reset();
            this.nfs.showNotification("default", 'Sent');
          } else {
            this.nfs.showNotification("default", 'Already Submitted.');
          }
        })
      } else {
        this.nfs.showNotification("default", 'Will you attend?');
      }
    } else {
      this.nfs.showNotification("default", 'Inputs not valid.');
    }
  }

  _copied(event) {
    if (event.isSuccess) {
      this.nfs.showNotification("default", 'Copied !');
    }
  }


  _whatsappShare(mode: string, card_url: string, type: string) {
    if (card_url == "" || card_url == undefined) {
      card_url = window.location.href
      if (type == "ecard") {
        if (mode == "whatsapp") {
          let sharer = "https://web.whatsapp.com/send?text=" + encodeURI(`${card_url}`);
          window.open(sharer, 'sharer', 'width=300,height=300,menubar=0,toolbar=0');
        } else if (mode == "facebook") {
          let sharer = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURI(`${card_url}`);
          window.open(sharer, 'sharer', 'width=1,height=1,menubar=0,toolbar=0');
        }
      } else if (type == "vcard") {
        if (mode == "whatsapp") {
          let sharer = "https://web.whatsapp.com/send?text=" + encodeURI(`${card_url}`);
          window.open(sharer, 'sharer', 'width=300,height=300,menubar=0,toolbar=0');
        } else if (mode == "facebook") {
          let sharer = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURI(`${card_url}`);
          window.open(sharer, 'sharer', 'width=1,height=1,menubar=0,toolbar=0');
        }
      }
      return false;
    }
    if (type == "ecard") {
      if (mode == "whatsapp") {
        if (!this.isMobile) {
          var sharer = "https://web.whatsapp.com/send?text=" + encodeURI(`https://lekarde.com/share/ecard/${card_url}`);
          window.open(sharer, 'sharer', 'width=300,height=300,menubar=0,toolbar=0');
        } else {
          var sharer = "whatsapp://send?text=" + encodeURI(`https://lekarde.com/share/ecard/${card_url}`)
          window.location.href = sharer;
        }
      } else if (mode == "facebook") {
        var sharer = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURI(`https://lekarde.com/share/ecard/${card_url}`);
        window.open(sharer, 'sharer', 'width=1,height=1,menubar=0,toolbar=0');
      }
    } else if (type == "vcard") {
      if (mode == "whatsapp") {
        if (!this.isMobile) {
          var sharer = "https://web.whatsapp.com/send?text=" + encodeURI(`https://lekarde.com/share/vcard/${card_url}`);
          window.open(sharer, 'sharer', 'width=300,height=300,menubar=0,toolbar=0');
        } else {
          var sharer = "whatsapp://send?text=" + encodeURI(`https://lekarde.com/share/vcard/${card_url}`);
          window.location.href = sharer;
        }
      } else if (mode == "facebook") {
        var sharer = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURI(`https://lekarde.com/share/vcard/${card_url}`);
        window.open(sharer, 'sharer', 'width=1,height=1,menubar=0,toolbar=0');
      }
    }
  }

  /* Form Validations and Errors */
  formControlValid(field: string) {
    return !this.rsvpResponseFormGroup.get(field).valid && this.rsvpResponseFormGroup.get(field).touched;
  }
  /* Form Validations and Errors */

}
