import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms"
import { ApiService } from "../service/api.service"
import { NotificationService } from "../service/notification.service";
import { DeviceDetectorService } from 'ngx-device-detector';
@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styles: []
})
export class HelpComponent implements OnInit {
  isHelpModalActive: boolean = false;
  helpFormGroup: FormGroup
  @ViewChild("helpAttachmentRef") helpAttachmentRef
  attachmentFile: File
  attachmentText: string = "Add file or drop files here"
  isTicketGenerated: string = ""

  orders: string[] = []
  ecardOrderNumbers: any;
  vcardOrderNumbers: any;
  isOrderActive: boolean = false
  selectedHelpType: string = "other"
  isMobile: boolean = false
  help_banner = "assets/img/help-banner.jpg"
  constructor(private apiService: ApiService, private nfs: NotificationService, private deviceService: DeviceDetectorService) { }

  ngOnInit() {
    this.isMobile = this.deviceService.isMobile();
    if (this.isMobile) {
      this.help_banner = "assets/img/help-banner-m.jpg"
    }
    this.helpFormGroup = new FormGroup({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      type: new FormControl(this.selectedHelpType, [Validators.required]),
      order_no: new FormControl(''),
      description: new FormControl('', [Validators.required])
    })

    this.helpFormGroup.get('type').valueChanges.subscribe(
      d => {
        if (d == 'ecard') {
          this.orders = this.ecardOrderNumbers
          this.isOrderActive = true
        } else if (d == 'vcard') {
          this.orders = this.vcardOrderNumbers
          this.isOrderActive = true
        } else {
          this.orders = []
          this.isOrderActive = false
        }
      }
    )

    this._getOrderNumbers();
  }

  _getOrderNumbers() {
    this.apiService.getOrderNumbers().subscribe(res => {
      if (res.response == "success") {
        this.ecardOrderNumbers = res.data.ecard
        this.vcardOrderNumbers = res.data.vcard
      } else {
        this.ecardOrderNumbers = []
        this.vcardOrderNumbers = []
      }
    })
  }

  _uploadImage(event) {
    this.attachmentFile = event.target.files[0];
    if (this.attachmentFile != undefined) {
      this.attachmentText = "Attached"
    }
  }

  _submitTicket() {
    if (this.helpFormGroup.valid) {
      let { name, email, description, type, order_no } = this.helpFormGroup.value
      if (type == 'other') {
        order_no = ""
      }
      const helpFormData = new FormData();
      helpFormData.append('name', name);
      helpFormData.append('email', email);
      helpFormData.append('type', type);
      helpFormData.append('order_no', order_no);
      helpFormData.append('description', description);
      if (this.attachmentFile != undefined) {
        helpFormData.append("attachment", this.attachmentFile, this.attachmentFile.name);
      }
      this.apiService.postHelp(helpFormData).subscribe(res => {
        if (res.response == "success") {
          this.helpAttachmentRef.nativeElement.value = "";
          this.helpFormGroup.reset();
          this.isTicketGenerated = res.data.ticketNumber
        } else if (res.response == "error") {
          this.nfs.showNotification("default", 'Error Occurs');
        }
      });
    } else {
      this.nfs.showNotification("default", 'Fill out details to generate Help Ticket.');
    }
  }

  _helpModal(mode: boolean) {
    this.isTicketGenerated = ""
    this.isHelpModalActive = mode
  }


  /* Form Validations and Errors */
  formControlValid(field: string) {
    return !this.helpFormGroup.get(field).valid && this.helpFormGroup.get(field).touched;
  }

  /* Form Validations and Errors */

}
