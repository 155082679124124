import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { ApiService } from "../service/api.service"
import { NotificationService } from "../service/notification.service";
import { environment } from 'src/environments/environment';
import { FormBuilder, FormArray, FormControl, FormGroup, Validators } from "@angular/forms"
import { MapsAPILoader, MouseEvent } from '@agm/core';
// For Search Debounce
import { of } from "rxjs"
import {
  debounceTime,
  map,
  distinctUntilChanged,
  filter
} from "rxjs/operators"
import { fromEvent } from 'rxjs'

interface Order {
  order_no: string
  prod: number
}

@Component({
  selector: 'app-rsvp',
  templateUrl: './rsvp.component.html',
  styles: [`
    ._validation_message{
      float: left;
      margin: 20px 0;
      width: 100%;
      padding: 10px;
      border: 1px dashed #ddd;
      background: #fafafa;
      font-size: 15px;
      color: #0598c2;
    }
    ._agm_drag_info{
      font-size: 13px;
      color: #057291;
    }
  `]
})
export class RsvpComponent implements OnInit {
  // Local Order
  order: Order;
  // Product
  isProductLoading = true
  orientation: string = ''
  productName: string = ''
  productImage: string = ''
  productPrice: number = 0
  domainPrice: number = 0
  // RSVP 
  activeAccordionTab: number = 0;
  productUrl: string;
  rsvp_type: string;
  productMediaPath: string = ''
  isRsvpFormActive: boolean = false
  isCoHostActive: boolean = false
  isCustomUrlFormActive: boolean = false
  rsvpForm: FormGroup;
  isEventAdded: boolean = false
  isCustomUrlInputActive: boolean = false
  mapAddress: string = "https://www.google.com/maps/embed?origin=mfe&pb=!1m3!2m1!1sgurugram+sec+49!6i18"

  isSubmitDisabled: boolean = false
  personalisedLink: string = ''
  personalisedLinkAvailable: boolean = true
  submitButtonText: string = 'Proceed to checkout'
  personalisedLinkAvailableText: string = ''


  coHostCount: number = 0
  coHostArray: number[] = []
  coHostData: any[] = []

  rzp: any;
  rzp_order_id: string = ""
  totalPrice: number = 0
  orderTotalAmount: number = 0
  todayDate: Date = new Date();

  numberOfTickets: number = 0;
  submitted: boolean = false


  _coHosts: any = []
  _mapUrls: string[] = ['https://www.google.com/maps/embed?origin=mfe&pb=!1m3!2m1!1sgurugram+sec+49!6i18']

  coupons: any[] = [];
  isCouponFormActive: boolean = false
  countDiscountAmount: number = 0

  _eventMarker = "assets/img/agm-marker.png"
  _eventLocations: any = [{
    'latitude': 28.4479248,
    'longitude': 77.0582817,
    'address': "",
    'zoom': 18
  }]
  private geoCoder;
  appliedCouponInfo: string = ""
  isFormValidationComplete: boolean = true
  constructor(private formBuilder: FormBuilder, private apiService: ApiService, private nfs: NotificationService, private router: Router, private route: ActivatedRoute,
    private mapsAPILoader: MapsAPILoader, private ngZone: NgZone) {
    this.productMediaPath = environment.PROD_BASEPATH

    this.route.params.subscribe(p => {
      if (p.product_url && p.rsvp_type) {
        this.rsvp_type = p.rsvp_type
        this.productUrl = p.product_url
      }
    })

  }

  ngOnInit() {
    if (this.productUrl) {
      if (this.rsvp_type == 'ecard') {
        let order: any = localStorage.getItem('e_order');
        if (order != null) {
          order = JSON.parse(order);
          this.order = order
          this.apiService.getRsvpDetails({ ...order, 'rsvp_type': this.rsvp_type }).subscribe(data => {
            if (data.response == 'success') {
              this.isProductLoading = false
              this.orientation = data.data.orientation
              this.productName = data.data.product_name
              this.productImage = data.data.image_url
              this.productPrice = parseFloat(data.data.price)
              this.domainPrice = parseInt(data.data.customized_domain_price)
              this.totalPrice = this.productPrice
              this.orderTotalAmount = this.productPrice
            }
          })
        }
      } else {
        let order: any = localStorage.getItem('order');
        if (order != null) {
          order = JSON.parse(order);
          this.order = order
          this.apiService.getRsvpDetails({ ...order, 'rsvp_type': this.rsvp_type }).subscribe(data => {
            if (data.response == 'success') {
              this.isProductLoading = false
              this.orientation = data.data.orientation
              this.productName = data.data.product_name
              this.productImage = data.data.image_url
              this.productPrice = parseFloat(data.data.price)
              this.domainPrice = parseInt(data.data.customized_domain_price)
              this.totalPrice = this.productPrice
              this.orderTotalAmount = this.productPrice
            }
          })
        }
      }
    }

    // On Routing Page load to top 
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.router.navigated = false;
        window.scrollTo(0, 0);
      }
    });

    this.rsvpForm = new FormGroup(
      {
        customUrl: new FormControl('', [Validators.maxLength(150)]),
        events: new FormArray([]),
        coupon: new FormControl(''),
      }
    )

    // this.apiService.getCoupons().subscribe(data => {
    //   if (data.response == 'success') {
    //     this.coupons = data.data
    //     console.log(this.coupons)
    //   }
    // })
  }

  get f() { return this.rsvpForm.controls; }
  get t() { return this.f.events as FormArray; }

  _addMoreEvent(mode = null) {
    if (mode == 'add') {
      this.numberOfTickets++;
      this._mapUrls.push(this.mapAddress)
    }
    if (this.t.length < this.numberOfTickets) {
      for (let i = this.t.length; i < this.numberOfTickets; i++) {
        this.t.push(this.formBuilder.group({
          message: new FormControl('', [Validators.required, Validators.maxLength(600)]),
          event: ['', [Validators.required, Validators.maxLength(100)]],
          hostedBy: ['', [Validators.required, Validators.maxLength(100)]],
          hostedByMobile: ['', [Validators.required, Validators.pattern(/^[0-9]+$/), Validators.maxLength(15), Validators.minLength(6)]],
          heldOn: ['', [Validators.required]],
          location: ['', [Validators.required]],
          address: ['', [Validators.required, Validators.maxLength(200)]],
          city: ['', [Validators.required, Validators.pattern('[a-zA-Z ]*')]],
          state: ['', [Validators.required, Validators.pattern('[a-zA-Z ]*')]],
          postal: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(6), Validators.maxLength(6)]],
          event_location: ['']
        }));

        // Init AGM
        this._eventLocations.push({
          'latitude': 28.4479248,
          'longitude': 77.0582817,
          'address': "",
          'zoom': 18
        });
        setTimeout(() => {
          this._agmInit(i);
        }, 2000);
      }
    } else {
      for (let i = this.t.length; i >= this.numberOfTickets; i--) {
        this.t.removeAt(i);
      }
    }
  }

  _removeAddEventForm(i) {
    if (i > 0) {
      this.numberOfTickets = this.numberOfTickets - 1
      this.t.removeAt(i);
    }
  }

  /* Co-Host */
  _addCoHost(index) {
    if (this.numberOfTickets > 0) {
      let _checkExist = this._coHosts[`event_${index}`]
      if (_checkExist == undefined) {
        this._coHosts[`event_${index}`] = [{ 'name': "", 'mobile': "" }];
      } else {
        let _costHostLength = this._coHosts[`event_${index}`].length
        if (_costHostLength < 4) {
          this._coHosts[`event_${index}`].push({ 'name': "", 'mobile': "" })
        } else {
          this.nfs.showNotification("default", 'Multiple Co-Host limit exceeds.');
          return false;
        }
      }
    }
  }

  _removeCoHost(j, index) {
    if (this._coHosts[`event_${index}`] != undefined) {
      this._coHosts[`event_${index}`].splice(j, 1)
    }
  }

  _getCoHostValue(event, j, mode, index) {
    if (mode == "name") {
      this._coHosts[`event_${index}`][j].name = event.target.value
    } else if (mode == "mobile") {
      this._validateMobileNumber(event);
      this._coHosts[`event_${index}`][j].mobile = event.target.value
    }
  }

  _validateMobileNumber(e, i = null) {
    var regex = /^[0-9]+$/;
    let valid = regex.test(e.target.value)
    if (valid) {
      return true;
    } else {
      e.target.value = ""
      return false;
    }
  }


  /* Co-Host */

  accordionAction(tab: number) {
    if (this.activeAccordionTab == tab) {
      this.activeAccordionTab = 0
    } else {
      this.activeAccordionTab = tab
    }
  }

  _isRsvpChecked(event) {
    let isChecked = event.target.checked
    this.isRsvpFormActive = isChecked

    if (isChecked) {
      this.numberOfTickets = 1;
      this._addMoreEvent();
    } else {
      this._coHosts = []
      this.numberOfTickets = 0;
    }

    if (!this.isRsvpFormActive) {
      this.rsvpForm.reset();
      this.isEventAdded = false
    }
  }

  _loadMap(index) {
    let model = this.rsvpForm.value
    let events = model.events
    if (events.length > 0) {
      model = events[index]
      if (model.address || model.state || model.city) {
        this.isEventAdded = true
        let mapAddress = `${model.location} ${model.address} ${model.city} ${model.state}`
        this._mapUrls[index] = `https://maps.google.com/maps?q=${mapAddress}&t=&z=18&ie=UTF8&iwloc=&output=embed`
      } else {
        this.isEventAdded = false
      }
    }
  }

  _isCustomUrlChecked(event) {
    let isChecked = event.target.checked
    this.isCustomUrlFormActive = isChecked
    if (!this.isCustomUrlFormActive) {
      this.totalPrice = this.productPrice
      this.rsvpForm.get('customUrl').setValue("");
      this.isCouponFormActive = false;
      let _c: any = document.getElementById("_coupon_active")
      _c.checked = false;
      this.rsvpForm.get('coupon').setValue("");
      this.countDiscountAmount = 0
    } else {
      this.totalPrice = (this.productPrice - this.countDiscountAmount) + this.domainPrice
    }
  }

  _isCouponChecked(event) {
    let isChecked = event.target.checked
    this.isCouponFormActive = isChecked
    if (!this.isCouponFormActive) {
      this.rsvpForm.get('coupon').setValue("");
      this.countDiscountAmount = 0;
      let a = 0;
      if (this.isCustomUrlFormActive) {
        a = this.domainPrice
      }
      this.totalPrice = (this.productPrice - this.countDiscountAmount) + a
      this.appliedCouponInfo = ""
    }
  }

  _applyCoupon1() {
    let coupon = this.rsvpForm.get('coupon').value;
    console.log(coupon);
    if (coupon == "") {
      this.nfs.showNotification("default", 'Enter Coupon');
      return false;
    }
    let productPrice: number = this.productPrice;
    this.apiService.getCoupon(coupon, this.rsvp_type).subscribe(data => {
      if (data.response == 'success') {
        // this.coupons = data.data
        let couponRow = data.data
        let couponCode = couponRow.coupon_code
        let couponType = couponRow.coupon_type
        this.rsvpForm.get('coupon').setValue(couponCode);
        if (couponType == "amount") {
          let couponAmount = parseInt(couponRow.coupon_value)
          this.appliedCouponInfo = `${couponAmount} Rs. coupon applied.`
          this.countDiscountAmount = couponAmount
          let a = 0;
          if (this.isCustomUrlFormActive) {
            a = this.domainPrice
          }
          this.totalPrice = productPrice - this.countDiscountAmount + a
        } else if (couponType == "percent") {
          let couponPercent = parseInt(couponRow.coupon_value)
          this.appliedCouponInfo = `${couponPercent}% discount coupon applied.`
          let percentAmount = (couponPercent / 100) * productPrice
          this.countDiscountAmount = Math.ceil(percentAmount);
          let a = 0;
          if (this.isCustomUrlFormActive) {
            a = this.domainPrice
          }
          // ONLY FOR TESTING
          if (couponPercent == 100) {
            this.totalPrice = 1
            return false;
          }
          this.totalPrice = productPrice - this.countDiscountAmount + a
        }
      } else if (data.response == 'invalid') {
        this.appliedCouponInfo = ""
        this.nfs.showNotification("default", 'Invalid Coupon');
        return false;
      }
    })
  }

  _applyCoupon(i: number) {
    let productPrice: number = this.productPrice;
    if (i > -1) {
      let couponCode = this.coupons[i].coupon_code
      let couponType = this.coupons[i].coupon_type
      this.rsvpForm.get('coupon').setValue(couponCode);
      if (couponType == "amount") {
        let couponAmount = parseInt(this.coupons[i].coupon_value)
        this.appliedCouponInfo = `${couponAmount} Rs. coupon applied.`
        this.countDiscountAmount = couponAmount
        let a = 0;
        if (this.isCustomUrlFormActive) {
          a = this.domainPrice
        }
        this.totalPrice = productPrice - this.countDiscountAmount + a
      } else if (couponType == "percent") {
        let couponPercent = parseInt(this.coupons[i].coupon_value)
        this.appliedCouponInfo = `${couponPercent}% discount coupon applied.`
        let percentAmount = (couponPercent / 100) * productPrice
        this.countDiscountAmount = Math.ceil(percentAmount);
        let a = 0;
        if (this.isCustomUrlFormActive) {
          a = this.domainPrice
        }
        // ONLY FOR TESTING
        if (couponPercent == 100) {
          this.totalPrice = 1
          return false;
        }
        this.totalPrice = productPrice - this.countDiscountAmount + a
      }
    }
  }

  _activeCustomUrl() {
    this.isCustomUrlInputActive = true
    setTimeout(() => {
      document.getElementById("customUrl").focus();
    }, 200);
  }


  _addEvent() {
    this.submitted = true;
    var postdata = {};
    var coHostOutput = []
    // IF BOTH CHECKBOX IS NOT CHECKED
    if (!this.isRsvpFormActive && !this.isCustomUrlFormActive) {
      let model = this.rsvpForm.value
      postdata = { 'coupon': model.coupon, 'unChecked': "both", 'rsvpType': this.rsvp_type, 'rsvpStyle': "", 'order_no': this.order.order_no, 'product_id': this.order.prod, 'coHostedBy': "" }
      this._saveCheckout(postdata);
      return false;
    } else {
      // WHEN RSVP ACTIVE
      if (this.isRsvpFormActive) {
        if (!this.rsvpForm.valid) {
          this.isFormValidationComplete = false
          setTimeout(() => {
            this.isFormValidationComplete = true
          }, 5000);
          return false;
        }
      }

      // WHEN CUSTOM URL ACTIVE
      if (this.isCustomUrlFormActive) {
        let url = this.rsvpForm.get('customUrl').value;
        if (!url) {
          let _el = document.getElementById('customUrl');
          if (_el == null) {
            this.isCustomUrlInputActive = true
            setTimeout(() => {
              document.getElementById('customUrl').focus();
            }, 200);
          } else {
            _el.focus();
            return false;
          }
          this.isSubmitDisabled = true
          return false;
        } else {
          this.isSubmitDisabled = false
        }
      }

      if (this.isRsvpFormActive) {
        var rsvpStyle = JSON.stringify([`Yes`, `No`, `May be`, `Decide Later`])
      } else {
        var rsvpStyle = JSON.stringify([])
      }
      //console.log(this._coHosts);
      let model = this.rsvpForm.value
      let events = model.events
      var b = [];
      let i = 0;
      for (let e of events) {
        let geocodes = [];
        if (this._eventLocations[i] != undefined) {
          geocodes = this._eventLocations[i];
        }
        b.push({ ...e, 'coHostedBy': this._coHosts[`event_${i}`], 'geocodes': geocodes })
        i++;
      }
      postdata = { 'events': JSON.stringify(b), 'rsvpType': this.rsvp_type, 'customUrl': model.customUrl, 'coupon': model.coupon, 'rsvpStyle': rsvpStyle, 'order_no': this.order.order_no, 'product_id': this.order.prod }
      this._saveCheckout(postdata);
    }
  }


  _saveCheckout(data) {
    this.submitButtonText = "Processing..."
    this.apiService.postCheckout(data).subscribe(res => {
      if (res.response == 'success') {
        if (res.data.order_no) {
          let order_no = res.data.order_no
          if (order_no == this.order.order_no) {
            if (this.rsvp_type == "vcard") {
              this._createRzpOrder(order_no);
            } else if (this.rsvp_type == "ecard") {
              this._createRzpOrder(order_no);
            } else {
              this.router.navigate(['/dashboard', 'orders']);
            }
          }
        }
      } else if (res.response == "coupon_invalid") {
        this.nfs.showNotification("default", 'Invalid Coupon!');
        return false;
      } else {
        this.nfs.showNotification("default", 'Please check inputs and try again.');
        return false;
      }
    })
  }


  /* AGM GOOGLE MAPS */

  _agmInit(i) {
    // this.searchElementRef.nativeElement
    let elem: any = document.getElementById(`_location_${i}`);
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;
      // let opts = {
      //   types: ['(cities)']
      // }
      let autocomplete = new google.maps.places.Autocomplete(elem);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          this._eventLocations[i].latitude = place.geometry.location.lat();
          this._eventLocations[i].longitude = place.geometry.location.lng();
          this._eventLocations[i].zoom = 18;
        });
      });
    });
  }

  _setLocationPointer($event, i) {
    this._eventLocations[i].latitude = $event.coords.lat;
    this._eventLocations[i].longitude = $event.coords.lng;
    this._getLocationAddress($event.coords.lat, $event.coords.lng, i);
  }

  _getLocationAddress(latitude, longitude, i) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this._eventLocations[i].zoom = 18
          this._eventLocations[i].address = results[0].formatted_address
          let dom: any = document.getElementById(`_location_${i}`)
          dom.value = this._eventLocations[i].address
        }
      }
    });
  }

  /* AGM GOOGLE MAPS */


  /*
  Form Validations
  */
  formControlValid(field: string) {
    return (
      !this.rsvpForm.get(field).valid &&
      this.rsvpForm.get(field).touched
    );
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }


  _checkUrlExist(e) {
    let url = this.rsvpForm.get('customUrl').value;
    if (url && url.trim() != '' && url.length > 2) {
      this.apiService.postCheckPersonalisedUrl({ 'customize_url': url, 'rsvp_type': this.rsvp_type }).subscribe(data => {
        if (data.response == 'success') {
          if (!data.valid) {
            this.isSubmitDisabled = true
            this.personalisedLinkAvailable = false
            this.personalisedLinkAvailableText = 'This url is already taken.'
            setTimeout(() => {
              this.personalisedLinkAvailableText = ''
            }, 2000)
          } else {
            this.isSubmitDisabled = false
            this.personalisedLinkAvailable = true
            this.personalisedLinkAvailableText = 'Available'

            setTimeout(() => {
              this.personalisedLinkAvailableText = ''
            }, 2000)
          }
        }
      })
    } else {
      this.isSubmitDisabled = true
      // this.nfs.showNotification("default", 'Personalised link can not be blank.');
    }
  }


  _createRzpOrder(order_no: string) {
    this.submitButtonText = "Loading...";
    if (order_no != undefined && order_no != "") {
      this.apiService.createRzpOrder({ order_no: order_no, type: this.rsvp_type }).subscribe(res => {
        if (res.response == "success") {
          if (res.skip != undefined && res.skip == true) {
            localStorage.removeItem('url');
            this.nfs.showNotification("default", 'Congrats! Your Order has been placed Successfully.');
            if (this.rsvp_type == "vcard") {
              localStorage.removeItem('order');
              window.location.href = "/dashboard/orders";
            } else if (this.rsvp_type == "ecard") {
              localStorage.removeItem('e_order');
              window.location.href = `${environment.BASE_URL}ecard/handler/${order_no}`
            }
            return false;
          }
          let orderData = res.orderData
          this._initPayment(orderData);
        } else if (res.response == "coupon_invalid") {
          this.nfs.showNotification("default", 'Invalid Coupon!');
          this.submitButtonText = "Proceed to checkout"
        } else {
          this.nfs.showNotification("default", 'Failed to create order. RAZORPAY');
        }
      })
    }
  }

  //orderData.amount
  _initPayment(orderData) {
    if (orderData != undefined && orderData.status == "created") {
      var pg_config = {
        "key": "rzp_live_Divj6uX2DdVU67",
        "amount": orderData.amount,
        "currency": "INR",
        "name": "Lekarde",
        "description": "",
        "image": "https://lekarde.com/live/assets/img/logo.png",
        "order_id": orderData.order_id,
        "handler": this._capturePayment.bind(this),
        "prefill": {
          "name": orderData.user.name,
          "email": orderData.user.email,
          "contact": orderData.user.mobile
        },
        "notes": {
          "address": orderData.user.address
        },
        "theme": {
          "color": "#000000"
        },
        "modal": {
          "ondismiss": this._rzrClose.bind(this)
        }
      };
      this.rzp = new window.Razorpay(pg_config);
      this.rzp.open();
    } else {
      this.submitButtonText = "Proceed to checkout"
    }
  }

  _rzrClose($this) {
    document.getElementById('_rsvpSubmit').innerHTML = "Proceed to checkout";
  }

  _capturePayment(response) {
    this.submitButtonText = "Proceed to checkout";
    let checkKeys = Object.keys(response).length
    if (checkKeys > 0) {
      this.apiService.capturePayment({ ...response, order_no: this.order.order_no, type: this.rsvp_type }).subscribe(res => {
        if (res.response == "success") {
          localStorage.removeItem('url');
          this.nfs.showNotification("default", 'Congrats! Your Order has been placed Successfully.');
          if (this.rsvp_type == "vcard") {
            localStorage.removeItem('order');
            window.location.href = "/dashboard/orders";
          } else if (this.rsvp_type == "ecard") {
            localStorage.removeItem('e_order');
            window.location.href = `${environment.BASE_URL}ecard/handler/${this.order.order_no}`
          }
        } else {
          this.nfs.showNotification("default", 'Order Not Placed.');
        }
      })
    }
  }



}
