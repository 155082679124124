import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, RouterEvent } from '@angular/router'
import { Location } from '@angular/common';
import { ApiService } from "../service/api.service"
import { environment } from 'src/environments/environment';
import { NotificationService } from "../service/notification.service";
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs'

interface ProductsModel {
  card_id: string,
  card_template: string
  card_name: string
  design_no: string,
  card_prefix: string,
  spl_price: string,
  wishlist: boolean,
  price: string
}

@Component({
  selector: 'app-ecards',
  templateUrl: './ecards.component.html',
  styleUrls: ['./ecards.component.scss']
})
export class EcardsComponent implements OnInit, OnDestroy {
  isProductsLoading: boolean = true
  products: Array<ProductsModel>
  productMediaPath: string = '';

  productCategory: string;
  productSubCategory: string;
  productCategoryUrl: string
  // Filters
  filterCategories: any[] = []
  filterCategoryTitle: string = ""
  selectedFilters: any = [
    {
      "mode": "category",
      "category": "",
      "sub_category": []
    },
    {
      "mode": "shape",
      "value": []
    },
    {
      "mode": "sort",
      "value": ""
    }
  ];
  isMobileFilterOpen: boolean = false;
  mobileFilterButtonText: string = "Filter"
  public destroyed = new Subject<any>();
  ashu: boolean = true
  mySubscription: any;
  page: number = 1
  loadMoreActive: boolean = true
  loadMoreShow: boolean = true

  categoryBanner: string = ""

  constructor(private location: Location, private apiService: ApiService, private route: ActivatedRoute, private router: Router, private nfs: NotificationService) {
    this.productMediaPath = environment.PROD_BASEPATH

    this.mySubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.router.navigated = false;
      }
    });
  }

  ngOnInit() {

    this.route.params.subscribe(param => {
      if (this.router.url != undefined && this.router.url != '') {
        this.productCategory = param.category
        this.productSubCategory = param.sub_category
        this.productCategoryUrl = this.router.url.replace(/^\/|\/$/g, '')

        this.selectedFilters[0].category = this.productCategory
        this.selectedFilters[0].sub_category[0] = this.productSubCategory
        this._getProductsByCategory();
        this.loadMoreShow = true
        this.page = 1
      }
    })

    // this.router.events.pipe(
    //   filter((e: RouterEvent) => e instanceof NavigationEnd),
    //   takeUntil(this.destroyed)
    // ).subscribe(e => {
    //   console.log(e.url);
    //   setTimeout(() => {
    //     this.ashu = false
    //   }, 2000)
    //   this.location.go(e.url);
    // });
  }

  _loadMore() {
    this.page++;
    this.loadMoreActive = false
    let filterParams = { 'category': this.productCategory, 'sub_category': this.productSubCategory, 'type': "ecard", page: this.page }
    if (this.selectedFilters != undefined) {
      Object.assign(filterParams, { filter: this.selectedFilters })
    }
    this.apiService.getEcards(filterParams).subscribe(res => {
      if (res.response == 'success') {
        this.loadMoreActive = true
        this.loadMoreShow = res.data.load_more
        let cardData = res.data.cardData
        for (let d of cardData) {
          this.products.push(d)
        }
        if (this.selectedFilters == undefined) {
          this.filterCategoryTitle = res.data.categories.category
          this.filterCategories = res.data.categories.subCategories
        }
      }
    })
  }

  _getProductsByCategory(filter?) {
    var filterParams = {}
    if (filter == undefined) {
      filterParams = { 'category': this.productCategory, 'sub_category': this.productSubCategory, 'type': "ecard", page: 1 }
    } else {
      filterParams = { 'category': this.productCategory, 'sub_category': this.productSubCategory, 'type': "ecard", filter, page: 1 }
    }
    this.apiService.getEcards(filterParams).subscribe(res => {
      if (res.response == 'success') {
        this.isProductsLoading = false
        this.products = res.data.cardData

        if (filter == undefined) {
          this.filterCategoryTitle = res.data.categories.category
          this.filterCategories = res.data.categories.subCategories
          this.categoryBanner = res.data.categories.category_banner
        }
      }
    })
  }

  _filterProducts(i, event, mode) {
    let category_prefix = event.target.value
    if (mode == "category") {
      let isCategoryExist = this.selectedFilters[0].sub_category.includes(category_prefix)
      if (!isCategoryExist) {
        this.selectedFilters[0].sub_category.push(category_prefix);
      } else {
        let categoryIndex = this.selectedFilters[0].sub_category.indexOf(category_prefix);
        this.selectedFilters[0].sub_category.splice(categoryIndex, 1)
      }
    } else if (mode == "sort") {
      let category_prefix = event.target.value
      this.selectedFilters[2].value = category_prefix
    }
    this._getProductsByCategory(this.selectedFilters);
  }

  // _expandFilter(el: any) {
  //   var element: any = el.target.nextSibling
  //   let isClosed = element.classList.contains("_closed");
  //   if (isClosed) {
  //     el.target.getElementsByClassName("fa-minus")[0].style.display = "flex"
  //     el.target.getElementsByClassName("fa-plus")[0].style.display = "none"
  //     element.classList.remove("_closed");
  //   } else {
  //     el.target.getElementsByClassName("fa-minus")[0].style.display = "none"
  //     el.target.getElementsByClassName("fa-plus")[0].style.display = "flex"
  //     element.classList.add("_closed");
  //   }
  // }

  _addWishlist(product, i) {
    if (product) {
      let card_prefix = product.card_prefix
      this.apiService.postWishlist({ 'prod_url': card_prefix, 'type': "ecard" }).subscribe(data => {
        if (data.response == 'success') {
          let status = data.data
          this.products[i].wishlist = status
        } else if (data.response == "login") {
          this.nfs.showNotification("default", 'Please Login.');
          this.router.navigate([`/login`]);
        } else {
          this.nfs.showNotification("default", 'Action not completed. Error');
        }
      })
    }
  }

  _openMobileFilters() {
    let _sort: any = document.getElementsByClassName('_sort')[0];
    let _filter_category: any = document.getElementsByClassName('_filter_category')[0];
    if (!this.isMobileFilterOpen) {
      this.isMobileFilterOpen = true
      _sort.style.display = 'block'
      _filter_category.style.display = 'block'
      this.mobileFilterButtonText = "Close"
    } else {
      this.isMobileFilterOpen = false
      _sort.style.display = 'none'
      _filter_category.style.display = 'none'
      this.mobileFilterButtonText = "Filter"
    }
  }

  ngOnDestroy() {
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }

    this.destroyed.next();
    this.destroyed.complete();
  }


}
