<div class="_main_container _header_shadow">
  <app-header></app-header>
</div>

<div class="_main_container" *ngIf="isProductLoading">
  <p class="_loading">
    <span class="loader"></span>
  </p>
</div>

<!-- Content -->
<div class="_card_detail" *ngIf="!isProductLoading">
  <div class="_left _bg">
    <h1 class="_heading">Customize your card</h1>
    <div class="_slide _{{orientation}}">
      <img [src]="productMediaPath + activeSlideSrc" lazy>
    </div>

    <button type="button" class="_previous" [disabled]="activeSlideIndex == 0" (click)="gotoSlide('previous')">
      <img src="assets/img/left-arrow.png" />
    </button>
    <button type="button" class="_next" [disabled]="isSubmitButtonActive" (click)="gotoSlide('next')">
      <img src="assets/img/right-arrow.png" />
    </button>
  </div>
  <div class="_right">
    <h2 class="_heading">Your text (Please put exact text)</h2>

    <form #slidesForm="ngForm" method="post" (ngSubmit)="_saveSlides(slidesForm)" name="_slidesForm_{{activeSlide}}"
      id="_slidesForm_{{activeSlide}}">
      <ng-container *ngIf="activeSlideFields?.length > 0 else noFieldsTemplate">
        <ng-container *ngFor="let field of activeSlideFields">
          <div class="_field" *ngIf="field.type == 'text'">
            <input type="text" autocomplete="off" name="{{field.name}}" id="{{field.id}}" class="_enter_text"
              placeholder="{{field.placeholder}}" />
          </div>
          <div class="_field" *ngIf="field.type == 'textarea'">
            <textarea name="{{field.name}}" id="{{field.id}}" class="_enter_text" placeholder="{{field.placeholder}}"
              (keyup)="word_count($event)"></textarea>
          </div>
          <div class="_field" *ngIf="field.type == 'file'">
            <input type="file" name="{{field.id}}" id="{{field.id}}" #slideImageRef
              (change)="_selectSlideImage($event,field.id)" />
            <input type="hidden" name="{{field.id}}_hidden" id="{{field.id}}_hidden" [(ngModel)]="uploaded_file" />
            <div class="_upload_field">
              <label for="{{field.id}}">
                <span [innerHTML]="uploadMessage">Upload picture</span>
              </label>
              <div *ngIf="media || slideImage != ''" class="_uploaded_file">
                <div class="_inner">
                  <img class="_image" [src]="media" />
                  <span class="_remove" (click)="_removeUploadedSlideImage()">
                    x
                  </span>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <div class="_field _flexbox _bottom">
        <ul class="_actions">
          <li *ngIf="slidesCount > 1"><button type="button" (click)="_prevSlide()">Prev</button></li>
          <li *ngIf="isSubmitButtonActive">
            <button type="submit" class="_save" [ngClass]="{'_active' : !isNextButtonActive}"
              [innerHtml]="submitButtonText"></button>
          </li>
          <li *ngIf="isNextButtonActive"><button type="button" (click)="_nextSlide(slidesForm)">Next</button></li>
        </ul>
      </div>
    </form>

    <ng-template #noFieldsTemplate>
      <p class="_noFields">Empty Mapped Fields</p>
    </ng-template>
  </div>

  <ng-container *ngIf="isDesktop">
    <ng-container *ngIf="orientation == 'horizontal' && !isProductLoading">
      <div class="_detail_slides _horizontal" [ngClass]="{'_temp_vertical' : isTempVerticalLayout}">
        <owl-carousel-o [options]="customOptions">
          <ng-container *ngFor="let slide of slides; let i = index">
            <ng-template carouselSlide [id]="slide.id">
              <div class="_slide" (click)="slideAction(slide.id,slide,i)"
                [ngClass]="{'_slide_active' : i == activeSlideIndex}">
                <img [src]="productMediaPath + slide.src" [alt]="slide.alt" [title]="slide.title" lazy>
              </div>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>
      <a href="javascript:;" class="_custom_prev" [ngClass]="{'_disabled' : activeSlideIndex == 0}"
        (click)="gotoSlide('previous')"><img src="assets/img/left-arrow.png" /></a>
      <a href="javascript:;" class="_custom_next" [ngClass]="{'_disabled' : isSubmitButtonActive}"
        (click)="gotoSlide('next')"><img src="assets/img/right-arrow.png" /></a>
    </ng-container>

    <ng-container *ngIf="(orientation == 'vertical' || orientation == 'square') && !isProductLoading">
      <div class="_detail_slides _vertical">
        <owl-carousel-o [options]="customOptions" #owlCar>
          <ng-container *ngFor="let slide of slides;let i = index">
            <ng-template carouselSlide [id]="slide.id">
              <div class="_slide" (click)="slideAction(slide.id,slide,i)"
                [ngClass]="{'_slide_active' : i == activeSlideIndex, '_square' : orientationClass == 'square'}">
                <img [src]="productMediaPath + slide.src" [alt]="slide.alt" [title]="slide.title" lazy>
              </div>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>
      <a href="javascript:;" class="_custom_prev" [ngClass]="{'_disabled' : activeSlideIndex == 0}"
        (click)="gotoSlide('previous')"><img src="assets/img/left-arrow.png" /></a>
      <a href="javascript:;" class="_custom_next" [ngClass]="{'_disabled' : isSubmitButtonActive}"
        (click)="gotoSlide('next')"><img src="assets/img/right-arrow.png" /></a>
    </ng-container>
  </ng-container>


</div>
<!-- End -->
<div class="_clearfix"></div>