import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, ElementRef, Renderer2 } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from "../service/api.service";
import { NotificationService } from "../service/notification.service";
import { DataService } from "../service/data.service";
import { environment } from 'src/environments/environment';
import domtoimage from 'retina-dom-to-image';
import { DeviceDetectorService } from 'ngx-device-detector';
var EcardDetailComponent = /** @class */ (function () {
    function EcardDetailComponent(document, renderer, _location, dataService, route, nfs, router, apiService, deviceService) {
        this.document = document;
        this.renderer = renderer;
        this._location = _location;
        this.dataService = dataService;
        this.route = route;
        this.nfs = nfs;
        this.router = router;
        this.apiService = apiService;
        this.deviceService = deviceService;
        this.activeEditStyleMode = 'align';
        this.isProductsLoading = true;
        this.productMediaPath = '';
        this.isUploadFieldAcive = false;
        this.selectedFieldIndex = -1;
        this.selectedFieldType = '';
        this.frontendCustomizatedData = [];
        // Range Options
        this.value = 16;
        this.options = {
            floor: 10,
            ceil: 120,
            showSelectionBar: false,
            animate: false
        };
        this.defaultCardImage = './assets/img/blank-placeholder.jpg';
        this.fontFamilies = [];
        this.fontFamilySelected = "";
        this.isProductLoading = true;
        this.cardInfoActive = false;
        this.proceedBtnText = "Save & Proceed";
        this.imageChangedEvent = '';
        this.croppedImage = [];
        this.isCropperActive = false;
        this.croppedBtnText = "Save";
        this.tempEcardImagesEvent = [];
        this.colors = [];
        this.moreColorActive = false;
        /* Mobile Ecard */
        this.selectedMobileTab = "";
        this.selectedFieldText = "";
        this.isMobile = false;
        this.isTablet = false;
        this.isDesktopDevice = false;
        this.usableUploadFieldIndex = "";
        this.usableUploadField = "";
        this.imageChangedEventSaved = [];
        this.imageFields = [];
        this.defaultInputFontFamilies = [];
        // 09/11/2021 
        this.hasImageField = false;
        this.imageFieldsCount = 0;
        this.corpLogo = null;
        this.uploadedImages = [];
        this.productMediaPath = environment.PROD_BASEPATH;
    }
    EcardDetailComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isMobile = this.deviceService.isMobile();
        this.isTablet = this.deviceService.isTablet();
        this.isDesktopDevice = this.deviceService.isDesktop();
        var deviceInfo = this.deviceService.getDeviceInfo();
        this.os = deviceInfo;
        if (this.isMobile) {
            this.renderer.addClass(this.document.body, '_m_ecard_body');
        }
        this.route.params.subscribe(function (params) {
            if (params.product_url != '') {
                _this.designNo = params.design_no;
                _this.productUrl = params.product_url;
                // Load Ecard Properties
                _this.apiService.getEcardProperties({ card_prefix: _this.productUrl }).subscribe(function (data) {
                    if (data.response == 'success') {
                        _this.isProductsLoading = false;
                        _this.colors = data.colors;
                        _this.products = data.data;
                        // Upload Field
                        if (_this.products[0].is_image == '1') {
                            _this.isUploadFieldAcive = true;
                        }
                        else {
                            _this.isUploadFieldAcive = false;
                        }
                        _this.properties = JSON.parse(_this.products[0].property_detail);
                        var imageFieldsCount = _this.properties.filter(function (d) { return d.type == 'image'; });
                        _this.imageFieldsCount = imageFieldsCount.length;
                        var getPropertiesInputs = _this.properties.filter(function (d) { return (d.type == 'input' || d.type == 'image'); });
                        if (getPropertiesInputs.length > 0) {
                            for (var _i = 0, getPropertiesInputs_1 = getPropertiesInputs; _i < getPropertiesInputs_1.length; _i++) {
                                var i = getPropertiesInputs_1[_i];
                                var validateField = false;
                                if (i.validate != undefined) {
                                    if (i.validate == "true") {
                                        validateField = true;
                                    }
                                    else {
                                        validateField = false;
                                    }
                                }
                                if (i.type == 'input') {
                                    var d = {
                                        'id': i.id,
                                        'value': i.defaultValue,
                                        'color': '',
                                        'textAlign': i.textAlign,
                                        'fontSize': i.fontSize,
                                        'fontFamily': '',
                                        'styles': i.styles,
                                        'type': i.type,
                                        'width': i.width,
                                        'height': i.height,
                                        'validate': validateField
                                    };
                                    _this.frontendCustomizatedData.push(d);
                                }
                                else {
                                    if (i.type == "image") {
                                        _this.hasImageField = true;
                                        _this.imageFields.push({ 'id': i.id });
                                    }
                                    var d = {
                                        'id': i.id,
                                        'type': i.type,
                                        'value': i.defaultValue,
                                        'width': i.width,
                                        'height': i.height,
                                        'styles': i.styles,
                                        'validate': validateField
                                    };
                                    if (i.corp_logo) {
                                        Object.assign(d, { 'corp_logo': i.corp_logo });
                                        Object.assign(d, { 'uploadPlaceholder': 'logo-' + i.upload_placeholder });
                                    }
                                    else {
                                        Object.assign(d, { 'uploadPlaceholder': 'picture-' + i.upload_placeholder });
                                    }
                                    _this.frontendCustomizatedData.push(d);
                                }
                            }
                            _this._loadSavedDraftData();
                        }
                    }
                    else if (data.response == 'redirect') {
                        // this.nfs.showNotification("default", "you'r not logged in.");
                        localStorage.removeItem("token");
                        localStorage.removeItem("user");
                        _this.dataService.sessionMessage(false);
                        _this.router.navigate(["/login"]);
                    }
                    else {
                        _this.nfs.showNotification("default", 'Properties not loaded successfully.');
                    }
                    _this.isProductLoading = false;
                });
            }
        });
        setTimeout(function () {
            var getPropertiesInputs = _this.properties.filter(function (d) { return (d.type == 'input'); });
            if (getPropertiesInputs.length > 0) {
                for (var _i = 0, getPropertiesInputs_2 = getPropertiesInputs; _i < getPropertiesInputs_2.length; _i++) {
                    var i = getPropertiesInputs_2[_i];
                    var value = document.getElementById("_get_prop_" + i.id).style.fontFamily;
                    _this.defaultInputFontFamilies['_get_prop_' + i.id] = value.replace(/['"]+/g, '');
                }
            }
        }, 1000);
    };
    EcardDetailComponent.prototype.ngOnDestroy = function () {
        this.renderer.removeClass(this.document.body, '_m_ecard_body');
    };
    EcardDetailComponent.prototype._moreColor = function () {
        this.moreColorActive = this.moreColorActive ? false : true;
    };
    EcardDetailComponent.prototype._stopDrop = function (e) {
        e.preventDefault();
        return false;
    };
    EcardDetailComponent.prototype._new_uploader = function (event) {
        var _this = this;
        if (this.imageFieldsCount == 1) {
            var getObj = this.frontendCustomizatedData.filter(function (d) { return d.type == "image"; });
            if (getObj.length == 1) {
                this.selectedFieldType = 'image';
                this.selectedFieldIndex = getObj[0].id;
            }
        }
        else {
        }
        if (this.selectedFieldType == 'image' && this.selectedFieldIndex > -1) {
            this.selectedFile = event.target.files[0];
            var uploadData = new FormData();
            uploadData.append("image", this.selectedFile, this.selectedFile.name);
            this._uploadImageLoader('show', this.selectedFieldIndex);
            this.apiService.postUploadCardImage(uploadData).subscribe(function (res) {
                _this._ecard_image_ref.nativeElement.value = "";
                // let el: any = document.getElementById(`_image_${this.selectedFieldIndex}`);
                if (res.response == "success") {
                    console.log(_this.selectedFieldIndex);
                    var uploadedImage = res.data.image;
                    //   el.src = uploadedImage
                    _this.uploadedImages[_this.usableUploadFieldIndex] = uploadedImage;
                    _this.corpLogo = uploadedImage;
                    _this._setUploadImageValue(uploadedImage);
                    _this._uploadImageLoader('hide', _this.selectedFieldIndex);
                }
                else if (res.response == "error") {
                    _this.nfs.showNotification("default", res.message);
                    _this._uploadImageLoader('hide', _this.selectedFieldIndex);
                }
            });
        }
    };
    EcardDetailComponent.prototype._open_new_upload = function (index, prop) {
        var _this = this;
        console.log(this.uploadedImages);
        this.usableUploadFieldIndex = index;
        this.usableUploadField = prop;
        setTimeout(function () {
            _this._ecard_image_ref.nativeElement.click();
        }, 500);
        // if (prop.corp_logo == "true") {
        // }
    };
    EcardDetailComponent.prototype.fileChangeEvent = function (event) {
        var isFileExist = event.target.files.length;
        if (event && isFileExist > 0) {
            this.isCropperActive = true;
            this.imageChangedEventSaved["temp_image_" + this.usableUploadFieldIndex] = event;
            this.imageChangedEvent = event;
        }
    };
    EcardDetailComponent.prototype._editCropped = function (index, prop) {
        var _this = this;
        console.log(prop);
        if (prop.corp_logo == "true") {
            setTimeout(function () {
                _this._ecard_image_ref.nativeElement.click();
            }, 500);
        }
        this.usableUploadFieldIndex = index;
        this.usableUploadField = prop;
        this.selectedFieldType = 'image';
        var checkExist = this.imageChangedEventSaved["temp_image_" + this.usableUploadFieldIndex];
        if (checkExist != undefined) {
            var imageEvent = this.imageChangedEventSaved["temp_image_" + this.usableUploadFieldIndex];
            // console.log(imageEvent.srcElement.value);
            this.imageChangedEvent = imageEvent;
            this.isCropperActive = true;
        }
        else {
            this.imageChangedEvent = null;
            this.isCropperActive = false;
            setTimeout(function () {
                _this._ecard_image_ref.nativeElement.click();
            }, 500);
        }
    };
    EcardDetailComponent.prototype._cancelCroppedImage = function () {
        this.isCropperActive = this.isCropperActive ? false : true;
        var el = document.getElementById("_image_" + this.selectedFieldIndex);
        if (el != null) {
            var matchedIndex = this.frontendCustomizatedData
                .map(function (obj) {
                return obj.id;
            })
                .indexOf(this.selectedFieldIndex);
            var data = this.frontendCustomizatedData[matchedIndex];
            if (data.type == "image" && data.value != "") {
                el.src = data.value;
                return true;
            }
            else {
                el.src = this.defaultCardImage;
            }
            //el.value = ""
            this.imageChangedEvent = "";
            this._ecard_image_ref.nativeElement.value = "";
        }
    };
    EcardDetailComponent.prototype.imageCropped = function (event) {
        this.croppedImage[this.usableUploadFieldIndex] = event.base64;
    };
    EcardDetailComponent.prototype.imageLoaded = function () {
        // show cropper
    };
    EcardDetailComponent.prototype.cropperReady = function () {
        // cropper ready
    };
    EcardDetailComponent.prototype.loadImageFailed = function () {
        this.nfs.showNotification("default", '* png,jpg,gif is allowed.');
    };
    EcardDetailComponent.prototype._b64toBlob = function (b64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;
        var byteCharacters = atob(b64Data);
        var byteArrays = [];
        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);
            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        var blob = new Blob(byteArrays, { type: contentType });
        return blob;
    };
    EcardDetailComponent.prototype._uploadCroppedImage = function (e) {
        var _this = this;
        this.croppedBtnText = "Uploading..";
        if (this.selectedFieldType == 'image' && this.selectedFieldIndex > -1) {
            // this._createTempImageDataEvents();
            // Convert base64 to blob
            var ImageURL = this.croppedImage[this.usableUploadFieldIndex];
            var block = ImageURL.split(";");
            var contentType = block[0].split(":")[1];
            var realData = block[1].split(",")[1];
            var blob = this._b64toBlob(realData, contentType, 512);
            var uploadData = new FormData();
            uploadData.append("image", blob, 'image.png');
            this._uploadImageLoader('show', this.selectedFieldIndex);
            this.apiService.postUploadCardImage(uploadData).subscribe(function (res) {
                var el = document.getElementById("_image_" + _this.selectedFieldIndex);
                if (res.response == "success") {
                    var uploadedImage = res.data.image;
                    el.src = uploadedImage;
                    _this._setUploadImageValue(uploadedImage);
                    _this._uploadImageLoader('hide', _this.selectedFieldIndex);
                    _this.croppedBtnText = "Save";
                }
                else if (res.response == "error") {
                    el.src = _this.defaultCardImage;
                    _this.nfs.showNotification("default", res.message);
                    _this._uploadImageLoader('hide', _this.selectedFieldIndex);
                    _this.croppedBtnText = "Save";
                }
                _this.isCropperActive = false;
            });
        }
        else {
            this.nfs.showNotification("default", 'Select Image Area to update.');
            this.croppedBtnText = "Save";
        }
    };
    EcardDetailComponent.prototype._createTempImageDataEvents = function () {
        var matchedIndex = this.tempEcardImagesEvent.map(function (obj) {
            return obj.id;
        }).indexOf(this.selectedFieldIndex);
        if (matchedIndex > -1) {
            this.tempEcardImagesEvent[matchedIndex].image = this.imageChangedEventSaved[this.usableUploadFieldIndex];
        }
        else {
            var a = { 'id': this.selectedFieldIndex, image: this.imageChangedEventSaved[this.usableUploadFieldIndex] };
            this.tempEcardImagesEvent.push(a);
        }
    };
    EcardDetailComponent.prototype._loadSavedDraftData = function () {
        var _this = this;
        // Load Card From Drafts
        var _e_order = localStorage.getItem('e_order');
        _e_order = JSON.parse(_e_order);
        if (_e_order != null) {
            if (_e_order.design == this.designNo) {
                // this.isProductsLoading = true
                this.apiService.getEcardDraft(_e_order).subscribe(function (data) {
                    if (data.response == 'success') {
                        if (data.data == null) {
                            return false;
                        }
                        var _savedProperties = JSON.parse(data.data);
                        var _savedPropertiesInputs = _savedProperties.filter(function (d) { return (d.type == 'input' || d.type == 'image'); });
                        if (_savedPropertiesInputs.length > 0) {
                            _this.frontendCustomizatedData = [];
                            for (var _i = 0, _savedPropertiesInputs_1 = _savedPropertiesInputs; _i < _savedPropertiesInputs_1.length; _i++) {
                                var i = _savedPropertiesInputs_1[_i];
                                var validateField = false;
                                if (i.validate != undefined) {
                                    if (i.validate == "true") {
                                        validateField = true;
                                    }
                                    else {
                                        validateField = false;
                                    }
                                }
                                if (i.type == 'input') {
                                    var d = {
                                        'id': i.id,
                                        'value': i.value,
                                        'color': '',
                                        'textAlign': i.textAlign,
                                        'fontSize': i.fontSize,
                                        'styles': i.styles,
                                        'type': i.type,
                                        'width': i.width,
                                        'height': i.height,
                                        'validate': validateField
                                    };
                                    _this.frontendCustomizatedData.push(d);
                                }
                                else {
                                    var d = {
                                        'id': i.id,
                                        'type': i.type,
                                        'value': i.value,
                                        'width': i.width,
                                        'height': i.height,
                                        'styles': i.styles,
                                        'validate': validateField,
                                        'uploadPlaceholder': i.upload_placeholder
                                    };
                                    if (i.corp_logo) {
                                        Object.assign(d, { 'corp_logo': i.corp_logo });
                                    }
                                    _this.frontendCustomizatedData.push(d);
                                }
                            }
                        }
                    }
                });
            }
        }
    };
    EcardDetailComponent.prototype._getSelectedFields = function (type, propId) {
        this.selectedFieldIndex = propId;
        this.selectedFieldType = type;
        if (this.selectedFieldType != 'image') {
            var el = document.getElementById("_inputs_" + propId);
            var matchedIndex = this.properties
                .map(function (obj) {
                return obj.id;
            })
                .indexOf(this.selectedFieldIndex);
            this.fontFamilies = this.properties[matchedIndex].fontFamily;
            this.fontFamilySelected = "";
            // Set font size slider value
            var matchedIndex1 = this.frontendCustomizatedData
                .map(function (obj) {
                return obj.id;
            })
                .indexOf(this.selectedFieldIndex);
            var fontSize = this.frontendCustomizatedData[matchedIndex1].fontSize;
            this.value = parseInt(fontSize);
            // Selected Font Dropdown
            this.fontFamilySelected = "";
            var getSelectedFont = this.frontendCustomizatedData[matchedIndex1].fontFamily;
            if (getSelectedFont != "") {
                this.fontFamilySelected = getSelectedFont;
            }
        }
        else {
            this.fontFamilies = [];
        }
    };
    EcardDetailComponent.prototype._removeSelectedField = function () {
        this.selectedFieldIndex = -1;
        this.selectedFieldType = "";
    };
    EcardDetailComponent.prototype._closeAction = function (i, propId) {
        var type = this.properties[i].type;
        if (type != 'image') {
            this.fontFamilies = this.properties[i].fontFamily;
            // this.properties[i].defaultValue = "";
            this.selectedFieldIndex = propId;
            var el = document.getElementById("_inputs_" + propId);
            el.innerHTML = "";
            el.focus();
        }
        else {
            // if (this.properties[i].corp_logo == "true") { }
            // this.fontFamilies = []
            // this.selectedFieldIndex = propId
            // let el: any = document.getElementById(`_image_${propId}`);
            // el.src = this.defaultCardImage
            this.selectedFieldIndex = propId;
            this.fontFamilies = [];
            this._ecard_image_ref.nativeElement.value = "";
            this.uploadedImages[i] = null;
        }
    };
    EcardDetailComponent.prototype._setDefaultField = function () {
        if (this.properties.length > 0 && this.selectedFieldIndex < 1) {
            var propId = this.properties[0].id;
            var type = this.properties[0].type;
            this.selectedFieldIndex = propId;
            this.selectedFieldType = type;
            var defaultValue = this.properties[0].defaultValue;
            this._createCardDataArray(defaultValue, propId);
        }
    };
    EcardDetailComponent.prototype._getInputValues = function (event, propId) {
        var getValue = event.target.innerHTML;
        if (getValue != undefined) {
            this._createCardDataArray(getValue, propId);
        }
    };
    // Create array of data to save
    EcardDetailComponent.prototype._createCardDataArray = function (getValue, propId) {
        var isCustomizedDataExist = this.frontendCustomizatedData.length;
        if (isCustomizedDataExist > 0) {
            var matchedIndex = this.frontendCustomizatedData
                .map(function (obj) {
                return obj.id;
            })
                .indexOf(propId);
            this.frontendCustomizatedData[matchedIndex].value = getValue;
        }
    };
    EcardDetailComponent.prototype._setStyles = function (mode, value) {
        this.activeEditStyleMode = mode;
        if (this.selectedFieldIndex > -1) {
            var matchedIndex = this.frontendCustomizatedData
                .map(function (obj) {
                return obj.id;
            })
                .indexOf(this.selectedFieldIndex);
            var el = document.getElementById("_inputs_" + this.selectedFieldIndex);
            if (mode == 'align') {
                this.frontendCustomizatedData[matchedIndex].textAlign = value;
                el.style.textAlign = value;
                if (value != 'right') {
                    el.parentElement.style.justifyContent = value;
                }
                else {
                    el.parentElement.style.justifyContent = "flex-end";
                }
            }
            else if (mode == 'fontSize') {
                this.frontendCustomizatedData[matchedIndex].fontSize = value;
                el.style.fontSize = value;
            }
            else if (mode == 'color') {
                this.frontendCustomizatedData[matchedIndex].color = value;
                el.parentElement.style.color = value;
            }
        }
        else {
            this._setDefaultField();
            // this.nfs.showNotification("default", 'No Area selected!');
            return false;
        }
    };
    EcardDetailComponent.prototype._getFontSizeValue = function (value) {
        if (this.selectedFieldIndex > -1) {
            var el = document.getElementById("_inputs_" + this.selectedFieldIndex);
            el.style.fontSize = value + "px";
            var matchedIndex = this.frontendCustomizatedData
                .map(function (obj) {
                return obj.id;
            })
                .indexOf(this.selectedFieldIndex);
            this.frontendCustomizatedData[matchedIndex].fontSize = value;
        }
        else {
            this._setDefaultField();
            // this.nfs.showNotification("default", 'No Area selected!');
            return false;
        }
    };
    EcardDetailComponent.prototype._setUploadImageValue = function (imageUrl) {
        if (this.selectedFieldIndex > -1) {
            var matchedIndex = this.frontendCustomizatedData
                .map(function (obj) {
                return obj.id;
            })
                .indexOf(this.selectedFieldIndex);
            if (matchedIndex < 0) {
                var d = {
                    'id': this.selectedFieldIndex,
                    'type': 'image',
                    'value': imageUrl
                };
                this.frontendCustomizatedData.push(d);
            }
            else {
                // Exist then Update
                this.frontendCustomizatedData[matchedIndex].value = imageUrl;
            }
        }
    };
    EcardDetailComponent.prototype._getFontFamily = function (event) {
        var getValue = event.target.value;
        // console.log(this.selectedFieldIndex, event);
        if (getValue == "") {
            console.log(document.getElementById("_get_prop_" + this.selectedFieldIndex).style);
            document.getElementById("_get_prop_" + this.selectedFieldIndex).style.fontFamily = this.defaultInputFontFamilies["_get_prop_" + this.selectedFieldIndex];
            return false;
        }
        if (this.selectedFieldType != 'image' && this.selectedFieldIndex > -1) {
            var matchedIndex = this.frontendCustomizatedData
                .map(function (obj) {
                return obj.id;
            })
                .indexOf(this.selectedFieldIndex);
            this.frontendCustomizatedData[matchedIndex].fontFamily = getValue;
            var el = document.getElementsByClassName("_active");
            el[0].style.fontFamily = getValue;
            this.fontFamilySelected = getValue;
        }
    };
    EcardDetailComponent.prototype._getFontFamily_m = function (font) {
        console.log(font);
        if (this.selectedFieldType != 'image' && this.selectedFieldIndex > -1) {
            var matchedIndex = this.frontendCustomizatedData
                .map(function (obj) {
                return obj.id;
            })
                .indexOf(this.selectedFieldIndex);
            this.frontendCustomizatedData[matchedIndex].fontFamily = font;
            var el = document.getElementsByClassName("_active");
            el[0].style.fontFamily = font;
            this.fontFamilySelected = font;
        }
    };
    EcardDetailComponent.prototype._uploadCardImage = function (event) {
        var _this = this;
        console.log(this.usableUploadFieldIndex, this.usableUploadField);
        if (this.selectedFieldType == 'image' && this.selectedFieldIndex > -1) {
            this.selectedFile = event.target.files[0];
            var uploadData = new FormData();
            uploadData.append("image", this.selectedFile, this.selectedFile.name);
            this._uploadImageLoader('show', this.selectedFieldIndex);
            this.apiService.postUploadCardImage(uploadData).subscribe(function (res) {
                _this.uploadCardImageRef.nativeElement.value = "";
                var el = document.getElementById("_image_" + _this.selectedFieldIndex);
                if (res.response == "success") {
                    var uploadedImage = res.data.image;
                    el.src = uploadedImage;
                    _this._setUploadImageValue(uploadedImage);
                    _this._uploadImageLoader('hide', _this.selectedFieldIndex);
                }
                else if (res.response == "error") {
                    el.src = _this.defaultCardImage;
                    _this.nfs.showNotification("default", res.message);
                    _this._uploadImageLoader('hide', _this.selectedFieldIndex);
                }
            });
        }
        else {
            this.uploadCardImageRef.nativeElement.value = "";
            this.nfs.showNotification("default", 'Select Image Area to update.');
        }
    };
    EcardDetailComponent.prototype._stopEnter = function (event) {
        var _this = this;
        if (this.isDesktopDevice) {
            if (event.key === "Enter" && !event.shiftKey) {
                if (!this.cardInfoActive) {
                    this.cardInfoActive = true;
                }
                setTimeout(function () {
                    _this.cardInfoActive = false;
                }, 1000);
                return false;
            }
            else if (event.key === "Enter" && event.shiftKey) {
                return true;
            }
            else {
                return true;
            }
        }
    };
    EcardDetailComponent.prototype._checkImageIsUploaded = function () {
        var totalUploadFields = 0;
        var totalUploadFound = 0;
        var getImages = this.frontendCustomizatedData.filter(function (d) { return (d.type == 'image'); });
        totalUploadFields = getImages.length;
        if (getImages.length > 0) {
            for (var _i = 0, getImages_1 = getImages; _i < getImages_1.length; _i++) {
                var i = getImages_1[_i];
                if (i.value != "") {
                    totalUploadFound++;
                }
                else {
                    this._getSelectedFields('image', i.id);
                    totalUploadFound = 0;
                }
            }
            if (totalUploadFields == totalUploadFound) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return true;
        }
    };
    EcardDetailComponent.prototype._saveCheckout = function () {
        var _this = this;
        var uploadValidation = this._checkImageIsUploaded();
        if (!uploadValidation) {
            this.nfs.showNotification("default", 'Upload image to proceed.');
            return false;
        }
        var checkLength = this.frontendCustomizatedData.length;
        if (checkLength > 0) {
            this.proceedBtnText = "Processing..";
            this.selectedFieldIndex = -1;
            var order_number_1 = "";
            var e_order = localStorage.getItem('e_order');
            e_order = JSON.parse(e_order);
            if (e_order != null) {
                if (e_order.order_no != "") {
                    order_number_1 = e_order.order_no;
                }
            }
            if (this.frontendCustomizatedData.length > 0) {
                var i = 0;
                for (var _i = 0, _a = this.frontendCustomizatedData; _i < _a.length; _i++) {
                    var d = _a[_i];
                    var _get_styles = document.getElementById('_get_prop_' + d.id).style.cssText;
                    this.frontendCustomizatedData[i].styles = _get_styles;
                    var validate = this.frontendCustomizatedData[i].validate;
                    if (validate) {
                        var checkValue = this.frontendCustomizatedData[i].value.trim();
                        if (checkValue == "") {
                            this.selectedFieldIndex = this.frontendCustomizatedData[i].id;
                            this.selectedFieldType = "input";
                            this.nfs.showNotification("`default`", 'This is a mandatory field, please add text to proceed');
                            return false;
                        }
                    }
                    i++;
                }
            }
            setTimeout(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var node, blob, uploadData;
                var _this = this;
                return tslib_1.__generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            node = document.getElementById('_card');
                            return [4 /*yield*/, domtoimage.toBlob(node)];
                        case 1:
                            blob = _a.sent();
                            uploadData = new FormData();
                            uploadData.append('image', blob);
                            uploadData.append('prefix', this.productUrl);
                            uploadData.append('order_number', order_number_1);
                            uploadData.append('os', JSON.stringify(this.os));
                            this.proceedBtnText = "Saving..";
                            uploadData.append('data', JSON.stringify(this.frontendCustomizatedData));
                            this.apiService.postEcardWatermarkPreview(uploadData).subscribe(function (res) {
                                if (res.response == "success") {
                                    var url = res.data.url;
                                    var order_no = res.data.order_number;
                                    var product_id = res.data.product_id;
                                    var storeOrder = { 'order_no': order_no, 'prod': product_id, 'design': _this.designNo };
                                    localStorage.setItem('e_order', JSON.stringify(storeOrder));
                                    if (url) {
                                        _this.router.navigate(["/rsvp/ecard/" + _this.productUrl]);
                                    }
                                }
                                else {
                                    _this.nfs.showNotification("default", 'Preview not created. Try Again.');
                                }
                                _this.proceedBtnText = "Save & Proceed";
                            });
                            return [2 /*return*/];
                    }
                });
            }); }, 200);
        }
        else {
            this.nfs.showNotification("default", 'Please make changes to proceed.');
        }
    };
    EcardDetailComponent.prototype._saveAsDraft = function (el) {
        var _this = this;
        var checkLength = this.frontendCustomizatedData.length;
        if (checkLength > 0) {
            el.target.innerHTML = "Saving..";
            var order_number = "";
            var e_order = localStorage.getItem('e_order');
            e_order = JSON.parse(e_order);
            if (e_order != null) {
                if (e_order.order_no != "") {
                    order_number = e_order.order_no;
                }
            }
            var i = 0;
            for (var _i = 0, _a = this.frontendCustomizatedData; _i < _a.length; _i++) {
                var d = _a[_i];
                var _get_styles = document.getElementById('_get_prop_' + d.id).style.cssText;
                this.frontendCustomizatedData[i].styles = _get_styles;
                i++;
            }
            this.apiService.postEcardDraft({ 'prefix': this.productUrl, 'data': JSON.stringify(this.frontendCustomizatedData), 'order_number': order_number }).subscribe(function (res) {
                if (res.response == "success") {
                    _this.nfs.showNotification("default", "Saved");
                    var order_no = res.data.order_number;
                    var product_id = res.data.product_id;
                    var storeOrder = { 'order_no': order_no, 'prod': product_id, 'design': _this.designNo };
                    // let storeOrder = { 'order_no': order_no, 'prod': product_id }
                    localStorage.setItem('e_order', JSON.stringify(storeOrder));
                }
                else {
                    _this.nfs.showNotification("default", 'Not Saved Error Occurs');
                    return false;
                }
                el.target.innerHTML = "Save as Draft";
            });
        }
        else {
            this.nfs.showNotification("default", 'Please make changes to proceed.');
        }
    };
    EcardDetailComponent.prototype._uploadImageLoader = function (mode, propId) {
        var el = document.getElementById("_loader_" + propId);
        if (mode == 'show') {
            el.style.display = 'flex';
        }
        else {
            el.style.display = 'none';
        }
    };
    EcardDetailComponent.prototype._setCaretPosition = function (el) {
        var selection = window.getSelection();
        var range = document.createRange();
        selection.removeAllRanges();
        range.selectNodeContents(el);
        range.collapse(false);
        selection.addRange(range);
        el.focus();
    };
    /* MOBILE ECARD */
    EcardDetailComponent.prototype._m_tabs = function (mode) {
        if (mode === this.selectedMobileTab) {
            this.selectedMobileTab = "";
            return;
        }
        this.selectedMobileTab = mode;
        if (this.selectedMobileTab === "content") {
            if (this.selectedFieldIndex < 0) {
                this.selectedMobileTab = "";
            }
            else {
                var matchedIndex = this.frontendCustomizatedData
                    .map(function (obj) {
                    return obj.id;
                })
                    .indexOf(this.selectedFieldIndex);
                this.selectedFieldText = this.frontendCustomizatedData[parseInt(matchedIndex)].value;
            }
        }
    };
    EcardDetailComponent.prototype._m_cancel_content = function () {
        this.selectedMobileTab = "";
        return;
    };
    EcardDetailComponent.prototype._m_update_content = function () {
        console.log(this.selectedFieldIndex, this.selectedFieldType);
        if (this.selectedFieldIndex > 0 && this.selectedFieldType == 'input') {
            var matchedIndex = this.frontendCustomizatedData
                .map(function (obj) {
                return obj.id;
            })
                .indexOf(this.selectedFieldIndex);
            this.frontendCustomizatedData[parseInt(matchedIndex)].value = this.mContentRef.nativeElement.innerHTML;
            this.selectedMobileTab = "";
        }
    };
    EcardDetailComponent.prototype._m_goback = function () {
        this._location.back();
    };
    return EcardDetailComponent;
}());
export { EcardDetailComponent };
