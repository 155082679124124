import { BehaviorSubject } from "rxjs/BehaviorSubject";
import * as i0 from "@angular/core";
var DataService = /** @class */ (function () {
    function DataService() {
        this.sessionMessageSource = new BehaviorSubject(false);
        this.getSessionMessage = this.sessionMessageSource.asObservable();
        this.categoriesSource = new BehaviorSubject([]);
        this.getCategories = this.categoriesSource.asObservable();
        this.selectCategorySource = new BehaviorSubject('0');
        this.getCategory = this.selectCategorySource.asObservable();
        this.wishlistSource = new BehaviorSubject([]);
        this.getWishlists = this.wishlistSource.asObservable();
    }
    DataService.prototype.sessionMessage = function (message) {
        this.sessionMessageSource.next(message);
    };
    DataService.prototype.setCategories = function (categories) {
        this.categoriesSource.next(categories);
    };
    DataService.prototype.setCategory = function (category) {
        this.selectCategorySource.next(category);
    };
    DataService.prototype.setWishlists = function (wishlist) {
        this.wishlistSource.next(wishlist);
    };
    DataService.ngInjectableDef = i0.defineInjectable({ factory: function DataService_Factory() { return new DataService(); }, token: DataService, providedIn: "root" });
    return DataService;
}());
export { DataService };
