<div class="_main_container _header_shadow">
  <app-header></app-header>
</div>
<!-- Slideshow -->
<div class="_main_container">
  <div class="_slideshow" style="height:330px;">
    <img src="assets/img/banner.png" class="_slides" alt="Slides">
  </div>
</div>

<div class="_main_container">
  <div class="_help_desk">
    <div class="_help_heading">FAQ'S</div>
    <div class="_inner_help">
      <div class="_faq_row">
        <div class="_question" (click)="_expandFilter($event)">Why do we use it?
          <i aria-hidden="true" class="fa fa-minus"></i><i class="fa fa-plus" aria-hidden="true"></i>
        </div>
        <div class="_answer">It is a long established fact that a reader will be distracted by the readable content of a
          page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal
          distribution of letters, as opposed to using 'Content here, content here', making it look like readable
          English.</div>
      </div>
      <div class="_faq_row">
        <div class="_question" (click)="_expandFilter($event)">Why do we use it?
          <i aria-hidden="true" class="fa fa-minus"></i><i class="fa fa-plus" aria-hidden="true"></i>
        </div>
        <div class="_answer">It is a long established fact that a reader will be distracted by the readable content of a
          page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal
          distribution of letters, as opposed to using 'Content here, content here', making it look like readable
          English.</div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>