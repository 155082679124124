<div class="_main_container _header_shadow">
  <app-header></app-header>
</div>
<!-- Slideshow -->
<div class="_main_container">
  <div class="_help_banner">
    <img [src]="help_banner" class="_slides" alt="Slides">
  </div>
</div>

<div class="_main_container">
  <div class="_help_desk">
    <div class="_help_heading">SUPPORT</div>
    <div class="_inner_help">
      <ul class="_item_area">
        <li><a [routerLink]="" (click)="_helpModal(true)"><img class="_help_img" src="assets/img/tickets.png">
            <p class="_title">Create new ticket</p>
          </a></li>
        <li><a href="mailto:support@lekarde.com?Subject=Hello" target="_top"><img class="_help_img"
              src="assets/img/email.png">
            <p class="_title">Email us</p>
          </a></li>
        <li><a href="tel:9810186266"><img class="_help_img" src="assets/img/phone.png">
            <p class="_title">Call us</p>
          </a> </li>
      </ul>
    </div>
  </div>
</div>

<div class="_help_overlay" [ngClass]="{'_active' : isHelpModalActive}">
  <div class="_help_form">

    <form class="_form_desk" [formGroup]="helpFormGroup" (ngSubmit)="_submitTicket()">
      <button type="button" class="_close" (click)="_helpModal(false)">
        <img class="_cross" src="assets/img/close_modal.jpg">
      </button>
      <h2 class="_heading"> Create new ticket</h2>
      <div class="_field _inputs" [ngClass]="{'_invalid' : formControlValid('name')}">
        <label for="event_date">Name</label>
        <input type="text" formControlName="name" name="name" id="name" />
      </div>
      <div class="_field _inputs" [ngClass]="{'_invalid' : formControlValid('email')}">
        <label for="event_date">Email</label>
        <input type="text" formControlName="email" name="email" id="email" />
      </div>
      <div class="_field _inputs" [ngClass]="{'_invalid' : formControlValid('type')}">
        <label for="event_date">Type</label>
        <div class="_type">
          <input type="radio" name="type" formControlName="type" value="ecard" /><span>Ecard</span>
        </div>
        <div class="_type">
          <input type="radio" name="type" formControlName="type" value="vcard" /><span>Vcard</span>
        </div>
        <div class="_type">
          <input type="radio" name="type" formControlName="type" value="other" id="other" /><span>Other</span>
        </div>
      </div>
      <div class="_field _inputs" *ngIf="isOrderActive">
        <label for="order_no">Select Order</label>
        <select id="order_no" name="order_no" formControlName="order_no">
          <option value="">Select Order No.</option>
          <option *ngFor="let o of orders" [value]="o" [innerHtml]="o"></option>
        </select>
      </div>
      <div class="_field _inputs" [ngClass]="{'_invalid' : formControlValid('description')}">
        <label for="event_date">Description</label>
        <textarea class="_input_text_area" formControlName="description" name="description" id="description"></textarea>
        <span class="_note">Please enter the details of your request. A member of our support<br>staff will respond as
          soon as
          possible</span>
      </div>
      <div class="_field _inputs">
        <label for="event_date">Attachments</label>
        <div class="input-file-container">
          <input class="input-file" id="my-file" type="file" #helpAttachmentRef (change)="_uploadImage($event)">
          <label tabindex="0" for="my-file" class="input-file-try"><img
              src="assets/img/attachment.jpg">{{attachmentText}}</label>
        </div>
      </div>
      <div class="_checkout">
        <button class="_action">Submit</button>
      </div>
      <div class="_ticket_no" *ngIf="isTicketGenerated != ''">
        Your Ticket No : {{isTicketGenerated}}
      </div>
    </form>
  </div>
</div>



<app-footer></app-footer>