import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'
import { ApiService } from "../service/api.service"
import { environment } from 'src/environments/environment';
import domtoimage from 'retina-dom-to-image';
interface ProductsModel {
  card_id: string,
  card_template: string,
  card_img: string,
  card_name: string,
  design_no: string,
  card_prefix: string,
  is_image: string,
  property_detail: any
}

@Component({
  selector: 'app-mockup',
  templateUrl: './mockup.component.html',
  styles: [`.Static_card{justify-content: center;}._left{background:#f8f8f8}._preview_download{ background: #3c8dbc;}`]
})
export class MockupComponent implements OnInit {
  products: Array<ProductsModel>
  productMediaPath: string = '';
  isProductLoading: boolean = true
  card_prefix: string;
  isUploadFieldAcive: boolean
  properties: any
  frontendCustomizatedData: any = [];
  constructor(private route: ActivatedRoute, private apiService: ApiService) {
    this.productMediaPath = environment.PROD_BASEPATH
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params.card_prefix != '') {
        this.card_prefix = params.card_prefix

        this.apiService.getEcardProperties({ card_prefix: this.card_prefix, mockup: true }).subscribe(data => {
          if (data.response == 'success') {
            this.products = data.data
            // Upload Field
            if (this.products[0].is_image == '1') {
              this.isUploadFieldAcive = true
            } else {
              this.isUploadFieldAcive = false
            }
            this.properties = JSON.parse(this.products[0].property_detail)


            let getPropertiesInputs = this.properties.filter(d => (d.type == 'input' || d.type == 'image'));
            if (getPropertiesInputs.length > 0) {
              for (let i of getPropertiesInputs) {
                let validateField = false
                if (i.validate != undefined) {
                  if (i.validate == "true") {
                    validateField = true
                  } else {
                    validateField = false
                  }
                }

                if (i.type == 'input') {
                  let d = {
                    'id': i.id,
                    'value': i.defaultValue,
                    'color': '',
                    'textAlign': i.textAlign,
                    'fontSize': i.fontSize,
                    'fontFamily': '',
                    'styles': i.styles,
                    'type': i.type,
                    'width': i.width,
                    'height': i.height,
                    'validate': validateField
                  }
                  this.frontendCustomizatedData.push(d);
                } else {
                  let d = {
                    'id': i.id,
                    'type': i.type,
                    'value': i.defaultValue,
                    'width': i.width,
                    'height': i.height,
                    'styles': i.styles,
                    'validate': validateField
                  }
                  if (i.corp_logo) {
                    Object.assign(d, { 'corp_logo': i.corp_logo })
                    Object.assign(d, { 'uploadPlaceholder': 'logo-' + i.upload_placeholder })
                  } else {
                    Object.assign(d, { 'uploadPlaceholder': 'picture-' + i.upload_placeholder })
                  }
                  this.frontendCustomizatedData.push(d);
                }
              }
            }
          }
          this.isProductLoading = false
        })

      }
    })
  }

  async _download_mockup() {
    let card_prefix = this.card_prefix
    var node = document.getElementById('_card');
    domtoimage.toPng(node)
      .then(function (dataUrl) {
        var link = document.createElement('a');
        link.download = `${card_prefix}.png`;
        link.href = dataUrl;
        link.click();
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });
  }

}
