import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthSessionParamsService {

  constructor() { }

  isUserParamsAuthenticated(): boolean {
    const token = localStorage.getItem("token");
    const user = JSON.parse(localStorage.getItem("user"));
    if (token && user && Object.keys(user).length > 0) {
      return true;
    } else {
      return false;
    }
  }
}
