import { Component, OnInit, AfterViewInit, Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { DataService } from "../service/data.service"
import { ApiService } from "../service/api.service"
import { environment } from 'src/environments/environment';
import { NotificationService } from "../service/notification.service";
import { OwlOptions } from 'ngx-owl-carousel-o';
import { DeviceDetectorService } from 'ngx-device-detector';
import { jarallax } from 'jarallax';
import { Meta, Title } from '@angular/platform-browser';

interface Banners {
  image_link: string,
  img_url: string
}

interface LatestCollection {
  card_image: string,
  card_name: string,
  card_prefix: string,
  design_no: string,
  parent_card_id: string,
  price: string,
  prod_id: string,
  spl_price: string
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styles: []
})
export class HomeComponent implements OnInit, AfterViewInit {
  weddingList: any[] = []
  home_videos: string[] = ["LK_banner_1.mp4", "LK_banner_3.mp4", "LK_banner_2.mp4"]
  home_video: string = ''
  banner_image1: string = ''
  bannerImageLink1: string = ''
  bannerImage2: string = ''
  bannerImageLink2: string = ''
  bannerImage3: string = ''
  bannerImageLink3: string = ''
  bannerImage4: string = ''
  bannerImageLink4: string = ''
  bannerImageLink4_ecard: string = ''
  bannerImage5: string = ''
  bannerImageLink5: string = ''
  bannerImageLink5_ecard: string = ''
  bannerImage6: string = ''
  bannerImageLink6: string = ''
  bannerImageLink6_ecard: string = ''
  bannerImage7: string = ''
  bannerImageLink7: string = ''
  bannerImageLink7_ecard: string = ''
  bannerImage8: string = ''
  bannerImageLink8: string = ''
  bannerImageLink8_ecard: string = ''
  bannerImage9: string = ''
  bannerImage9Link: string = ''
  bannerImage9Link_ecard: string = ''
  bannerImage9Title: string = ''
  bannerImage9Content: string = ''
  bannerImage10: string = ''
  bannerImage10Link: string = ''
  bannerImage11: string = ''
  bannerImage11Title: string = ''
  bannerImage11Content: string = ''
  bannerImage11Link: string = ''
  bannerImage12: string = ''
  bannerImage12Title: string = ''
  bannerImage12Content: string = ''
  bannerImage12Link: string = ''
  latestCollection: Array<LatestCollection> = []
  verticalProducts: any[] = []
  horizontalProducts: any[] = []
  squareProducts: any[] = []
  verticalBirthdayProducts: any[] = []
  horizontalBirthdayProducts: any[] = []
  squareBirthdayProducts: any[] = []
  verticalPartyProducts: any[] = []
  horizontalPartyProducts: any[] = []
  squarePartyProducts: any[] = []
  productMediaPath: string = '';
  productGifPath: string = ''
  wishlists: number[]
  bannerWedding: Banners
  bannerBirthday: Banners
  bannerParty: Banners
  isWeddingLoading: boolean = true;
  isBirthdayLoading: boolean = true;
  isPartyLoading: boolean = true;
  customOptions: OwlOptions = {
    loop: false,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    autoplay: true,
    autoplayTimeout: 6000,
    autoplaySpeed: 1000,
    navSpeed: 700,
    navText: ['<img src="assets/img/_prev.png" width="20" />', '<img src="assets/img/_next.png" width="20" />'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: true
  }

  isMobile: boolean = false;
  banner_image2_mobile: string = ""
  banner_image3_mobile: string = ""
  banner_image11_mobile: string = ""
  banner_image12_mobile: string = ""
  bannerImage10_mobile: string = ""
  banner_image1_mobile: string = ""
  isVirtualInvitationActive: boolean = false
  parallax_image: string = ""
  modalActive: boolean = false
  modalActiveMode: string = ""
  constructor(private title: Title, private meta: Meta, private renderer: Renderer2, private dataService: DataService, private apiService: ApiService, private nfs: NotificationService, private deviceService: DeviceDetectorService) {
    this.productMediaPath = environment.PROD_BASEPATH
  }

  ngOnInit() {

    this.isMobile = this.deviceService.isMobile();

    this._getHomePageSection();
    this.getWeddingSection();
    this.getBirthdaySection();
    this.getPartySection();

  }

  ngAfterViewInit() {
    var _video_counter = 0;
    if (!this.isMobile) {
      setTimeout(function () {
        var video: any = document.getElementById(`_banner_slide_0`);
        if (video) {
          video.muted = true;
          video.play();
        }
        _video_counter++;
      }, 500);
    }


    jarallax(document.querySelectorAll('.jarallax'), {
      speed: 0.5
    });

  }


  _ng_modal_event(modalActiveMode: string) {
    this.modalActive = !this.modalActive
    this.modalActiveMode = modalActiveMode
  }

  /*
  onImageWillLoad(name: string): void {
    console.log(`Image ${name} Will Load`);
  }
  */

  _banner_slide_changed(event) {
    if (event) {
      let startPosition: number = event.startPosition
      if (startPosition == 2) {
        startPosition = 0
      } else {
        startPosition++
      }
      setTimeout(function () {
        let video: any = document.getElementById(`_banner_slide_${startPosition}`);
        if (video) {
          video.muted = true;
          video.play();
        }
      }, 1000);
    }
  }

  _getHomePageSection() {
    this.apiService.getHomePageSetting().subscribe(data => {

      if (data.message == 'Success') {
        this.title.setTitle(data.response.meta_title);
        this.meta.updateTag({ name: 'description', content: data.response.meta_desc });
        this.meta.updateTag({ name: 'keywords', content: data.response.meta_keywords });

        this.home_video = data.response.home_video
        this.banner_image1 = data.response.banner_image1
        this.bannerImageLink1 = data.response.banner_image1_text
        this.bannerImage2 = data.response.banner_image2
        this.bannerImageLink2 = data.response.banner_image2_link
        this.bannerImage3 = data.response.bannerImage3
        this.bannerImageLink3 = data.response.bannerImage3Link
        this.bannerImage4 = data.response.bannerImage4
        this.bannerImageLink4 = data.response.bannerImage4Link
        this.bannerImage5 = data.response.bannerImage5
        this.bannerImageLink5 = data.response.bannerImage5Link
        this.bannerImage6 = data.response.bannerImage6
        this.bannerImageLink6 = data.response.bannerImage6Link
        this.bannerImage7 = data.response.bannerImage7
        this.bannerImageLink7 = data.response.bannerImage7Link
        this.bannerImage8 = data.response.bannerImage8
        this.bannerImageLink8 = data.response.bannerImage8Link
        this.bannerImage9 = data.response.bannerImage9
        this.bannerImage9Title = data.response.banner_image9_title
        this.bannerImage9Content = data.response.bannerImage9Content
        this.bannerImage9Link = data.response.banner_image9_link
        this.bannerImage10 = data.response.bannerImage10
        this.bannerImage10Link = data.response.bannerImage10Link
        this.bannerImage11 = data.response.bannerImage11
        this.bannerImage11Title = data.response.bannerImage11Title
        this.bannerImage11Content = data.response.bannerImage11Content
        this.bannerImage11Link = data.response.bannerImage11Link
        this.bannerImage12 = data.response.bannerImage12
        this.bannerImage12Title = data.response.bannerImage12Title
        this.bannerImage12Content = data.response.bannerImage12Content
        this.bannerImage12Link = data.response.bannerImage12Link
        this.latestCollection = data.response.latestCollection
        this.banner_image2_mobile = data.response.banner_image2_mobile
        this.banner_image3_mobile = data.response.banner_image3_mobile
        this.banner_image11_mobile = data.response.banner_image11_mobile
        this.banner_image12_mobile = data.response.banner_image12_mobile
        this.bannerImage10_mobile = data.response.banner_image10_mobile
        this.banner_image1_mobile = data.response.banner_image1_mobile

        this.bannerImageLink4_ecard = data.response.bannerImage4Link_ecard
        this.bannerImageLink5_ecard = data.response.bannerImage5Link_ecard
        this.bannerImageLink6_ecard = data.response.bannerImage6Link_ecard
        this.bannerImageLink7_ecard = data.response.bannerImage7Link_ecard
        this.bannerImageLink8_ecard = data.response.bannerImage8Link_ecard
        this.bannerImage9Link_ecard = data.response.bannerImage9Link_ecard

        if (this.isMobile) {
          this.bannerImage2 = this.banner_image2_mobile
          this.bannerImage3 = this.banner_image3_mobile
          this.bannerImage11 = this.banner_image11_mobile
          this.bannerImage12 = this.banner_image12_mobile
          this.bannerImage10 = this.bannerImage10_mobile
          this.banner_image1 = this.banner_image1_mobile
          this.parallax_image = "assets/img/parallax-mobile.jpg"
        } else {
          this.parallax_image = "assets/img/parallax_background.jpg"
        }


      }
    })
  }

  getWeddingSection() {
    this.apiService.getHomeWeddinglist().subscribe((data) => {
      // console.log(data);
      if (data.message == 'Success') {
        this.isWeddingLoading = false
        this.verticalProducts = data.response.vertical
        this.horizontalProducts = data.response.horizontal
        this.squareProducts = data.response.square
        this.bannerWedding = data.response.banner
      }
    });
  }

  getBirthdaySection() {
    this.apiService.getHomeBirthdaylist().subscribe((data) => {
      if (data.message == 'Success') {
        this.isBirthdayLoading = false
        this.verticalBirthdayProducts = data.response.vertical
        this.horizontalBirthdayProducts = data.response.horizontal
        this.squareBirthdayProducts = data.response.square
        this.bannerBirthday = data.response.banner
      }
    });
  }

  getPartySection() {
    this.apiService.getHomePartylist().subscribe((data) => {
      if (data.message == "Success") {
        this.isPartyLoading = false
        this.verticalPartyProducts = data.response.vertical
        this.horizontalPartyProducts = data.response.horizontal
        this.squarePartyProducts = data.response.square
        this.bannerParty = data.response.banner
      }
    });
  }



  // Modify Data Add Wishlist Key 
  _modifyData(data: any) {
    let modifiedData = []
    if (data.length > 0) {
      for (let d of data) {
        var flag = false
        if (this.wishlists) {
          if (this.wishlists.includes(Number(d.prod_id))) {
            flag = true
          } else {
            flag = false
          }
        }
        modifiedData.push({ ...d, 'wishlist': flag })
      }
      return modifiedData;
    } else {
      return []
    }
  }

  _addWishlist(product, i, mode) {
    if (product) {
      this.apiService.postWishlist({ 'prod_url': product.product_url_name }).subscribe(data => {
        if (data.response == 'success') {
          let status = data.data
          if (mode == 'squareProducts') {
            this.squareProducts[i].wishlist = status
          } else if (mode == 'horizontalProducts') {
            this.horizontalProducts[i].wishlist = status
          } else if (mode == 'verticalProducts') {
            this.verticalProducts[i].wishlist = status
          } else if (mode == 'squareBirthdayProducts') {
            this.squareBirthdayProducts[i].wishlist = status
          } else if (mode == 'horizontalBirthdayProducts') {
            this.horizontalBirthdayProducts[i].wishlist = status
          } else if (mode == 'verticalBirthdayProducts') {
            this.verticalBirthdayProducts[i].wishlist = status
          } else if (mode == 'squarePartyProducts') {
            this.squarePartyProducts[i].wishlist = status
          } else if (mode == 'horizontalPartyProducts') {
            this.horizontalPartyProducts[i].wishlist = status
          } else if (mode == 'verticalPartyProducts') {
            this.verticalPartyProducts[i].wishlist = status
          }

          if (status) {
            this.nfs.showNotification("default", 'Wishlisted');
          } else {
            this.nfs.showNotification("default", 'Removed from Wishlist');
          }
        }
      })
    }
  }

  _changeSrcArrtibute(mode: string, event, product) {
    if (mode == 'in') {
      if (product.gif_img != '') {
        event.target.src = `${environment.GIF_PATH}${product.gif_img}`
      }
    } else {
      event.target.src = `${environment.PROD_BASEPATH}${product.image_url}`
    }
  }

}


