import * as i0 from "@angular/core";
var AuthSessionParamsService = /** @class */ (function () {
    function AuthSessionParamsService() {
    }
    AuthSessionParamsService.prototype.isUserParamsAuthenticated = function () {
        var token = localStorage.getItem("token");
        var user = JSON.parse(localStorage.getItem("user"));
        if (token && user && Object.keys(user).length > 0) {
            return true;
        }
        else {
            return false;
        }
    };
    AuthSessionParamsService.ngInjectableDef = i0.defineInjectable({ factory: function AuthSessionParamsService_Factory() { return new AuthSessionParamsService(); }, token: AuthSessionParamsService, providedIn: "root" });
    return AuthSessionParamsService;
}());
export { AuthSessionParamsService };
