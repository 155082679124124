<div class="_main_container _header_shadow">
  <app-header></app-header>
</div>

<div class="_main_container">
  <div class="_social_container">
    <div class="_top">
      <button class="_social_buttons" (click)="registerFacebook()"><i class="fa fa-facebook" aria-hidden="true"></i>
        Signup with
        Facebook</button>
      <button class="_social_buttons" (click)="registerGoogle()"><i class="fa fa-google" aria-hidden="true"></i> Signup
        with
        Google</button>
    </div>


    <div class="_form_parent">
      <p class="_content">Continue with email:</p>
      <form class="_form_sign custom-forms" novalidate="" [formGroup]="signupFormGroup" (ngSubmit)="createAccount()">
        <div class="_form_field" [ngClass]="{'_invalid' : formControlValid('name')}">
          <input formControlName="name" id="name" name="name" placeholder="Name" type="text">
        </div>
        <div class="_form_field" [ngClass]="{'_invalid' : formControlValid('email')}">
          <input formControlName="email" id="email" name="email" placeholder="Email" type="email">
        </div>
        <div class="_form_field" [ngClass]="{'_invalid' : formControlValid('mobile')}">
          <input formControlName="mobile" id="mobile" name="mobile" placeholder="Mobile" type="text" maxlength=10
            minlength=10>
        </div>
        <div class="_form_field" [ngClass]="{'_invalid' : formControlValid('password')}">
          <input formControlName="password" id="password" name="password" placeholder="Password" type="password">
          <span class="_password_error">Must have one Capital letter, Number, Special char and length must be 8 or
            greater</span>
        </div>
        <div class="_action">
          <div class="_btn">
            <button type="submit" [disabled]="signupFormGroup.invalid"> Sign up </button>
          </div>
          <p><a routerLink="/login">Login</a></p>
        </div>
      </form>
    </div>
  </div>

</div>


<div class="_clearfix"></div>
<app-footer></app-footer>