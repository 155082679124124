import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { ApiService } from "../service/api.service"
import { NotificationService } from "../service/notification.service";
import { environment } from 'src/environments/environment';

interface Users {
  location: string
  street: string
  city: string
  state: string
  postal_code: string
  id: string
  rsvp_id: string
  name: string
  email: string
  mobile: string
  attend: string
  created_at: string,
  address: string,
  event: string
}
@Component({
  selector: 'app-rsvp-listings',
  templateUrl: './rsvp-listings.component.html',
  styles: []
})
export class RsvpListingsComponent implements OnInit {
  isLoading: boolean = true
  order_type: string
  order_no: string
  shared_url: string
  users: Array<Users>
  cardImage: string = ""
  constructor(private route: ActivatedRoute, private router: Router, private apiService: ApiService, private nfs: NotificationService) {
    this.route.params.subscribe(params => {
      this.order_type = params.rsvp_type
      this.order_no = params.order_no
      this.shared_url = params.shared_url
    })
  }

  ngOnInit() {
    this._getRsvpLists();
  }

  _getRsvpLists() {
    this.apiService.getRsvpLists({ order_type: this.order_type, order_no: this.order_no, shared_url: this.shared_url }).subscribe(data => {
      if (data.response == 'success') {
        this.users = data.data
        this.cardImage = data.image
      } else {
        this.users = []
      }
      this.isLoading = false
    })
  }


  _sortRsvpList(el) {
    let value = el.target.value;
    this.apiService.getRsvpLists({ order_type: this.order_type, order_no: this.order_no, shared_url: this.shared_url, filter: value }).subscribe(data => {
      if (data.response == 'success') {
        this.users = data.data
      } else {
        this.users = []
      }
      this.isLoading = false
    })
  }

  _exportRsvpList() {
    this.nfs.showNotification("default", 'Exporting in process..');
    this.apiService.getExportedRsvpList({ order_type: this.order_type, order_no: this.order_no, shared_url: this.shared_url }).subscribe(data => {
      if (data.response == 'success') {
        let fileUrl = data.data
        var tempLink = document.createElement('a');
        tempLink.href = fileUrl;
        tempLink.setAttribute('download', 'export.csv');
        tempLink.click();
      } else {
        this.nfs.showNotification("default", 'Failed to export.');
      }
    })
  }

}
