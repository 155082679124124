import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router'

import { AuthBeforeSessionGuardService } from "./service/auth-before-session-guard.service";
import { AuthSessionGuardService } from "./service/auth-session-guard.service";

import { SignupComponent } from './signup/signup.component'
import { LoginComponent } from './login/login.component'
import { DashboardComponent } from './dashboard/dashboard.component'

import { HomeComponent } from './home/home.component'
import { RsvpComponent } from './rsvp/rsvp.component'
import { FilterPageComponent } from './filter-page/filter-page.component'
import { ProductVerticalComponent } from './product-vertical/product-vertical.component'
import { DetailComponent } from './detail/detail.component'
import { PreviewComponent } from './preview/preview.component'
import { SettingsComponent } from './settings/settings.component'
import { SharedcardComponent } from './sharedcard/sharedcard.component';
import { SearchComponent } from './search/search.component'
import { EcardsComponent } from './ecards/ecards.component'
import { EcardDetailComponent } from './ecard-detail/ecard-detail.component'
import { EcardHandlerComponent } from './ecard-handler/ecard-handler.component';
import { HelpComponent } from './help/help.component';
import { FaqComponent } from './faq/faq.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { RsvpListingsComponent } from './rsvp-listings/rsvp-listings.component';

import { MockupComponent } from './mockup/mockup.component';
import { BespokenComponent } from './bespoken/bespoken.component'

const routes: Routes = [
  { path: "", component: HomeComponent },
  { path: "custom_design", component: BespokenComponent },
  { path: "help", component: HelpComponent },
  { path: "reset/:forgot_url", component: ResetPasswordComponent },
  { path: "registration", component: SignupComponent, canActivate: [AuthBeforeSessionGuardService] },
  { path: "login", component: LoginComponent, canActivate: [AuthBeforeSessionGuardService] },
  { path: 'dashboard', redirectTo: 'dashboard/' },
  { path: "dashboard/:mode", component: DashboardComponent, canActivate: [AuthSessionGuardService] },
  { path: "rsvp/detail/:rsvp_type/:order_no", component: RsvpListingsComponent, canActivate: [AuthSessionGuardService] },
  { path: "order/setting/:order_no", component: SettingsComponent, canActivate: [AuthSessionGuardService] },
  { path: "preview/:product_url", component: PreviewComponent, canActivate: [AuthSessionGuardService] },
  { path: "rsvp/:rsvp_type/:product_url", component: RsvpComponent, canActivate: [AuthSessionGuardService] },
  { path: "vcards/:category/:sub_category", component: FilterPageComponent },
  { path: "vcards/customize/:category/:product_url", component: ProductVerticalComponent },
  { path: "vcard/create/:product_url", component: DetailComponent, canActivate: [AuthSessionGuardService] },
  { path: "search/:category", component: SearchComponent },
  { path: "ecard/create/:product_url/:design_no", component: EcardDetailComponent },
  { path: "ecards/:category/:sub_category", component: EcardsComponent },
  { path: "ecard/handler/:order_no", component: EcardHandlerComponent, canActivate: [AuthSessionGuardService] },
  { path: "vcard/:shared_url", component: SharedcardComponent },
  { path: "ecard/:shared_url", component: SharedcardComponent },
  { path: "view/:mode/:order_number/:order_id", component: SharedcardComponent },
  { path: 'mockup/:card_prefix', component: MockupComponent },
  { path: "faqs", component: FaqComponent },
  { path: "form", component: SettingsComponent, canActivate: [AuthSessionGuardService] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
