<div class="_main_container _header_shadow">
  <app-header></app-header>
</div>

<div class="_main_container">
  <div class="_filter_page_bg" style="padding: 20px 0 0 0;">
    <div class="_inner_filter">
      <div class="_filter_right">
        <div class="_heading">
          <div class="_left">Showing Invites for <span class="_search_term"> - {{productCategory}}</span></div>
          <div class="_sort">
            <select [(ngModel)]="sortType" name="_sortByType" (change)="_sortProducts()">
              <option value="">Sort</option>
              <option value="vertical">Vertical</option>
              <option value="horizontal">Horizontal</option>
              <option value="instagram">Instagram</option>
            </select>
          </div>
        </div>
        <!--Products-->
        <p class="_loading" *ngIf="isProductsLoading">
          <span class="loader"></span>
          <span class="_text">Loading..</span>
        </p>
        <ng-container *ngIf="verticalProducts?.length > 0 && !isProductsLoading">
          <div class="_products">
            <div class="_grid_vertical" *ngFor="let product of verticalProducts;let i = index">
              <div class="_inner">
                <a routerLink="/product/info/{{productCategory}}/{{product.product_url_name}}">
                  <img [src]="productMediaPath + product.image_url" class="_v_product_image">
                </a>
                <span class="_price">{{product.product_name}} <br /><i class="fa fa-inr" aria-hidden="true"></i>
                  {{product.price}}</span>
                <button class="_add_wishlist" [ngClass]="{'_active' : product.wishlist }"
                  (click)="_addWishlist(product,i,'verticalProducts')">
                  <i class="fa fa-heart"></i>
                </button>
              </div>
            </div>
          </div>
        </ng-container>


        <ng-container *ngIf="horizontalProducts?.length > 0 && !isProductsLoading">
          <div class="_products">
            <div class="_grid_horizontal" *ngFor="let product of horizontalProducts;let i = index">
              <div class="_inner">
                <a routerLink="/product/info/{{productCategory}}/{{product.product_url_name}}">
                  <img [src]="productMediaPath + product.image_url" class="_v_product_image">
                </a>
                <span class="_price">{{product.product_name}} <br /><i class="fa fa-inr" aria-hidden="true"></i>
                  {{product.price}}</span>
                <button class="_add_wishlist" [ngClass]="{'_active' : product.wishlist }"
                  (click)="_addWishlist(product,i,'horizontalProducts')">
                  <i class="fa fa-heart"></i>
                </button>
              </div>
            </div>
          </div>
        </ng-container>


        <ng-container *ngIf="squareProducts?.length > 0 && !isProductsLoading">
          <div class="_products">
            <div class="_grid_vertical" *ngFor="let product of squareProducts;let i = index">
              <div class="_inner">
                <a routerLink="/product/info/{{productCategory}}/{{product.product_url_name}}">
                  <img [src]="productMediaPath + product.image_url" class="_v_product_image">
                </a>
                <span class="_price">{{product.product_name}} <br /><i class="fa fa-inr" aria-hidden="true"></i>
                  {{product.price}}</span>
                <button class="_add_wishlist" [ngClass]="{'_active' : product.wishlist }"
                  (click)="_addWishlist(product,i,'squareProducts')">
                  <i class="fa fa-heart"></i>
                </button>
              </div>
            </div>
          </div>
        </ng-container>


      </div>
    </div>
  </div>
</div>

<div class="_clearfix"></div>
<app-footer></app-footer>