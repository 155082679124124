import { Injectable } from '@angular/core'

import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
  HttpHeaders
} from "@angular/common/http"
import { environment } from "../../environments/environment";
// import { Observable } from 'rxjs/Observable'


interface MenuData {
  items: any,
  parents: string
}

interface SignupData {
  data: {
    name: string,
    email: string
  },
  response: string,
  token: string,
  message: string
}

interface LoginData {
  data: {
    name: string,
    email: string
  },
  response: string,
  token: string,
  message: string
}

interface GetProfileData {
  response: string,
  data: {
    address: string,
    name: string,
    verified: number,
    image: string,
    created_on: string,
    email: string,
    mobile: string
  },
  token: string
}

interface PostProfileData {
  response: string,
  message: string
}

interface PostProfileImageData {
  response: string,
  message: string,
  data: any
}

interface WeddingHomeData {
  response: any,
  message: string
}

interface BirthdayHomeData {
  response: any,
  message: string
}

interface PartyHomeData {
  response: any,
  message: string
}

interface HomeSettingData {
  response: any,
  message: string
}

interface FilterProductData {
  data: {
    product_type: string
  },
  response: string,
  token: string,
  message: string
}

interface VerifyTokenData {
  response: string,
  message: string,
  data: any
}


interface ProductsData {
  response: string,
  data: any
}

interface ProductDetail {
  response: string,
  data: {
    cat_id: string,
    created_date: string,
    description: string,
    home_page: string,
    image_url: string,
    meta_desc: string,
    meta_keywords: string,
    meta_title: string,
    ordering: string,
    price: string,
    prod_id: string,
    prod_type: string,
    product_name: string,
    product_url_name: string,
    short_description: string,
    sku: string,
    spl_price: string,
    status: string,
    video_url: string,
  }
}

interface ProductsCustomized {
  response: string,
  order_number: string,
  data: any
}

interface CustomizedSlides {
  response: string,
  orientation: string,
  data: any
}

interface SaveCustomizedSlides {
  response: string,
  data: {
    order_number: string,
    product_id: string
  }
}

interface SlidesData {
  response: string,
  data: any
}

interface PreviewSlidesData {
  orientation: string,
  response: string,
  data: [{
    image_url: string
    ordering: string
    prod_type: string
    product_id: string
    slide_id: string
  }],
  fields: any
}

interface RsvpDetailData {
  response: string,
  data: {
    image_url: string,
    orientation: string
    product_name: string,
    price: string,
    customized_domain_price: string
  }
}

interface CheckUrlData {
  valid: boolean,
  response: string
}

interface RsvpCheckout {
  response: string,
  data: {
    url: string,
    order_no: string
  }
}

interface OrdersData {
  response: string,
  data: any
}

interface SettingOrderData {
  response: string,
  data: {
    event_name: string,
    hosted_by: string,
    created_at: string,
    image_url: string,
    order_number: string
    my_activity: boolean,
    event_activity: boolean,
    guest_rsvp: boolean
  }
}

interface RecentOrderData {
  response: string,
  data: Array<{ 'slide': string }>
}

interface ViewOrder {
  response: string,
  data: any
}

interface WishlistHistory {
  response: string,
  data: {
    ecard: any,
    vcard: any
  }
}

interface Wishlist {
  response: string,
  data: boolean
}


interface WishlistCount {
  response: string,
  data: number
}

interface SearchInvites {
  response: string,
  data: any
}

interface Ecards {
  response: string,
  data: any,
  colors: any
}

interface SaveEcard {
  response: string,
  data: any,
  message: string,
  order_no: string
}
interface SocialMediaLinkFooter {
  response: string,
  data: {
    facebook: string,
    instragram: string,
    youtube: string,
    twitter: string
  },
  token: string
}

interface ForgotPassword {
  response: string,
  data: any,
  message: string
}

interface RsvpLists {
  response: string,
  data: any,
  image: string
}

interface PrePayment {
  response: string,
  orderData: {
    "order_no": string,
    "amount": number,
    "order_id": string,
    "status": string
  },
  skip: boolean,
}

interface PostEcardWatermarkPreview {
  response: string,
  message: string,
  data: {
    url: string,
    order_number: string,
    product_id: string
  }
}

interface Coupons {
  response: string,
  data: any
}

@Injectable({
  providedIn: 'root'
})

export class ApiService {

  baseUrl = ""
  baseUrl1 = environment.API_URL
  postJsonHeader = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    })
  };
  constructor(private http: HttpClient) { }

  verifyToken() {
    return this.http.get<VerifyTokenData>(`${this.baseUrl1}me/verify`);
  }

  register(user: any) {
    return this.http.post<SignupData>(
      `${this.baseUrl1}signupAuthorization`,
      JSON.stringify(user),
      this.postJsonHeader
    );
  }

  login(user) {
    return this.http.post<LoginData>(
      `${this.baseUrl1}loginAuthorization`,
      JSON.stringify(user),
      this.postJsonHeader
    );
  }

  getProfile() {
    return this.http.get<GetProfileData>(`${this.baseUrl1}me`, this.postJsonHeader);
  }

  getWishlistCount() {
    return this.http.get<WishlistCount>(`${this.baseUrl1}getWishlistCount`, this.postJsonHeader);
  }

  postProfile(profile: any) {
    return this.http.post<PostProfileData>(`${this.baseUrl1}me/u`, JSON.stringify(profile), this.postJsonHeader)
  }

  postProfileImage(uploadData) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data");
    return this.http.post<PostProfileImageData>(
      this.baseUrl1 + "me/image",
      uploadData
    );
  }

  getCategories() {
    return this.http.get<MenuData>(`${this.baseUrl1}getCategories`);
  }

  getHomePageSetting() {
    return this.http.get<HomeSettingData>(`${this.baseUrl1}getHomePageSetting`);
  }


  getHomeWeddinglist() {
    return this.http.get<WeddingHomeData>(`${this.baseUrl1}getHomeWeddinglist`);
  }

  getHomeBirthdaylist() {
    return this.http.get<BirthdayHomeData>(`${this.baseUrl1}getHomeBirthdaylist`);
  }

  getHomePartylist() {
    return this.http.get<PartyHomeData>(`${this.baseUrl1}getHomePartylist`);
  }

  getFilterProductList() {
    return this.http.get<FilterProductData>(`${this.baseUrl1}getFilterProductList`);
  }

  getProductsByCategory(cat: any) {
    return this.http.post<ProductsData>(`${this.baseUrl1}getProductByCategory`, JSON.stringify(cat), this.postJsonHeader);
  }

  getProductDetail(cat: any) {
    return this.http.post<ProductDetail>(`${this.baseUrl1}getProductDetail`, JSON.stringify(cat), this.postJsonHeader);
  }

  // postProductDetail(data: any) {
  //   return this.http.post<ProductsCustomized>(`${this.baseUrl1}getCustomize`, JSON.stringify(data), this.postJsonHeader);
  // }

  getCustomizedSlides(data) {
    return this.http.post<CustomizedSlides>(`${this.baseUrl1}getCustomize`, JSON.stringify(data), this.postJsonHeader);
  }

  postCustomizedSlides(data) {
    return this.http.post<SaveCustomizedSlides>(`${this.baseUrl1}saveCustomize`, JSON.stringify(data), this.postJsonHeader);
  }

  getSlidesDataByOrderno(data) {
    return this.http.post<SlidesData>(`${this.baseUrl1}getSlideData`, JSON.stringify(data), this.postJsonHeader);
  }

  getPreview(data) {
    return this.http.post<PreviewSlidesData>(`${this.baseUrl1}getPreviewData`, JSON.stringify(data), this.postJsonHeader)
  }

  postSlideImage(uploadData) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data");
    return this.http.post<PostProfileImageData>(`${this.baseUrl1}saveCustomizeImage`,
      uploadData
    );
  }

  getRsvpDetails(data) {
    return this.http.post<RsvpDetailData>(`${this.baseUrl1}getRsvpDetails`, JSON.stringify(data), this.postJsonHeader)
  }

  postCheckPersonalisedUrl(data) {
    return this.http.post<CheckUrlData>(`${this.baseUrl1}verify/customizeUrl`, JSON.stringify(data), this.postJsonHeader)
  }

  postCheckout(data) {
    return this.http.post<RsvpCheckout>(`${this.baseUrl1}postCheckout`, JSON.stringify(data), this.postJsonHeader)
  }

  getOrders() {
    return this.http.get<OrdersData>(`${this.baseUrl1}getOrders`, this.postJsonHeader);
  }

  getArchivedOrders() {
    return this.http.get<OrdersData>(`${this.baseUrl1}getArchivedOrders`, this.postJsonHeader);
  }

  getArchivedEcardOrders() {
    return this.http.get<OrdersData>(`${this.baseUrl1}getArchivedEcardOrders`, this.postJsonHeader);
  }

  getOrderByOrderno(data) {
    return this.http.post<SettingOrderData>(`${this.baseUrl1}getOrderByOrderno`, JSON.stringify(data), this.postJsonHeader)
  }

  getOrderSetting(data) {
    return this.http.post<SettingOrderData>(`${this.baseUrl1}getOrderSetting`, JSON.stringify(data), this.postJsonHeader)
  }

  postOrderSetting(data) {
    return this.http.post<SettingOrderData>(`${this.baseUrl1}postOrderSetting`, JSON.stringify(data), this.postJsonHeader)
  }

  getRecentOrders() {
    return this.http.get<RecentOrderData>(`${this.baseUrl1}getRecentOrders`, this.postJsonHeader)
  }

  getOrderDataByUrl(data) {
    return this.http.post<ViewOrder>(`${this.baseUrl1}getViewData`, JSON.stringify(data), this.postJsonHeader)
  }

  postWishlist(data) {
    return this.http.post<Wishlist>(`${this.baseUrl1}postWishlist`, JSON.stringify(data), this.postJsonHeader)
  }

  getWishlist() {
    return this.http.get<WishlistHistory>(`${this.baseUrl1}getWishlistHistory`, this.postJsonHeader)
  }

  getSearchResult(data) {
    return this.http.post<SearchInvites>(`${this.baseUrl1}getSearch`, JSON.stringify(data), this.postJsonHeader)
  }

  getEcards(data) {
    return this.http.post<Ecards>(`${this.baseUrl1}getEcards`, JSON.stringify(data), this.postJsonHeader)
  }

  getEcardProperties(data) {
    return this.http.post<Ecards>(`${this.baseUrl1}getEcardProperties`, JSON.stringify(data), this.postJsonHeader)
  }

  postUploadCardImage(uploadData) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data");
    return this.http.post<PostProfileImageData>(
      this.baseUrl1 + "postCardImage",
      uploadData
    );
  }

  postEcard(data) {
    return this.http.post<SaveEcard>(`${this.baseUrl1}postEcard`, JSON.stringify(data), this.postJsonHeader)
  }

  postEcardDraft(data) {
    return this.http.post<SaveEcard>(`${this.baseUrl1}ecardDraft`, JSON.stringify(data), this.postJsonHeader)
  }

  getEcardDraft(data) {
    return this.http.post<SaveEcard>(`${this.baseUrl1}getEcardDraft`, JSON.stringify(data), this.postJsonHeader)
  }

  postEcardHandler(data) {
    return this.http.post<SaveEcard>(`${this.baseUrl1}eCardHandler`, JSON.stringify(data), this.postJsonHeader)
  }

  getSocialMediaLinkFooter() {
    return this.http.get<SocialMediaLinkFooter>(`${this.baseUrl1}getSocialMediaLinks`, this.postJsonHeader);
  }

  postHelp(uploadData) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data");
    return this.http.post<PostProfileImageData>(
      this.baseUrl1 + "postHelp",
      uploadData
    );
  }
  getOrderNumbers() {
    return this.http.get<WishlistHistory>(`${this.baseUrl1}getOrderNumbers`, this.postJsonHeader)
  }

  postEcardPreview(uploadData) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data");
    return this.http.post<PostProfileImageData>(
      this.baseUrl1 + "postEcardPreview",
      uploadData
    );
  }

  postEcardWatermarkPreview(uploadData) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data");
    return this.http.post<PostEcardWatermarkPreview>(
      this.baseUrl1 + "postEcardWatermarkPreview",
      uploadData
    );
  }

  postRsvpResponse(data) {
    return this.http.post<SaveEcard>(`${this.baseUrl1}postRsvpUserData`, JSON.stringify(data), this.postJsonHeader)
  }

  postForgotPassword(data) {
    return this.http.post<ForgotPassword>(`${this.baseUrl1}postForgotPassword`, JSON.stringify(data), this.postJsonHeader)
  }

  postResetPassword(data) {
    return this.http.post<ForgotPassword>(`${this.baseUrl1}postResetPassword`, JSON.stringify(data), this.postJsonHeader)
  }

  getRsvpLists(data) {
    return this.http.post<RsvpLists>(`${this.baseUrl1}getRsvpLists`, JSON.stringify(data), this.postJsonHeader);
  }

  getExportedRsvpList(data) {
    return this.http.post<RsvpLists>(`${this.baseUrl1}exportRsvp`, JSON.stringify(data), this.postJsonHeader);
  }

  createRzpOrder(data) {
    return this.http.post<PrePayment>(`${this.baseUrl1}prePayment`, JSON.stringify(data), this.postJsonHeader);
  }

  capturePayment(data) {
    return this.http.post<PrePayment>(`${this.baseUrl1}postPayment`, JSON.stringify(data), this.postJsonHeader);
  }

  // getCoupons() {
  //   return this.http.get<Coupons>(`${this.baseUrl1}getCoupons`, this.postJsonHeader)
  // }

  getCoupon(coupon, card_type = null) {
    return this.http.get<Coupons>(`${this.baseUrl1}getCoupon/${coupon}/${card_type}`, this.postJsonHeader)
  }

  removeDraft(data) {
    return this.http.post<Coupons>(`${this.baseUrl1}postRemoveDraft`, JSON.stringify(data), this.postJsonHeader)
  }

}
