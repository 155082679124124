import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styles: []
})
export class FaqComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  _expandFilter(el: any) {
    var element: any = el.target.nextSibling
    let isOpen = element.classList.contains("_active");
    if (isOpen) {
      el.target.getElementsByClassName("fa-minus")[0].style.display = "none"
      el.target.getElementsByClassName("fa-plus")[0].style.display = "flex"
      element.classList.remove("_active");
    } else {
      el.target.getElementsByClassName("fa-minus")[0].style.display = "flex"
      el.target.getElementsByClassName("fa-plus")[0].style.display = "none"
      element.classList.add("_active");
    }
  }

}
