import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

// Interceptor
import { AuthInterceptor } from "./interceptors/auth.interceptor";

// Social Login Module
import { SocialLoginModule, AuthServiceConfig } from "angularx-social-login";
import { GoogleLoginProvider, FacebookLoginProvider } from "angularx-social-login";

// Slider  https://www.npmjs.com/package/ngx-owl-carousel-o
import { CarouselModule } from 'ngx-owl-carousel-o';
// https://github.com/angular-slider/ng5-slider
import { Ng5SliderModule } from 'ng5-slider';
import { NotifierModule, NotifierOptions } from "angular-notifier";
import { environment } from '../environments/environment'

// AGM
import { AgmCoreModule } from '@agm/core';

// const fbLoginOptions = {
//   scope: 'email',
//   return_scopes: true,
//   enable_profile_selector: true
// };
const config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider(
      environment.G_APP_ID
    )
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider(environment.FB_APP_ID)
  }
]);

export function provideConfig() {
  return config;
}

/**
 * Custom angular notifier options
 */
const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: "right",
      distance: 12
    },
    vertical: {
      position: "top",
      distance: 12,
      gap: 10
    }
  },
  behaviour: {
    autoHide: 5000,
    onClick: "hide",
    onMouseover: "pauseAutoHide",
    showDismissButton: true,
    stacking: 4
  },
  animations: {
    enabled: true,
    show: {
      preset: "slide",
      speed: 300,
      easing: "ease"
    },
    hide: {
      preset: "fade",
      speed: 300,
      easing: "ease",
      offset: 50
    },
    shift: {
      speed: 300,
      easing: "ease"
    },
    overlap: 150
  }
};

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { DetailComponent } from './detail/detail.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { RsvpComponent } from './rsvp/rsvp.component';
import { VerticalComponent } from './vertical/vertical.component';
import { FilterPageComponent } from './filter-page/filter-page.component';
import { ProductVerticalComponent } from './product-vertical/product-vertical.component';
import { SignupComponent } from './signup/signup.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PreviewComponent } from './preview/preview.component';
import { SafeUrlPipe } from './safe-url.pipe';
import { SettingsComponent } from './settings/settings.component';
import { SharedcardComponent } from './sharedcard/sharedcard.component';
import { InarrayPipe } from './inarray.pipe';
import { SearchComponent } from './search/search.component';
import { EcardsComponent } from './ecards/ecards.component';
import { EcardDetailComponent } from './ecard-detail/ecard-detail.component';
import { SafeStylePipe } from './safe-style.pipe';
import { EcardHandlerComponent } from './ecard-handler/ecard-handler.component';
import { HelpComponent } from './help/help.component';
import { FaqComponent } from './faq/faq.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { RsvpListingsComponent } from './rsvp-listings/rsvp-listings.component';


import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { ImageCropperModule } from 'ngx-image-cropper';
import { DeviceDetectorModule } from 'ngx-device-detector';

import { ClipboardModule } from 'ngx-clipboard';
import { LazyImageModule } from '@trendster-io/ng-lazy-image';

import { MockupComponent } from './mockup/mockup.component';
import { BespokenComponent } from './bespoken/bespoken.component';

// ANIMATIONS MODULE
import { AnimateModule } from './animate/animate.module';



@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    DetailComponent,
    FooterComponent,
    HeaderComponent,
    RsvpComponent,
    VerticalComponent,
    FilterPageComponent,
    ProductVerticalComponent,
    SignupComponent,
    LoginComponent,
    DashboardComponent,
    PreviewComponent,
    SafeUrlPipe,
    SettingsComponent,
    SharedcardComponent,
    InarrayPipe,
    SearchComponent,
    EcardsComponent,
    EcardDetailComponent,
    SafeStylePipe,
    EcardHandlerComponent,
    HelpComponent,
    FaqComponent,
    ResetPasswordComponent,
    RsvpListingsComponent,
    MockupComponent,
    BespokenComponent
  ],
  imports: [
    BrowserModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAsPRVg8JCPqIL-3bxxi2d2eY3v_ByevK8',
      libraries: ['places']
    }),
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SocialLoginModule,
    CarouselModule,
    Ng5SliderModule,
    NotifierModule.withConfig(customNotifierOptions),
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    ImageCropperModule,
    DeviceDetectorModule.forRoot(),
    ClipboardModule,
    LazyImageModule,
    AnimateModule
  ],
  providers: [
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
