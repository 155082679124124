export const environment = {
  production: true,
  FB_APP_ID: "693425081069570",
  G_APP_ID: "799504125244-8ij5t8jkbdr09rc7c9v0pf551tck16se.apps.googleusercontent.com",
  API_URL: "https://lekarde.com/authority/",
  PROD_BASEPATH: "https://lekarde.com/authority/",
  GIF_PATH: "https://lekarde.com/authority/upload/gif/",
  ECARD_PATH: "https://lekarde.com/authority/upload/cards/",
  INTERCEPT_DOMAIN_PATH: "https://lekarde.com/authority/",
  BASE_URL: "https://lekarde.com/"
};
