import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { AuthSessionParamsService } from './auth-session-params.service';
import { NotificationService } from "../service/notification.service";

@Injectable({
  providedIn: 'root'
})
export class AuthSessionGuardService {

  constructor(private router: Router, private authSession: AuthSessionParamsService, private nfs: NotificationService) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // if (route.params) {
    //   let refererUrl = route.params.product_url
    //   if (refererUrl != '') {
    //     localStorage.setItem('url', refererUrl)
    //   }
    // }

    let roles = route.data["roles"];
    if (!this.authSession.isUserParamsAuthenticated()) {
      this.nfs.showNotification("default", 'Your are not logged in.');
      this.router.navigate(['login']);
      return false;
    } else {
      return true;
    }
  }



}
