// Angular Notifier
import { NotifierService } from 'angular-notifier';
import * as i0 from "@angular/core";
import * as i1 from "angular-notifier";
var NotificationService = /** @class */ (function () {
    function NotificationService(notifier) {
        this.notifier = notifier;
    }
    NotificationService.prototype.showNotification = function (type, message) {
        this.notifier.notify(type, message);
    };
    NotificationService.ngInjectableDef = i0.defineInjectable({ factory: function NotificationService_Factory() { return new NotificationService(i0.inject(i1.NotifierService)); }, token: NotificationService, providedIn: "root" });
    return NotificationService;
}());
export { NotificationService };
