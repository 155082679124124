<div class="_main_container _header_shadow">
  <app-header></app-header>
</div>

<div class="_main_container" *ngIf="isLoading">
  <p class="_loading">
    <span class="loader"></span>
  </p>
</div>

<div class="_main_container" *ngIf="!isLoading">
  <div class="_rsvp_list">
    <div class="_inner_rsvp_list">
      <div class="_right_area">
        <div class="_export">
          <a href="javascript:;"><img src="assets/img/export.png" width="20" /></a>
          <button type="button" (click)="_exportRsvpList()">Export</button>
        </div>
        <div class="_sort_by">
          <div class="_range_dropdown">
            <ul>
              <li>
                <select name="wdqd" (change)="_sortRsvpList($event)">
                  <option value="">Filter by</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                  <option value="May be">Maybe</option>
                  <option value="Decide Later">Decide Later</option>
                </select>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="_left">
      <div class="_card">
        <img *ngIf="cardImage" [src]="cardImage">
      </div>
    </div>
    <div class="_right">
      <table class="_table_list">
        <tbody>
          <tr class="_row_section">
            <th>Event</th>
            <th>Name</th>
            <th>Response</th>
            <th>Email</th>
            <th>Phone.No</th>
            <th class="_address">Address</th>
          </tr>
          <ng-container *ngIf="users?.length > 0 else noRsvpList">
            <ng-container *ngFor="let user of users; let i = index">
              <tr class="_row_section2">
                <td class="_user_icon" [innerHTML]="user.event"></td>
                <td class="_user_icon" [innerHTML]="user.name"></td>
                <td class="_response" [innerHTML]="user.attend">Yes</td>
                <td [innerHTML]="user.email"></td>
                <td [innerHTML]="user.mobile"></td>
                <td class="_address_data" [innerHTML]="user.address">
                </td>
              </tr>
            </ng-container>
          </ng-container>
          <ng-template #noRsvpList>
            <tr>
              <td colspan="6">No data</td>
            </tr>
          </ng-template>
        </tbody>
      </table>
    </div>
    <div class="_clear_both"></div>
  </div>
</div>
<div class="_clearfix"></div>
<app-footer></app-footer>