import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from "../service/api.service";
import { environment } from 'src/environments/environment';
import domtoimage from 'retina-dom-to-image';
var MockupComponent = /** @class */ (function () {
    function MockupComponent(route, apiService) {
        this.route = route;
        this.apiService = apiService;
        this.productMediaPath = '';
        this.isProductLoading = true;
        this.frontendCustomizatedData = [];
        this.productMediaPath = environment.PROD_BASEPATH;
    }
    MockupComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.params.subscribe(function (params) {
            if (params.card_prefix != '') {
                _this.card_prefix = params.card_prefix;
                _this.apiService.getEcardProperties({ card_prefix: _this.card_prefix, mockup: true }).subscribe(function (data) {
                    if (data.response == 'success') {
                        _this.products = data.data;
                        // Upload Field
                        if (_this.products[0].is_image == '1') {
                            _this.isUploadFieldAcive = true;
                        }
                        else {
                            _this.isUploadFieldAcive = false;
                        }
                        _this.properties = JSON.parse(_this.products[0].property_detail);
                        var getPropertiesInputs = _this.properties.filter(function (d) { return (d.type == 'input' || d.type == 'image'); });
                        if (getPropertiesInputs.length > 0) {
                            for (var _i = 0, getPropertiesInputs_1 = getPropertiesInputs; _i < getPropertiesInputs_1.length; _i++) {
                                var i = getPropertiesInputs_1[_i];
                                var validateField = false;
                                if (i.validate != undefined) {
                                    if (i.validate == "true") {
                                        validateField = true;
                                    }
                                    else {
                                        validateField = false;
                                    }
                                }
                                if (i.type == 'input') {
                                    var d = {
                                        'id': i.id,
                                        'value': i.defaultValue,
                                        'color': '',
                                        'textAlign': i.textAlign,
                                        'fontSize': i.fontSize,
                                        'fontFamily': '',
                                        'styles': i.styles,
                                        'type': i.type,
                                        'width': i.width,
                                        'height': i.height,
                                        'validate': validateField
                                    };
                                    _this.frontendCustomizatedData.push(d);
                                }
                                else {
                                    var d = {
                                        'id': i.id,
                                        'type': i.type,
                                        'value': i.defaultValue,
                                        'width': i.width,
                                        'height': i.height,
                                        'styles': i.styles,
                                        'validate': validateField
                                    };
                                    if (i.corp_logo) {
                                        Object.assign(d, { 'corp_logo': i.corp_logo });
                                        Object.assign(d, { 'uploadPlaceholder': 'logo-' + i.upload_placeholder });
                                    }
                                    else {
                                        Object.assign(d, { 'uploadPlaceholder': 'picture-' + i.upload_placeholder });
                                    }
                                    _this.frontendCustomizatedData.push(d);
                                }
                            }
                        }
                    }
                    _this.isProductLoading = false;
                });
            }
        });
    };
    MockupComponent.prototype._download_mockup = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var card_prefix, node;
            return tslib_1.__generator(this, function (_a) {
                card_prefix = this.card_prefix;
                node = document.getElementById('_card');
                domtoimage.toPng(node)
                    .then(function (dataUrl) {
                    var link = document.createElement('a');
                    link.download = card_prefix + ".png";
                    link.href = dataUrl;
                    link.click();
                })
                    .catch(function (error) {
                    console.error('oops, something went wrong!', error);
                });
                return [2 /*return*/];
            });
        });
    };
    return MockupComponent;
}());
export { MockupComponent };
