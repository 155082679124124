import * as tslib_1 from "tslib";
import { map, startWith, distinctUntilChanged, take, scan, switchMap, debounceTime, shareReplay } from 'rxjs/operators';
import { AnimateConfig, animateConfigFactory } from './animate.config';
import { ElementRef, NgZone } from '@angular/core';
import { ScrollDispatcher, ViewportRuler } from '@angular/cdk/scrolling';
import { Observable, BehaviorSubject, of } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/cdk/scrolling";
import * as i2 from "./animate.config";
var AnimateService = /** @class */ (function () {
    function AnimateService(scroll, viewPort, zone, config) {
        var _this = this;
        this.scroll = scroll;
        this.viewPort = viewPort;
        this.zone = zone;
        this.config = config;
        this.options$ = new BehaviorSubject({});
        // Gets the module configuration
        this.config = animateConfigFactory(config);
        // Computes a common view observable to support the 'scrolling' triggering method 
        this.view$ = this.options$.pipe(
        // Tracks for viewport changes giving it 100ms time to accurately update for orientation changes  
        switchMap(function (options) { return viewPort.change(100).pipe(
        // Starts with a value
        startWith(null), 
        // Gets the viewport
        map(function () {
            // Picks the ClientRect of the relevant container 
            var rt = (options.root instanceof Element) ? options.root.getBoundingClientRect() : _this.viewPort.getViewportRect();
            // Combines the various options to build the final container
            var left = rt.left + (options.left || _this.config.offsetLeft || 0);
            var top = rt.top + (options.top || _this.config.offsetTop || 0);
            var right = rt.right + (options.right || _this.config.offsetRight || 0);
            var bottom = rt.bottom + (options.bottom || _this.config.offsetBottom || 0);
            // Returns the reultins client rect 
            return { top: top, left: left, bottom: bottom, right: right, height: bottom - top, width: right - left };
        }), 
        // Debounces to aggregate fast changes (like during orientation changes)
        debounceTime(20)); }), 
        // Makes all the component to share the same viewport values
        shareReplay(1));
    }
    Object.defineProperty(AnimateService.prototype, "useIntersectionObserver", {
        /** True when the trigger is provided using the IntersectionObserver API */
        get: function () {
            return this.config.triggerMode === 'intersectionObserver';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AnimateService.prototype, "useScrolling", {
        /** True when the trigger is provided using cdk/scrolling package */
        get: function () {
            return this.config.triggerMode === 'scrolling';
        },
        enumerable: true,
        configurable: true
    });
    /** Applies the given options to the triggering service */
    AnimateService.prototype.setup = function (options) {
        this.options$.next(options);
    };
    // Triggers the animation
    AnimateService.prototype.trigger = function (elm, threshold) {
        var _this = this;
        // Waits until the zone is stable once, aka the render is complete so the element to measure is there 
        return function (source) { return _this.zone.onStable.pipe(
        // Waits just once
        take(1), 
        // Triggers the play and replay requests
        switchMap(function () { return source; }), 
        // Triggers upon the most suitable method
        switchMap(function (trigger) {
            // Simply return the sourced trigger when threshold is 0
            return (threshold <= 0) ? of(trigger) : (
            // Check upon the configured method otherwise
            _this.useIntersectionObserver ?
                // Triggers upon element intersection (IntersectionObserver API)
                _this.intersecting(elm, threshold) :
                // Triggers upon cdk/scrolling
                _this.scrolling(elm, threshold));
        })); };
    };
    // Triggers the animation on intersection (using the IntersectionObserver API)
    AnimateService.prototype.intersecting = function (elm, threshold) {
        var _this = this;
        return this.options$.pipe(
        // Turns the options into a suitable configuration for the IntersectionObserver AnimateOptions
        map(function (options) {
            // Identifies an optional element to be used as the container
            var root = options.root || null;
            // Merges the margins from both the global config and the local options 
            var top = options.top || _this.config.offsetTop || 0;
            var right = options.right || _this.config.offsetRight || 0;
            var bottom = options.bottom || _this.config.offsetBottom || 0;
            var left = options.left || _this.config.offsetLeft || 0;
            // Computes the rootMargin string acordingly
            var rootMargin = -top + "px " + -right + "px " + -bottom + "px " + -left + "px";
            // Returns the proper initialization object
            return { root: root, rootMargin: rootMargin };
        }), 
        // Observes the element
        switchMap(function (options) { return _this.observe(elm, threshold, options); }));
    };
    /** Builds an Obsevable out of the IntersectionObserver API */
    AnimateService.prototype.observe = function (elm, threshold, options) {
        var _this = this;
        return new Observable(function (subscriber) {
            // Creates a single entry observer
            var observer = new IntersectionObserver(function (entries) {
                // Monitors the only enry intesection ratio 
                var ratio = entries[0].intersectionRatio;
                // Emits true whenever the intersection cross the threashold (making sure to run in the angular zone)
                if (ratio >= threshold) {
                    _this.zone.run(function () { return subscriber.next(true); });
                }
                // Emits false whenever the intersection cross back to full invisibility (making sure to run in the angular zone)
                if (ratio <= 0) {
                    _this.zone.run(function () { return subscriber.next(false); });
                }
                // Initializes the observer with the given parameters
            }, tslib_1.__assign({}, options, { threshold: [0, threshold] }));
            // Starts observing the target element 
            observer.observe(elm.nativeElement);
            // Disconnects when unsubscribed
            return function () { return observer.disconnect(); };
        });
    };
    // Triggers the animation on scroll
    AnimateService.prototype.scrolling = function (elm, threshold) {
        var _this = this;
        // Returns an AOS observable using cdk/scrollilng
        return this.scroll.ancestorScrolled(elm, 0).pipe(
        // Makes sure triggering the start no matter there's no scroll event hits yet
        startWith(0), 
        // Maps the scrolling to the element visibility value
        switchMap(function () { return _this.visibility(elm); }), 
        // Applies an hysteresys, so, to trigger the animation on based on the treshold while off on full invisibility
        scan(function (result, visiblility) { return (visiblility >= threshold) || (result && visiblility > 0); }, false), 
        // Distincts the resulting triggers 
        distinctUntilChanged(), 
        // Runs within the angular zone to trigger change detection back on
        function (source) { return new Observable(function (subscriber) { return source.subscribe(function (value) { return _this.zone.run(function () { return subscriber.next(value); }); }); }); });
    };
    // Computes the element's visibility ratio against the container
    AnimateService.prototype.visibility = function (elm) {
        // Resolves from the latest viewport
        return this.view$.pipe(map(function (view) {
            // Gets the element's bounding rect
            var rect = elm && elm.nativeElement && elm.nativeElement.getBoundingClientRect();
            if (!rect) {
                return 0;
            }
            // Return 1.0 when the element is fully within the viewport
            if (rect.left > view.left - 1 && rect.top > view.top - 1 && rect.right < view.right + 1 && rect.bottom < view.bottom + 1) {
                return 1;
            }
            // Computes the intersection area otherwise
            var a = Math.round(rect.width * rect.height);
            var b = Math.max(0, Math.min(rect.right, view.right) - Math.max(rect.left, view.left));
            var c = Math.max(0, Math.min(rect.bottom, view.bottom) - Math.max(rect.top, view.top));
            // Returns the amount of visible area 
            return Math.round(b * c / a * 10) / 10;
        }));
    };
    AnimateService.ngInjectableDef = i0.defineInjectable({ factory: function AnimateService_Factory() { return new AnimateService(i0.inject(i1.ScrollDispatcher), i0.inject(i1.ViewportRuler), i0.inject(i0.NgZone), i0.inject(i2.ANIMATE_CONFIG, 8)); }, token: AnimateService, providedIn: "root" });
    return AnimateService;
}());
export { AnimateService };
