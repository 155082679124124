/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../header/header.component.ngfactory";
import * as i3 from "../header/header.component";
import * as i4 from "@angular/platform-browser";
import * as i5 from "../service/data.service";
import * as i6 from "../service/auth-session-params.service";
import * as i7 from "@angular/router";
import * as i8 from "../service/api.service";
import * as i9 from "../footer/footer.component.ngfactory";
import * as i10 from "../footer/footer.component";
import * as i11 from "./preview.component";
import * as i12 from "../service/notification.service";
var styles_PreviewComponent = [];
var RenderType_PreviewComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PreviewComponent, data: {} });
export { RenderType_PreviewComponent as RenderType_PreviewComponent };
function View_PreviewComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "_main_container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "p", [["class", "_loading"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "span", [["class", "loader"]], null, null, null, null, null))], null, null); }
function View_PreviewComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "_second_preview"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h2", [["class", "_heading"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Your Information"])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "div", [["class", "_field_area"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, "div", [["class", "_preview_container"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.text; _ck(_v, 4, 0, currVal_0); }); }
function View_PreviewComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "_second_preview"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h2", [["class", "_heading"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Your Information"])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "div", [["class", "_field_area"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, "div", [["class", "_preview_container"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.text; _ck(_v, 4, 0, currVal_0); }); }
function View_PreviewComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "_third_preview"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "_field_area"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "img", [["class", "_image"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.file; _ck(_v, 2, 0, currVal_0); }); }
function View_PreviewComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PreviewComponent_7)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PreviewComponent_8)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PreviewComponent_9)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = ((_v.context.$implicit.type == "textarea") && _v.context.$implicit.text); _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_v.context.$implicit.type == "text") && _v.context.$implicit.text); _ck(_v, 4, 0, currVal_1); var currVal_2 = ((_v.context.$implicit.type == "file") && _v.context.$implicit.file); _ck(_v, 6, 0, currVal_2); }, null); }
function View_PreviewComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "_middle_fields"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PreviewComponent_6)), i0.ɵdid(3, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.fields; _ck(_v, 3, 0, currVal_0); }, null); }
function View_PreviewComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "_inner_preview"]], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(2, { "_border": 0 }), (_l()(), i0.ɵeld(3, 0, null, null, 7, "div", [["class", "_under_inner_area"]], null, null, null, null, null)), i0.ɵdid(4, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 3, "div", [["class", "_first_preview"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 0, "div", [["class", "_count"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "div", [["class", "_card"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 0, "img", [["alt", ""]], [[8, "src", 4]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PreviewComponent_5)), i0.ɵdid(10, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "_inner_preview"; var currVal_1 = _ck(_v, 2, 0, (_v.context.index != 0)); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = "_under_inner_area"; var currVal_3 = _co.orientation; _ck(_v, 4, 0, currVal_2, currVal_3); var currVal_6 = (((_v.context.$implicit.fields == null) ? null : _v.context.$implicit.fields.length) > 0); _ck(_v, 10, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = (_v.context.index + 1); _ck(_v, 6, 0, currVal_4); var currVal_5 = (_co.productMediaPath + _v.context.$implicit.image_url); _ck(_v, 8, 0, currVal_5); }); }
function View_PreviewComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PreviewComponent_4)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.slides; _ck(_v, 2, 0, currVal_0); }, null); }
function View_PreviewComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "div", [["class", "_main_container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 12, "div", [["class", "_preview_content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "div", [["class", "_prev_heading"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Preview"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PreviewComponent_3)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 7, "div", [["class", "_button_area"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 3, "div", [["class", "_checkout"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 2, "button", [["class", "_action"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co._goToCustomize() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 0, "i", [["class", "fa fa-edit"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Continue Edit"])), (_l()(), i0.ɵeld(11, 0, null, null, 2, "div", [["class", "_checkout"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 1, "button", [["class", "_action"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co._goToRsvp() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Submit"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.slides == null) ? null : _co.slides.length) > 0); _ck(_v, 5, 0, currVal_0); }, null); }
export function View_PreviewComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "_main_container _header_shadow"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-header", [], null, null, null, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i0.ɵdid(2, 114688, null, 0, i3.HeaderComponent, [i4.Title, i4.Meta, i5.DataService, i6.AuthSessionParamsService, i7.Router, i8.ApiService], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PreviewComponent_1)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PreviewComponent_2)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(7, 0, null, null, 0, "div", [["class", " _clearfix"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "app-footer", [], null, null, null, i9.View_FooterComponent_0, i9.RenderType_FooterComponent)), i0.ɵdid(9, 114688, null, 0, i10.FooterComponent, [i8.ApiService], null, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_0 = _co.isProductLoading; _ck(_v, 4, 0, currVal_0); var currVal_1 = !_co.isProductLoading; _ck(_v, 6, 0, currVal_1); _ck(_v, 9, 0); }, null); }
export function View_PreviewComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-preview", [], null, null, null, View_PreviewComponent_0, RenderType_PreviewComponent)), i0.ɵdid(1, 114688, null, 0, i11.PreviewComponent, [i8.ApiService, i12.NotificationService, i7.Router, i7.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PreviewComponentNgFactory = i0.ɵccf("app-preview", i11.PreviewComponent, View_PreviewComponent_Host_0, {}, {}, []);
export { PreviewComponentNgFactory as PreviewComponentNgFactory };
