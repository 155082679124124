import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthSessionParamsService } from './auth-session-params.service';
import { NotificationService } from "../service/notification.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./auth-session-params.service";
import * as i3 from "./notification.service";
var AuthSessionGuardService = /** @class */ (function () {
    function AuthSessionGuardService(router, authSession, nfs) {
        this.router = router;
        this.authSession = authSession;
        this.nfs = nfs;
    }
    AuthSessionGuardService.prototype.canActivate = function (route, state) {
        // if (route.params) {
        //   let refererUrl = route.params.product_url
        //   if (refererUrl != '') {
        //     localStorage.setItem('url', refererUrl)
        //   }
        // }
        var roles = route.data["roles"];
        if (!this.authSession.isUserParamsAuthenticated()) {
            this.nfs.showNotification("default", 'Your are not logged in.');
            this.router.navigate(['login']);
            return false;
        }
        else {
            return true;
        }
    };
    AuthSessionGuardService.ngInjectableDef = i0.defineInjectable({ factory: function AuthSessionGuardService_Factory() { return new AuthSessionGuardService(i0.inject(i1.Router), i0.inject(i2.AuthSessionParamsService), i0.inject(i3.NotificationService)); }, token: AuthSessionGuardService, providedIn: "root" });
    return AuthSessionGuardService;
}());
export { AuthSessionGuardService };
