import { Component, Inject, OnInit, OnDestroy, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { Location, DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router'
import { ApiService } from "../service/api.service"
import { NotificationService } from "../service/notification.service";
import { DataService } from "../service/data.service"
import { Options } from 'ng5-slider';
import { environment } from 'src/environments/environment';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import domtoimage from 'retina-dom-to-image';
import { DeviceDetectorService } from 'ngx-device-detector';

interface ProductsModel {
  card_id: string,
  card_template: string,
  card_img: string,
  card_name: string,
  design_no: string,
  card_prefix: string,
  is_image: string,
  property_detail: any
}

interface PropertiesModel {
  fontFamily: string,
  height: string,
  width: string,
  textAlign: string;
  fontSize: string;
  left: string;
  top: string;
}


@Component({
  selector: 'app-ecard-detail',
  templateUrl: './ecard-detail.component.html',
  styles: []
})
export class EcardDetailComponent implements OnInit, OnDestroy {
  designNo: number;
  activeEditStyleMode: string = 'align'
  productUrl: string
  isProductsLoading: boolean = true
  products: Array<ProductsModel>
  productMediaPath: string = '';
  properties: any
  isUploadFieldAcive: boolean = false

  selectedFieldIndex: number = -1
  selectedFieldType: string = ''

  frontendCustomizatedData: any = [];

  // Range Options
  value: number = 16;
  options: Options = {
    floor: 10,
    ceil: 120,
    showSelectionBar: false,
    animate: false
  };
  selectedFile: File
  @ViewChild('uploadCardImageRef') uploadCardImageRef
  defaultCardImage: string = './assets/img/blank-placeholder.jpg';

  fontFamilies: string[] = []
  fontFamilySelected: string = ""
  isProductLoading: boolean = true

  cardInfoActive: boolean = false;
  proceedBtnText: string = "Save & Proceed"

  imageChangedEvent: any = '';
  croppedImage: any = [];
  isCropperActive: boolean = false
  croppedBtnText: string = "Save"
  @ViewChild('_ecard_image_ref') _ecard_image_ref;

  tempEcardImagesEvent: Array<{ 'id': number, image: any }> = []

  colors: any[] = []
  moreColorActive: boolean = false

  /* Mobile Ecard */
  selectedMobileTab: string = ""
  selectedFieldText: string = ""
  @ViewChild('mContentRef') mContentRef: ElementRef
  isMobile: boolean = false;
  isTablet: boolean = false;
  isDesktopDevice: boolean = false;
  os: any;

  usableUploadFieldIndex: any = ""
  usableUploadField: any = ""
  imageChangedEventSaved: any = []
  imageFields: any[] = []

  defaultInputFontFamilies: any[] = []

  // 09/11/2021 
  hasImageField: boolean = false
  imageFieldsCount: number = 0
  corpLogo: string = null

  uploadedImages: any[] = []

  constructor(@Inject(DOCUMENT) private document: Document, private renderer: Renderer2, private _location: Location, private dataService: DataService, private route: ActivatedRoute, private nfs: NotificationService, private router: Router, private apiService: ApiService, private deviceService: DeviceDetectorService) {
    this.productMediaPath = environment.PROD_BASEPATH
  }

  ngOnInit() {
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();
    let deviceInfo = this.deviceService.getDeviceInfo();
    this.os = deviceInfo
    if (this.isMobile) {
      this.renderer.addClass(this.document.body, '_m_ecard_body');
    }

    this.route.params.subscribe(params => {
      if (params.product_url != '') {
        this.designNo = params.design_no
        this.productUrl = params.product_url

        // Load Ecard Properties
        this.apiService.getEcardProperties({ card_prefix: this.productUrl }).subscribe(data => {
          if (data.response == 'success') {
            this.isProductsLoading = false
            this.colors = data.colors
            this.products = data.data
            // Upload Field
            if (this.products[0].is_image == '1') {
              this.isUploadFieldAcive = true
            } else {
              this.isUploadFieldAcive = false
            }
            this.properties = JSON.parse(this.products[0].property_detail)

            let imageFieldsCount = this.properties.filter(d => d.type == 'image');
            this.imageFieldsCount = imageFieldsCount.length

            let getPropertiesInputs = this.properties.filter(d => (d.type == 'input' || d.type == 'image'));
            if (getPropertiesInputs.length > 0) {
              for (let i of getPropertiesInputs) {
                let validateField = false
                if (i.validate != undefined) {
                  if (i.validate == "true") {
                    validateField = true
                  } else {
                    validateField = false
                  }
                }

                if (i.type == 'input') {
                  let d = {
                    'id': i.id,
                    'value': i.defaultValue,
                    'color': '',
                    'textAlign': i.textAlign,
                    'fontSize': i.fontSize,
                    'fontFamily': '',
                    'styles': i.styles,
                    'type': i.type,
                    'width': i.width,
                    'height': i.height,
                    'validate': validateField
                  }
                  this.frontendCustomizatedData.push(d);
                } else {
                  if (i.type == "image") {
                    this.hasImageField = true
                    this.imageFields.push({ 'id': i.id })
                  }
                  let d = {
                    'id': i.id,
                    'type': i.type,
                    'value': i.defaultValue,
                    'width': i.width,
                    'height': i.height,
                    'styles': i.styles,
                    'validate': validateField
                  }
                  if (i.corp_logo) {
                    Object.assign(d, { 'corp_logo': i.corp_logo })
                    Object.assign(d, { 'uploadPlaceholder': 'logo-' + i.upload_placeholder })
                  } else {
                    Object.assign(d, { 'uploadPlaceholder': 'picture-' + i.upload_placeholder })
                  }
                  this.frontendCustomizatedData.push(d);
                }
              }
              this._loadSavedDraftData();
            }
          } else if (data.response == 'redirect') {
            // this.nfs.showNotification("default", "you'r not logged in.");
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            this.dataService.sessionMessage(false);
            this.router.navigate([`/login`]);
          } else {
            this.nfs.showNotification("default", 'Properties not loaded successfully.');
          }
          this.isProductLoading = false
        })
      }
    })

    setTimeout(() => {
      let getPropertiesInputs = this.properties.filter(d => (d.type == 'input'));
      if (getPropertiesInputs.length > 0) {
        for (let i of getPropertiesInputs) {
          let value = document.getElementById(`_get_prop_${i.id}`).style.fontFamily
          this.defaultInputFontFamilies['_get_prop_' + i.id] = value.replace(/['"]+/g, '')
        }
      }
    }, 1000);
  }

  ngOnDestroy() {
    this.renderer.removeClass(this.document.body, '_m_ecard_body');
  }

  _moreColor() {
    this.moreColorActive = this.moreColorActive ? false : true
  }

  _stopDrop(e) {
    e.preventDefault();
    return false;
  }

  _new_uploader(event) {

    if (this.imageFieldsCount == 1) {
      let getObj = this.frontendCustomizatedData.filter(d => d.type == "image")
      if (getObj.length == 1) {
        this.selectedFieldType = 'image'
        this.selectedFieldIndex = getObj[0].id
      }
    } else {

    }

    if (this.selectedFieldType == 'image' && this.selectedFieldIndex > -1) {
      this.selectedFile = event.target.files[0];
      const uploadData = new FormData();
      uploadData.append("image", this.selectedFile, this.selectedFile.name);
      this._uploadImageLoader('show', this.selectedFieldIndex);
      this.apiService.postUploadCardImage(uploadData).subscribe(res => {
        this._ecard_image_ref.nativeElement.value = "";
        // let el: any = document.getElementById(`_image_${this.selectedFieldIndex}`);
        if (res.response == "success") {
          console.log(this.selectedFieldIndex);
          let uploadedImage = res.data.image
          //   el.src = uploadedImage

          this.uploadedImages[this.usableUploadFieldIndex] = uploadedImage
          this.corpLogo = uploadedImage


          this._setUploadImageValue(uploadedImage);
          this._uploadImageLoader('hide', this.selectedFieldIndex);

        } else if (res.response == "error") {
          this.nfs.showNotification("default", res.message);
          this._uploadImageLoader('hide', this.selectedFieldIndex);
        }
      });
    }

  }


  _open_new_upload(index, prop) {
    console.log(this.uploadedImages)
    this.usableUploadFieldIndex = index
    this.usableUploadField = prop
    setTimeout(() => {
      this._ecard_image_ref.nativeElement.click();
    }, 500);

    // if (prop.corp_logo == "true") {

    // }
  }

  fileChangeEvent(event: any): any {
    let isFileExist = event.target.files.length
    if (event && isFileExist > 0) {
      this.isCropperActive = true
      this.imageChangedEventSaved[`temp_image_${this.usableUploadFieldIndex}`] = event
      this.imageChangedEvent = event;
    }
  }




  _editCropped(index, prop) {

    console.log(prop);
    if (prop.corp_logo == "true") {
      setTimeout(() => {
        this._ecard_image_ref.nativeElement.click();
      }, 500);
    }



    this.usableUploadFieldIndex = index
    this.usableUploadField = prop
    this.selectedFieldType = 'image'
    let checkExist = this.imageChangedEventSaved[`temp_image_${this.usableUploadFieldIndex}`];
    if (checkExist != undefined) {
      let imageEvent = this.imageChangedEventSaved[`temp_image_${this.usableUploadFieldIndex}`];
      // console.log(imageEvent.srcElement.value);
      this.imageChangedEvent = imageEvent
      this.isCropperActive = true
    } else {
      this.imageChangedEvent = null
      this.isCropperActive = false
      setTimeout(() => {
        this._ecard_image_ref.nativeElement.click();
      }, 500);
    }
  }


  _cancelCroppedImage() {
    this.isCropperActive = this.isCropperActive ? false : true
    let el: any = document.getElementById(`_image_${this.selectedFieldIndex}`);
    if (el != null) {
      var matchedIndex = this.frontendCustomizatedData
        .map(function (obj) {
          return obj.id;
        })
        .indexOf(this.selectedFieldIndex);
      let data = this.frontendCustomizatedData[matchedIndex];
      if (data.type == "image" && data.value != "") {
        el.src = data.value
        return true;
      } else {
        el.src = this.defaultCardImage
      }
      //el.value = ""
      this.imageChangedEvent = ""
      this._ecard_image_ref.nativeElement.value = "";
    }

  }



  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage[this.usableUploadFieldIndex] = event.base64;
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    this.nfs.showNotification("default", '* png,jpg,gif is allowed.');
  }

  _b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;
    var byteCharacters = atob(b64Data);
    var byteArrays = [];
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  _uploadCroppedImage(e: any) {
    this.croppedBtnText = "Uploading.."
    if (this.selectedFieldType == 'image' && this.selectedFieldIndex > -1) {
      // this._createTempImageDataEvents();
      // Convert base64 to blob
      var ImageURL = this.croppedImage[this.usableUploadFieldIndex]
      var block = ImageURL.split(";");
      var contentType = block[0].split(":")[1];
      var realData = block[1].split(",")[1];
      var blob = this._b64toBlob(realData, contentType, 512);
      const uploadData = new FormData();
      uploadData.append("image", blob, 'image.png');
      this._uploadImageLoader('show', this.selectedFieldIndex);
      this.apiService.postUploadCardImage(uploadData).subscribe(res => {
        let el: any = document.getElementById(`_image_${this.selectedFieldIndex}`);
        if (res.response == "success") {
          let uploadedImage = res.data.image
          el.src = uploadedImage
          this._setUploadImageValue(uploadedImage);
          this._uploadImageLoader('hide', this.selectedFieldIndex);
          this.croppedBtnText = "Save"
        } else if (res.response == "error") {
          el.src = this.defaultCardImage
          this.nfs.showNotification("default", res.message);
          this._uploadImageLoader('hide', this.selectedFieldIndex);
          this.croppedBtnText = "Save"
        }
        this.isCropperActive = false;
      });
    } else {
      this.nfs.showNotification("default", 'Select Image Area to update.');
      this.croppedBtnText = "Save"
    }
  }


  _createTempImageDataEvents() {
    var matchedIndex = this.tempEcardImagesEvent.map(function (obj) {
      return obj.id;
    }).indexOf(this.selectedFieldIndex);
    if (matchedIndex > -1) {
      this.tempEcardImagesEvent[matchedIndex].image = this.imageChangedEventSaved[this.usableUploadFieldIndex]
    } else {
      var a = { 'id': this.selectedFieldIndex, image: this.imageChangedEventSaved[this.usableUploadFieldIndex] }
      this.tempEcardImagesEvent.push(a);
    }
  }

  _loadSavedDraftData() {
    // Load Card From Drafts
    let _e_order: any = localStorage.getItem('e_order');
    _e_order = JSON.parse(_e_order);
    if (_e_order != null) {
      if (_e_order.design == this.designNo) {
        // this.isProductsLoading = true
        this.apiService.getEcardDraft(_e_order).subscribe(data => {
          if (data.response == 'success') {
            if (data.data == null) {
              return false;
            }
            let _savedProperties = JSON.parse(data.data);
            let _savedPropertiesInputs = _savedProperties.filter(d => (d.type == 'input' || d.type == 'image'));
            if (_savedPropertiesInputs.length > 0) {

              this.frontendCustomizatedData = []
              for (let i of _savedPropertiesInputs) {

                let validateField = false
                if (i.validate != undefined) {
                  if (i.validate == "true") {
                    validateField = true
                  } else {
                    validateField = false
                  }
                }

                if (i.type == 'input') {
                  let d = {
                    'id': i.id,
                    'value': i.value,
                    'color': '',
                    'textAlign': i.textAlign,
                    'fontSize': i.fontSize,
                    'styles': i.styles,
                    'type': i.type,
                    'width': i.width,
                    'height': i.height,
                    'validate': validateField
                  }
                  this.frontendCustomizatedData.push(d);
                } else {
                  let d = {
                    'id': i.id,
                    'type': i.type,
                    'value': i.value,
                    'width': i.width,
                    'height': i.height,
                    'styles': i.styles,
                    'validate': validateField,
                    'uploadPlaceholder': i.upload_placeholder
                  }
                  if (i.corp_logo) {
                    Object.assign(d, { 'corp_logo': i.corp_logo })
                  }
                  this.frontendCustomizatedData.push(d);
                }
              }
            }
          }
        })
      }
    }
  }

  _getSelectedFields(type: string, propId: number) {
    this.selectedFieldIndex = propId
    this.selectedFieldType = type
    if (this.selectedFieldType != 'image') {
      let el: any = document.getElementById(`_inputs_${propId}`);
      var matchedIndex = this.properties
        .map(function (obj) {
          return obj.id;
        })
        .indexOf(this.selectedFieldIndex);
      this.fontFamilies = this.properties[matchedIndex].fontFamily
      this.fontFamilySelected = ""
      // Set font size slider value
      var matchedIndex1 = this.frontendCustomizatedData
        .map(function (obj) {
          return obj.id;
        })
        .indexOf(this.selectedFieldIndex);

      let fontSize = this.frontendCustomizatedData[matchedIndex1].fontSize
      this.value = parseInt(fontSize)

      // Selected Font Dropdown
      this.fontFamilySelected = ""
      let getSelectedFont = this.frontendCustomizatedData[matchedIndex1].fontFamily
      if (getSelectedFont != "") {
        this.fontFamilySelected = getSelectedFont
      }
    } else {
      this.fontFamilies = []
    }
  }

  _removeSelectedField() {
    this.selectedFieldIndex = -1
    this.selectedFieldType = ""
  }


  _closeAction(i, propId) {
    let type = this.properties[i].type
    if (type != 'image') {
      this.fontFamilies = this.properties[i].fontFamily
      // this.properties[i].defaultValue = "";
      this.selectedFieldIndex = propId
      let el: any = document.getElementById(`_inputs_${propId}`);
      el.innerHTML = "";
      el.focus();
    } else {
      // if (this.properties[i].corp_logo == "true") { }
      // this.fontFamilies = []
      // this.selectedFieldIndex = propId
      // let el: any = document.getElementById(`_image_${propId}`);
      // el.src = this.defaultCardImage
      this.selectedFieldIndex = propId
      this.fontFamilies = []
      this._ecard_image_ref.nativeElement.value = "";
      this.uploadedImages[i] = null
    }
  }

  _setDefaultField() {
    if (this.properties.length > 0 && this.selectedFieldIndex < 1) {
      let propId = this.properties[0].id
      let type = this.properties[0].type
      this.selectedFieldIndex = propId
      this.selectedFieldType = type
      let defaultValue = this.properties[0].defaultValue
      this._createCardDataArray(defaultValue, propId);
    }
  }

  _getInputValues(event: any, propId: number) {
    let getValue = event.target.innerHTML
    if (getValue != undefined) {
      this._createCardDataArray(getValue, propId);
    }
  }

  // Create array of data to save
  _createCardDataArray(getValue: string, propId: number) {
    let isCustomizedDataExist = this.frontendCustomizatedData.length
    if (isCustomizedDataExist > 0) {
      var matchedIndex = this.frontendCustomizatedData
        .map(function (obj) {
          return obj.id;
        })
        .indexOf(propId);
      this.frontendCustomizatedData[matchedIndex].value = getValue
    }
  }

  _setStyles(mode: string, value) {
    this.activeEditStyleMode = mode
    if (this.selectedFieldIndex > -1) {
      var matchedIndex = this.frontendCustomizatedData
        .map(function (obj) {
          return obj.id;
        })
        .indexOf(this.selectedFieldIndex);
      let el = document.getElementById(`_inputs_${this.selectedFieldIndex}`);
      if (mode == 'align') {
        this.frontendCustomizatedData[matchedIndex].textAlign = value
        el.style.textAlign = value
        if (value != 'right') {
          el.parentElement.style.justifyContent = value
        } else {
          el.parentElement.style.justifyContent = "flex-end"
        }
      } else if (mode == 'fontSize') {
        this.frontendCustomizatedData[matchedIndex].fontSize = value
        el.style.fontSize = value
      } else if (mode == 'color') {
        this.frontendCustomizatedData[matchedIndex].color = value
        el.parentElement.style.color = value
      }
    } else {
      this._setDefaultField();
      // this.nfs.showNotification("default", 'No Area selected!');
      return false;
    }
  }

  _getFontSizeValue(value: any) {
    if (this.selectedFieldIndex > -1) {
      let el = document.getElementById(`_inputs_${this.selectedFieldIndex}`);
      el.style.fontSize = `${value}px`
      var matchedIndex = this.frontendCustomizatedData
        .map(function (obj) {
          return obj.id;
        })
        .indexOf(this.selectedFieldIndex);
      this.frontendCustomizatedData[matchedIndex].fontSize = value
    } else {
      this._setDefaultField();
      // this.nfs.showNotification("default", 'No Area selected!');
      return false;
    }
  }

  _setUploadImageValue(imageUrl: string) {
    if (this.selectedFieldIndex > -1) {
      var matchedIndex = this.frontendCustomizatedData
        .map(function (obj) {
          return obj.id;
        })
        .indexOf(this.selectedFieldIndex);
      if (matchedIndex < 0) {
        let d = {
          'id': this.selectedFieldIndex,
          'type': 'image',
          'value': imageUrl
        }
        this.frontendCustomizatedData.push(d);
      } else {
        // Exist then Update
        this.frontendCustomizatedData[matchedIndex].value = imageUrl
      }
    }
  }

  _getFontFamily(event) {
    let getValue = event.target.value
    // console.log(this.selectedFieldIndex, event);
    if (getValue == "") {
      console.log(document.getElementById(`_get_prop_${this.selectedFieldIndex}`).style);
      document.getElementById(`_get_prop_${this.selectedFieldIndex}`).style.fontFamily = this.defaultInputFontFamilies[`_get_prop_${this.selectedFieldIndex}`]
      return false;
    }
    if (this.selectedFieldType != 'image' && this.selectedFieldIndex > -1) {
      var matchedIndex = this.frontendCustomizatedData
        .map(function (obj) {
          return obj.id;
        })
        .indexOf(this.selectedFieldIndex);

      this.frontendCustomizatedData[matchedIndex].fontFamily = getValue
      var el: any = document.getElementsByClassName("_active");
      el[0].style.fontFamily = getValue;
      this.fontFamilySelected = getValue
    }
  }

  _getFontFamily_m(font) {
    console.log(font);
    if (this.selectedFieldType != 'image' && this.selectedFieldIndex > -1) {
      var matchedIndex = this.frontendCustomizatedData
        .map(function (obj) {
          return obj.id;
        })
        .indexOf(this.selectedFieldIndex);
      this.frontendCustomizatedData[matchedIndex].fontFamily = font
      var el: any = document.getElementsByClassName("_active");
      el[0].style.fontFamily = font;
      this.fontFamilySelected = font
    }
  }

  _uploadCardImage(event) {
    console.log(this.usableUploadFieldIndex, this.usableUploadField);
    if (this.selectedFieldType == 'image' && this.selectedFieldIndex > -1) {
      this.selectedFile = event.target.files[0];
      const uploadData = new FormData();
      uploadData.append("image", this.selectedFile, this.selectedFile.name);
      this._uploadImageLoader('show', this.selectedFieldIndex);
      this.apiService.postUploadCardImage(uploadData).subscribe(res => {
        this.uploadCardImageRef.nativeElement.value = "";
        let el: any = document.getElementById(`_image_${this.selectedFieldIndex}`);
        if (res.response == "success") {
          let uploadedImage = res.data.image
          el.src = uploadedImage
          this._setUploadImageValue(uploadedImage);
          this._uploadImageLoader('hide', this.selectedFieldIndex);
        } else if (res.response == "error") {
          el.src = this.defaultCardImage
          this.nfs.showNotification("default", res.message);
          this._uploadImageLoader('hide', this.selectedFieldIndex);
        }
      });
    } else {
      this.uploadCardImageRef.nativeElement.value = "";
      this.nfs.showNotification("default", 'Select Image Area to update.');
    }
  }

  _stopEnter(event) {
    if (this.isDesktopDevice) {
      if (event.key === "Enter" && !event.shiftKey) {
        if (!this.cardInfoActive) {
          this.cardInfoActive = true
        }
        setTimeout(() => {
          this.cardInfoActive = false
        }, 1000);
        return false
      } else if (event.key === "Enter" && event.shiftKey) {
        return true
      } else {
        return true
      }
    }
  }

  _checkImageIsUploaded() {
    let totalUploadFields = 0
    let totalUploadFound = 0
    let getImages = this.frontendCustomizatedData.filter(d => (d.type == 'image'));
    totalUploadFields = getImages.length
    if (getImages.length > 0) {
      for (let i of getImages) {
        if (i.value != "") {
          totalUploadFound++;
        } else {
          this._getSelectedFields('image', i.id);
          totalUploadFound = 0
        }
      }
      if (totalUploadFields == totalUploadFound) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  _saveCheckout() {
    let uploadValidation = this._checkImageIsUploaded();
    if (!uploadValidation) {
      this.nfs.showNotification("default", 'Upload image to proceed.');
      return false;
    }

    let checkLength = this.frontendCustomizatedData.length
    if (checkLength > 0) {
      this.proceedBtnText = "Processing.."
      this.selectedFieldIndex = -1
      let order_number = "";
      let e_order: any = localStorage.getItem('e_order');
      e_order = JSON.parse(e_order)
      if (e_order != null) {
        if (e_order.order_no != "") {
          order_number = e_order.order_no
        }
      }

      if (this.frontendCustomizatedData.length > 0) {
        let i = 0
        for (let d of this.frontendCustomizatedData) {
          let _get_styles = document.getElementById('_get_prop_' + d.id).style.cssText
          this.frontendCustomizatedData[i].styles = _get_styles
          let validate = this.frontendCustomizatedData[i].validate
          if (validate) {
            let checkValue = this.frontendCustomizatedData[i].value.trim();
            if (checkValue == "") {
              this.selectedFieldIndex = this.frontendCustomizatedData[i].id
              this.selectedFieldType = "input"
              this.nfs.showNotification("`default`", 'This is a mandatory field, please add text to proceed');
              return false;
            }
          }
          i++;
        }
      }

      setTimeout(async () => {
        var node = document.getElementById('_card');
        let blob = await domtoimage.toBlob(node);
        var uploadData = new FormData()
        uploadData.append('image', blob);
        uploadData.append('prefix', this.productUrl);
        uploadData.append('order_number', order_number);
        uploadData.append('os', JSON.stringify(this.os));
        this.proceedBtnText = "Saving.."

        uploadData.append('data', JSON.stringify(this.frontendCustomizatedData));
        this.apiService.postEcardWatermarkPreview(uploadData).subscribe(res => {
          if (res.response == "success") {
            let url = res.data.url
            let order_no = res.data.order_number
            let product_id = res.data.product_id
            let storeOrder = { 'order_no': order_no, 'prod': product_id, 'design': this.designNo }
            localStorage.setItem('e_order', JSON.stringify(storeOrder));
            if (url) {
              this.router.navigate([`/rsvp/ecard/${this.productUrl}`])
            }
          } else {
            this.nfs.showNotification("default", 'Preview not created. Try Again.');
          }
          this.proceedBtnText = "Save & Proceed";
        });

      }, 200);
    } else {
      this.nfs.showNotification("default", 'Please make changes to proceed.');
    }
  }


  _saveAsDraft(el: any) {
    let checkLength = this.frontendCustomizatedData.length
    if (checkLength > 0) {
      el.target.innerHTML = "Saving.."
      let order_number = "";
      let e_order: any = localStorage.getItem('e_order');
      e_order = JSON.parse(e_order)
      if (e_order != null) {
        if (e_order.order_no != "") {
          order_number = e_order.order_no
        }
      }
      let i = 0
      for (let d of this.frontendCustomizatedData) {
        let _get_styles = document.getElementById('_get_prop_' + d.id).style.cssText
        this.frontendCustomizatedData[i].styles = _get_styles
        i++;
      }
      this.apiService.postEcardDraft({ 'prefix': this.productUrl, 'data': JSON.stringify(this.frontendCustomizatedData), 'order_number': order_number }).subscribe(res => {
        if (res.response == "success") {
          this.nfs.showNotification("default", "Saved");
          let order_no = res.data.order_number
          let product_id = res.data.product_id
          let storeOrder = { 'order_no': order_no, 'prod': product_id, 'design': this.designNo }
          // let storeOrder = { 'order_no': order_no, 'prod': product_id }
          localStorage.setItem('e_order', JSON.stringify(storeOrder));
        } else {
          this.nfs.showNotification("default", 'Not Saved Error Occurs');
          return false;
        }
        el.target.innerHTML = "Save as Draft"
      })
    } else {
      this.nfs.showNotification("default", 'Please make changes to proceed.');
    }
  }

  _uploadImageLoader(mode: string, propId) {
    let el: any = document.getElementById(`_loader_${propId}`);
    if (mode == 'show') {
      el.style.display = 'flex';
    } else {
      el.style.display = 'none';
    }
  }

  _setCaretPosition(el) {
    var selection = window.getSelection();
    var range = document.createRange();
    selection.removeAllRanges();
    range.selectNodeContents(el);
    range.collapse(false);
    selection.addRange(range);
    el.focus();
  }


  /* MOBILE ECARD */
  _m_tabs(mode: string) {
    if (mode === this.selectedMobileTab) {
      this.selectedMobileTab = ""
      return;
    }
    this.selectedMobileTab = mode
    if (this.selectedMobileTab === "content") {
      if (this.selectedFieldIndex < 0) {
        this.selectedMobileTab = "";
      } else {
        var matchedIndex = this.frontendCustomizatedData
          .map(function (obj) {
            return obj.id;
          })
          .indexOf(this.selectedFieldIndex);
        this.selectedFieldText = this.frontendCustomizatedData[parseInt(matchedIndex)].value
      }
    }
  }

  _m_cancel_content() {
    this.selectedMobileTab = ""
    return;
  }

  _m_update_content() {
    console.log(this.selectedFieldIndex, this.selectedFieldType);
    if (this.selectedFieldIndex > 0 && this.selectedFieldType == 'input') {
      var matchedIndex = this.frontendCustomizatedData
        .map(function (obj) {
          return obj.id;
        })
        .indexOf(this.selectedFieldIndex);
      this.frontendCustomizatedData[parseInt(matchedIndex)].value = this.mContentRef.nativeElement.innerHTML;
      this.selectedMobileTab = ""
    }
  }

  _m_goback() {
    this._location.back();
  }



}
