import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'
import { ApiService } from "../service/api.service"
import { NotificationService } from "../service/notification.service";
import { environment } from 'src/environments/environment';
// import domtoimage from 'dom-to-image';
import domtoimage from 'retina-dom-to-image';
import { DeviceDetectorService } from 'ngx-device-detector';
@Component({
  selector: 'app-ecard-handler',
  templateUrl: './ecard-handler.component.html',
  styles: []
})
export class EcardHandlerComponent implements OnInit {
  productMediaPath: string = '';
  order_no: string = ""

  previewCardData: any
  cardBackgroundTemplate: string = ""
  isProductsLoading: boolean = true
  generatedCardFinalUrl: string = ""
  isDownloadActive: boolean = false
  os: any;
  constructor(private apiService: ApiService, private nfs: NotificationService, private route: ActivatedRoute, private router: Router, private deviceService: DeviceDetectorService) {
    this.productMediaPath = environment.PROD_BASEPATH
  }

  ngOnInit() {
    let deviceInfo = this.deviceService.getDeviceInfo();
    this.os = deviceInfo

    this.route.params.subscribe(params => {
      if (params.order_no != '') {
        this.order_no = params.order_no
        this.apiService.postEcardHandler({ 'order_number': this.order_no }).subscribe(res => {
          if (res.response == "success") {
            this.isProductsLoading = false
            let cardData = res.data
            if (cardData != "") {
              this.previewCardData = JSON.parse(res.data.card_data)
              this.cardBackgroundTemplate = res.data.card_template
              this.nfs.showNotification("default", "Generating your E-CARD.");
              setTimeout(() => {
                this._generateImage();
              }, 2000);
            }
          } else if (res.response == "redirect") {
            this.router.navigate(['dashboard/orders']);
          } else if (res.response == "login") {
            this.router.navigate(['login']);
          }
        })
      }
    })

  }


  async _generateImage() {
    var node = document.getElementById('_card');
    let blob = await domtoimage.toBlob(node);
    var uploadData = new FormData()
    uploadData.append('image', blob);
    uploadData.append('order_number', this.order_no);
    uploadData.append('os', JSON.stringify(this.os));
    this.apiService.postEcardPreview(uploadData).subscribe(res => {
      if (res.response == "success") {
        let url = res.data.url
        if (url) {
          this.generatedCardFinalUrl = `${environment.API_URL}${url}`
          this.isDownloadActive = true
        }
      }
    });
  }



  _downloadImage(image_url: string) {
    var link = document.createElement('a');
    link.download = `P_${this.order_no}.jpeg`;
    link.href = `http://lekarde.com/authority/${image_url}`;
    link.click();
  }


}
