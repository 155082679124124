import { OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from "../service/api.service";
import { NotificationService } from "../service/notification.service";
import { environment } from 'src/environments/environment';
import { FormBuilder, FormArray, Validators } from '@angular/forms';
var SettingsComponent = /** @class */ (function () {
    function SettingsComponent(formBuilder, route, router, apiService, nfs) {
        var _this = this;
        this.formBuilder = formBuilder;
        this.route = route;
        this.router = router;
        this.apiService = apiService;
        this.nfs = nfs;
        this.isProductLoading = true;
        this.orderNumber = '';
        this.productMediaPath = '';
        this.submitted = false;
        this.productMediaPath = environment.PROD_BASEPATH;
        this.route.params.subscribe(function (param) {
            if (param.order_no != '') {
                _this.orderNumber = param.order_no;
            }
        });
    }
    SettingsComponent.prototype.ngOnInit = function () {
        this.dynamicForm = this.formBuilder.group({
            numberOfTickets: ['', Validators.required],
            tickets: new FormArray([])
        });
        // this.apiService.getOrderByOrderno({ order_number: this.orderNumber }).subscribe(res => {
        //   if (res.response == 'success') {
        //     this.order = res.data
        //     this._getOrderSettings();
        //   } else if (res.response == 'notexist') {
        //     this.nfs.showNotification("default", "Order detail you'r trying to fetch not exist.");
        //   }
        //   this.isProductLoading = false
        // })
    };
    Object.defineProperty(SettingsComponent.prototype, "f", {
        // convenience getters for easy access to form fields
        get: function () { return this.dynamicForm.controls; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SettingsComponent.prototype, "t", {
        get: function () { return this.f.tickets; },
        enumerable: true,
        configurable: true
    });
    SettingsComponent.prototype.onChangeTickets = function (e) {
        var numberOfTickets = e.target.value || 0;
        if (this.t.length < numberOfTickets) {
            for (var i = this.t.length; i < numberOfTickets; i++) {
                this.t.push(this.formBuilder.group({
                    name: ['', Validators.required],
                    email: ['', [Validators.required, Validators.email]]
                }));
            }
        }
        else {
            for (var i = this.t.length; i >= numberOfTickets; i--) {
                this.t.removeAt(i);
            }
        }
    };
    SettingsComponent.prototype.onSubmit = function () {
        this.submitted = true;
        // stop here if form is invalid
        if (this.dynamicForm.invalid) {
            return;
        }
        // display form values on success
        alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.dynamicForm.value, null, 4));
    };
    SettingsComponent.prototype.onReset = function () {
        // reset whole form back to initial state
        this.submitted = false;
        this.dynamicForm.reset();
        //this.t.clear();
    };
    SettingsComponent.prototype.onClear = function () {
        // clear errors and reset ticket fields
        this.submitted = false;
        this.t.reset();
    };
    return SettingsComponent;
}());
export { SettingsComponent };
