import { OnInit, EventEmitter, ElementRef } from '@angular/core';
import { Router, } from '@angular/router';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { DataService } from "../service/data.service";
import { ApiService } from "../service/api.service";
import { AuthSessionParamsService } from '../service/auth-session-params.service';
import { Meta, Title } from '@angular/platform-browser';
var HeaderComponent = /** @class */ (function () {
    function HeaderComponent(title, meta, dataService, authSession, router, apiService) {
        var _this = this;
        this.title = title;
        this.meta = meta;
        this.dataService = dataService;
        this.authSession = authSession;
        this.router = router;
        this.apiService = apiService;
        this.isUserDropdownActive = false;
        this.isSearchOverlayActive = false;
        this.isMobileMenuActive = false;
        this.isUserLoggedIn = false;
        this.emitCategories = new EventEmitter();
        this.isSearchLoading = false;
        this.searchProducts = [];
        this.isHeaderNavigationDropdownActive = false;
        this.isMenuHovered = false;
        this.activeMenuCategory = 0;
        this.menuParents = [];
        this.menuItems = [];
        this.parentCategory = [];
        this.wishlistCount = 0;
        this.pageUrl = "";
        this.hasProfilePicture = false;
        this.profile_picture = "assets/img/user_60.png";
        this.dataService.getSessionMessage.subscribe(function (message) {
            _this.isUserLoggedIn = message;
        });
    }
    Object.defineProperty(HeaderComponent.prototype, "ashu", {
        get: function () { return this.ashu; },
        set: function (a) {
            this.getMobileMenuHideEvent = a;
        },
        enumerable: true,
        configurable: true
    });
    HeaderComponent.prototype.ngOnInit = function () {
        var _this = this;
        var _page_url = this.router.url;
        _page_url = _page_url.split('/');
        if (_page_url[2] != undefined) {
            if (_page_url[2] != 'handler' && _page_url[2] != 'reset') {
                localStorage.setItem('url', this.router.url);
            }
        }
        _page_url = _page_url[1];
        if (_page_url == "vcards" || _page_url == "vcard") {
            this.pageUrl = "V-cards";
        }
        else if (_page_url == "ecards" || _page_url == "ecard") {
            this.pageUrl = "E-cards";
        }
        else if (_page_url == "occasions") {
            this.pageUrl = "Occasions";
        }
        else if (_page_url == "help") {
            this.pageUrl = "Help";
        }
        else if (_page_url == 'reset') {
            localStorage.removeItem('url');
        }
        this.searchInvitesGroup = new FormGroup({
            term: new FormControl('', [Validators.required]),
        });
        this.apiService.getCategories().subscribe(function (res) {
            _this.menuParents = res.parents;
            _this.parentCategory = _this.menuParents[0];
            _this.menuItems = res.items;
            _this.dataService.setCategories(_this.productCategories);
        });
        this.isUserLoggedIn = this.authSession.isUserParamsAuthenticated();
        if (this.isUserLoggedIn) {
            var user = localStorage.getItem('user');
            if (user != null) {
                user = JSON.parse(user);
                if (user.profile_picture != undefined && user.profile_picture != "") {
                    this.hasProfilePicture = true;
                    this.profile_picture = user.profile_picture;
                }
                else {
                    this.profile_picture = 'assets/img/preview-image-placeholder.jpg';
                }
            }
            this.apiService.getWishlistCount().subscribe(function (res) {
                if (res.response == "success") {
                    _this.wishlistCount = res.data;
                }
                else {
                    _this.wishlistCount = 0;
                }
            });
        }
        // Search 
        /*
        fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
          // get value
          map((event: any) => {
            return event.target.value;
          })
          , filter(res => {
            if (res.length > 2) {
              return true
            } else {
              return false
            }
          })
          , debounceTime(1000)
          , distinctUntilChanged()
        ).subscribe((q: string) => {
          this.searchTerm = q
          this.isSearchLoading = true
          this._searchInvites(q);
        });
      */
    };
    HeaderComponent.prototype._updateMeta = function (d) {
        if (d.parent_cat_id != "0") {
            if (d.meta_title) {
                this.title.setTitle(d.meta_title);
            }
            if (d.meta_desc) {
                this.meta.updateTag({ name: 'description', content: d.meta_desc });
            }
            if (d.meta_keywords) {
                this.meta.updateTag({ name: 'keywords', content: d.meta_keywords });
            }
        }
    };
    HeaderComponent.prototype._searchSubmit = function () {
        if (this.searchInvitesGroup.valid) {
            var data = this.searchInvitesGroup.value;
            this.router.navigate(["/search/" + data.term]);
        }
        else {
            console.log('Not Valid');
        }
    };
    HeaderComponent.prototype._searchInvites = function (q) {
        var _this = this;
        this.apiService.getSearchResult({ 'term': q }).subscribe(function (res) {
            if (res.response == 'success') {
                _this.searchProducts = res.data;
            }
            else {
                _this.searchProducts = [];
            }
            _this.isSearchLoading = false;
        });
    };
    HeaderComponent.prototype._search_mobile = function () {
        this.isSearchOverlayActive = true;
    };
    HeaderComponent.prototype._closeMobileSearch = function () {
        this.isSearchOverlayActive = false;
    };
    HeaderComponent.prototype._mobile_menu = function () {
        if (!this.isMobileMenuActive) {
            this.isMobileMenuActive = true;
        }
        else {
            this.isMobileMenuActive = false;
        }
    };
    HeaderComponent.prototype._openUserDropdown = function () {
        this.isUserDropdownActive ? this.isUserDropdownActive = false : this.isUserDropdownActive = true;
    };
    HeaderComponent.prototype._redirect = function (category) {
        this.dataService.setCategory(category.id);
        this.router.navigate(["/products/" + category.cat_url]);
    };
    HeaderComponent.prototype._logout = function () {
        if (this.isUserLoggedIn) {
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            this.dataService.sessionMessage(false);
            this.router.navigate(["/login"]);
        }
    };
    HeaderComponent.prototype._navigationDropdown = function (mode, category) {
        if (mode == 'over') {
            this.activeMenuCategory = category;
            this.isHeaderNavigationDropdownActive = true;
        }
        else if (mode == 'out') {
            this.activeMenuCategory = 0;
            this.isHeaderNavigationDropdownActive = false;
        }
    };
    return HeaderComponent;
}());
export { HeaderComponent };
