import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { ApiService } from "../service/api.service"
import { NotificationService } from "../service/notification.service";
import { environment } from 'src/environments/environment';

interface PreviewData {
  fields: any[],
  image_url: string
  ordering: string
  prod_type: string
  product_id: string
  slide_id: string
}

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styles: []
})
export class PreviewComponent implements OnInit {
  isProductLoading: boolean = true
  productUrl: string;
  slideFields: any;
  slides: Array<PreviewData>
  orientation: string
  productMediaPath: string = ''

  constructor(private apiService: ApiService, private nfs: NotificationService, private router: Router, private route: ActivatedRoute) {
    this.productMediaPath = environment.PROD_BASEPATH

    this.route.params.subscribe(p => {
      if (p.product_url) {
        this.productUrl = p.product_url
      }
    })

    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        // trick the Router into believing it's last link wasn't previously loaded
        this.router.navigated = false;
        window.scrollTo(0, 0);
      }
    });
  }

  ngOnInit() {
    let order: any = localStorage.getItem('order');
    if (order != null) {
      order = JSON.parse(order);
      this.apiService.getPreview(order).subscribe(data => {
        if (data.response == 'success') {
          this.isProductLoading = false
          this.orientation = data.orientation
          this.slideFields = JSON.parse(data.fields);
          let slides = data.data
          let finalSlides = []
          if (slides.length > 0) {
            for (let slide of slides) {
              let f = this._getSlideBySlideId(slide.slide_id);
              finalSlides.push({ ...slide, 'fields': f })
            }
            this.slides = finalSlides
          }
        } else {
          this.slides = []
        }
      })
    }
  }

  _getSlideBySlideId(slideId) {
    if (this.slideFields.length > 0) {
      let checkIndex = this.slideFields.map((s) => {
        return s.slide_id
      }).indexOf(slideId)
      return this.slideFields[checkIndex].slides;
    }
  }

  _goToCustomize() {
    this.nfs.showNotification("default", "Redirecting to customization.");
    this.router.navigate([`/vcard/create/${this.productUrl}`])
  }

  _goToRsvp() {
    this.router.navigate([`/rsvp/vcard/${this.productUrl}`])
  }

}
