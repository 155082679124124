import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService } from "angularx-social-login";
import { GoogleLoginProvider, FacebookLoginProvider } from "angularx-social-login";
import { ApiService } from "../service/api.service";
import { DataService } from '../service/data.service';
import { NotificationService } from "../service/notification.service";
var SignupComponent = /** @class */ (function () {
    function SignupComponent(authService, apiService, dataService, router, nfs) {
        this.authService = authService;
        this.apiService = apiService;
        this.dataService = dataService;
        this.router = router;
        this.nfs = nfs;
    }
    SignupComponent.prototype.ngOnInit = function () {
        this.signupFormInit();
    };
    SignupComponent.prototype.signupFormInit = function () {
        this.signupFormGroup = new FormGroup({
            name: new FormControl('', [Validators.required]),
            email: new FormControl('', [Validators.required, Validators.email]),
            mobile: new FormControl('', [Validators.required, Validators.pattern(/^[6-9]\d{9}$/)]),
            password: new FormControl('', [Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/)])
        });
    };
    SignupComponent.prototype.createAccount = function () {
        var _this = this;
        if (this.signupFormGroup.valid) {
            var postParams = tslib_1.__assign({}, this.signupFormGroup.value, { "provider": "WEB" });
            this.apiService.register(postParams).subscribe(function (response) {
                if (response.response == 'success') {
                    _this.router.navigate(['/login']);
                    _this.nfs.showNotification("default", 'Check your email');
                }
                else if (response.response == 'exist') {
                    _this.router.navigate(['/login']);
                    _this.nfs.showNotification("default", 'User Already Exist');
                }
            });
        }
        else {
            this.validateAllFormFields(this.signupFormGroup);
        }
    };
    /* Social Fb/Google Actions */
    SignupComponent.prototype.registerGoogle = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then(function (res) {
                            _this.apiService.register(res).subscribe(function (response) {
                                if (response.response == 'success') {
                                    localStorage.setItem('token', response.token);
                                    localStorage.setItem('user', JSON.stringify(response.data));
                                    _this.dataService.sessionMessage(true);
                                    _this.nfs.showNotification("default", 'Redirecting..');
                                    _this.router.navigate(['/']);
                                }
                                else if (response.response == 'exist') {
                                    _this.nfs.showNotification("default", 'User Already Exist');
                                }
                            });
                        }).catch(function (err) { return console.log(err); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SignupComponent.prototype.registerFacebook = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.authService
                            .signIn(FacebookLoginProvider.PROVIDER_ID)
                            .then(function (res) {
                            _this.apiService.register(res).subscribe(function (response) {
                                if (response.response == 'success') {
                                    localStorage.setItem('token', response.token);
                                    localStorage.setItem('user', JSON.stringify(response.data));
                                    _this.dataService.sessionMessage(true);
                                    _this.nfs.showNotification("default", 'Redirecting..');
                                    _this.router.navigate(['/']);
                                }
                                else if (response.response == 'exist') {
                                    _this.nfs.showNotification("default", 'User Already Exist');
                                }
                            });
                        })
                            .catch(function (err) { return console.log('err', err); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /* Social Fb/Google Actions */
    /* Form Validations and Errors */
    SignupComponent.prototype.formControlValid = function (field) {
        return !this.signupFormGroup.get(field).valid && this.signupFormGroup.get(field).touched;
    };
    SignupComponent.prototype.formControlClass = function (field) {
        if (!this.formControlValid(field)) {
            return { '_noerr': true };
        }
        else {
            return { '_err': true };
        }
    };
    SignupComponent.prototype.validateAllFormFields = function (formGroup) {
        var _this = this;
        Object.keys(formGroup.controls).forEach(function (field) {
            var control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
            }
            else if (control instanceof FormGroup) {
                _this.validateAllFormFields(control);
            }
        });
    };
    return SignupComponent;
}());
export { SignupComponent };
