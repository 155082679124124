import { Component } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { ConnectionService } from 'ng-connection-service';
import { NotificationService } from "./service/notification.service";
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'lekarde';
  connectionStatus: string = 'ONLINE';
  isConnected: boolean = true;
  currentUrl: string = ''
  constructor(private connectionService: ConnectionService, private router: Router, private nfs: NotificationService) {
    this.router.events.subscribe((url: any) => {
      if (url instanceof NavigationStart) {
        this.currentUrl = url.url
      }
    });
    this.connectionService.monitor().subscribe(isConnected => {
      this.isConnected = isConnected;
      if (this.isConnected) {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate([`${this.currentUrl}`]);
        this.connectionStatus = "ONLINE";
      }
      else {
        this.nfs.showNotification("default", 'Internet seems to be offline!');
        this.connectionStatus = "OFFLINE";
      }
    })
  }
}
