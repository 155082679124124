<div class="_main_container _header_shadow">
  <app-header></app-header>
</div>

<div class="_main_container" *ngIf="isProductLoading">
  <p class="_loading">
    <span class="loader"></span>
  </p>
</div>

<div class="Static_card" *ngIf="!isProductLoading">
  <a class="_ecard_draft" (click)="_saveAsDraft($event)" href="javascript:;">Save as Draft</a>
  <div class="_left _bg">
    <div class="_slide _vertical">
      <!-- Without Upload -->
      <ng-container *ngIf="products?.length > 0 && !isProductsLoading">
        <ng-container *ngFor="let product of products;let i = index">
          <div class="_card" id="_card">

            <div class="_popup_window" [ngClass]="{'_active' : cardInfoActive}">
              <p>Use SHIFT + ENTER<br> to add new line</p>
            </div>

            <img [src]="productMediaPath + '/upload/cards/' + product.card_img" class="_card_img">
            <div class="_input_filed">
              <ng-container *ngIf="frontendCustomizatedData?.length > 0">
                <ng-container *ngFor="let prop of frontendCustomizatedData; let i = index">

                  <ng-container *ngIf="prop.type == 'text'">
                    <div class="_form_details _font_color_default" id="_get_prop_{{prop.id}}"
                      [style]="prop.styles | safeStyle">
                      <p class="_text" [innerHtml]="prop.value"></p>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="prop.type == 'input'">
                    <div class="_form_details" id="_get_prop_{{prop.id}}" [style]="prop.styles | safeStyle"
                      [style.font-size.px]="prop.fontSize" [style.width.px]="prop.width" [style.height.px]="prop.height"
                      [ngClass]="{'_active' : selectedFieldIndex == prop.id}" (drop)="_stopDrop($event)">
                      <span class="_close" (click)="_closeAction(i,prop.id)">
                        <img src="./assets/img/cross.png">
                      </span>

                      <ng-container *ngIf="isMobile else desktopContentEditable">
                        <div class="_content_editable" id="_inputs_{{prop.id}}" [innerHTML]="prop.value"
                          (focus)="_getInputValues($event,prop.id)" (click)="_getSelectedFields('input',prop.id)">
                        </div>
                      </ng-container>

                      <ng-template #desktopContentEditable>
                        <div spellcheck="false" class="_content_editable" contenteditable="true"
                          id="_inputs_{{prop.id}}" [innerHTML]="prop.value" (keydown)="_stopEnter($event)"
                          (focus)="_getInputValues($event,prop.id)" (mouseleave)="_getInputValues($event,prop.id)"
                          (click)="_getSelectedFields('input',prop.id)">
                        </div>

                      </ng-template>

                      <span class="_dot1"></span>
                      <span class="_dot2"></span>
                      <span class="_dot3"></span>
                      <div id="_getTextLength_{{prop.id}}" style="overflow: auto; height: 50px; visibility: hidden;">
                      </div>
                    </div>
                  </ng-container>


                  <ng-container *ngIf="prop.type == 'image'">
                    <div class="_form_details _upload_area" id="_get_prop_{{prop.id}}" [style]="prop.styles | safeStyle"
                      [style.width.px]="prop.width" [style.height.px]="prop.height"
                      [ngClass]="{'_active' : selectedFieldIndex == prop.id}"
                      (click)="_getSelectedFields('image',prop.id)">
                      <span class="_close" (click)="_closeAction(i,prop.id)">
                        <img src="./assets/img/cross.png">
                      </span>

                      <div class="_show_image _upload_placeholder"
                        [ngStyle]="uploadedImages[i] || prop.value ? {'background' : 'none'} : {'background-image': 'url(./assets/img/upload-'+prop.uploadPlaceholder+'-placeholder.png)' }"
                        (click)="_open_new_upload(i,prop)">
                        <p class="_loading" id="_loader_{{prop.id}}">
                          <span class="loader"></span>
                        </p>

                        <!-- <img *ngIf="croppedImage[i]" class="_image" id="_image_{{prop.id}}" [src]="croppedImage[i]" />
                        <img *ngIf="!croppedImage[i] && prop.value" class="_image" id="_image_{{prop.id}}"
                          [src]="prop.value" /> -->

                        <img *ngIf="uploadedImages[i]" class="_image" id="_image_{{prop.id}}"
                          [src]="uploadedImages[i]" />
                        <img *ngIf="!uploadedImages[i] && prop.value" class="_image" id="_image_{{prop.id}}"
                          [src]="prop.value" />

                        <!-- <button class="_edit_ecard_image _edit_iamge_icon_{{prop.id}}" (click)="_editCropped(i,prop)">
                          <img src="./assets/img/edit-image.png" alt="Edit Image" />
                        </button> -->

                      </div>
                    </div>
                  </ng-container>

                </ng-container>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </ng-container>

    </div>
  </div>

  <div class="_right" [ngClass]="{'_active' : selectedFieldType == 'image'}">
    <h2 class="_heading">Format</h2>
    <form name="" id="">
      <div class="_field">
        <select name="wdqd" (change)="_getFontFamily($event)">
          <option value="">Font (Default)</option>
          <ng-container *ngIf="fontFamilies?.length > 0">
            <option *ngFor="let font of fontFamilies" [value]="font" [selected]="font == fontFamilySelected"
              [ngStyle]="{'font-family' : font}">
              {{font}}
            </option>
          </ng-container>
        </select>
      </div>
      <div class="_field alignment">
        <h2 class="_sub_heading">Text Alignment</h2>
        <ul class="_actions">
          <li class="_item_action">
            <a [routerLink]="" (click)="_setStyles('align','left')"><img src="./assets/img/bar.png"></a>
          </li>
          <li class="_item_action">
            <a [routerLink]="" (click)="_setStyles('align','center')"><img src="./assets/img/bar2.png"></a>
          </li>
          <li class="_item_action">
            <a [routerLink]="" (click)="_setStyles('align','right')"><img src="./assets/img/bar3.png"></a>
          </li>
        </ul>
      </div>
      <div class="_field progressbar">
        <h2 class="_sub_heading">Letter Size</h2>
        <ng5-slider [(value)]="value" [options]="options" (valueChange)="_getFontSizeValue($event)"></ng5-slider>
      </div>

      <div class="_field _color_picker">
        <h2 class="_sub_heading">Text Color</h2>
        <ng-container *ngIf="colors?.length >0">
          <ul class="_actions" [ngClass]="{'_auto_height' : moreColorActive}">
            <ng-container *ngFor="let c of colors; let i = index">
              <li class="_item_action" (click)="_setStyles('color',c.color_code)">
                <div class="yellow" [ngStyle]="{'background': c.color_code}"></div>
              </li>
            </ng-container>
          </ul>
        </ng-container>
        <ng-container *ngIf="colors?.length > 5">
          <button class="_more_colors" (click)="_moreColor()">
            <img src="assets/img/more-colors-icon.png" width="20" />
          </button>
        </ng-container>
      </div>

      <!-- <ng-container *ngIf="selectedFieldType == 'image'">
      </ng-container> -->
      <ng-container *ngIf="hasImageField">
        <div class="_field _upload_area">
          <input type="file" #_ecard_image_ref name="_upload_card_image{{usableUploadFieldIndex}}"
            id="_upload_card_image{{usableUploadFieldIndex}}" (change)="_new_uploader($event)" />
          <!-- <div class="_upload_field">
            <label for="_upload_card_image">
              <span>Upload picture</span>
            </label>
          </div> -->
        </div>
      </ng-container>



      <div class="_field _checkout_area" (mouseover)="_removeSelectedField()">
        <div class="_checkout">
          <button class="_action" (click)="_saveCheckout()" [innerHTML]="proceedBtnText"></button>
        </div>
      </div>
    </form>
  </div>


  <!-- =============== MOBILE ECARD ============== -->
  <ng-container *ngIf="isMobile">
    <div class="_mobile_right _mobile_ecard_customize">
      <ul class="_mobile_menu_tab">
        <li class="_active" [ngClass]="{'_active' : selectedMobileTab == 'content'}" (click)="_m_tabs('content')">
          <div class="_tab_inner">
            <img src="./assets/img/mobile_edit.png">
            <p>Edit</p>
          </div>
        </li>
        <li [ngClass]="{'_active' : selectedMobileTab == 'align'}" (click)="_m_tabs('align')">
          <div class="_tab_inner">
            <img src="./assets/img/_mobile_align.png">
            <p>Align</p>
          </div>
        </li>
        <li [ngClass]="{'_active' : selectedMobileTab == 'fontFamily'}" (click)="_m_tabs('fontFamily')">
          <div class="_tab_inner">
            <img src="./assets/img/_mobile_font.png">
            <p>Font</p>
          </div>
        </li>
        <li [ngClass]="{'_active' : selectedMobileTab == 'color'}" (click)="_m_tabs('color')">
          <div class="_tab_inner">
            <img src="./assets/img/_mobile_color.png">
            <p>Color</p>
          </div>
        </li>
        <ng-container *ngIf="selectedFieldType != 'image' else mobileImageUploadTab">
          <li [ngClass]="{'_active' : selectedMobileTab == 'fontSize'}" (click)="_m_tabs('fontSize')">
            <div class="_tab_inner">
              <img src="./assets/img/_mobile_font_size.png">
              <p>Size</p>
            </div>
          </li>
        </ng-container>
        <ng-template #mobileImageUploadTab>
          <li [ngClass]="{'_active' : selectedMobileTab == 'image'}" (click)="_m_tabs('image')">
            <div class="_tab_inner">
              <img src="./assets/img/_mobile_camera.png">
              <p>Upload</p>
            </div>
          </li>
        </ng-template>
      </ul>
      <div class="_edit_options_grid">
        <form name="_m_ecard_form" id="_m_ecard_form">

          <ng-container *ngIf="selectedMobileTab == 'content'">
            <div class="_tabs _content">
              <div class="_field" style="margin-top: 120px;">
                <label class="_field_label">Add Text</label>
                <div spellcheck="false" class="_content_editable" contenteditable="true" #mContentRef
                  data-text="Add Text Here" [innerHTML]="selectedFieldText" (keydown)="_stopEnter($event)">
                </div>
                <div class="_content_editable_actions">
                  <button class="_cancel" (click)="_m_cancel_content()">Cancel</button>
                  <button class="_update" (click)="_m_update_content()">Update</button>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="selectedMobileTab == 'fontFamily'">
            <div class="_tabs">
              <div class="_field">
                <label class="_field_label">Font (Default)</label>
                <ng-container *ngIf="fontFamilies?.length > 0">
                  <ul class="_fonts_listing">
                    <ng-container *ngFor="let font of fontFamilies">
                      <li (click)="_getFontFamily_m(font)" [innerHTML]="font"
                        [class.selected]="font == fontFamilySelected" [ngStyle]="{'font-family' : font}"></li>
                    </ng-container>
                  </ul>
                </ng-container>

              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="selectedMobileTab == 'align'">
            <div class="_tabs">
              <div class="_field">
                <label class="_field_label">Text Alignment</label>
                <ul class="_font_alignments">
                  <li class="_item_action">
                    <a [routerLink]="" (click)="_setStyles('align','left')"><img src="./assets/img/bar.png"></a>
                  </li>
                  <li class="_item_action">
                    <a [routerLink]="" (click)="_setStyles('align','center')"><img src="./assets/img/bar2.png"></a>
                  </li>
                  <li class="_item_action">
                    <a [routerLink]="" (click)="_setStyles('align','right')"><img src="./assets/img/bar3.png"></a>
                  </li>
                </ul>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="selectedMobileTab == 'color'">
            <div class="_tabs">
              <div class="_field _color_picker">
                <label class="_field_label">Text Color</label>
                <ng-container *ngIf="colors?.length >0">
                  <ul class="_actions" [ngClass]="{'_auto_height' : moreColorActive}">
                    <ng-container *ngFor="let c of colors; let i = index">
                      <li class="_item_action" (click)="_setStyles('color',c.color_code)">
                        <div class="yellow" [ngStyle]="{'background': c.color_code}"></div>
                      </li>
                    </ng-container>
                  </ul>
                </ng-container>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="selectedMobileTab == 'fontSize'">
            <div class="_tabs">
              <div class="_field">
                <label class="_field_label">Font Size</label>
                <ng5-slider [(value)]="value" [options]="options" (valueChange)="_getFontSizeValue($event)">
                </ng5-slider>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="selectedMobileTab == 'image'">
            <div class="_tabs">
              <div class="_field _upload_area">
                <label class="_field_label">Upload Image</label>
                <div class="_upload_field">
                  <label for="_upload_card_image">
                    <span>Choose Image</span>
                  </label>
                </div>
                <input type="file" #_ecard_image_ref name="_upload_card_image" id="_upload_card_image"
                  (change)="fileChangeEvent($event)" />
              </div>
            </div>
          </ng-container>

        </form>
      </div>
    </div>
  </ng-container>
  <!-- =============== MOBILE ECARD ============== -->

</div>

<!-- =============== MOBILE ECARD ============== -->
<ng-container *ngIf="isMobile">
  <div class="_mobile_ecard_customize_header">
    <div class="_left">
      <a href="javascript:;" (click)="_m_goback()">
        < Back </a>
    </div>
    <div class="_right">
      <a (click)="_saveAsDraft($event)" href="javascript:;">Save as Draft</a>
      <button type="button" class="_update" (click)="_saveCheckout()" [innerHTML]="proceedBtnText"></button>
    </div>
  </div>
</ng-container>
<!-- =============== MOBILE ECARD ============== -->




<div class="_popup_window" [ngClass]="{'_active' : isCropperActive}">
  <div class="_cropper_grid">
    <div class="_inner">
      <ng-container *ngFor="let c of imageFields">
        <image-cropper *ngIf="isCropperActive && c.id == usableUploadField.id" [imageChangedEvent]="imageChangedEvent"
          [maintainAspectRatio]="true" [containWithinAspectRatio]="true" [aspectRatio]="4 / 3" [resizeToWidth]="0"
          [cropperMinWidth]="0" [onlyScaleDown]="true" [roundCropper]="false" format="png"
          (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()"
          (loadImageFailed)="loadImageFailed()">
        </image-cropper>
      </ng-container>

      <div class="_cropper_buttons">
        <button type="button" (click)="_uploadCroppedImage($event)" [innerHTML]="croppedBtnText"></button>
        <button style="background: #ca1818;" type="button" (click)="_cancelCroppedImage()">Cancel</button>
      </div>
    </div>
  </div>
</div>


<div class="_clearfix"></div>
<app-footer></app-footer>