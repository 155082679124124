/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../header/header.component.ngfactory";
import * as i2 from "../header/header.component";
import * as i3 from "@angular/platform-browser";
import * as i4 from "../service/data.service";
import * as i5 from "../service/auth-session-params.service";
import * as i6 from "@angular/router";
import * as i7 from "../service/api.service";
import * as i8 from "../footer/footer.component.ngfactory";
import * as i9 from "../footer/footer.component";
import * as i10 from "./faq.component";
var styles_FaqComponent = [];
var RenderType_FaqComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FaqComponent, data: {} });
export { RenderType_FaqComponent as RenderType_FaqComponent };
export function View_FaqComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "_main_container _header_shadow"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-header", [], null, null, null, i1.View_HeaderComponent_0, i1.RenderType_HeaderComponent)), i0.ɵdid(2, 114688, null, 0, i2.HeaderComponent, [i3.Title, i3.Meta, i4.DataService, i5.AuthSessionParamsService, i6.Router, i7.ApiService], null, null), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "_main_container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "div", [["class", "_slideshow"], ["style", "height:330px;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 0, "img", [["alt", "Slides"], ["class", "_slides"], ["src", "assets/img/banner.png"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 18, "div", [["class", "_main_container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 17, "div", [["class", "_help_desk"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "div", [["class", "_help_heading"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["FAQ'S"])), (_l()(), i0.ɵeld(10, 0, null, null, 14, "div", [["class", "_inner_help"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 6, "div", [["class", "_faq_row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 3, "div", [["class", "_question"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co._expandFilter($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Why do we use it? "])), (_l()(), i0.ɵeld(14, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-minus"]], null, null, null, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-plus"]], null, null, null, null, null)), (_l()(), i0.ɵeld(16, 0, null, null, 1, "div", [["class", "_answer"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English."])), (_l()(), i0.ɵeld(18, 0, null, null, 6, "div", [["class", "_faq_row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(19, 0, null, null, 3, "div", [["class", "_question"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co._expandFilter($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Why do we use it? "])), (_l()(), i0.ɵeld(21, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-minus"]], null, null, null, null, null)), (_l()(), i0.ɵeld(22, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-plus"]], null, null, null, null, null)), (_l()(), i0.ɵeld(23, 0, null, null, 1, "div", [["class", "_answer"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English."])), (_l()(), i0.ɵeld(25, 0, null, null, 1, "app-footer", [], null, null, null, i8.View_FooterComponent_0, i8.RenderType_FooterComponent)), i0.ɵdid(26, 114688, null, 0, i9.FooterComponent, [i7.ApiService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); _ck(_v, 26, 0); }, null); }
export function View_FaqComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-faq", [], null, null, null, View_FaqComponent_0, RenderType_FaqComponent)), i0.ɵdid(1, 114688, null, 0, i10.FaqComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FaqComponentNgFactory = i0.ɵccf("app-faq", i10.FaqComponent, View_FaqComponent_Host_0, {}, {}, []);
export { FaqComponentNgFactory as FaqComponentNgFactory };
