<div class="_main_container _header_shadow">
  <app-header [ashu]=ashu></app-header>
</div>

<div class="_main_container">
  <ng-container *ngIf="categoryBanner">
    <div class="_filter_banner">
      <img [src]="categoryBanner" lazy />
    </div>
  </ng-container>

  <div class="_filter_page_bg">
    <div class="_inner_filter">

      <div class="_filter_left">
        <div class="_inner_card">
          <h1 class="_heading">Filters</h1>
          <div class="_card_design _filter_category">
            <p class="_inr_sub_title">{{filterCategoryTitle}}
              <!-- (click)="_expandFilter($event)" <i class="fa fa-minus" aria-hidden="true"></i>
              <i class="fa fa-plus" aria-hidden="true"></i> -->
            </p>
            <div class="_filter_area">
              <ng-container *ngFor="let category of filterCategories; let i = index">
                <div class="_field _checkbox">
                  <span [innerHTML]="category.cat_name"></span>
                  <div class="_checkbox_input">
                    <input type="checkbox" id="category_checkbox_{{i}}" (click)="_filterProducts(i,$event,'category')"
                      [value]="category.cat_url" [checked]="productSubCategory == category.cat_url" />
                    <label for="category_checkbox_{{i}}"></label>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>

      <div class="_filter_right">

        <button class="_mobile_sort_open" (click)="_openMobileFilters()" [innerHTML]="mobileFilterButtonText"></button>

        <div class="_heading">
          <!-- <div class="_left" [innerHTML]="filterCategoryTitle"></div> -->
          <div class="_sort">
            <label>Sort by: </label>
            <select name="_sortByType" (change)="_filterProducts(0,$event,'sort')">
              <option value="" selected>Choose</option>
              <option value="popular">Most Popular</option>
              <option value="low">Price (Low)</option>
              <option value="high">Price (High)</option>
            </select>
          </div>
        </div>
        <!--Products-->
        <p class="_loading" *ngIf="isProductsLoading">
          <span class="loader"></span>
          <span class="_text">Loading..</span>
        </p>
        <ng-container *ngIf="!isProductsLoading; else noProductsTemplate">
          <div class="_products" *ngIf="products?.length > 0">
            <ng-container *ngFor="let product of products;let i = index">
              <div class="_grid_vertical _ecard_vertical">
                <div class="_inner">
                  <a routerLink="/ecard/create/{{product.card_prefix}}/{{product.design_no}}" target="_blank"
                    class="_vertical_flex">
                    <img [src]="product.card_template" class="_v_product_image">
                  </a>
                  <span class="_price">
                    <span class="_live" *ngIf="product.spl_price != product.price">

                      <ng-container *ngIf="product.spl_price == '0'">
                        Free
                      </ng-container>
                      <ng-container *ngIf="product.spl_price != '0'">
                        <i class="fa fa-inr" aria-hidden="true"></i>
                        {{product.spl_price}}
                      </ng-container>
                    </span>
                    <span
                      [ngClass]="{'_original' : product.spl_price != product.price, '_live' : product.spl_price == product.price}"
                      *ngIf="product.price">


                      <ng-container *ngIf="product.price == '0'">
                        Free
                      </ng-container>
                      <ng-container *ngIf="product.price != '0'">
                        <i class="fa fa-inr" aria-hidden="true"></i>
                        {{product.price}}
                      </ng-container>


                    </span>
                  </span>
                  <button class="_add_wishlist" [ngClass]="{'_active' : product.wishlist }"
                    (click)="_addWishlist(product,i)">
                    <i class="fa fa-heart"></i>
                  </button>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="loadMoreShow">
              <div class="_load_more_container">
                <button type="button" class="_load_more" (click)="_loadMore()">
                  <ng-container *ngIf="loadMoreActive else loadMoreGif">
                    Load More
                  </ng-container>
                  <ng-template #loadMoreGif>
                    Loading &nbsp;&nbsp;<img src="assets/img/load_more.gif" width="20" />
                  </ng-template>
                </button>
              </div>
            </ng-container>
            <ng-template #noMoreProductsTemplate>
              <div class="_load_more_container">
                No more products
              </div>
            </ng-template>
          </div>
        </ng-container>

        <ng-template #noProductsTemplate>
          <div class="_blank">

          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>

<div class="_clearfix"></div>
<app-footer></app-footer>