import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs/BehaviorSubject";

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private sessionMessageSource = new BehaviorSubject<boolean>(false);
  getSessionMessage = this.sessionMessageSource.asObservable();

  private categoriesSource = new BehaviorSubject<any>([])
  getCategories = this.categoriesSource.asObservable();

  private selectCategorySource = new BehaviorSubject<string>('0')
  getCategory = this.selectCategorySource.asObservable();

  private wishlistSource = new BehaviorSubject<any>([])
  getWishlists = this.wishlistSource.asObservable();
  constructor() { }

  sessionMessage(message: boolean) {
    this.sessionMessageSource.next(message);
  }

  setCategories(categories: any) {
    this.categoriesSource.next(categories)
  }

  setCategory(category: any) {
    this.selectCategorySource.next(category)
  }

  setWishlists(wishlist: any) {
    this.wishlistSource.next(wishlist)
  }
}
