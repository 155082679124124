import { Component, OnInit, ViewChild } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { ApiService } from "../service/api.service"
import { FormControl, FormGroup, Validators } from "@angular/forms"
import { NotificationService } from "../service/notification.service";
import { OwlOptions } from 'ngx-owl-carousel-o';
import { environment } from 'src/environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector';

interface Orders {
  order_id: string,
  event_name: string,
  hosted_by: string,
  image_url: string,
  order_date: string,
  order_number: string,
  shared_url: string,
  product_url_name: string,
  order_type: string,
  status: boolean,
  order_total: string,
  card_template: string,
  design_no: string,
  order_image_card: string,
  card_url: string,
  guest_rsvp_status: boolean,
  video_url: string,
  order_rsvp: boolean,
  coupon: string
}

interface ArchivedOrders {
  product_name: string,
  product_url: string,
  order_number: string,
  order_date: string,
  image_url: string,
  product_id: number,
  wishlist: boolean,
  price: number,
  type: string,
  status: boolean,
  order_total: string,
  card_template: string,
  design_no: string,
  card_url: string,
  video_url: string,
  coupon: string;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styles: []
})
export class DashboardComponent implements OnInit {
  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    margin: 0,
    navSpeed: 700,
    navText: ['<img src="assets/img/recent-nav-prev.png" />', '<img src="assets/img/recent-nav-next.png" />'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 6
      }
    },
    nav: true
  }
  selectedFile: File
  isProfileTabActive: string = ''
  isEditFormActive: boolean = false
  editProfileFormGroup: FormGroup;
  editProfileImageFormGroup: FormGroup
  name: string = ''
  address: string = ''
  email: string = ''
  profileImage: string
  mobile: string = ""
  @ViewChild('profileRef') profileRef
  vCardOrders: Array<Orders>
  eCardOrders: Array<Orders>
  allOrders: Array<Orders>
  productMediaPath: string = ''
  eCardPath: string = ''
  recentOrders: Array<{ 'slide': string }>
  archivedOrders: Array<ArchivedOrders>
  archivedEcardOrders: Array<ArchivedOrders>
  VcardWishlists: Array<ArchivedOrders>
  EcardWishlists: Array<ArchivedOrders>
  isOrdersLoading: boolean = true;
  isProfileLoading: boolean = true
  isWishlistLoading: boolean = true
  selctedOrderFilter: string = "all";
  ifAllFilterSelected: boolean = true;
  selctedEcardOrderFilter: string = "vcard"

  isMobile: boolean = false;

  constructor(private apiService: ApiService, private nfs: NotificationService, private router: Router, private route: ActivatedRoute, private deviceService: DeviceDetectorService) {
    this.productMediaPath = environment.PROD_BASEPATH
    this.eCardPath = environment.ECARD_PATH
    this.route.params.subscribe(param => {
      if (param.mode) {
        if (param.mode == 'profile') {
          this.isProfileTabActive = 'profile'
        } else if (param.mode == 'orders') {
          this.apiService.getOrders().subscribe(res => {
            if (res.response == 'success') {
              this.vCardOrders = res.data.vcard
              this.eCardOrders = res.data.ecard
              console.log(this.vCardOrders);
              this.allOrders = res.data.both
            } else {
              this.vCardOrders = []
            }
            this.isOrdersLoading = false
          })
          this.isProfileTabActive = 'order'
        } else if (param.mode == 'wishlist') {
          this.isProfileTabActive = 'wishlist'
        } else {
          this.router.navigate(['/']);
        }
      }
    })
  }

  ngOnInit() {
    this.isMobile = this.deviceService.isMobile();

    this.apiService.getProfile().subscribe(res => {
      if (res.response == 'success') {
        if (res.token == 'expired') {
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          location.href = "/login"
        } else {
          this.name = res.data.name
          this.address = res.data.address
          this.profileImage = res.data.image
          this.email = res.data.email
          this.mobile = res.data.mobile
          this.editProfileFormGroup.setValue({
            name: this.name,
            address: this.address,
            mobile: this.mobile
          });
          this.isProfileLoading = false
        }
      }
    })

    this.editProfileFormGroup = new FormGroup(
      {
        name: new FormControl(this.name, [Validators.required]),
        mobile: new FormControl("", [Validators.required]),
        address: new FormControl(this.address, [Validators.required])
      }
    )

    this._getRecentOrders();
    this._getArchivedOrders();
    this._getArchivedEcardOrders();
    this._getWishlistItems();
  }

  _copied(event) {
    if (event.isSuccess) {
      this.nfs.showNotification("default", 'Copied !');
    }
  }


  _getWishlistItems() {
    this.apiService.getWishlist().subscribe(res => {
      if (res.response == 'success') {
        this.VcardWishlists = res.data.vcard
        this.EcardWishlists = res.data.ecard
      } else {
        this.VcardWishlists = []
        this.EcardWishlists = []
      }
      this.isWishlistLoading = false
    })
  }

  _removeFromWishlist(product, i, wishlist_type) {
    if (product) {
      this.apiService.postWishlist({ 'prod_url': product.product_url, 'type': wishlist_type }).subscribe(data => {
        if (data.response == 'success') {
          let status = data.data
          if (!status) {
            this.nfs.showNotification("default", 'Removed from Wishlist');
            if (wishlist_type == "vcard") {
              this.VcardWishlists.splice(i, 1);
            } else if (wishlist_type == "ecard") {
              this.EcardWishlists.splice(i, 1);
            }
          }
        }
      })
    }
  }

  _getArchivedOrders() {
    this.apiService.getArchivedOrders().subscribe(res => {
      if (res.response == 'success') {
        this.archivedOrders = res.data
      } else {
        this.archivedOrders = []
      }
    })
  }

  _getArchivedEcardOrders() {
    this.apiService.getArchivedEcardOrders().subscribe(res => {
      if (res.response == 'success') {
        this.archivedEcardOrders = res.data
      } else {
        this.archivedOrders = []
      }
    })
  }

  _getRecentOrders() {
    this.apiService.getRecentOrders().subscribe(res => {
      if (res.response == 'success') {
        this.recentOrders = res.data
      }
    })
  }

  _selectProfileImage(event) {
    this.selectedFile = event.target.files[0];
    const uploadData = new FormData();
    uploadData.append("image", this.selectedFile, this.selectedFile.name);
    this.apiService.postProfileImage(uploadData).subscribe(res => {
      this.profileRef.nativeElement.value = "";
      if (res.response == "success") {
        this.profileImage = res.data.image
        this._assa();
        let a: any = document.getElementsByClassName('_pic');
        a[0].src = this.profileImage

        this.nfs.showNotification("default", "Profile Photo Uploaded.");
      } else if (res.response == "size") {
        this.nfs.showNotification("default", "Not Updated.");
      }
    });
  }

  _assa() {
    let a: any = localStorage.getItem('user');
    if (a != null) {
      a = JSON.parse(a);
      if (a.profile_picture != undefined) {
        a.profile_picture = this.profileImage
      } else {
        Object.assign(a, { 'profile_picture': this.profileImage });
      }
      localStorage.setItem('user', JSON.stringify(a));
    }
  }

  _profileTabs(tab: string) {
    this.isEditFormActive = false;
    this.isProfileTabActive = tab
    if (tab == 'profile') {
      this.router.navigate(['/dashboard', 'profile'])
    } else if (tab == 'order') {
      this.router.navigate(['/dashboard', 'orders'])
    } else if (tab == 'wishlist') {
      this.router.navigate(['/dashboard', 'wishlist'])
    }
  }

  _openEditProfile() {
    this.isEditFormActive = true
  }

  _updateProfile() {
    if (this.editProfileFormGroup.valid) {
      let postParams = this.editProfileFormGroup.value
      this.apiService.postProfile({ ...postParams, mode: 'profile' }).subscribe(response => {
        if (response.response == 'success') {
          this.name = postParams.name
          this.address = postParams.address
          this.isEditFormActive = false;
          this.nfs.showNotification("default", 'Updated');
        } else if (response.response == 'failed') {
          this.nfs.showNotification("default", 'Failed');
        }
      })
    } else {
      this.validateAllFormFields(this.editProfileFormGroup)
    }
  }

  _goToOrderDetail(order_type, type: string, index: string, optional: string = null) {
    if (optional != null) {
      if (order_type == 'vcard') {
        let order: any = {}
        if (optional == "vcard") {
          order = this.vCardOrders[index]
        } else {
          order = this.allOrders[index]
        }
        let orderNumber = order.order_number
        if (type == 'setting') {
          this.router.navigate([`order/setting/${orderNumber}`])
        } else if (type == 'detail') {
          let sharedUrl = order.shared_url
          this.router.navigate([`rsvp/detail/${order_type}/${orderNumber}`])
        } else if (type == 'link') {
          let sharedUrl = order.shared_url
          const url = `/share/vcard/${sharedUrl}`;
          window.open(url, '_blank');
        }
      } else if (order_type == 'ecard') {
        let order: any = {}
        if (optional == "ecard") {
          order = this.eCardOrders[index]
        } else {
          order = this.allOrders[index]
        }


        let orderNumber = order.order_number
        if (type == 'link') {
          let sharedUrl = order.shared_url
          const url = `/share/ecard/${sharedUrl}`;
          window.open(url, '_blank');
        } else if (type == 'detail') {
          let sharedUrl = order.shared_url
          this.router.navigate([`rsvp/detail/${order_type}/${orderNumber}`])
        }
      }
      return false;
    }
    if (type) {
      if (order_type == 'vcard') {
        let order = this.vCardOrders[index]
        let orderNumber = order.order_number
        if (type == 'setting') {
          this.router.navigate([`order/setting/${orderNumber}`])
        } else if (type == 'detail') {
          let sharedUrl = order.shared_url
          this.router.navigate([`rsvp/detail/${order_type}/${orderNumber}/${sharedUrl}`])
        } else if (type == 'link') {
          let sharedUrl = order.shared_url
          const url = `/share/vcard/${sharedUrl}`;
          window.open(url, '_blank');
        }
      } else if (order_type == 'ecard') {
        let order = this.eCardOrders[index]
        let orderNumber = order.order_number
        if (type == 'link') {
          let sharedUrl = order.shared_url
          const url = `/share/ecard/${sharedUrl}`;
          window.open(url, '_blank');
        } else if (type == 'detail') {
          let sharedUrl = order.shared_url
          this.router.navigate([`rsvp/detail/${order_type}/${orderNumber}/${sharedUrl}`])
        }
      }
    } else {
      this.nfs.showNotification("default", 'Failed to redirect');
    }
  }

  _goCustomizeSavedCard(i, mode) {
    if (mode == "vcard") {
      if (i == 0 || i > 0) {
        let order = this.archivedOrders[i]
        let storeOrder = { 'order_no': order.order_number, 'prod': order.product_id }
        localStorage.setItem('order', JSON.stringify(storeOrder));
        this.router.navigate([`/vcard/create/${order.product_url}`]);
      }
    } else if (mode == "ecard") {
      if (i == 0 || i > 0) {
        let order = this.archivedEcardOrders[i]
        let storeOrder = { 'order_no': order.order_number, 'prod': order.product_id, 'design': order.design_no }
        localStorage.setItem('e_order', JSON.stringify(storeOrder));
        this.router.navigate([`/ecard/create/${order.product_url}/${order.design_no}`]);
      }
    }
  }

  _removeDraft(i, mode) {
    console.log();
    if (mode == "vcard") {
      let order = this.archivedOrders[i]
      let storeOrder = { 'order_no': order.order_number, 'prod': order.product_id, 'mode': "vcard" }
      this.apiService.removeDraft(storeOrder).subscribe(res => {
        if (res.response == 'success') {
          this.archivedOrders.splice(i, 1);
          this.nfs.showNotification("default", 'V-Card removed from drafts');
        }
      })
    } else if (mode == "ecard") {
      let order = this.archivedEcardOrders[i]
      let storeOrder = { 'order_no': order.order_number, 'prod': order.product_id, 'mode': "ecard" }
      this.apiService.removeDraft(storeOrder).subscribe(res => {
        if (res.response == 'success') {
          this.archivedEcardOrders.splice(i, 1)
          this.nfs.showNotification("default", 'E-Card removed from drafts');
        }
      })
    }
  }


  _orderFilter(mode) {
    if (mode != "") {
      this.selctedOrderFilter = mode
    }
  }

  _isGuestRsvpChecked(mode, i, event) {
    if (mode != "") {
      if (mode == "vcard" || mode == "ecard") {
        let checkedVal = 'no'
        let isChecked = event.target.checked
        if (isChecked) {
          checkedVal = 'yes'
        }
        let order_no = ""
        if (mode == "vcard") {
          order_no = this.vCardOrders[i].order_number
        } else {
          order_no = this.eCardOrders[i].order_number
        }
        this._saveOrderSetting({ 'guest_rsvp': checkedVal, 'mode': mode, 'order_no': order_no });
      }
    }
  }

  _saveOrderSetting(data) {
    this.apiService.postOrderSetting(data).subscribe(res => {
      if (res.response == 'success') {
        this.nfs.showNotification("default", "Order settings updated.");
      } else {
        this.nfs.showNotification("default", "Settings not updated.");
      }
    })
  }


  _whatsappShare(mode: string, card_url: string, type: string) {

    if (type == "ecard") {
      if (mode == "whatsapp") {
        if (!this.isMobile) {
          var sharer = "https://web.whatsapp.com/send?text=" + encodeURI(`https://lekarde.com/share/ecard/${card_url}`);
          window.open(sharer, 'sharer', 'width=300,height=300,menubar=0,toolbar=0');
        } else {
          var sharer = "whatsapp://send?text=" + encodeURI(`${this.productMediaPath}/${card_url}`)
          window.location.href = sharer;
        }
      } else if (mode == "facebook") {
        var sharer = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURI(`https://lekarde.com/share/ecard/${card_url}`);
        window.open(sharer, 'sharer', 'width=1,height=1,menubar=0,toolbar=0');
      }
    } else if (type == "vcard") {
      if (mode == "whatsapp") {
        if (!this.isMobile) {
          var sharer = "https://web.whatsapp.com/send?text=" + encodeURI(`https://lekarde.com/share/vcard/${card_url}`);
          window.open(sharer, 'sharer', 'width=300,height=300,menubar=0,toolbar=0');
        } else {
          var sharer = "whatsapp://send?text=" + encodeURI(`${card_url}`);
          window.location.href = sharer;
        }
      } else if (mode == "facebook") {
        var sharer = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURI(`https://lekarde.com/share/vcard/${card_url}`);
        window.open(sharer, 'sharer', 'width=1,height=1,menubar=0,toolbar=0');
      }
    }
  }

  /* Form Validations and Errors */
  formControlValid(field: string) {
    return !this.editProfileFormGroup.get(field).valid && this.editProfileFormGroup.get(field).touched;
  }

  formControlClass(field: string) {
    if (!this.formControlValid(field)) {
      return { '_noerr': true }
    } else {
      return { '_err': true }
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
  /* Form Validations and Errors */

}
