<div class="_main_container _header_shadow">
  <app-header></app-header>
</div>

<div class="_main_container" *ngIf="isProductsLoading">
  <p class="_loading">
    <span class="loader"></span>
  </p>
</div>

<div class="_ecard_handler">
  <ng-container *ngIf="previewCardData?.length > 0 && !isProductsLoading">
    <div class="_card_grid">
      <div class="_card" id="_card">
        <img [src]="productMediaPath + '/upload/cards/' + cardBackgroundTemplate" class="_card_img">
        <div class="_input_filed">
          <ng-container *ngIf="previewCardData?.length > 0">
            <ng-container *ngFor="let prop of previewCardData; let i = index">
              <ng-container *ngIf="prop.type == 'text'">
                <div class="_form_details _font_color_default" [style]="prop.styles | safeStyle">
                  <p class="_content_editable" [style.color]="prop.color" [innerHtml]="prop.defaultValue"></p>
                </div>
              </ng-container>
              <ng-container *ngIf="prop.type == 'input'">
                <div class="_form_details" [style]="prop.styles | safeStyle">
                  <p class="_content_editable" [style.color]="prop.color" [style.fontSize]="prop.fontSize+'px'"
                    [innerHtml]="prop.value"></p>
                </div>
              </ng-container>
              <ng-container *ngIf="prop.type == 'image'">
                <div class="_form_details _media_preview" [style]="prop.styles | safeStyle">
                  <div class="_show_image">
                    <img class="_image" id="_image_{{prop.id}}" [src]="prop.value" />
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="previewCardData?.length > 0 && !isProductsLoading">
    <div class="_status">
      <div class="_order_status">
        <h1>Order No : {{order_no}}</h1>
        <span class="_badge">Hey! Your order has been placed successfully</span>
        <span class="_badge" style="margin-top: 5px; font-weight: bold;">Click below to download</span>
        <div *ngIf="isDownloadActive" class="_download_ecard">
          <a target='_blank' [href]="generatedCardFinalUrl" download>Download</a>
        </div>
      </div>
    </div>
  </ng-container>

</div>

<div class="_clearfix"></div>
<app-footer></app-footer>