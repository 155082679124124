import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router'
import { ApiService } from "../service/api.service"
import { NotificationService } from "../service/notification.service";
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
interface OrderData {
  event_name: string,
  hosted_by: string,
  created_at: string,
  image_url: string,
  order_number: string
}

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styles: []
})
export class SettingsComponent implements OnInit {
  isProductLoading: boolean = true
  orderNumber: string = ''
  order: OrderData
  productMediaPath: string = ''
  isActivityInvolvesChecked: number
  isActivityMediaChecked: number
  isGuestRsvpChecked: number

  dynamicForm: FormGroup;
  submitted = false;


  constructor(private formBuilder: FormBuilder, private route: ActivatedRoute, private router: Router, private apiService: ApiService, private nfs: NotificationService) {
    this.productMediaPath = environment.PROD_BASEPATH
    this.route.params.subscribe(param => {
      if (param.order_no != '') {
        this.orderNumber = param.order_no
      }
    })

  }

  ngOnInit() {

    this.dynamicForm = this.formBuilder.group({
      numberOfTickets: ['', Validators.required],
      tickets: new FormArray([])
    });

    // this.apiService.getOrderByOrderno({ order_number: this.orderNumber }).subscribe(res => {
    //   if (res.response == 'success') {
    //     this.order = res.data
    //     this._getOrderSettings();
    //   } else if (res.response == 'notexist') {
    //     this.nfs.showNotification("default", "Order detail you'r trying to fetch not exist.");
    //   }
    //   this.isProductLoading = false
    // })
  }


  // convenience getters for easy access to form fields
  get f() { return this.dynamicForm.controls; }
  get t() { return this.f.tickets as FormArray; }

  onChangeTickets(e) {
    const numberOfTickets = e.target.value || 0;
    if (this.t.length < numberOfTickets) {
      for (let i = this.t.length; i < numberOfTickets; i++) {
        this.t.push(this.formBuilder.group({
          name: ['', Validators.required],
          email: ['', [Validators.required, Validators.email]]
        }));
      }
    } else {
      for (let i = this.t.length; i >= numberOfTickets; i--) {
        this.t.removeAt(i);
      }
    }
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.dynamicForm.invalid) {
      return;
    }

    // display form values on success
    alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.dynamicForm.value, null, 4));
  }

  onReset() {
    // reset whole form back to initial state
    this.submitted = false;
    this.dynamicForm.reset();
    //this.t.clear();
  }

  onClear() {
    // clear errors and reset ticket fields
    this.submitted = false;
    this.t.reset();
  }


  // _getOrderSettings() {
  //   this.apiService.getOrderSetting({ order_number: this.orderNumber }).subscribe(res => {
  //     if (res.response == 'success') {
  //       this.isActivityInvolvesChecked = Number(res.data.my_activity)
  //       this.isActivityMediaChecked = Number(res.data.event_activity)
  //       this.isGuestRsvpChecked = Number(res.data.guest_rsvp)
  //     }
  //   })
  // }

  // _isActivityInvolvesChecked(event) {
  //   let checkedVal = 'no'
  //   let isChecked = event.target.checked
  //   if (isChecked) {
  //     checkedVal = 'yes'
  //   }
  //   this._saveOrderSetting({ 'my_activity': checkedVal });
  // }

  // _isActivityMediaChecked(event) {
  //   let checkedVal = 'no'
  //   let isChecked = event.target.checked
  //   if (isChecked) {
  //     checkedVal = 'yes'
  //   }
  //   this._saveOrderSetting({ 'event_activity': checkedVal });
  // }

  // _isGuestRsvpChecked(event) {
  //   let checkedVal = 'no'
  //   let isChecked = event.target.checked
  //   if (isChecked) {
  //     checkedVal = 'yes'
  //   }
  //   this._saveOrderSetting({ 'guest_rsvp': checkedVal });
  // }

  // _saveOrderSetting(data) {
  //   data = { ...data, 'order_no': this.orderNumber }
  //   this.apiService.postOrderSetting(data).subscribe(res => {
  //     if (res.response == 'success') {
  //       this.nfs.showNotification("default", "Order settings updated.");
  //     } else {
  //       this.nfs.showNotification("default", "Settings not updated.");
  //     }
  //   })
  // }

}
