<div class="_main_container _header_shadow" wmAnimate="fadeIn" speed="fast" aos>
  <app-header></app-header>
</div>


<!-- Slideshow -->
<ng-container *ngIf="!isMobile">
  <div class="_main_container _banner_slideshow_" wmAnimate="fadeInDown" aos once>
    <div class="_slideshow">
      <ng-container *ngIf="home_video != ''">
        <video id="_banner_slide_0" class="_slides" autoplay="autoplay" loop>
          <source [src]="home_video" type="video/mp4">
        </video>
      </ng-container>
    </div>
  </div>
</ng-container>




<!-- Virtual Invitation -->
<div class="_main_container">

  <ng-container *ngIf="isVirtualInvitationActive">
    <div class="_home_content">
      <div class="_virtual_invitation">
        <h1>Virtual Invitation</h1>
        <div class="_inner">
          <div class="_boxes">
            <img src="assets/img/live-stream.png" alt="" title="" />
            <span class="_title">Live Stream</span>
          </div>
          <div class="_boxes">
            <img src="assets/img/performance.png" alt="" title="" />
            <span class="_title">Performance</span>
          </div>
          <div class="_boxes">
            <img src="assets/img/discussion.png" alt="" title="" />
            <span class="_title">Discussion</span>
          </div>
          <div class="_boxes">
            <img src="assets/img/youtube.png" alt="" title="" />
            <span class="_title">Youtube Live</span>
          </div>
          <div class="_boxes">
            <img src="assets/img/eclass.png" alt="" title="" />
            <span class="_title">E - Class</span>
          </div>
          <div class="_boxes">
            <img src="assets/img/seminars.png" alt="" title="" />
            <span class="_title">Seminars</span>
          </div>
          <div class="_boxes">
            <img src="assets/img/debates.png" alt="" title="" />
            <span class="_title">Debates</span>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <!---Virtual Invitation-->

  <div class="_home_content">

    <ng-container *ngIf="banner_image1">
      <div class="_birthday_full_width" wmAnimate="fadeInUp" aos speed="fast" once>
        <div class="_birthday_banner">
          <a routerLink="{{bannerImageLink1}}" target="_blank"><img [src]="productMediaPath+banner_image1" lazy></a>
        </div>
      </div>
    </ng-container>

    <div class="_ecard_vcard">
      <ng-container *ngIf="bannerImage2">
        <div class="_card_item" wmAnimate="fadeInRight" aos speed="fast" once>
          <div class="_card">
            <a routerLink="" (click)="_ng_modal_event('ecard')"><img [src]="productMediaPath+bannerImage2" lazy></a>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="bannerImage3">
        <div class="_card_item" wmAnimate="fadeInLeft" aos speed="fast" once>
          <div class="_card">
            <a routerLink="" (click)="_ng_modal_event('vcard')"><img [src]="productMediaPath+bannerImage3" lazy></a>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="_home_content_full_width">
    <div class="_collection_banner">
      <div class="_collection">
        <div class="_inner_collection">
          <h1 class="_collection_heading">Our Latest Collection</h1>
          <ng-container *ngIf="latestCollection?.length > 0">
            <ng-container *ngFor="let product of latestCollection">
              <div class="_collection_item" wmAnimate="fadeInDown" aos speed="fast" once>
                <div class="_card">
                  <a routerLink="/ecard/create/{{product.card_prefix}}/{{product.design_no}}" target="_blank"><img
                      [src]="product.card_image" lazy></a>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div class="_jparallax_main">
    <div class="jarallax">
      <img class="jarallax-img" [src]="parallax_image" alt="">
    </div>
  </div>


  <!-- grid section-->
  <div class="_home_content">
    <h1 class="_categories_heading">Wedding E-Cards</h1>
    <div class="_grid_section _categories">

      <div class="_card" wmAnimate="fadeInDown" aos speed="fast" once>

        <a class="_inner" href="{{bannerImageLink4}}">
          <img class="_main_img" [src]="productMediaPath+bannerImage4" lazy>
        </a>
      </div>
      <div class="_card" wmAnimate="fadeInDown" aos speed="fast" once>
        <a class="_inner" href="{{bannerImageLink5}}">
          <img class="_main_img" [src]="productMediaPath+bannerImage5" lazy>
        </a>
      </div>
      <div class="_card" wmAnimate="fadeInDown" aos speed="fast" once>
        <a class="_inner" href="{{bannerImageLink6}}">
          <img class="_main_img" [src]="productMediaPath+bannerImage6" lazy>
        </a>
      </div>
      <div class="_card" wmAnimate="fadeInDown" aos speed="fast" once>
        <a class="_inner" href="{{bannerImageLink7}}">
          <img class="_main_img" [src]="productMediaPath+bannerImage7" lazy>
        </a>
      </div>
      <div class="_card" wmAnimate="fadeInDown" aos speed="fast" once>
        <a class="_inner" href="{{bannerImageLink8}}">
          <img class="_main_img" [src]="productMediaPath+bannerImage8" lazy>
        </a>
      </div>
      <div class="_card" wmAnimate="fadeInDown" aos speed="fast" once>
        <a class="_inner" href="{{bannerImage9Link}}">
          <img class="_main_img" [src]="productMediaPath+bannerImage9" lazy>
          <!-- <div class="_overlay">
            <div class="_inner_category _vrline">
              <a [href]="bannerImage9Link">V-card</a>
            </div>
            <div class="_inner_category">
              <a [href]="bannerImage9Link_ecard">E-card</a>
            </div>
          </div> -->
        </a>
      </div>
    </div>
  </div>


  <!-- birthday invitation-->
  <ng-container *ngIf="bannerImage10">
    <div class="_birthday_full_width" wmAnimate="fadeInUp" aos once>
      <div class="_birthday_banner">
        <a routerLink="{{bannerImage10Link}}" target="_blank"><img [src]="productMediaPath+bannerImage10" lazy></a>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="bannerImage12">
    <div class="_party_full_width" wmAnimate="fadeInUp" aos once>
      <div class="_party_banner">
        <a routerLink="{{bannerImage12Link}}" target="_blank"><img [src]="productMediaPath+bannerImage12" lazy></a>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="bannerImage12">
    <div class="_party_full_width" wmAnimate="fadeInUp" aos once>
      <div class="_party_banner">
        <a routerLink="{{bannerImage11Link}}" target="_blank"><img [src]="productMediaPath+bannerImage11" lazy></a>
      </div>
    </div>
  </ng-container>
</div>


<div *ngIf="modalActive" class="_ng_modal_backdrop">
  <div class="_ng_modal_inner">
    <div class="_ng_modal_close" (click)="_ng_modal_event()"><img src="assets/img/close_modal.jpg" /></div>
    <ng-container *ngIf="modalActiveMode == 'ecard'">
      <iframe src="https://player.vimeo.com/video/589309599?h=f48cd26a67&title=0&byline=0&portrait=0"
        style="width: 100%; height: 100%;" frameborder="0" allow="autoplay; fullscreen; picture-in-picture"
        allowfullscreen></iframe>
    </ng-container>
    <ng-container *ngIf="modalActiveMode == 'vcard'">
      <iframe src="https://player.vimeo.com/video/589309683?h=c41691a97e&title=0&byline=0&portrait=0"
        style="width: 100%; height: 100%;" frameborder="0" allow="autoplay; fullscreen; picture-in-picture"
        allowfullscreen></iframe>
    </ng-container>
  </div>
</div>

<app-footer></app-footer>