<div class="_main_container" *ngIf="isProductLoading">
  <p class="_loading">
    <span class="loader"></span>
  </p>
</div>

<div class="Static_card" *ngIf="!isProductLoading">
  <div class="_left">
    <a class="_ecard_draft _preview_download" (click)="_download_mockup()" href="javascript:;">Download E-CARD</a>
    <div class="_slide _vertical">
      <!-- Without Upload -->
      <ng-container *ngIf="products?.length > 0 && !isProductLoading">
        <ng-container *ngFor="let product of products;let i = index">
          <div class="_card" id="_card">

            <img [src]="productMediaPath + '/upload/cards/' + product.card_img" class="_card_img">
            <div class="_input_filed">

              <ng-container *ngIf="frontendCustomizatedData?.length > 0">
                <ng-container *ngFor="let prop of frontendCustomizatedData; let i = index">

                  <ng-container *ngIf="prop.type == 'text'">
                    <div class="_form_details _font_color_default" id="_get_prop_{{prop.id}}"
                      [style]="prop.styles | safeStyle">
                      <p class="_text" [innerHtml]="prop.value"></p>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="prop.type == 'input'">
                    <div class="_form_details" id="_get_prop_{{prop.id}}" [style]="prop.styles | safeStyle"
                      [style.font-size.px]="prop.fontSize" [style.width.px]="prop.width"
                      [style.height.px]="prop.height">

                      <div spellcheck="false" class="_content_editable" contenteditable="true" id="_inputs_{{prop.id}}"
                        [innerHTML]="prop.value">
                      </div>
                      <div id="_getTextLength_{{prop.id}}" style="overflow: auto; height: 50px; visibility: hidden;">
                      </div>
                    </div>
                  </ng-container>


                  <ng-container *ngIf="prop.type == 'image'">
                    <div class="_form_details _upload_area" id="_get_prop_{{prop.id}}" [style]="prop.styles | safeStyle"
                      [style.width.px]="prop.width" [style.height.px]="prop.height">
                      <div class="_show_image _upload_placeholder"
                        [ngStyle]="{'background-image': 'url(./assets/img/upload-'+prop.uploadPlaceholder+'-placeholder.png)' }">
                        <img *ngIf="prop.value" class="_image" id="_image_{{prop.id}}" [src]="prop.value" />
                      </div>
                    </div>
                  </ng-container>

                </ng-container>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </ng-container>

    </div>
  </div>
</div>