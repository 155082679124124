import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router"
import { AuthService, GoogleLoginProvider, FacebookLoginProvider } from "angularx-social-login";
import { ApiService } from "../service/api.service"
import { DataService } from '../service/data.service';
import { NotificationService } from "../service/notification.service";


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styles: []
})
export class LoginComponent implements OnInit {
  loginFormGroup: FormGroup
  isLoginFormActive: boolean = true
  forgotFormGroup: FormGroup
  constructor(private authService: AuthService, private apiService: ApiService, private dataService: DataService, private router: Router, private nfs: NotificationService) { }

  ngOnInit() {
    this.signupFormInit();
  }

  signupFormInit() {
    // password: new FormControl('', [Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/)])
    this.loginFormGroup = new FormGroup(
      {
        email: new FormControl('', [Validators.required, Validators.email]),
        password: new FormControl('', [Validators.required])
      }
    )

    this.forgotFormGroup = new FormGroup(
      {
        email: new FormControl('', [Validators.required, Validators.email])
      }
    )
  }

  _login() {
    if (this.loginFormGroup.valid) {
      let postParams = this.loginFormGroup.value
      this.apiService.login(postParams).subscribe(response => {
        if (response.response == 'success') {
          localStorage.setItem('token', response.token);
          localStorage.setItem('user', JSON.stringify(response.data));
          this.dataService.sessionMessage(true);
          this.nfs.showNotification("default", 'Redirecting..');
          this._redirectToPage();
        } else {
          this.nfs.showNotification("default", response.message);
          return false;
        }
      })
    } else {
      this.validateAllFormFields(this.loginFormGroup)
    }
  }

  _forgotPassword() {
    if (this.forgotFormGroup.valid) {
      let postParams = this.forgotFormGroup.value
      this.apiService.postForgotPassword(postParams).subscribe(response => {
        if (response.response == 'success') {
          this.forgotFormGroup.reset();
          this.nfs.showNotification("default", 'Please check you email.');
        } else {
          this.nfs.showNotification("default", response.message);
        }
      })
    } else {
      this.validateAllFormFields(this.forgotFormGroup)
    }
  }


  _redirectToPage() {
    let url: any = localStorage.getItem('url');
    if (url != null && url != 'undefined') {
      this.router.navigate([`${url}`]);
    } else {
      this.router.navigate(['/']);
    }
  }

  /* Form Validations and Errors */
  formControlValid(field: string) {
    return !this.loginFormGroup.get(field).valid && this.loginFormGroup.get(field).touched;
  }

  formControlClass(field: string) {
    if (!this.formControlValid(field)) {
      return { '_noerr': true }
    } else {
      return { '_err': true }
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
  /* Form Validations and Errors */

  /* Social Fb/Google Actions */
  async registerGoogle() {
    await this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then(res => {
      this.apiService.login(res).subscribe(response => {
        if (response.response == 'success') {
          localStorage.setItem('token', response.token);
          localStorage.setItem('user', JSON.stringify(response.data));
          this.dataService.sessionMessage(true);
          this.nfs.showNotification("default", 'Redirecting..');
          this._redirectToPage();
        }
      })
    }).catch(err => console.log('ashutosh'));
  }

  async registerFacebook() {
    await this.authService
      .signIn(FacebookLoginProvider.PROVIDER_ID)
      .then(res => {
        this.apiService.login(res).subscribe(response => {
          if (response.response == 'success') {
            localStorage.setItem('token', response.token);
            localStorage.setItem('user', JSON.stringify(response.data));
            this.dataService.sessionMessage(true);
            this.nfs.showNotification("default", 'Redirecting..');
            this._redirectToPage();
          }
        })
      })
      .catch(err => console.log(err));
  }
  /* Social Fb/Google Actions */
}
