import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms"
import { ActivatedRoute, Router } from '@angular/router'
// import custom validator to validate that password and confirm password fields match
// import { MustMatch } from '../helpers/must.match.helper';
import { ApiService } from "../service/api.service"
import { NotificationService } from "../service/notification.service";
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styles: []
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordFormGroup: FormGroup
  forgot_url: string = ""
  constructor(private route: ActivatedRoute, private nfs: NotificationService, private router: Router, private apiService: ApiService) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params.forgot_url != '') {
        this.forgot_url = params.forgot_url
      }
    })
    this.resetPasswordFormGroup = new FormGroup(
      {
        password: new FormControl('', [Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/)]),
        passwordConfirm: new FormControl('', [Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/)])
      }
    )
  }

  // convenience getter for easy access to form fields
  get f() { return this.resetPasswordFormGroup.controls; }

  _resetPassword() {
    if (this.resetPasswordFormGroup.valid) {
      let _data = { ...this.resetPasswordFormGroup.value, 'forgot_url': this.forgot_url }
      if (_data.password === _data.passwordConfirm) {
        this.apiService.postResetPassword(_data).subscribe(response => {
          if (response.response == 'success') {
            this.router.navigate([`login`]);
            // this.nfs.showNotification("default", 'Redirecting..');
          } else {
            this.nfs.showNotification("default", 'Failed to reset password.');
          }
        })
      } else {
        this.nfs.showNotification("default", 'Confirm Password not matched');
      }
    }
  }

  formControlValid(field: string) {
    return !this.resetPasswordFormGroup.get(field).valid && this.resetPasswordFormGroup.get(field).touched;
  }

}
