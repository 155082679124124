<div class="_main_container _header_shadow">
  <app-header></app-header>
</div>

<div class="_main_container">
  <div class="_social_container">
    <div class="_top" *ngIf="isLoginFormActive">
      <button class="_social_buttons" (click)="registerFacebook()"><i class="fa fa-facebook" aria-hidden="true"></i>
        Login with Facebook</button>
      <button class="_social_buttons" (click)="registerGoogle()"><i class="fa fa-google" aria-hidden="true"></i>
        Login with Google</button>
    </div>

    <div class="_form_parent">
      <ng-container *ngIf="isLoginFormActive">
        <p class="_content">Continue with email:</p>
        <form class="_form_sign custom-forms" novalidate="" [formGroup]="loginFormGroup" (ngSubmit)="_login()">
          <div class="_form_field" [ngClass]="{'_invalid' : formControlValid('email')}">
            <input formControlName="email" id="email" name="email" placeholder="Email" type="email">
          </div>
          <div class="_form_field" [ngClass]="{'_invalid' : formControlValid('password')}">
            <input formControlName="password" id="password" name="password" placeholder="Password" type="password">
            <!-- <span class="_password_error">Must have one Capital letter, Number, Special char and length must be 8 or
              greater</span> -->
          </div>
          <div class="_action">
            <div class="_btn">
              <button type="submit" [disabled]="loginFormGroup.invalid"> Login </button>
            </div>
            <p><a [routerLink]="" (click)="isLoginFormActive=false">Forgot Password?</a></p>
          </div>
        </form>
      </ng-container>

      <ng-container *ngIf="!isLoginFormActive">
        <p class="_content">Forgot Password:</p>
        <form class="_form_sign custom-forms" novalidate="" [formGroup]="forgotFormGroup"
          (ngSubmit)="_forgotPassword()">
          <div class="_form_field" [ngClass]="{'_invalid' : formControlValid('email')}">
            <input formControlName="email" id="email" name="email" placeholder="Email (e.g. ashu@gmail.com)"
              type="email">
          </div>
          <div class="_action">
            <div class="_btn">
              <button type="submit" [disabled]="forgotFormGroup.invalid"> Send </button>
            </div>
            <p><a [routerLink]="" (click)="isLoginFormActive=true">Login</a></p>
          </div>
        </form>
      </ng-container>

      <p class="_register_here">
        <a routerLink="/registration">Click here to Register</a>
      </p>
    </div>
  </div>

</div>


<div class=" _clearfix">
</div>
<app-footer></app-footer>