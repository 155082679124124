import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ApiService = /** @class */ (function () {
    function ApiService(http) {
        this.http = http;
        this.baseUrl = "";
        this.baseUrl1 = environment.API_URL;
        this.postJsonHeader = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            })
        };
    }
    ApiService.prototype.verifyToken = function () {
        return this.http.get(this.baseUrl1 + "me/verify");
    };
    ApiService.prototype.register = function (user) {
        return this.http.post(this.baseUrl1 + "signupAuthorization", JSON.stringify(user), this.postJsonHeader);
    };
    ApiService.prototype.login = function (user) {
        return this.http.post(this.baseUrl1 + "loginAuthorization", JSON.stringify(user), this.postJsonHeader);
    };
    ApiService.prototype.getProfile = function () {
        return this.http.get(this.baseUrl1 + "me", this.postJsonHeader);
    };
    ApiService.prototype.getWishlistCount = function () {
        return this.http.get(this.baseUrl1 + "getWishlistCount", this.postJsonHeader);
    };
    ApiService.prototype.postProfile = function (profile) {
        return this.http.post(this.baseUrl1 + "me/u", JSON.stringify(profile), this.postJsonHeader);
    };
    ApiService.prototype.postProfileImage = function (uploadData) {
        var headers = new HttpHeaders();
        headers.append("Content-Type", "multipart/form-data");
        return this.http.post(this.baseUrl1 + "me/image", uploadData);
    };
    ApiService.prototype.getCategories = function () {
        return this.http.get(this.baseUrl1 + "getCategories");
    };
    ApiService.prototype.getHomePageSetting = function () {
        return this.http.get(this.baseUrl1 + "getHomePageSetting");
    };
    ApiService.prototype.getHomeWeddinglist = function () {
        return this.http.get(this.baseUrl1 + "getHomeWeddinglist");
    };
    ApiService.prototype.getHomeBirthdaylist = function () {
        return this.http.get(this.baseUrl1 + "getHomeBirthdaylist");
    };
    ApiService.prototype.getHomePartylist = function () {
        return this.http.get(this.baseUrl1 + "getHomePartylist");
    };
    ApiService.prototype.getFilterProductList = function () {
        return this.http.get(this.baseUrl1 + "getFilterProductList");
    };
    ApiService.prototype.getProductsByCategory = function (cat) {
        return this.http.post(this.baseUrl1 + "getProductByCategory", JSON.stringify(cat), this.postJsonHeader);
    };
    ApiService.prototype.getProductDetail = function (cat) {
        return this.http.post(this.baseUrl1 + "getProductDetail", JSON.stringify(cat), this.postJsonHeader);
    };
    // postProductDetail(data: any) {
    //   return this.http.post<ProductsCustomized>(`${this.baseUrl1}getCustomize`, JSON.stringify(data), this.postJsonHeader);
    // }
    ApiService.prototype.getCustomizedSlides = function (data) {
        return this.http.post(this.baseUrl1 + "getCustomize", JSON.stringify(data), this.postJsonHeader);
    };
    ApiService.prototype.postCustomizedSlides = function (data) {
        return this.http.post(this.baseUrl1 + "saveCustomize", JSON.stringify(data), this.postJsonHeader);
    };
    ApiService.prototype.getSlidesDataByOrderno = function (data) {
        return this.http.post(this.baseUrl1 + "getSlideData", JSON.stringify(data), this.postJsonHeader);
    };
    ApiService.prototype.getPreview = function (data) {
        return this.http.post(this.baseUrl1 + "getPreviewData", JSON.stringify(data), this.postJsonHeader);
    };
    ApiService.prototype.postSlideImage = function (uploadData) {
        var headers = new HttpHeaders();
        headers.append("Content-Type", "multipart/form-data");
        return this.http.post(this.baseUrl1 + "saveCustomizeImage", uploadData);
    };
    ApiService.prototype.getRsvpDetails = function (data) {
        return this.http.post(this.baseUrl1 + "getRsvpDetails", JSON.stringify(data), this.postJsonHeader);
    };
    ApiService.prototype.postCheckPersonalisedUrl = function (data) {
        return this.http.post(this.baseUrl1 + "verify/customizeUrl", JSON.stringify(data), this.postJsonHeader);
    };
    ApiService.prototype.postCheckout = function (data) {
        return this.http.post(this.baseUrl1 + "postCheckout", JSON.stringify(data), this.postJsonHeader);
    };
    ApiService.prototype.getOrders = function () {
        return this.http.get(this.baseUrl1 + "getOrders", this.postJsonHeader);
    };
    ApiService.prototype.getArchivedOrders = function () {
        return this.http.get(this.baseUrl1 + "getArchivedOrders", this.postJsonHeader);
    };
    ApiService.prototype.getArchivedEcardOrders = function () {
        return this.http.get(this.baseUrl1 + "getArchivedEcardOrders", this.postJsonHeader);
    };
    ApiService.prototype.getOrderByOrderno = function (data) {
        return this.http.post(this.baseUrl1 + "getOrderByOrderno", JSON.stringify(data), this.postJsonHeader);
    };
    ApiService.prototype.getOrderSetting = function (data) {
        return this.http.post(this.baseUrl1 + "getOrderSetting", JSON.stringify(data), this.postJsonHeader);
    };
    ApiService.prototype.postOrderSetting = function (data) {
        return this.http.post(this.baseUrl1 + "postOrderSetting", JSON.stringify(data), this.postJsonHeader);
    };
    ApiService.prototype.getRecentOrders = function () {
        return this.http.get(this.baseUrl1 + "getRecentOrders", this.postJsonHeader);
    };
    ApiService.prototype.getOrderDataByUrl = function (data) {
        return this.http.post(this.baseUrl1 + "getViewData", JSON.stringify(data), this.postJsonHeader);
    };
    ApiService.prototype.postWishlist = function (data) {
        return this.http.post(this.baseUrl1 + "postWishlist", JSON.stringify(data), this.postJsonHeader);
    };
    ApiService.prototype.getWishlist = function () {
        return this.http.get(this.baseUrl1 + "getWishlistHistory", this.postJsonHeader);
    };
    ApiService.prototype.getSearchResult = function (data) {
        return this.http.post(this.baseUrl1 + "getSearch", JSON.stringify(data), this.postJsonHeader);
    };
    ApiService.prototype.getEcards = function (data) {
        return this.http.post(this.baseUrl1 + "getEcards", JSON.stringify(data), this.postJsonHeader);
    };
    ApiService.prototype.getEcardProperties = function (data) {
        return this.http.post(this.baseUrl1 + "getEcardProperties", JSON.stringify(data), this.postJsonHeader);
    };
    ApiService.prototype.postUploadCardImage = function (uploadData) {
        var headers = new HttpHeaders();
        headers.append("Content-Type", "multipart/form-data");
        return this.http.post(this.baseUrl1 + "postCardImage", uploadData);
    };
    ApiService.prototype.postEcard = function (data) {
        return this.http.post(this.baseUrl1 + "postEcard", JSON.stringify(data), this.postJsonHeader);
    };
    ApiService.prototype.postEcardDraft = function (data) {
        return this.http.post(this.baseUrl1 + "ecardDraft", JSON.stringify(data), this.postJsonHeader);
    };
    ApiService.prototype.getEcardDraft = function (data) {
        return this.http.post(this.baseUrl1 + "getEcardDraft", JSON.stringify(data), this.postJsonHeader);
    };
    ApiService.prototype.postEcardHandler = function (data) {
        return this.http.post(this.baseUrl1 + "eCardHandler", JSON.stringify(data), this.postJsonHeader);
    };
    ApiService.prototype.getSocialMediaLinkFooter = function () {
        return this.http.get(this.baseUrl1 + "getSocialMediaLinks", this.postJsonHeader);
    };
    ApiService.prototype.postHelp = function (uploadData) {
        var headers = new HttpHeaders();
        headers.append("Content-Type", "multipart/form-data");
        return this.http.post(this.baseUrl1 + "postHelp", uploadData);
    };
    ApiService.prototype.getOrderNumbers = function () {
        return this.http.get(this.baseUrl1 + "getOrderNumbers", this.postJsonHeader);
    };
    ApiService.prototype.postEcardPreview = function (uploadData) {
        var headers = new HttpHeaders();
        headers.append("Content-Type", "multipart/form-data");
        return this.http.post(this.baseUrl1 + "postEcardPreview", uploadData);
    };
    ApiService.prototype.postEcardWatermarkPreview = function (uploadData) {
        var headers = new HttpHeaders();
        headers.append("Content-Type", "multipart/form-data");
        return this.http.post(this.baseUrl1 + "postEcardWatermarkPreview", uploadData);
    };
    ApiService.prototype.postRsvpResponse = function (data) {
        return this.http.post(this.baseUrl1 + "postRsvpUserData", JSON.stringify(data), this.postJsonHeader);
    };
    ApiService.prototype.postForgotPassword = function (data) {
        return this.http.post(this.baseUrl1 + "postForgotPassword", JSON.stringify(data), this.postJsonHeader);
    };
    ApiService.prototype.postResetPassword = function (data) {
        return this.http.post(this.baseUrl1 + "postResetPassword", JSON.stringify(data), this.postJsonHeader);
    };
    ApiService.prototype.getRsvpLists = function (data) {
        return this.http.post(this.baseUrl1 + "getRsvpLists", JSON.stringify(data), this.postJsonHeader);
    };
    ApiService.prototype.getExportedRsvpList = function (data) {
        return this.http.post(this.baseUrl1 + "exportRsvp", JSON.stringify(data), this.postJsonHeader);
    };
    ApiService.prototype.createRzpOrder = function (data) {
        return this.http.post(this.baseUrl1 + "prePayment", JSON.stringify(data), this.postJsonHeader);
    };
    ApiService.prototype.capturePayment = function (data) {
        return this.http.post(this.baseUrl1 + "postPayment", JSON.stringify(data), this.postJsonHeader);
    };
    // getCoupons() {
    //   return this.http.get<Coupons>(`${this.baseUrl1}getCoupons`, this.postJsonHeader)
    // }
    ApiService.prototype.getCoupon = function (coupon, card_type) {
        if (card_type === void 0) { card_type = null; }
        return this.http.get(this.baseUrl1 + "getCoupon/" + coupon + "/" + card_type, this.postJsonHeader);
    };
    ApiService.prototype.removeDraft = function (data) {
        return this.http.post(this.baseUrl1 + "postRemoveDraft", JSON.stringify(data), this.postJsonHeader);
    };
    ApiService.ngInjectableDef = i0.defineInjectable({ factory: function ApiService_Factory() { return new ApiService(i0.inject(i1.HttpClient)); }, token: ApiService, providedIn: "root" });
    return ApiService;
}());
export { ApiService };
