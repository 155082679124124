import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { DataService } from "../service/data.service"
import { ApiService } from "../service/api.service"
import { Options, LabelType } from 'ng5-slider';
import { environment } from 'src/environments/environment';
import { NotificationService } from "../service/notification.service";
import { DeviceDetectorService } from 'ngx-device-detector';

interface ProductsModel {
  image_url: string,
  price: string
  prod_id: string
  prod_type: string
  product_name: string
  product_url_name: string
  spl_price: string
  wishlist: boolean
  orientation: string
}

interface Category {
  cat_name: string
  cat_url: string
  id: number
  ordering: number
}

@Component({
  selector: 'app-filter-page',
  templateUrl: './filter-page.component.html',
  styles: []
})
export class FilterPageComponent implements OnInit, OnDestroy {
  value: number = 100;
  highValue: number = 500;
  options: Options = {
    floor: 0,
    ceil: 1000,
    step: 100,
    showTicks: true
  };
  productCategory: string;
  productSubCategory: string;
  productCategoryUrl: string
  allProducts: any
  verticalProducts: Array<ProductsModel>
  horizontalProducts: Array<ProductsModel>
  squareProducts: Array<ProductsModel>
  productMediaPath: string = '';

  productCategories: Array<Category>;
  priceRange: [{ max_price: number, min_price: number }]
  minPriceValue: number = 0
  maxPriceValue: number = 0

  // Filters
  filterCategories: any[] = []
  filterCategoryTitle: string = ""
  selectedFilters: any = [
    {
      "mode": "category",
      "category": "",
      "sub_category": []
    },
    {
      "mode": "shape",
      "value": []
    },
    {
      "mode": "sort",
      "value": ""
    }
  ];

  sortFilters = [
    {
      "title": "Instagram",
      "value": "square"
    },
    {
      "title": "Wide",
      "value": "horizontal"
    },
    {
      title: "Long",
      value: "vertical"
    }
  ]
  isProductsLoading: boolean = true
  sortType: string = 'vertical'

  isCategoryFilterOpen: boolean = true
  isTypeFilterOpen: boolean = true

  isMobileFilterOpen: boolean = false;
  mobileFilterButtonText: string = "Filter"
  categoryBanner: string = ""

  mySubscription: any;
  isMobile: boolean = false;
  activeGifProductId: String = ""
  activeGifProductImage: String = ""

  page: number = 1
  pageHorizontal: number = 1
  pageInstagram: number = 1
  loadMoreActive: boolean = true
  loadMoreShow: boolean = true

  loadMoreShowHorizontal: boolean = true
  loadMoreShowInstagram: boolean = true

  constructor(private route: ActivatedRoute, private router: Router, private renderer: Renderer2, private deviceService: DeviceDetectorService, private apiService: ApiService, private nfs: NotificationService) {
    this.productMediaPath = environment.PROD_BASEPATH
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.mySubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Trick the Router into believing it's last link wasn't previously loaded
        this.router.navigated = false;
      }
    });


  }

  ngOnInit() {
    this.isMobile = this.deviceService.isMobile();
    this.route.params.subscribe(param => {
      if (this.router.url != undefined && this.router.url != '') {
        this.productCategory = param.category
        this.productSubCategory = param.sub_category
        this.productCategoryUrl = this.router.url.replace(/^\/|\/$/g, '')

        this.selectedFilters[0].category = this.productCategory
        this.selectedFilters[0].sub_category[0] = this.productSubCategory
        this._getProductsByCategory();
      }
    })
  }

  ngOnDestroy() {
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
  }

  _sortProducts(data) {
    this.apiService.getProductsByCategory({ 'category': this.productCategory, 'sub_category': this.productSubCategory, 'type': "vcard", "filter": data }).subscribe(res => {
      if (res.response == 'success') {

        // Set Defaults
        this.loadMoreShow = true
        this.page = 1
        this.loadMoreShowHorizontal = true
        this.pageHorizontal = 1
        this.loadMoreShowInstagram = true
        this.pageInstagram = 1

        this.isProductsLoading = false
        this.allProducts = res.data
        this.verticalProducts = res.data.vertical
        this.horizontalProducts = res.data.horizontal
        this.squareProducts = res.data.square
      }
    })
  }

  _loadMore(mode = null) {
    this.loadMoreActive = false

    let page = 1;

    if (mode == "vertical") {
      this.page++;
      page = this.page
    } else if (mode == "horizontal") {
      this.pageHorizontal++;
      page = this.pageHorizontal
    } else if (mode == "instagram") {
      this.pageInstagram++;
      page = this.pageInstagram
    }

    let filterParams = { 'category': this.productCategory, 'sub_category': this.productSubCategory, 'type': "vcard", 'page': page, mode }
    if (this.selectedFilters != undefined) {
      Object.assign(filterParams, { filter: this.selectedFilters })
    }
    this.apiService.getProductsByCategory(filterParams).subscribe(res => {
      if (res.response == 'success') {
        this.isProductsLoading = false
        this.loadMoreActive = true


        let allProducts = res.data
        for (let d of allProducts) {
          this.allProducts.push(d)
        }

        let verticalProducts = res.data.vertical
        if (verticalProducts != undefined) {
          this.loadMoreShow = res.data.load_more
          for (let d of verticalProducts) {
            this.verticalProducts.push(d)
          }
        }

        let horizontalProducts = res.data.horizontal
        if (horizontalProducts != undefined) {
          this.loadMoreShowHorizontal = res.data.load_more
          for (let d of horizontalProducts) {
            this.horizontalProducts.push(d)
          }
        }

        let squareProducts = res.data.square
        if (squareProducts != undefined) {
          this.loadMoreShowInstagram = res.data.load_more
          for (let d of squareProducts) {
            this.squareProducts.push(d)
          }
        }

      }
    })
  }

  _getProductsByCategory() {
    this.apiService.getProductsByCategory({ 'category': this.productCategory, 'sub_category': this.productSubCategory, 'type': "vcard" }).subscribe(res => {
      if (res.response == 'success') {
        this.isProductsLoading = false
        this.verticalProducts = res.data.vertical
        this.horizontalProducts = res.data.horizontal
        this.squareProducts = res.data.square
        this.filterCategoryTitle = res.data.categories.category
        this.filterCategories = res.data.categories.subCategories
        this.categoryBanner = res.data.categories.category_banner
      }
    })
  }

  _filterProducts(i, event, mode) {
    let category_prefix = event.target.value
    if (mode == "category") {
      let isCategoryExist = this.selectedFilters[0].sub_category.includes(category_prefix)
      if (!isCategoryExist) {
        this.selectedFilters[0].sub_category.push(category_prefix);
      } else {
        let categoryIndex = this.selectedFilters[0].sub_category.indexOf(category_prefix);
        this.selectedFilters[0].sub_category.splice(categoryIndex, 1)
      }
    } else if (mode == "shape") {
      let isCategoryExist = this.selectedFilters[1].value.includes(category_prefix)
      if (!isCategoryExist) {
        this.selectedFilters[1].value.push(category_prefix);
      } else {
        let categoryIndex = this.selectedFilters[1].value.indexOf(category_prefix);
        this.selectedFilters[1].value.splice(categoryIndex, 1)
      }
    } else if (mode == "sort") {
      let category_prefix = event.target.value
      this.selectedFilters[2].value = category_prefix
    }
    this._sortProducts(this.selectedFilters);
  }

  _expandFilter(el: any) {
    var element: any = el.target.nextSibling
    let isClosed = element.classList.contains("_closed");
    if (isClosed) {
      el.target.getElementsByClassName("fa-minus")[0].style.display = "flex"
      el.target.getElementsByClassName("fa-plus")[0].style.display = "none"
      element.classList.remove("_closed");
    } else {
      el.target.getElementsByClassName("fa-minus")[0].style.display = "none"
      el.target.getElementsByClassName("fa-plus")[0].style.display = "flex"
      element.classList.add("_closed");
    }
  }

  _addWishlist(product, i, mode) {
    if (product) {
      this.apiService.postWishlist({ 'prod_url': product.product_url_name, 'type': "vcard" }).subscribe(data => {
        if (data.response == 'success') {
          let status = data.data
          if (mode == 'verticalProducts') {
            this.verticalProducts[i].wishlist = status
          } else if (mode == 'horizontalProducts') {
            this.horizontalProducts[i].wishlist = status
          } else if (mode == 'squareProducts') {
            this.squareProducts[i].wishlist = status
          }
        } else if (data.response == "login") {
          this.nfs.showNotification("default", 'Please Login.');
          this.router.navigate([`/login`]);
        }
      })
    }
  }

  _setCategoryIdOnLoad() {
    let row = this.productCategories.filter(c => {
      return c.cat_url == this.productCategory
    })
    return row[0].id;
  }

  _changeSrcArrtibute(mode: string, event, product) {
    if (product) {
      if (this.isMobile) {
        if (this.activeGifProductId == "") {
          this.activeGifProductId = product.prod_id
          this.activeGifProductImage = this.productMediaPath + product.image_url
        } else {
          if (this.activeGifProductId != product.prod_id) {
            this.renderer.setAttribute(document.getElementById(`_v_element_${this.activeGifProductId}`), 'src', `${this.activeGifProductImage}`)
          }
          this.activeGifProductId = product.prod_id
          this.activeGifProductImage = this.productMediaPath + product.image_url
        }
      }

      if (mode == 'in') {
        if (product.gif_img != '') {
          event.target.src = `${environment.GIF_PATH}${product.gif_img}`
        }
      } else {
        event.target.src = `${environment.PROD_BASEPATH}${product.image_url}`
      }
    }
  }

  _openMobileFilters() {
    let _sort: any = document.getElementsByClassName('_sort')[0];
    let _filter_category: any = document.getElementsByClassName('_filter_category')[0];

    if (!this.isMobileFilterOpen) {
      this.isMobileFilterOpen = true
      _sort.style.display = 'block'
      _filter_category.style.display = 'block'
      this.mobileFilterButtonText = "Close"
    } else {
      this.isMobileFilterOpen = false
      _sort.style.display = 'none'
      _filter_category.style.display = 'none'
      this.mobileFilterButtonText = "Filter"
    }


  }

}
