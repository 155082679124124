<form [formGroup]="dynamicForm" (ngSubmit)="onSubmit()">
  <div class="card m-3">
    <h5 class="card-header">Angular 8 Dynamic Reactive Forms Example</h5>
    <div class="card-body">
      <div class="form-row">
        <div class="form-group">
          <label>Number of Tickets</label>
          <select formControlName="numberOfTickets" class="form-control" (change)="onChangeTickets($event)"
            [ngClass]="{ 'is-invalid': submitted && f.numberOfTickets.errors }">
            <option value=""></option>
            <option *ngFor="let i of [1,2,3,4,5,6,7,8,9,10]">{{i}}</option>
          </select>
          <div *ngIf="submitted && f.numberOfTickets.errors" class="invalid-feedback">
            <div *ngIf="f.numberOfTickets.errors.required">Number of tickets is required</div>
          </div>
        </div>
      </div>
    </div>
    <div *ngFor="let ticket of t.controls; let i = index" class="list-group list-group-flush">
      <div class="list-group-item">
        <h5 class="card-title">Ticket {{i + 1}}</h5>
        <div [formGroup]="ticket" class="form-row">
          <div class="form-group col-6">
            <label>Name</label>
            <input type="text" formControlName="name" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && ticket.controls.name.errors }" />
            <div *ngIf="submitted && ticket.controls.name.errors" class="invalid-feedback">
              <div *ngIf="ticket.controls.name.errors.required">Name is required</div>
            </div>
          </div>
          <div class="form-group col-6">
            <label>Email</label>
            <input type="text" formControlName="email" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && ticket.controls.email.errors }" />
            <div *ngIf="submitted && ticket.controls.email.errors" class="invalid-feedback">
              <div *ngIf="ticket.controls.email.errors.required">Email is required</div>
              <div *ngIf="ticket.controls.email.errors.email">Email must be a valid email address</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer text-center">
      <button class="btn btn-primary mr-1">Buy Tickets</button>
      <button class="btn btn-secondary mr-1" type="reset" (click)="onReset()">Reset</button>
      <button class="btn btn-secondary" type="button" (click)="onClear()">Clear</button>
    </div>
  </div>
</form>

<!-- <div class="_main_container _header_shadow">
  <app-header></app-header>
</div>

<div class="_main_container" *ngIf="isProductLoading">
  <p class="_loading">
    <span class="loader"></span>
    <span class="_text">Loading..</span>
  </p>
</div>

<div class="_main_container _setting" *ngIf="!isProductLoading">
  <div class="_setting_event">
    <div class="_left">
      <div class="_card">
        <img [src]="productMediaPath + order.image_url">
      </div>
    </div>
    <div class="_right">
      <div class="_inner">
        <h2 class="_heading">Event setting</h2>
        <div class="_field _checkbox">
          <div class="_checkbox_input">
            <input type="checkbox" id="rsvp_checkbox_link" [checked]="isActivityInvolvesChecked"
              (change)="_isActivityInvolvesChecked($event)" />
            <label for="rsvp_checkbox_link"></label>
          </div>
          <span>Notify me of activity that involves me</span>
        </div>
        <div class="_field _checkbox">
          <div class="_checkbox_input">
            <input type="checkbox" id="rsvp_checkbox_link1" [checked]="isActivityMediaChecked"
              (change)="_isActivityMediaChecked($event)" />
            <label for="rsvp_checkbox_link1"></label>
          </div>
          <span>Notify me of event activity (photos, comments, and more)</span>
        </div>
        <div class="_field _checkbox">
          <div class="_checkbox_input">
            <input type="checkbox" id="rsvp_checkbox_link2" [checked]="isGuestRsvpChecked"
              (change)="_isGuestRsvpChecked($event)" />
            <label for="rsvp_checkbox_link2"></label>
          </div>
          <span>Notify me when guests RSVP</span>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="_clearfix"></div>
<app-footer></app-footer> -->