import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from '@angular/router';
// import custom validator to validate that password and confirm password fields match
// import { MustMatch } from '../helpers/must.match.helper';
import { ApiService } from "../service/api.service";
import { NotificationService } from "../service/notification.service";
var ResetPasswordComponent = /** @class */ (function () {
    function ResetPasswordComponent(route, nfs, router, apiService) {
        this.route = route;
        this.nfs = nfs;
        this.router = router;
        this.apiService = apiService;
        this.forgot_url = "";
    }
    ResetPasswordComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.params.subscribe(function (params) {
            if (params.forgot_url != '') {
                _this.forgot_url = params.forgot_url;
            }
        });
        this.resetPasswordFormGroup = new FormGroup({
            password: new FormControl('', [Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/)]),
            passwordConfirm: new FormControl('', [Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/)])
        });
    };
    Object.defineProperty(ResetPasswordComponent.prototype, "f", {
        // convenience getter for easy access to form fields
        get: function () { return this.resetPasswordFormGroup.controls; },
        enumerable: true,
        configurable: true
    });
    ResetPasswordComponent.prototype._resetPassword = function () {
        var _this = this;
        if (this.resetPasswordFormGroup.valid) {
            var _data = tslib_1.__assign({}, this.resetPasswordFormGroup.value, { 'forgot_url': this.forgot_url });
            if (_data.password === _data.passwordConfirm) {
                this.apiService.postResetPassword(_data).subscribe(function (response) {
                    if (response.response == 'success') {
                        _this.router.navigate(["login"]);
                        // this.nfs.showNotification("default", 'Redirecting..');
                    }
                    else {
                        _this.nfs.showNotification("default", 'Failed to reset password.');
                    }
                });
            }
            else {
                this.nfs.showNotification("default", 'Confirm Password not matched');
            }
        }
    };
    ResetPasswordComponent.prototype.formControlValid = function (field) {
        return !this.resetPasswordFormGroup.get(field).valid && this.resetPasswordFormGroup.get(field).touched;
    };
    return ResetPasswordComponent;
}());
export { ResetPasswordComponent };
