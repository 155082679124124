<div class="_main_container _header_shadow">
  <app-header></app-header>
</div>

<div class="_main_container">
  <ng-container *ngIf="categoryBanner">
    <div class="_filter_banner">
      <img [src]="categoryBanner" alt="{{filterCategoryTitle}}" title="{{filterCategoryTitle}}" lazy />
    </div>
  </ng-container>


  <div class="_filter_page_bg">
    <div class="_inner_filter">
      <div class="_filter_left">
        <div class="_inner_card">
          <h1 class="_heading">Filters</h1>


          <div class="_card_design _filter_category">
            <p class="_inr_sub_title" (click)="_expandFilter($event)">Card Shape
              <i class="fa fa-minus" aria-hidden="true"></i>
              <i class="fa fa-plus" aria-hidden="true"></i>
            </p>
            <div class="_filter_area">
              <ng-container *ngFor="let category of sortFilters; let i = index">
                <div class="_field _checkbox">
                  <span [innerHTML]="category.title"></span>
                  <div class="_checkbox_input">
                    <input type="checkbox" id="shape_checkbox_{{i}}" (click)="_filterProducts(i,$event,'shape')"
                      [value]="category.value" />
                    <label for="shape_checkbox_{{i}}"></label>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>

      <div class="_filter_right">
        <button class="_mobile_sort_open" (click)="_openMobileFilters()" [innerHTML]="mobileFilterButtonText"></button>
        <div class="_heading">
          <!-- <div class="_left" [innerHtml]="filterCategoryTitle"></div> -->
          <div class="_sort">
            <label>Sort by: </label>
            <select name="_sortByType" (change)="_filterProducts(0,$event,'sort')">
              <option value="" selected>Choose</option>
              <option value="popular">Most Popular</option>
              <option value="low">Price (Low)</option>
              <option value="high">Price (High)</option>
            </select>
          </div>
        </div>
        <!--Products-->
        <p class="_loading" *ngIf="isProductsLoading">
          <span class="loader"></span>
        </p>
        <ng-container *ngIf="verticalProducts?.length > 0 && !isProductsLoading">
          <div class="_products">
            <ng-container *ngFor="let product of verticalProducts;let i = index">
              <div class="_grid_{{product.orientation}}">
                <div class="_inner">
                  <a routerLink="/vcards/customize/{{productCategory}}/{{product.product_url_name}}"
                    class="_vertical_flex">
                    <img [src]="productMediaPath + product.image_url"
                      (mouseover)="_changeSrcArrtibute('in',$event,product)"
                      (mouseout)="_changeSrcArrtibute('out',$event,product)"
                      (touchstart)="_changeSrcArrtibute('in',$event,product)" class="_v_product_image"
                      id="_v_element_{{product.prod_id}}">
                  </a>
                  <button class="_add_wishlist" [ngClass]="{'_active' : product.wishlist }"
                    (click)="_addWishlist(product,i,'verticalProducts')">
                    <i class="fa fa-heart"></i>
                  </button>
                </div>
                <span class="_price">
                  <span class="_live" *ngIf="product.spl_price != product.price">
                    <i class="fa fa-inr" aria-hidden="true"></i> {{product.spl_price}}
                  </span>
                  <span
                    [ngClass]="{'_original' : product.spl_price != product.price, '_live' : product.spl_price == product.price}"
                    *ngIf="product.price">
                    <i class="fa fa-inr" aria-hidden="true"></i> {{product.price}}
                  </span>
                </span>
              </div>
            </ng-container>
          </div>
          <ng-container *ngIf="loadMoreShow">
            <div class="_load_more_container">
              <button type="button" class="_load_more" (click)="_loadMore('vertical')">
                <ng-container *ngIf="loadMoreActive else loadMoreGif">
                  Load More
                </ng-container>
                <ng-template #loadMoreGif>
                  Loading &nbsp;&nbsp;<img src="assets/img/load_more.gif" width="20" />
                </ng-template>
              </button>
            </div>
          </ng-container>
        </ng-container>


        <ng-container *ngIf="horizontalProducts?.length > 0 && !isProductsLoading">
          <div class="_products">
            <ng-container *ngFor="let product of horizontalProducts;let i = index">
              <div class="_grid_{{product.orientation}}">
                <div class="_inner">
                  <a routerLink="/vcards/customize/{{productCategory}}/{{product.product_url_name}}"
                    class="_horizontal_flex">
                    <img [src]="productMediaPath + product.image_url"
                      (mouseover)="_changeSrcArrtibute('in',$event,product)"
                      (mouseout)="_changeSrcArrtibute('out',$event,product)"
                      (touchstart)="_changeSrcArrtibute('in',$event,product)" class="_v_product_image"
                      id="_v_element_{{product.prod_id}}">
                  </a>

                  <button class="_add_wishlist" [ngClass]="{'_active' : product.wishlist }"
                    (click)="_addWishlist(product,i,'horizontalProducts')">
                    <i class="fa fa-heart"></i>
                  </button>
                </div>
                <span class="_price">
                  <span class="_live" *ngIf="product.spl_price">
                    <i class="fa fa-inr" aria-hidden="true"></i> {{product.spl_price}}
                  </span>
                  <span class="_original" *ngIf="product.price">
                    <i class="fa fa-inr" aria-hidden="true"></i> {{product.price}}
                  </span>
                </span>
              </div>
            </ng-container>
          </div>
          <ng-container *ngIf="loadMoreShowHorizontal">
            <div class="_load_more_container">
              <button type="button" class="_load_more" (click)="_loadMore('horizontal')">
                <ng-container *ngIf="loadMoreActive else loadMoreGif">
                  Load More
                </ng-container>
                <ng-template #loadMoreGif>
                  Loading &nbsp;&nbsp;<img src="assets/img/load_more.gif" width="20" />
                </ng-template>
              </button>
            </div>
          </ng-container>
        </ng-container>


        <ng-container *ngIf="squareProducts?.length > 0 && !isProductsLoading">
          <div class="_products">
            <ng-container *ngFor="let product of squareProducts;let i = index">
              <div class="_grid_{{product.orientation}}">
                <div class="_inner">
                  <a routerLink="/vcards/customize/{{productCategory}}/{{product.product_url_name}}"
                    class="_insta_flex">
                    <img [src]="productMediaPath + product.image_url"
                      (mouseover)="_changeSrcArrtibute('in',$event,product)"
                      (mouseout)="_changeSrcArrtibute('out',$event,product)"
                      (touchstart)="_changeSrcArrtibute('in',$event,product)" class="_v_product_image"
                      id="_v_element_{{product.prod_id}}">
                  </a>


                  <button class="_add_wishlist" [ngClass]="{'_active' : product.wishlist }"
                    (click)="_addWishlist(product,i,'squareProducts')">
                    <i class="fa fa-heart"></i>
                  </button>
                </div>
                <span class="_price">
                  <span class="_live" *ngIf="product.spl_price">
                    <i class="fa fa-inr" aria-hidden="true"></i> {{product.spl_price}}
                  </span>
                  <span class="_original" *ngIf="product.price">
                    <i class="fa fa-inr" aria-hidden="true"></i> {{product.price}}
                  </span>
                </span>
              </div>
            </ng-container>
          </div>
          <ng-container *ngIf="loadMoreShowInstagram">
            <div class="_load_more_container">
              <button type="button" class="_load_more" (click)="_loadMore('instagram')">
                <ng-container *ngIf="loadMoreActive else loadMoreGif">
                  Load More
                </ng-container>
                <ng-template #loadMoreGif>
                  Loading &nbsp;&nbsp;<img src="assets/img/load_more.gif" width="20" />
                </ng-template>
              </button>
            </div>
          </ng-container>
        </ng-container>


      </div>



    </div>
  </div>
</div>

<div class="_clearfix"></div>
<app-footer></app-footer>