import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { DataService } from "../service/data.service"
import { ApiService } from "../service/api.service"
import { Options, LabelType } from 'ng5-slider';
import { environment } from 'src/environments/environment';
import { NotificationService } from "../service/notification.service";
interface ProductsModel {
  image_url: string,
  price: string
  prod_id: string
  prod_type: string
  product_name: string
  product_url_name: string
  spl_price: string
  wishlist: boolean
  orientation: string
}

interface Category {
  cat_name: string
  cat_url: string
  id: number
  ordering: number
}

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styles: []
})
export class SearchComponent implements OnInit {
  value: number = 100;
  highValue: number = 500;
  options: Options = {
    floor: 0,
    ceil: 1000,
    step: 100,
    showTicks: true
  };
  productCategory: string;
  verticalProducts: Array<ProductsModel>
  horizontalProducts: Array<ProductsModel>
  squareProducts: Array<ProductsModel>
  productMediaPath: string = '';

  // Filters
  isProductsLoading: boolean = true
  sortType: string = 'vertical'
  constructor(private route: ActivatedRoute, private router: Router, private dataService: DataService, private apiService: ApiService, private nfs: NotificationService) {
    this.productMediaPath = environment.PROD_BASEPATH
  }

  ngOnInit() {
    this.verticalProducts = []
    this.horizontalProducts = []
    this.squareProducts = []
    this.route.params.subscribe(param => {
      this.productCategory = param.category
      this.getSearchInvites();
    })
  }


  getSearchInvites() {
    this.apiService.getSearchResult({ 'term': this.productCategory }).subscribe(res => {
      if (res.response == 'success') {
        this.verticalProducts = res.data.vertical
        this.horizontalProducts = res.data.horizontal
        this.squareProducts = res.data.square
      } else {
        this.verticalProducts = []
        this.horizontalProducts = []
        this.squareProducts = []
      }
      this.isProductsLoading = false
    })
  }

  _sortProducts() {
    if (this.sortType && this.sortType != '') {
      this.apiService.getSearchResult({ 'term': this.productCategory, 'sort_type': this.sortType }).subscribe(res => {
        if (res.response == 'success') {
          this.isProductsLoading = false
          this.verticalProducts = res.data.vertical
          this.horizontalProducts = res.data.horizontal
          this.squareProducts = res.data.square
        }
      })
    }
  }

  _addWishlist(product, i, mode) {
    if (product) {
      console.log(i, mode);
      this.apiService.postWishlist({ 'prod_url': product.product_url_name }).subscribe(data => {
        if (data.response == 'success') {
          let status = data.data
          if (mode == 'verticalProducts') {
            this.verticalProducts[i].wishlist = status
          } else if (mode == 'horizontalProducts') {
            this.horizontalProducts[i].wishlist = status
          } else if (mode == 'squareProducts') {
            this.squareProducts[i].wishlist = status
          }
          if (status) {
            this.nfs.showNotification("default", 'Wishlisted');
          } else {
            this.nfs.showNotification("default", 'Removed from Wishlist');
          }
        }
      })
    }
  }


}

