import { Router } from '@angular/router';
import { AuthSessionParamsService } from './auth-session-params.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./auth-session-params.service";
var AuthBeforeSessionGuardService = /** @class */ (function () {
    function AuthBeforeSessionGuardService(router, authSession) {
        this.router = router;
        this.authSession = authSession;
    }
    AuthBeforeSessionGuardService.prototype.canActivate = function () {
        var isAuthenticated = this.authSession.isUserParamsAuthenticated();
        if (isAuthenticated) {
            this.router.navigate(['customize']);
            return false;
        }
        else {
            return true;
        }
    };
    AuthBeforeSessionGuardService.ngInjectableDef = i0.defineInjectable({ factory: function AuthBeforeSessionGuardService_Factory() { return new AuthBeforeSessionGuardService(i0.inject(i1.Router), i0.inject(i2.AuthSessionParamsService)); }, token: AuthBeforeSessionGuardService, providedIn: "root" });
    return AuthBeforeSessionGuardService;
}());
export { AuthBeforeSessionGuardService };
