import { OnInit, OnDestroy, EventEmitter, ElementRef, Renderer2 } from '@angular/core';
import { startWith, map, takeWhile, delay, distinctUntilChanged } from 'rxjs/operators';
import { coerceBooleanProperty, coerceNumberProperty } from '@angular/cdk/coercion';
import { Subject } from 'rxjs';
import { AnimateService } from './animate.service';
var AnimateComponent = /** @class */ (function () {
    function AnimateComponent(elm, scroll, renderer) {
        this.elm = elm;
        this.scroll = scroll;
        this.renderer = renderer;
        this.replay$ = new Subject();
        // Animating properties
        this.animating = false;
        this.animated = false;
        this.disabled = false;
        /** Emits at the end of the animation */
        this.start = new EventEmitter();
        /** Emits at the end of the animation */
        this.done = new EventEmitter();
        this.paused = false;
        this.threshold = 0;
        this.once = false;
    }
    Object.defineProperty(AnimateComponent.prototype, "idle", {
        get: function () { return { value: "idle-" + this.animate }; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AnimateComponent.prototype, "play", {
        get: function () {
            var params = {};
            // Builds the params object, so, leaving to the default values when undefined
            if (!!this.timing) {
                params['timing'] = this.timing;
            }
            if (!!this.delay) {
                params['delay'] = this.delay;
            }
            return { value: this.animate, params: params };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AnimateComponent.prototype, "speed", {
        /** Speeds up or slows down the animation */
        set: function (speed) {
            // Turns the requested speed into a valid timing
            this.timing = {
                slower: '3s',
                slow: '2s',
                normal: '1s',
                fast: '500ms',
                faster: '300ms'
            }[speed || 'normal'] || '1s';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AnimateComponent.prototype, "postpone", {
        /** Delays the animation */
        set: function (delay) {
            // Coerces the input into a number first
            var value = coerceNumberProperty(delay, 0);
            if (value) {
                // Turns a valid number into a ms delay
                this.delay = value + "ms";
            }
            else {
                // Test the string for a valid delay combination
                this.delay = /^\d+(?:ms|s)$/.test(delay) ? delay : '';
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AnimateComponent.prototype, "disableAnimation", {
        /** Disables the animation */
        set: function (value) { this.disabled = coerceBooleanProperty(value); },
        enumerable: true,
        configurable: true
    });
    AnimateComponent.prototype.animationStart = function () { this.animating = true; this.animated = false; this.start.emit(); };
    AnimateComponent.prototype.animationDone = function () {
        this.animating = false;
        this.animated = true;
        this.done.emit();
        /**
         * Removes spurious 'animation' style from the element once done with the animation.
         * This behaviour has been observed when running on iOS devices where for some reason
         * the animation engine do not properly clean-up the animation style using cubic-bezier()
         * as its timing function. The issue do not appear with ease-in/out and others.
         * */
        this.renderer.removeStyle(this.elm.nativeElement, 'animation');
    };
    Object.defineProperty(AnimateComponent.prototype, "pauseAnimation", {
        /** When true, keeps the animation idle until the next replay triggers */
        set: function (value) { this.paused = coerceBooleanProperty(value); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AnimateComponent.prototype, "enableAOS", {
        /** When defined, triggers the animation on element scrolling in the viewport by the specified amount. Amount defaults to 50% when not specified */
        set: function (value) { this.threshold = coerceNumberProperty(value, 0.5); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AnimateComponent.prototype, "aosOnce", {
        /** When true, triggers the animation on element scrolling in the viewport */
        set: function (value) { this.once = coerceBooleanProperty(value); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AnimateComponent.prototype, "replay", {
        /** Replays the animation */
        set: function (replay) {
            // Re-triggers the animation again on request (skipping the very fist value)
            if (!!this.trigger && coerceBooleanProperty(replay)) {
                this.trigger = this.idle;
                this.replay$.next(true);
            }
        },
        enumerable: true,
        configurable: true
    });
    AnimateComponent.prototype.ngOnInit = function () {
        var _this = this;
        // Triggers the animation based on the input flags
        this.sub = this.replay$.pipe(
        // Waits the next round to re-trigger
        delay(0), 
        // Triggers immediately when not paused
        startWith(!this.paused), 
        // Builds the AOS observable from the common service
        this.scroll.trigger(this.elm, this.threshold), 
        // Stop taking the first on trigger when aosOnce is set
        takeWhile(function (trigger) { return !trigger || !_this.once; }, true), 
        // takeWhile(trigger => !trigger || !this.once),
        // Maps the trigger into animation states
        map(function (trigger) { return trigger ? _this.play : _this.idle; }), 
        // Always start with idle
        startWith(this.idle), 
        // Eliminates multiple triggers
        distinctUntilChanged()).subscribe(function (trigger) { return _this.trigger = trigger; });
    };
    // Disposes of the observable
    AnimateComponent.prototype.ngOnDestroy = function () { this.sub.unsubscribe(); };
    return AnimateComponent;
}());
export { AnimateComponent };
